import React, { useState, useEffect, useContext } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import Text from "./Text";
import { getDateFullTime } from "../../utility/common";
import { CiLock } from "react-icons/ci";
import { UserContext } from "../../context/Users";
import { readnotice } from "../../utility/askfirebase";

const Container = styled.div``;

const QuestionLine = styled.div`
  display: flex;
  flex-direction: row;

  padding-bottom: 5px;
  border-bottom: 1px solid #ededed;
  height: 50px;
  align-items: center;
`;
const QuestionItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
  width:100%;
`;
const QuestionButtonLayer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
  width :15%;
`;

const AnswerLine = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ededed;
  padding: 20px 10px;
  margin-top: 5px;
  min-height: 300px;
  align-items: flex-start;
`;

const Unread = styled.div`
    background: #fd6a0df2;
    border-radius: 5px;
    padding: 5px 10px;
    color: #fff;
    margin-left: 10px;
    display: flex;
    font-size: 12px;
    flex-direction: column;
`
const Question = ({ containerStyle, items, index, notice, html }) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [check, setCheck] = useState(false);

  const { user, dispatch2 } = useContext(UserContext);

  function IsContainNoticeid(items) {
    console.log("IsContainNoticeid", items, user.USERID);

    if (items.READ == undefined) {
      return false;
    }

    const FindIndex = items.READ.findIndex((x) => x == user.USERID);

    if (FindIndex != -1) {
      console.log("읽음");

      return true;
    } else {
      console.log("읽지 않음");
      return false;
    }
  }


  function IsMyAskid(item) {

    console.log("IsMyAskid", item);
  
    if (item.USERID != user.USERID) {
  
      return false;
    } else {

      return true;
    }
  }
  const _handleNoticeCheck = async(items) => {
    setCheck(!check);

    if (!IsContainNoticeid(items)) {
      
      const NOTICE_ID = items.NOTICE_ID;

      let READ = [];
      if (items.READ != undefined) {
        READ = items.READ;
      }

      console.log("READ", READ);
  
      const USERID = user.USERID;
      const updateRead = await readnotice({ NOTICE_ID, READ, USERID });

      items.READ.push(user.USERID);
    }

    setRefresh((refresh) => refresh + 1);
  };


  const _handleCheck = () => {
    setCheck(!check);

    setRefresh((refresh) => refresh + 1);
  };

  useEffect(() => {
    async function fetchData() {}
    fetchData();
  }, []);

  useEffect(() => {
    setCheck(check);
  }, [refresh]);



  return (
    <Container style={containerStyle}>
      {notice == true ? (
        <QuestionLine>
          <QuestionItem>
            <div style={{ marginLeft: 5, display: "flex", width: "100%" }}>
              <div style={{ width: "10%" }}>{index + 1}</div>

              <div style={{ width: "90%" }}>
                {items.NOTICE.slice(0, 50)}
                {items.NOTICE.length > 50 ? "..." : null}
              </div>
              {IsContainNoticeid(items) == false && (
                <div className="unreadblink">{"읽지않음"}</div>
              )}
            </div>
          </QuestionItem>

          <QuestionButtonLayer>
            <div style={{ marginRight: 20 }}>
              <Text value={getDateFullTime(items.REGISTDATE)} size={14} />
            </div>
            {check == false && (
              <SlArrowDown
                onClick={() => {
                  _handleNoticeCheck(items);
                }}
              />
            )}
            {check == true && (
              <SlArrowUp
                onClick={() => {
                  _handleNoticeCheck(items);
                }}
              />
            )}
          </QuestionButtonLayer>
        </QuestionLine>
      ) : (
        <QuestionLine>
          <QuestionItem>
            <div style={{ marginLeft: 5, display: "flex", width: "100%" }}>
              <div style={{ width: "10%" }}>{index + 1}</div>
              <div style={{ width: "10%" }}>{items.CONTENTTYPE}</div>
              <div
                style={{ width: "40%", display: "flex", flexDirection: "row" }}
              >
                {items.SECRET == true ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "200px",
                    }}
                  >
                    <CiLock />
                    <div>비밀글입니다</div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "130px",
                    }}
                  >
                    <div>공개글입니다</div>
                  </div>
                )}

                <div
                  style={{
                    marginLeft: 5,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {items.SECRET == true ? (
                    <span>{"******************************************"}</span>
                  ) : (
                    <span>
                      {items.CONTENT.slice(0, 15)}
                      {items.CONTENT.length > 15 ? "..." : null}
                    </span>
                  )}
                </div>
              </div>
              <div style={{ width: "10%" }}>
                {IsMyAskid(items) == true && (
                  <div className="myblink">{"내가쓴글"}</div>
                )}
                {items.CEONAME}
              </div>
              <div style={{ width: "10%" }}>
                {items.ANSWER != "" && items.ANSWER != undefined ? (
                  <div style={{ marginLeft: 20 }}>{"[답변글 있음]"}</div>
                ) : (
                  <div style={{ marginLeft: 20 }}>{"[답변글 없음]"}</div>
                )}
              </div>
              <div style={{ width: "20%", display:"flex", flexDirection:"row", justifyContent:"center" }}>
                {getDateFullTime(items.REGISTDATE)}
                {items.SECRET == false || IsMyAskid(items) == true ? (
                  <div style={{paddingLeft:20}}>
                    {check == false && <SlArrowDown onClick={_handleCheck} />}
                    {check == true && <SlArrowUp onClick={_handleCheck} />}
                  </div>
                ) : (
                  <div style={{ width: 15, paddingLeft:20 }}></div>
                )}
              </div>
            </div>
          </QuestionItem>

          {/* <QuestionButtonLayer>
            <div style={{ marginRight: 20 }}>
              <Text value={getDateFullTime(items.REGISTDATE)} size={14} />
            </div>
            {items.SECRET == false || IsMyAskid(items) == true ? (
              <>
                {check == false && <SlArrowDown onClick={_handleCheck} />}
                {check == true && <SlArrowUp onClick={_handleCheck} />}
              </>
            ) : (
              <div style={{ width: 15 }}></div>
            )}
          </QuestionButtonLayer> */}
        </QuestionLine>
      )}

      {check == true && (
        <AnswerLine>
          {notice == false && (
            <div style={{ marginBottom: 20 }}>작성자 : {items.CEONAME}</div>
          )}

          {html == true ? (
            <div
              style={{ padding: "5px 0px", backgroundColor: "#fff" }}
              dangerouslySetInnerHTML={{ __html: items.CONTENT }}
            ></div>
          ) : (
            <>
              <textarea
                value={items.CONTENT}
                size={14}
                disabled
                color={"#999"}
                style={{
                  width: "100%",
                  outline: 0,
                  resize: "none",
                  border: "none",
                  height: 300,
                }}
              />
              {items.ANSWER && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    답변글
                  </div>
                  <textarea
                    value={items.ANSWER}
                    size={14}
                    disabled
                    color={"#999"}
                    style={{
                      width: "100%",
                      outline: 0,
                      resize: "none",
                      border: "none",
                      height: 300,
                      marginTop: 10,
                    }}
                  />
                </>
              )}
            </>
          )}
        </AnswerLine>
      )}
    </Container>
  );
};

export default Question;
