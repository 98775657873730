import React, { useContext, useEffect, useState } from "react";
import { imageDB } from "../components/imageDB";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

import "../components/css/common.css";

import useDimensions from "react-use-dimensions";
import Button from "../components/Layout/Button";
import { COLORS } from "../components/Theme";
import Advertise from "./component/Advertise";
import Label from "./component/Label";
import Preview from "./component/Categoryview";
import Noticeview from "./component/Noticeview";
import Center from "./component/Center";
import Best from "./component/Best";
import Pick from "./component/Pick";
import News from "./component/News";
import Search from "../components/Layout/SearchBasket";
import MainItem from "./component/MainItem";
import ProductItem from "./component/ProductItem";
import OrderItem from "./component/Selectorder";
import OrderInfo from "./component/OrderInfo";
import ReceiveInfo from "./component/ReceiveInfo";
import PayInfo from "./component/PayInfo";
import {
  deleteBasket,
  getBaskset,
  getBasksetbyBASKET_ID,
  updateBasket,
} from "../utility/basketfirebase";
import { UserContext } from "../context/Users";
import { getproductbyid } from "../utility/productfirebase";
import Loading from "./component/Loading";
import BasketItem from "./component/BasketItem";
import SelectOrder from "./component/Selectorder";
import Fade from "react-reveal/Fade";
import OrderItem2 from "./component/Orderitem2";
import { BiSolidRectangle } from "react-icons/bi";
import Address from "./component/Address";
import { MaxPC, SmallPC } from "../components/Responsive";
import Approve from "./component/Approve";
import CardModule from "./component/CardModule";
import { commaescapevalue, useSleep } from "../utility/common";
import { registOrder, registOrderTmp } from "../utility/orderfirebase";
import { getUser } from "../utility/userfirebase";
import { loadTossPayments } from '@tosspayments/payment-sdk';

const NoProduct = styled.div`
  font-size: 25px;
  font-weight: 900;
  margin-bottom: 500px;
`;

const ProductLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: #f8f8f8;
  overflow-x: auto;
`;

const TopShowButton = styled.div`
  height: 70px;
  width: 70px;
  background-color: #222;
  position: fixed;
  bottom: 30px;
  right: 100px;
  border-radius: 100px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const MainLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  margin: 20px 0px;
`;

const Ordercontainer = ({ orderlist, basket }) => {
  const navigate = useNavigate();
  const { dispatch, user } = useContext(UserContext);
  const [width, setWidth] = useState(100);
  const [ref, setRef] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [topButton, setTopButton] = useState(false);

  const [productprice, setProductprice] = useState(0);
  const [productdeliveryfee, setProductdeliveryfee] = useState(0);
  const [productislanddelveryfee, setProductislanddeliveryfee] = useState(0);
  const [productjejudeliveryfee, setProductjejudelvieryfee] = useState(0);
  const [productcost, setProductcost] = useState(0);

  const [cardcheck, setCardcheck] = useState(false);
  const [bankcheck, setBankcheck] = useState(false);
  const [bankuser, setBankuser] = useState("");
  const [bankname, setBankname] = useState("");
  const [banknum, setBanknum] = useState("");
  const [cardname, setCardname] = useState("");
  const [cardnum, setCardnum] = useState("");
  const [cardtype, setCardtype] = useState("");

  console.log("orderlist", orderlist);

  const getFetchProduct = (PRODUCT_ID) => {
    return new Promise((resolve, reject) => {
      console.log("PRODUCT_ID", PRODUCT_ID);
      const productinfo = getproductbyid({ PRODUCT_ID });

      resolve(productinfo);
    });
  };

  //최초 구동시
  useEffect(() => {
    //mount 시에
    console.log("init");
    window.scrollTo(0, 0);

    return () => {
      //unmount 시에
      console.log("dispose");
    };
  }, []);

  //초기데이타
  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 300) {
        setTopButton(true);
      } else {
        setTopButton(false);
      }
    };

    window.addEventListener("scroll", handleShowButton);

    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  useEffect(() => {
    setLoading(true);

    async function FetchData() {
      let productdeliveryfeeTmp = 0;
      let productislanddelveryfeeTmp = 0;
      let productjejudeliveryfeeTmp = 0;

      let productpriceTmp = 0;
      orderlist.map((item, index) => {
        productpriceTmp += item.productprice;
        console.log("data", item);

        const PRODUCT_ID = item.productid;

        getFetchProduct(PRODUCT_ID).then((result) => {
          console.log("getFetchProduct", result);
          let data = result.PRODUCTDELIVERYFEE;
          if (
            parseInt(commaescapevalue(data)) >
            parseInt(commaescapevalue(productdeliveryfeeTmp))
          ) {
            productdeliveryfeeTmp = data;
          }

          data = result.PRODUCTISLANDDELIVERYFEE;

          if (
            parseInt(commaescapevalue(data)) >
            parseInt(commaescapevalue(productislanddelveryfeeTmp))
          ) {
            productislanddelveryfeeTmp = data;
          }

          data = result.PRODUCTJEJUDELIVERYFEE;

          if (
            parseInt(commaescapevalue(data)) >
            parseInt(commaescapevalue(productjejudeliveryfeeTmp))
          ) {
            productjejudeliveryfeeTmp = data;
          }
        });
      });

      await useSleep(1000);

      setProductprice(productpriceTmp);

      setProductdeliveryfee(productdeliveryfeeTmp);
      setProductjejudelvieryfee(productjejudeliveryfeeTmp);
      setProductislanddeliveryfee(productislanddelveryfeeTmp);

      setRefresh((refresh) => refresh + 1);
      setLoading(false);
    }

    FetchData();
  }, []);

  const TopMove = () => {
    window.scrollTo(0, 0);
  };

  // 실제 결재가 이루어져서 DB에 저장되는곳 중요
  const cardcallback = async (card_user, card_type, card_num) => {
    setCardstatus(!cardstatus);

    if (card_user != "") {
      const USER_ID = user.USER_ID;
      const BANK_USER = bankuser;
      const BANK_NAME = bankname;
      const BANK_NUM = banknum;
      const CARD_NAME = card_user;
      const CARD_NUM = card_num;
      const CARD_TYPE = card_type;
      const MONEY =
        parseInt(commaescapevalue(productprice)) +
        parseInt(commaescapevalue(productdeliveryfee));

      const ORDERLIST = orderlist;
      const ORDERUSER = orderuser;
      const ORDERRECEIVEUSER = receiveuser;
      const ORDERTEL = ordertel;
      const RECEIVETEL = receivetel;
      const RECEIVEADDRESS1 = receiveaddress1;
      const RECEIVEADDRESS2 = receiveaddress2;
      const DELIVERYMESSAGE = receivedeliverymsg;
      const STORENAME = storename;
      const BRANDNAME = brandname;

      const ORDERNUM = Date.now() - 1711654178996;

      console.log("orderlist", ORDERLIST);

      const ORDER_TYPE = "결제완료";
      const ORDER_MASSORDER = '건별주문';

      const registorder_ = await registOrder({
        ORDER_MASSORDER,
        USER_ID,
        BANK_USER,
        BANK_NAME,
        BANK_NUM,
        CARD_NAME,
        CARD_NUM,
        CARD_TYPE,
        MONEY,
        ORDERLIST,
        ORDERUSER,
        ORDERRECEIVEUSER,
        DELIVERYMESSAGE,
        ORDERTEL,
        RECEIVETEL,
        RECEIVEADDRESS1,
        RECEIVEADDRESS2,
        STORENAME,
        BRANDNAME,
        ORDERNUM,
        ORDER_TYPE,
      });

      alert("주문이 완료 되었습니다");

      if (basket) {
        orderlist.map(async (data, index) => {
          const BASKET_ID = data.BASKET_ID;
          const deletebasket = await deleteBasket({ BASKET_ID });
        });
      }

      navigate("/myorder");
    }
  };
  // 실제 결재가 이루어져서 DB에 저장되는곳 중요
  const _handlePurchase = async () => {

    const ORDERNUM = Date.now() - 1711654178996;
    const MONEY =
      parseInt(commaescapevalue(productprice)) +
      parseInt(commaescapevalue(productdeliveryfee));

    if (bankcheck == true) {
      if (bankname == "" || bankuser == "") {
        alert("계좌 정보가 올바르지 않습니다");
        return;
      }

      const USER_ID = user.USER_ID;
      const BANK_USER = bankuser;
      const BANK_NAME = bankname;
      const BANK_NUM = banknum;
      const CARD_NAME = cardname;
      const CARD_NUM = cardnum;
      const CARD_TYPE = cardtype;

      const ORDERLIST = orderlist;
      const ORDERUSER = orderuser;
      const ORDERRECEIVEUSER = receiveuser;
      const ORDERTEL = ordertel;
      const RECEIVETEL = receivetel;
      const RECEIVEADDRESS1 = receiveaddress1;
      const RECEIVEADDRESS2 = receiveaddress2;
      const RECEIVEDELIVERYMSG = receivedeliverymsg;
      const RECEIVEZONECODE = receivezonecode;
      const STORENAME = storename;
      const BRANDNAME = brandname;


      console.log("orderlist", ORDERLIST);
      const ORDER_TYPE = "주문접수";
      const ORDER_MASSORDER = '건별주문';

      const registorder_ = await registOrder({
        ORDER_MASSORDER,
        USER_ID,
        BANK_USER,
        BANK_NAME,
        BANK_NUM,
        CARD_NAME,
        CARD_NUM,
        CARD_TYPE,
        MONEY,
        ORDERLIST,
        ORDERUSER,
        ORDERRECEIVEUSER,
        ORDERTEL,
        RECEIVETEL,
        RECEIVEADDRESS1,
        RECEIVEADDRESS2,
        RECEIVEDELIVERYMSG,
        RECEIVEZONECODE,
        STORENAME,
        BRANDNAME,
        ORDERNUM,
        ORDER_TYPE,
      });

      alert("주문이 완료 되었습니다");

      if (basket) {
        orderlist.map(async (data, index) => {
          const BASKET_ID = data.BASKET_ID;
          const deletebasket = await deleteBasket({ BASKET_ID });
        });
      }

      navigate("/myorder");
    } else {

      // 주문 내용을 주문 Tmp폴더에 기록해둔다 나중에 성공적으로 주문되면 정상 주문 정보로 이동 하고
      // 주문에 실패 하면 삭제 하도록 한다
        const USER_ID = user.USER_ID;
        const BANK_USER = "";
        const BANK_NAME = "";
        const BANK_NUM = "";
        const CARD_NAME = "TOS결재";
        const CARD_NUM = "TOS결재";
        const CARD_TYPE = "TOS결재";
        const MONEY =
          parseInt(commaescapevalue(productprice)) +
          parseInt(commaescapevalue(productdeliveryfee));

        const ORDERLIST = orderlist;
        const ORDERUSER = orderuser;
        const ORDERRECEIVEUSER = receiveuser;
        const ORDERTEL = ordertel;
        const RECEIVETEL = receivetel;
        const RECEIVEADDRESS1 = receiveaddress1;
        const RECEIVEADDRESS2 = receiveaddress2;
        const RECEIVEDELIVERYMSG = receivedeliverymsg;
        const RECEIVEZONECODE = receivezonecode;
        const STORENAME = storename;
        const BRANDNAME = brandname;
    
      const ORDER_MASSORDER = '건별주문';


        console.log("orderlist", ORDERLIST);

        const ORDER_TYPE = "결제진행중";

        const registorder_ = await registOrderTmp({
          USER_ID,
          ORDER_MASSORDER,
          BANK_USER,
          BANK_NAME,
          BANK_NUM,
          CARD_NAME,
          CARD_NUM,
          CARD_TYPE,
          MONEY,
          ORDERLIST,
          ORDERUSER,
          ORDERRECEIVEUSER,
          ORDERTEL,
          RECEIVETEL,
          RECEIVEADDRESS1,
          RECEIVEADDRESS2,
          RECEIVEDELIVERYMSG,
          RECEIVEZONECODE,
          STORENAME,
          BRANDNAME,
          ORDERNUM,
          ORDER_TYPE,
        });

      if (basket) {

        // 카드 결제 시도시에 주문에대한  항목을 장바구니에 표시한다(주문이 성공하면 장바구니에서 
        // 해당 주문 번호에 대한 항목을 삭제하고
        // 주문이 실패 하면 넣어두엇던 오더 아이디를 다시 삭제 해준다

        orderlist.map(async (data, index) => {
          const BASKET_ID = data.BASKET_ID;
          const ORDER_TMP_ID = ORDERNUM;
          const deletebasket = await updateBasket({ BASKET_ID, ORDER_TMP_ID });
        });
      }

 

      const random = new Date().getTime() + Math.random()
      const randomId = btoa(random)

      const clientKey = "live_ck_Gv6LjeKD8aPgaa1O5EPxrwYxAdXy";

      loadTossPayments(clientKey).then(tossPayments => {
        // 카드 결제 메서드 실행
        tossPayments.requestPayment(`간편결제`, {
          amount: MONEY, // 가격
          orderId: ORDERNUM, // 주문 id
          orderName: orderlist[0].productname + "외" + orderlist.length + "건", // 결제 이름
          customerName: storename, // 판매자, 판매처 이름
          successUrl: 'http://murrayb2b.com/success?orderId=' + ORDERNUM, // 성공시 리다이렉트 주소
          failUrl: 'http://murrayb2b.com/failed', // 실패시 리다이렉트 주소

        })
      })

    }
  };

  const [cardstatus, setCardstatus] = useState(false);

  const approvecallback = (
    cardcheck,
    bankcheck,
    bankuser,
    bankname,
    banknum
  ) => {
    setCardcheck(cardcheck);
    setBankcheck(bankcheck);
    setBankuser(bankuser);
    setBankname(bankname);
    setBanknum(banknum);

    console.log("approvecallback", cardcheck, bankcheck, bankuser, bankname);
  };

  const [orderuser, setOrderuser] = useState("");
  const [receiveuser, setReceiveuser] = useState("");
  const [ordertel, setOrdertel] = useState("");
  const [receivetel, setReceivetel] = useState("");
  const [receiveaddress1, setReceiveaddress1] = useState("");
  const [receiveaddress2, setReceiveaddress2] = useState("");
  const [receivedeliverymsg, setReceivedeliverymsg] = useState("");
  const [receivezonecode, setReceivezonecode] = useState("");
  const [storename, setStorename] = useState("");
  const [brandname, setBrandname] = useState("");

  const addresscallback = (
    orderuser,
    receiveuser,
    ordertel,
    receivetel,
    receiveaddress1,
    receiveaddress2,
    receivedeliverymsg,
    receivezonecode,
    storename,
    brandname
  ) => {

    console.log("receiveaddress2", receiveaddress2);
    setOrderuser(orderuser);
    setReceiveuser(receiveuser);
    setOrdertel(ordertel);
    setReceivetel(receivetel);
    setReceiveaddress1(receiveaddress1);
    setReceiveaddress2(receiveaddress2);
    setReceivedeliverymsg(receivedeliverymsg);
    setReceivezonecode(receivezonecode);
    setStorename(storename);
    setBrandname(brandname);
    setRefresh((refresh) => refresh + 1);
  };

  useEffect(() => {
    setProductdeliveryfee(productdeliveryfee);
    setProductjejudelvieryfee(productjejudeliveryfee);
    setProductislanddeliveryfee(productislanddelveryfee);
    setOrderuser(orderuser);
    setReceiveuser(receiveuser);
    setOrdertel(ordertel);
    setReceivetel(receivetel);
    setReceiveaddress1(receiveaddress1);
    setReceiveaddress2(receiveaddress2);
    setReceivedeliverymsg(receivedeliverymsg);
    setReceivezonecode(receivezonecode);
    setStorename(storename);
    setBrandname(brandname);
  }, [refresh]);

  useEffect(() => {
    const USERID = user.USERID;
    async function FetchData() {
      const userdata = await getUser({ USERID });

      setOrderuser("");
      setOrdertel("");
      setStorename(userdata.STORENAME);
      setReceiveuser("");
      setReceivetel("");
      await useSleep(100);
    }

    FetchData();
  }, []);

  return (
    <>
      {loading == false ? (
        <>
          {cardstatus == true ? (
            <CardModule
              callback={cardcallback}
              money={
                parseInt(commaescapevalue(productprice)) +
                parseInt(commaescapevalue(productdeliveryfee))
              }
              orderuser={orderuser}
            ></CardModule>
          ) : null}

          <SmallPC>
            <div className="SmallItemContainer">
              <>
                {orderlist.length > 0 ? (
                  <div className="Ordercontainer">
                    <MainLabel>
                      <BiSolidRectangle size={20} />
                      <div style={{ marginLeft: 10 }}>주문서로 가져온 상품</div>
                    </MainLabel>

                    <ProductLayout className="box">
                      {orderlist.map((item, index) => (
                        <OrderItem2 itemdata={item}></OrderItem2>
                      ))}
                    </ProductLayout>

                    <MainLabel>
                      <BiSolidRectangle size={20} />
                      <div style={{ marginLeft: 10 }}>배송선택및 정보입력</div>
                    </MainLabel>

                    <Address callback={addresscallback}></Address>

                    <MainLabel
                      style={{
                        borderBottom: "2px solid #000",
                        paddingBottom: 20,
                        margin: "20px 0px 0px 0px",
                      }}
                    >
                      <BiSolidRectangle size={20} />
                      <div style={{ marginLeft: 10 }}>결제완료</div>
                    </MainLabel>

                    <Approve
                      productprice={productprice}
                      productdeliveryfee={productdeliveryfee}
                      productjejudeliveryfee={productjejudeliveryfee}
                      productislanddelveryfee={productislanddelveryfee}
                      callback={approvecallback}
                    ></Approve>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "30px",
                      }}
                    >
                      <Button
                        Label={"결제하기"}
                        bgcolor={"#000"}
                        width={"30%"}
                        borderRadius={10}
                        color={"#fff"}
                        bordercolor={"#000"}
                        height={50}
                        callback={_handlePurchase}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <img
                      src={imageDB.mainlogo}
                      style={{ paddingTop: 100, height: 100 }}
                    />
                    <NoProduct>주문 항목이 존재 하지 않습니다</NoProduct>
                  </>
                )}
              </>
            </div>
          </SmallPC>

          <MaxPC>
            <div className="MaxItemContainer">
              <>
                {orderlist.length > 0 ? (
                  <div className="Ordercontainer">
                    <MainLabel>
                      <BiSolidRectangle size={20} />
                      <div style={{ marginLeft: 10 }}>주문서로 가져온 상품</div>
                    </MainLabel>

                    <ProductLayout className="box">
                      {orderlist.map((item, index) => (
                        <OrderItem2 itemdata={item}></OrderItem2>
                      ))}
                    </ProductLayout>

                    <MainLabel>
                      <BiSolidRectangle size={20} />
                      <div style={{ marginLeft: 10 }}>배송선택및 정보입력</div>
                    </MainLabel>

                    <Address callback={addresscallback}></Address>

                    <MainLabel
                      style={{
                        borderBottom: "2px solid #000",
                        paddingBottom: 20,
                        margin: "20px 0px 0px 0px",
                      }}
                    >
                      <BiSolidRectangle size={20} />
                      <div style={{ marginLeft: 10 }}>결제완료</div>
                    </MainLabel>

                    <Approve
                      productprice={productprice}
                      productdeliveryfee={productdeliveryfee}
                      productjejudeliveryfee={productjejudeliveryfee}
                      productislanddelveryfee={productislanddelveryfee}
                      callback={approvecallback}
                    ></Approve>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "30px",
                      }}
                    >
                      <Button
                        Label={"결제하기"}
                        bgcolor={"#000"}
                        width={"30%"}
                        borderRadius={10}
                        color={"#fff"}
                        bordercolor={"#000"}
                        height={50}
                        callback={_handlePurchase}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <img
                      src={imageDB.mainlogo}
                      style={{ paddingTop: 100, height: 100 }}
                    />
                    <NoProduct>주문 항목이 존재 하지 않습니다</NoProduct>
                  </>
                )}
              </>
            </div>
          </MaxPC>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Ordercontainer;
