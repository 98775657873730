import React, { useContext, useEffect } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/PageEx";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/Users";
import { CgFileDocument } from "react-icons/cg";

const ControlView = () => {
  const navigate = useNavigate();

  const { user, dispatch } = useContext(UserContext);

  const _handlebasket = () => {
    if (user.USERID == "") {
      alert("로그인이 필요한 메뉴입니다");
      navigate("/memberlogin");
      return;
    }

    navigate("/basket");
  };
  const _handleHeart = () => {
    if (user.USERID == "") {
      alert("로그인이 필요한 메뉴입니다");
      navigate("/memberlogin");
      return;
    }
    navigate("/heartproduct");
  };

  const _handleMyorder = () => {
    if (user.USERID == "") {
      alert("로그인이 필요한 메뉴입니다");
      navigate("/memberlogin");
      return;
    }
    navigate("/myorder");
  };

  const _handleNotice = () => {
    navigate("/notice");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        width: "90%",
        marginTop: 20,
      }}
    >
      <div
        onClick={_handleNotice}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="button"
      >
        <div style={{ display: "flex" }}>
          <img src={imageDB.bell} width={20} />
        </div>
        <div style={{ display: "flex", fontSize: 12, marginTop: 5 }}>
          공지사항
        </div>
      </div>
      <div style={{ display: "flex", borderLeft: "1px solid #DADADA" }}></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={_handleHeart}
        className="button"
      >
        <div style={{ display: "flex" }}>
          <img src={imageDB.heart} width={20} />
        </div>
        <div style={{ display: "flex", fontSize: 12, marginTop: 5 }}>
          찜리스트
        </div>
      </div>
      <div style={{ display: "flex", borderLeft: "1px solid #DADADA" }}></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={_handlebasket}
        className="button"
      >
        <div style={{ display: "flex" }}>
          <img src={imageDB.basket} width={20} />
        </div>
        <div style={{ display: "flex", fontSize: 12, marginTop: 5 }}>
          장바구니
        </div>
      </div>

      <div style={{ display: "flex", borderLeft: "1px solid #DADADA" }}></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={_handleMyorder}
        className="button"
      >
        <div style={{ display: "flex" }}>
          <CgFileDocument size={20} />
        </div>
        <div style={{ display: "flex", fontSize: 12, marginTop: 5 }}>
          주문조회
        </div>
      </div>
    </div>
  );
};

export default ControlView;
