import React, { useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";

const Container = styled.div``;
const ButtonLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justifycontent: center;
  padding-left: 10px;
`;
const CardButton = styled.div`
  height: 25px;
  width: 60px;
  background-color: ${({ enable }) => (enable == true ? "#ededed" : "#fff")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: ${({ enable }) =>
    enable == true ? "1px solid #000;" : "1px solid #ededed;"};
  margin: 2px 5px;
  font-size: 12px;
`;

const CardType = ({ name, color, weight, callback }) => {
  const [card, setCard] = useState("");
  const [refresh, setRefresh] = useState(1);

  const cardSelect = (type) => {
    setCard(type);
    setRefresh((refresh) => refresh + 1);
  };
  useEffect(() => {
    setCard(card);
    callback(card);
  }, [refresh]);

  return (
    <Container>
      <ButtonLayout>
        <CardButton
          onClick={() => {
            cardSelect("비씨카드");
          }}
          enable={"비씨카드" == card}
        >
          비씨카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("국민카드");
          }}
          enable={"국민카드" == card}
        >
          국민카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("삼성카드");
          }}
          enable={"삼성카드" == card}
        >
          삼성카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("신한카드");
          }}
          enable={"신한카드" == card}
        >
          신한카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("롯데카드");
          }}
          enable={"롯데카드" == card}
        >
          롯데카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("우리카드");
          }}
          enable={"우리카드" == card}
        >
          우리카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("농협카드");
          }}
          enable={"농협카드" == card}
        >
          농협카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("제주카드");
          }}
          enable={"제주카드" == card}
        >
          제주카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("광주카드");
          }}
          enable={"광주카드" == card}
        >
          광주카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("전북카드");
          }}
          enable={"전북카드" == card}
        >
          전북카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("하나카드");
          }}
          enable={"하나카드" == card}
        >
          하나카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("해외카드");
          }}
          enable={"해외카드" == card}
        >
          해외카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("씨티카드");
          }}
          enable={"씨티카드" == card}
        >
          씨티카드
        </CardButton>
        <CardButton
          onClick={() => {
            cardSelect("은련카드");
          }}
          enable={"은련카드" == card}
        >
          은련카드
        </CardButton>
      </ButtonLayout>
    </Container>
  );
};

CardType.defaultProps = {};

export default CardType;
