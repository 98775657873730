import {db, auth, storage} from '../api/config';
import { collection, getDocs, query, updateDoc,where,doc,setDoc, deleteDoc, orderBy } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";



export const registHeart = async({
    USER_ID, 
    PRODUCT_ID,
}) =>{
    let success = false;

    const Ref = doc(collection(db, "Heart"));
    const id = Ref.id;

    try{
      
        const newData = {
            HEART_ID : id,
            USER_ID, 
            PRODUCT_ID, 
            REGISTDATE : Date.now(),
        }

        await setDoc(Ref, newData);
    }catch(e){
   
        success =false;
        return null;

    }finally{
        return id;
    }

}

export const getHeart = async(USER_ID)=>{
    const Ref = collection(db, "Heart");

    console.log("userid", USER_ID);

    let Item = [];
    let success = false;
    const q = query(Ref, where("USER_ID",'==',USER_ID));

    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {   
            Item.push(doc.data());
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(Item);
            }else{
                resolve(-1);  
            }          
        }) 
    }
}


export const deleteHeart = async(HEART_ID)=>{

    const heartRef = doc(db, "Heart", HEART_ID);
    try{
        await deleteDoc(heartRef);
    }catch(e){
        console.log("error", e.message);
    }finally{
        return;
    }
}