import React, { useEffect } from "react";
export const Define = {
  allcategory: "전체카테고리",
  best: "BEST 100",
  new: "신규출시",
  md: "MD",
  gomargin: "GO!마진",
  top: "TOP10",
  soldout: "임시품절",
  notice: "게시판",
  home: "home",
  heart: "찜한상품",
  notice: "공지사항",
  search: "search",
};

export const OrderType = {
  order1: "주문접수",
  order2: "결제완료",
  order3: "배송준비",
  order4: "배송중",
  order5: "배송완료",
  order6: "반품",
  order7: "교환",
  order8: "주문취소",
  order9: "반품신청",
  order10: "교환신청"
};
export const ProductNameTag = "[머레이B2B] ";
