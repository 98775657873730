import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import { CommaFormatted, commaescapevalue } from "../../utility/common";

const Container = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding : 0px 20px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid #ededed;
`;

const ItemTitle = styled.div`
  display: flex;
  font-size: 16px;
  width: 30%;
`;
const ItemContent = styled.div`
  display: flex;
  width: 60%;
  margin-left: 20px;
`;

const SwitchInput = styled.input`
  background-color: ${({ disabled }) =>
    disabled == true ? "#ededed" : "#fff"};
  width: 100%;
  padding-left: unset;
  border: 1px solid #c5c5c5;
  border-radius: unset;
  padding: 10px;
`;

const Approve2 = ({
  productprice,

  callback,
}) => {


  const [refresh, setRefresh] = useState(1);

  const [bankuser, setBankuser] = useState("");
  const onbankuserChange = (e) => {
    setBankuser(e.target.value);
    setRefresh((refresh) => refresh + 1);
  };

  const [bankname, setBankname] = useState("");
  const onbanknameChange = (e) => {
    setBankname(e.target.value);
    setRefresh((refresh) => refresh + 1);
  };

  const [banknum, setBanknum] = useState("");
  const onbanknumChange = (e) => {
    setBanknum(e.target.value);
    setRefresh((refresh) => refresh + 1);
  };

  const [producttotalprice, setProducttotalprice] = useState(0);

  useEffect(() => {

  }, []);

  useEffect(() => {

    setBankuser(bankuser);
    setBankname(bankname);
    setBanknum(banknum);

    callback( bankuser, bankname, banknum);
  }, [refresh]);

  return (
    <Container>
      <div style={{ padding: 10, width: "100%" }}>
        <Item>
          <ItemTitle>상품합계금액</ItemTitle>
          <ItemContent style={{ color: "#ff0000" }}>
            <div>{CommaFormatted(productprice)}원</div>
          </ItemContent>
        </Item>
      </div>
      <div style={{ padding: 20, width: "100%" }}>
        <Item style={{ border: "none" }}>
          <ItemTitle>입금자명</ItemTitle>
          <ItemContent>
            <SwitchInput
            
              type="text"
              value={bankuser}
              onChange={onbankuserChange}
            ></SwitchInput>
          </ItemContent>
        </Item>

        <Item style={{ border: "none" }}>
          <ItemTitle>입금은행</ItemTitle>
          <ItemContent>
            <SwitchInput

              type="text"
              value={bankname}
              onChange={onbanknameChange}
            ></SwitchInput>
          </ItemContent>
        </Item>

        <Item style={{ border: "none" }}>
          <ItemTitle>입금계좌번호</ItemTitle>
          <ItemContent>
            <SwitchInput
              type="text"
              value={banknum}
              onChange={onbanknumChange}
            ></SwitchInput>
          </ItemContent>
        </Item>
      </div>
    </Container>
  );
};

Approve2.defaultProps = {
  color: "#000",
  weight: 600,
};

export default Approve2;
