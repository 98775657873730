import React from 'react';
import Homecontainer from '../container/Homecontainer';
import Layout from '../components/Layout/Layout';
import Productcontainer from '../container/Productcontainer';
import ProductLayout from '../components/Layout/ProductLayout';
import { useLocation } from 'react-router-dom';
import ProductImagecontainer from '../container/ProductImagecontainer';

const ProductImagepage = () => {
  const {state} = useLocation();	// 2번 라인
  const {PRODUCT_IMGS} = state;	// 3번 라인
  console.log("PRODUCT_IMGS", PRODUCT_IMGS);

  return (
    <Layout>
      <ProductImagecontainer PRODUCT_IMGS={PRODUCT_IMGS}/>
    </Layout>

  );
}

export default ProductImagepage;
