import moment from "moment";

export const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return regex.test(email);
};

export const removeWhitespace = (text) => {
  const regex = /\s/g;
  return text.replace(regex, "");
};

export const getDateOrTime = (ts) => {
  const now = moment().startOf("day");
  const target = moment(ts).startOf("day");
  return moment(ts).format(now.diff(target, "days") > 0 ? "MM/DD" : "HH:mm");
};
export const getDateFullTime = (ts) => {
  const now = moment().startOf("day");
  const target = moment(ts).startOf("day");
  return moment(ts).format("YYYY.MM.DD HH:mm");
};

export const getDateTime = (ts) => {
  const now = moment().startOf("day");
  const target = moment(ts).startOf("day");
  return moment(ts).format("MM.DD HH:mm");
};

export const getDate = (ts) => {
  const now = moment().startOf("day");
  const target = moment(ts).startOf("day");
  return moment(ts).format("YYYY.MM.DD");
};

export const getTime = (ts) => {
  const now = moment().startOf("day");
  const target = moment(ts).startOf("day");
  return moment(ts).format("HH:mm");
};

export const CommaFormatted = (amount) => {
  if (isNaN(amount)) {
    return amount;
  }
  let amount_ = Number(amount);

  return amount_.toLocaleString(navigator.language, {
    minimumFractionDigits: 0,
  });
};
export const CommaFormattedEx = (amount) => {
  if (isNaN(amount)) {
    return amount;
  }
  let amount_ = Number(amount);

  return amount_.toLocaleString(navigator.language, {
    minimumFractionDigits: 0,
  }) +"원";
};

export const commaescapevalue = (a1) => {
  if (isNaN(a1)) {
    a1 = a1.replaceAll(",", "");
  }
  return a1;
};

export const ratevalue = (a1, a2) => {
  a1 = a1.replaceAll(",", "");
  a2 = a2.replaceAll(",", "");
  let amount_ = Number(a1) / Number(a2);

  amount_ = amount_ * 100;

  if (amount_ > 100) {
    return Math.floor(amount_) - 100 + "% 할인";
  } else {
    return "";
  }
};
export const PriceRateConvert = (price, saleprice) => {
  if (price == "") {
    alert("정상가를 입력해주세요");
    return;
  }

  // if(representivesaleprice == ''){
  // 	alert("판매가를 입력해주세요");
  // 	return;
  // }
  // if(Number(representivesaleprice) > Number(representiveprice) ){
  // 	alert("판매가가 정상가보다 높게 설정할수 없습니다");
  // 	return;
  // }

  if (price == saleprice) {
    //	setRepresentiveratio("0");

    return "0";
  } else {
    let ratio = Number((1 - Number(saleprice) / Number(price)) * 100);

    return Math.round(ratio);
  }
};

export const removeemail = (text) => {
  const regex = /\s/g;
  return text.replace("@gmail.com", "");
};

export const useSleep = (delay) =>
  new Promise((resolve) => setTimeout(resolve, delay));
