import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/PageEx";
import { getBest } from "../../utility/bestfirebase";
import SubLabel from "./SubLabel";
import Fade from "react-reveal/Fade";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { getMd } from "../../utility/mdfirebase";
import BestItem2 from "./BestItem2";
import { MaxPC, SmallPC } from "../../components/Responsive";
import { getproduct } from "../../utility/productfirebase";
import { ProductNameTag } from "../../components/Define";
import AllItem from "./AllItem";
import { UserContext } from "../../context/Users";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]); // *

const swiperStyle = {
  position: "relative",
  width: "100%",
};

const AllProduct = () => {
  const [bestitems, setBestitems] = useState([]);
  const [bestitems1, setBestitems1] = useState([]);
  const [bestitems2, setBestitems2] = useState([]);
  const [bestitems3, setBestitems3] = useState([]);
  const [bestitems4, setBestitems4] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const { dispatch, user } = useContext(UserContext);
  useEffect(() => {
    async function fetchData() {
      let bestitems = [];
      let bestitems1 = [];
      let bestitems2 = [];
      let bestitems3 = [];
      let bestitems4 = [];
 

      let productitemstmp2 = [];

      productitemstmp2 = await getproduct();

      productitemstmp2.map((data) => {
        if (user.USERID == '') {
          if (data.PRODUCTDISPOSE == '일반') {
            bestitems.push(data);
          }
        } else {
          if (user.LEVEL == '일반') {
            if (data.PRODUCTDISPOSE == '일반') {
              bestitems.push(data);
            }
          } else if (user.LEVEL == 'VIP') {
            if (data.PRODUCTDISPOSE == 'VIP') {
              bestitems.push(data);
            }
          } else if (user.LEVEL == '빅셀러') {
            if (data.PRODUCTDISPOSE == '빅셀러') {
              bestitems.push(data);
            }
          } 
        }
      })


      bestitems.map((data, index) => {
        if (index % 4 == 0) {
          bestitems1.push(data);
        } else if (index % 4 == 1) {
          bestitems2.push(data);
        } else if (index % 4 == 2) {
          bestitems3.push(data);
        } else if (index % 4 == 3) {
          bestitems4.push(data);
        }
      });

      setBestitems(bestitems);
      setBestitems1(bestitems1);
      setBestitems2(bestitems2);
      setBestitems3(bestitems3);
      setBestitems4(bestitems4);

      // console.log("bestitems", bestitems);
    }

    fetchData();
  }, [refresh]);

  return (
    <div style={{ display: "flex", marginTop: 20, flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          height: 150,
          flexDirection: "column",
          alignItems: "flex-start",
          alignItems: "center",
          paddingTop: "70px",
        }}
      >
        <Label name={"전체상품"} weight={800} />
        <div style={{ height: 20 }} />
        <SubLabel
          name={"머레이의 전상품 목록입니다"}
          weight={100}
          color={"#999"}
        />
      </div>

      <MaxPC>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              padding: "20px 0px",
              backgroundColor: "white",
              justifyContent: "center",
            }}
          >
            {bestitems.map((data, index = 0) => (
              <AllItem
                column={1}
                index={index}
                product_id={data.PRODUCT_ID}
                img={data.PRODUCTIMAGEARY[0]}
                name={ProductNameTag + data.PRODUCTNAME}
                select={data.PRODUCTSELECT}
                price={data.PRODUCTREGULARPRICE}
                lowprice={data.PRODUCTLOWPRICE}
                component={data.PRODUCTCOMPONENT}
                add={data.PRODUCT_ADD11_INFO}
                supply={data.PRODUCTSUPPLYPRICE}
              />
            ))}
          </div>
        </div>
      </MaxPC>
      <SmallPC>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              padding: "20px 0px",
              backgroundColor: "white",
              justifyContent: "space-between",
            }}
          >
            {bestitems.map((data, index = 0) => (
              <AllItem
                column={1}
                index={index}
                product_id={data.PRODUCT_ID}
                img={data.PRODUCTIMAGEARY[0]}
                name={ProductNameTag + data.PRODUCTNAME}
                select={data.PRODUCTSELECT}
                price={data.PRODUCTREGULARPRICE}
                lowprice={data.PRODUCTLOWPRICE}
                component={data.PRODUCTCOMPONENT}
                add={data.PRODUCT_ADD11_INFO}
                supply={data.PRODUCTSUPPLYPRICE}
              />
            ))}
          </div>
        </div>
      </SmallPC>
    </div>
  );
};

export default AllProduct;
