import {db, auth, storage} from '../api/config';
import { collection, getDocs, query, updateDoc,where,doc,setDoc, deleteDoc, orderBy } from 'firebase/firestore';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";



export const registAsk = async ({
  CONTENT,
  CONTENTTYPE,
  ASKTYPE,
  USERID,
  USER_ID,
  CEONAME,
  SECRET,
}) => {
  const askRef = doc(collection(db, "ASK"));
  const id = askRef.id;
  const newask = {
    ASK_ID: id,
    CONTENT,
    CONTENTTYPE,
    ASKTYPE,
    USERID,
    USER_ID,
    CEONAME,
    SECRET,
    REGISTDATE: Date.now(),
  };
  try {
    await setDoc(askRef, newask);
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return id;
  }
};

export const getAsk = async () => {
  const askRef = collection(db, "ASK");

  let askItems = [];
  let success = false;
  const q = query(askRef);

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      askItems.push(doc.data());
    });
    console.log("askItems", askItems);

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(askItems);
      } else {
        resolve(askItems);
      }
    });
  }
};

export const deleteAsk = async({MD_ID})=>{
    console.log("delete MDPICK", MD_ID);
    const mdRef = doc(db, "MDPICK", MD_ID);
    try{
        await deleteDoc(mdRef);
    }catch(e){
        console.log("error", e.message);
    }finally{
        return;
    }
}
export const updateAsk = async({PRODUCTIMG, PRODUCT_ID,MD_ID})=>{

    const mdRef = collection(db, "MD");
    const rows = query(mdRef, where("MD_ID",'==', MD_ID ));
    try{

        const querySnapshot =  await getDocs(rows);

        querySnapshot.forEach(function (doc) {

            console.log("update", doc.data())
            updateDoc(doc.ref, {
                PRODUCTIMG : PRODUCTIMG,
                PRODUCT_ID : PRODUCT_ID,
         
            });
        });

    }catch(e){
        console.log("error", e.message);
    }finally{

        return new Promise((resolve, resject)=>{
            resolve(0);

            console.log("member update complete");
        })  

    }


}

export const getnotice = async () => {
  const noticeRef = collection(db, "NOTICE");

  let noticeItem = [];
  let success = false;
  const q = query(noticeRef, orderBy("REGISTDATE", "desc"));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let notice = {
        CONTENT: "",
        NOTICE: "",
        REGISTDATE: "",
        NOTICE_ID: "",
        VIEWCOUNT: 0,
        READ : []
      };
      notice.NOTICE_ID = doc.data().NOTICE_ID;
      notice.NOTICE = doc.data().NOTICE;
      notice.REGISTDATE = doc.data().REGISTDATE;
      notice.CONTENT = doc.data().CONTENT;
      notice.VIEWCOUNT = doc.data().VIEWCOUNT;
      notice.READ = doc.data().READ;
      noticeItem.push(notice);
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(noticeItem);
      } else {
        resolve(noticeItem);
      }
    });
  }
};

export const getnoticebynoticeid = async ({ NOTICE_ID }) => {
  const noticeRef = collection(db, "NOTICE");

  let noticeItem = [];
  let success = false;
  const q = query(noticeRef, where("NOTICE_ID", "==", NOTICE_ID));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let notice = {
        READ: [],
      };

      notice.READ = doc.data().READ;
      noticeItem.push(notice);
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(noticeItem);
      } else {
        resolve(noticeItem);
      }
    });
  }
};

export const readnotice = async ({ NOTICE_ID, READ, USERID }) => {
  const noticeRef = collection(db, "NOTICE");
  const rows = query(noticeRef, where("NOTICE_ID", "==", NOTICE_ID));
  try {
    READ.push(USERID);
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      console.log("update", doc.data());
      updateDoc(doc.ref, {
        READ: READ,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      resolve(0);

      console.log("member update complete");
    });
  }
};