import React, { useEffect } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/PageEx";
import { useNavigate } from "react-router-dom";

const Guide = () => {
  const navigate = useNavigate();
  const Center = styled.div`
    width: 50px;
    border-radius: 50px;
    background-color: #fff;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const _handleGuide = () => {
    navigate("/notice");
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: 60,
        backgroundColor: "#F4F4F6",
        borderRadius: 20,
      }}
      onClick={_handleGuide}
    >
      <div
        style={{
          display: "flex",
          width: "40%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Center>
          <img src={imageDB.guide} width={30} />
        </Center>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", fontSize: 14, color: "#000" }}>
          입점 / 상품 / 배송
        </div>
        <div style={{ display: "flex", fontSize: 14, color: "#000" }}>
          가이드
        </div>
      </div>
    </div>
  );
};

export default Guide;
