

import React, { Fragment, useContext, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import './ItemProduct.css'
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted, ratevalue } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import { UserContext } from "../../context/Users";

const Container = styled.div`
  border-radius: 5px;
  flex-direction:column;
  margin-right:10px;
  display: flex;
  border-radius:10px;
  margin-bottom:20px;
`

const CheckItem = styled.div`
  background-color: #b5b5b7c9;
  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`

const UnCheckItem = styled.div`

  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`
const Sticker1 = styled.div`
  background-color: #ff00007d;
  color: #fff;
  font-size: 12px;
  top: -8px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 30px;
`
const ProductInfo = styled.div`
  padding: 15px 0px;
  border-top: none;
`
const ProductName = styled.div`
  font-size: 18px;
  text-align: left;
  padding-left: 10px;
  color: #000;
  font-weight:900;
`

const ProductPrice = styled.span`
  font-size: 16px;
  text-align: left;
  color: #000;
  font-weight:900;
  font-family: Pretendard-Bold;
`

const ProductRate = styled.span`
  font-size: 16px;
  text-align: left;
  color: #ff0000;
  font-weight:900;
  font-family: Pretendard-Bold;
  padding-left:5px;
`
const ProductSubPrice = styled.span`
  font-size: 14px;
  text-align: left;
  padding-left:10px;
  color: #929292;
  text-decoration:line-through;
`

const ItemProduct = ({product_id, img, name, price,lowprice, component,add ,select, time, index}) => {

  const navigate = useNavigate();
  const { user, dispatch2 } = useContext(UserContext);
  

  const [isHovering, setIsHovering] = useState(false);
  const _handleMouseOver = () =>{
    if(isHovering){
      return;
    }
    setIsHovering(true); 

  }
  const _handleMouseOut= () =>{

    if(!isHovering){
      return;
    }
    setIsHovering(false);

  
  }


  const _handleProduct = () => {
    
    if (user.USERID == "") {
      alert("로그인을 먼저 진행해주세요");
      return;
    }
    
    navigate("/product", {state : {PRODUCT_ID: product_id}});
  }

  const existselect = (selectname)=>{
    let selectlist = select;

    const FindIndex = selectlist.findIndex(x=>x == selectname);
  
    if(FindIndex == -1){
      return false;
    }else{
      return true;
    }

  }

  return (
    <Fragment>
      <Fade bottom delay={index * 2000}>
        <Container
          onClick={_handleProduct}
          onMouseOver={_handleMouseOver}
          onMouseOut={_handleMouseOut}
        >
          <div style={{ display: "flex" }}>
            <div className="itemcontainer">
              <img
                src={img}
                class="image"
                style={{ height: "250px", width: "250px" }}
              />
              <div className="middle">
                <div className="text">
                  온라인 최저가 | {CommaFormatted(lowprice)}원
                </div>
                <div className="text">제품 구성품 | {component}</div>
                <div className="text">누적주문건수 | 1,230건</div>
                <div className="text">추가항목 | {add}</div>
              </div>

              <div class="wrapper">
                {existselect("newselect") == true && (
                  <div class="NewButton">
                    <div className="NewButtonText">NEW</div>
                  </div>
                )}

                {existselect("mdselect") == true && (
                  <div class="MdButton">
                    <div className="MdButtonText">MD픽</div>
                  </div>
                )}
                {existselect("bestselect") == true && (
                  <div class="BestButton">
                    <div className="BestButtonText">BEST</div>
                  </div>
                )}

                {existselect("saleselect") == true && (
                  <div class="SaleButton">
                    <div className="SaleButtonText">판매급상승</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <ProductInfo>
            <div style={{ display: "flex", marginTop: 10, flexGrow: 1 }}>
              {" "}
              <ProductName>
                {name.slice(0, 30)}
                {name.length > 30 ? "..." : null}
              </ProductName>
            </div>

            <div style={{ display: "flex", flexGrow: 1, marginTop: 10 }}>
              <div style={{ fontSize: 13, textAlign: "left", paddingLeft: 10 }}>
                <span style={{ color: "#929292", fontWeight: 700 }}>
                  {"파트너 공급가   |   "}
                </span>
                <ProductPrice>{CommaFormatted(price)}원</ProductPrice>
                <ProductSubPrice>{CommaFormatted(lowprice)}원</ProductSubPrice>
                <ProductRate>{ratevalue(lowprice, price)}</ProductRate>
              </div>
            </div>
          </ProductInfo>
        </Container>
      </Fade>
    </Fragment>
  );
};

ItemProduct.defaultProps={
  price : 0,
  time :0,
}
export default ItemProduct;
