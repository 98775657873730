import React, { useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";

const Container = styled.div``;

const CardUser = ({ callback }) => {
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    setCarduser(carduser);
  }, [refresh]);

  const [carduser, setCarduser] = React.useState("");
  const oncarduserchange = (e) => {
    setCarduser(e.target.value);
    callback(e.target.value);
  };

  return (
    <Container>
      <div style={{ width: "80%", paddingLeft: 20 }}>
        <input
          type="text"
          className="InputCommon2"
          value={carduser}
          onChange={oncarduserchange}
          placeholder="카드사용자 이름을 입력해주세요"
          style={{
            height: "10px",
            fontSize: "12px",
          }}
        ></input>
      </div>
    </Container>
  );
};

CardUser.defaultProps = {};

export default CardUser;
