import React, { useState, useEffect, useRef } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import "../../components/css/common.css";
import { IoIosAddCircleOutline } from "react-icons/io";
import { PeriodType, SearchType } from "../../utility/commondefine";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import moment from "moment";
import Label from "./Label";

const Container = styled.div`
  width: 100%;
  font-size: 14px;
`;

const SearchItem = styled.div`
  display: flex;
`;
const SearchTextItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  background: #eaeaea;
  color: #818080;
`;
const LabelData = styled.div`
  width: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-left: 5px;
  border: 1px solid #f0f0f0;
`;

const ButtonSet = styled.div`
  height: 35px;
  width: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 10px;
  border: ${({ enable }) => (enable == true ? null : "1px solid #d6d6d6")};
  background: ${({ enable }) => (enable == true ? "antiquewhite" : "#f5f5f5")};
`;

const Periodsearch = ({ containerStyle, callback, btncallback }) => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(100);
  const [refresh, setRefresh] = useState(1);

  const [registstartDate, setRegiststartDate] = useState("");
  const [registendDate, setRegistendDate] = useState("");

  const [type, setType] = useState("");

  useEffect(() => {
    setRegiststartDate(registstartDate);
    setRegistendDate(registendDate);
  }, [refresh]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _handleSelect = (type) => {
    setType(type);
    const now = moment();
    let timevalue = "";
    if (type == PeriodType.period1) {
      timevalue = moment(now).subtract(moment(now).format("H"), "h").unix();
    } else if (type == PeriodType.period2) {
      timevalue = moment(now).subtract(7, "days").unix();
    } else if (type == PeriodType.period3) {
      timevalue = moment(now).subtract(30, "days").unix();
    } else if (type == PeriodType.period4) {
      timevalue = moment(now).subtract(90, "days").unix();
    } else if (type == PeriodType.period5) {
      timevalue = moment(now).subtract(900, "days").unix();
    }
    console.log("timevalue", timevalue, now);

    setRegistendDate("");
    setRegiststartDate("");
    setRefresh((refresh) => refresh + 1);

    btncallback(timevalue);
  };

  const onStartDateChange = (date) => {
    setRegiststartDate(date);
    setType("");
    setRefresh((refresh) => refresh + 1);
  };

  const onEndDateChange = (date) => {
    setRegistendDate(date);
    setType("");
    setRefresh((refresh) => refresh + 1);
    callback(registstartDate, date);
  };

  return (
    <Container style={containerStyle}>
      <SearchItem>
        <Label Label={"주문일"}></Label>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <LabelData>시작일시</LabelData>
          <DatePicker
            style={{ width: 70 }}
            selected={registstartDate}
            onChange={(date) => onStartDateChange(date)}
          />
          <LabelData style={{ marginLeft: 30 }}>종료일시</LabelData>
          <DatePicker
            style={{ width: 70 }}
            selected={registendDate}
            onChange={(date) => onEndDateChange(date)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginLeft: 20,
            alignItems: "center",
          }}
        >
          <ButtonSet
            enable={type == "1일"}
            onClick={() => {
              _handleSelect("1일");
            }}
            height={35}
          >
            1일
          </ButtonSet>
          <ButtonSet
            enable={type == "일주일"}
            onClick={() => {
              _handleSelect("일주일");
            }}
            height={35}
          >
            일주일
          </ButtonSet>
          <ButtonSet
            enable={type == "1개월"}
            onClick={() => {
              _handleSelect("1개월");
            }}
            height={35}
          >
            1개월
          </ButtonSet>
          <ButtonSet
            enable={type == "3개월"}
            onClick={() => {
              _handleSelect("3개월");
            }}
            height={35}
          >
            3개월
          </ButtonSet>
          <ButtonSet
            enable={type == "전체"}
            onClick={() => {
              _handleSelect("전체");
            }}
            height={35}
          >
            전체
          </ButtonSet>
        </div>
      </SearchItem>
    </Container>
  );
};

export default Periodsearch;
