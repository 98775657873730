import React, { useEffect } from "react";
import styled from "styled-components";
import { COLORS, SIZE } from "../Theme";

const Container = styled.div`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width};
  background-color: ${({ bgcolor }) => bgcolor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  border: 1px solid ${({ bordercolor }) => bordercolor};
`;

const LabelText = styled.span`
  color: ${({ color }) => color};
  font-size: ${({ size }) => size}px;

`;

const Button = ({
  containerStyle,
  Label,
  bgcolor,
  color,
  bordercolor,
  height,
  width,
  callback,
  borderRadius,
  item,
  size,
}) => {
  const _handleclick = () => {
    callback(item);
  };

  return (
    <Container
      style={containerStyle}
      bgcolor={bgcolor}
      height={height}
      width={width}
      bordercolor={bordercolor}
      borderRadius={borderRadius}
      onClick={_handleclick}
      className="button"
    >
      <LabelText color={color} size={size}>
        {Label}
      </LabelText>
    </Container>
  );
};

Button.defaultProps = {
  bordercolor: "#000",
  borderRadius: 5,
  width: "100%",
  size : SIZE.largesize
};
export default Button;
