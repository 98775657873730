
import React, { useEffect, useState } from 'react';
import { imageDB } from "../components/imageDB";
import { useNavigate} from "react-router-dom";
import styled from 'styled-components';
import '../components/css/common.css';


const NavButtonView = styled.div`
  width: 120px;
  height: 50px;
  display:flex;
  justify-content:space-around;
`
const NavButton = styled.div`
  border-radius: 50px;
  height: 50px;
  width: 50px;
`


const Nav = () => {
  const navigate = useNavigate();
  return (
    <div style={{display:"flex", justifyContent:"flex-end"}}>
      <NavButtonView>
            <NavButton>
              <img src={imageDB.left} width={30}/>
            </NavButton>
            <NavButton>
              <img src={imageDB.right} width={30}/>
            </NavButton>
      </NavButtonView>
    </div>
  );
}

export default Nav;
