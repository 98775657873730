import React, { useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";

import NewsItem from "./NewsItem";
import YouTube from "react-youtube";
import Page from "../../components/Layout/PageEx";
import { getnews } from "../../utility/newsfirebase";

import Fade from "react-reveal/Fade";

const PictureBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ededed;
  borderradius: 5px;
  height: 100px;
  margin-left: 10px;
  margin-bottom: 2px;
  background-color: #ededed;
`;

const MainBannerText = styled.div`
  font-size: 33px;
  position: absolute;
  top: 35%;
  z-index: 10;
  padding-left: 15%;
  color: ${({ color }) => color};
  justify-content: flex-start;
  font-weight: 900;
  font-family: Pretendard-Bold;
`;
const SubBannerText = styled.div`
  font-size: 20px;
  position: absolute;
  top: 45%;
  padding-left: 15%;
  z-index: 10;
  width: 30%;
  color: ${({ color }) => color};
  justify-content: flex-start;
  font-family: "Pretendard-Regular";
`;

const BannerButtonView = styled.div`
  font-size: 20px;
  position: absolute;
  top: 70%;
  z-index: 10;
  left: 15%;
`;
const BannerButton = styled.div`
  font-size: 20px;
  width: 200px;
  height: 50px;
  border: 1px solid #000;
  border-radius: 30px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Pretendard-Regular";
`;

const Advertise2 = () => {
  const [newsitems, setNewsitems] = useState([]);
  const [refresh, setRefresh] = useState(1);

  const [news1, setNews1] = useState("");
  const [news1_id, setNews1_id] = useState("");
  const [type1, setType1] = useState("");
  const [news2, setNews2] = useState("");
  const [news2_id, setNews2_id] = useState("");
  const [type2, setType2] = useState("");

  useEffect(() => {
    async function fetchData() {
      let newslist = [];

      newslist = await getnews();

      console.log("newslist", newslist);

      setNews1("");
      setNews2("");
      newslist.forEach((element) => {
        if (element.ORDER == 1) {
          setNews1(element.NEWS);
          console.log("news1", element.NEWS, news1);
          setNews1_id(element.NEWS_ID);
          setType1(element.TYPE);
          setRefresh((refresh) => refresh * -1);
        }
        if (element.ORDER == 2) {
          setNews2(element.NEWS);
          setNews2_id(element.NEWS_ID);
          setType2(element.TYPE);
          setRefresh((refresh) => refresh * -1);
        }
      });

      console.log("newslist", newslist);
    }

    fetchData();
  }, [refresh]);

  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 50 }}>
      <img src={imageDB.sample3} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default Advertise2;
