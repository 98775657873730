import React, { useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";

const Container = styled.div``;

const CardNum = ({ callback }) => {
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    setCardnum(cardnum);
  }, [refresh]);

  const [cardnum, setCardnum] = React.useState("");
  const oncardnumchange = (e) => {
    setCardnum(e.target.value);
    callback(e.target.value);
  };

  return (
    <Container>
      <div style={{ width: "80%", paddingLeft: 20 }}>
        <input
          type="number"
          className="InputCommon2"
          value={cardnum}
          onChange={oncardnumchange}
          placeholder="번호만 입력해주세요"
          style={{
            height: "10px",
            fontSize: "12px",
          }}
        ></input>
      </div>
    </Container>
  );
};

CardNum.defaultProps = {};

export default CardNum;
