import React, { useEffect} from "react";
export const imageDB =  {
  logo  : "https://firebasestorage.googleapis.com/v0/b/connectist-cb8bc.appspot.com/o/define%2Ficon.png?alt=media&token=360df8cc-ff8c-4e8e-aac9-e3e32b684aee",
  mainlogo : require('../assets/images/logo.png'),
  mainlogo2 : require('../assets/images/logo2.png'),
  calendar :  require('../assets/images/calendar.png'),
  banner : require('../assets/images/banner.png'),
  down : require('../assets/images/down.png'),
  agree : require('../assets/images/agree.png'),
  GlobeOn : require('../assets/images/GlobeOn.png'),
  Taxoff : require('../assets/images/Taxoff.png'),
  NationOff : require('../assets/images/Nationoff.png'),
  logoimage : require('../assets/images/insurancelogo.png'),
  meritzlogo :require('../assets/images/meritzlogo.png'),
  ChevronRight :require('../assets/images/ChevronRight.png'),
  ChevronRight2 :require('../assets/images/ChevronRight2.png'),
  maindisply : require('../assets/images/maindisplay.png'),
  plus : require('../assets/images/home/plus.png'),
  preview1 : require('../assets/images/home/preview1.png'),
  preview2 : require('../assets/images/home/preview2.png'),
  preview3 : require('../assets/images/home/preview3.png'),
  preview4 : require('../assets/images/home/preview4.png'),
  preview5 : require('../assets/images/home/preview5.png'),
  preview6 : require('../assets/images/home/preview6.png'),
  preview7 : require('../assets/images/home/preview7.png'),
  preview8 : require('../assets/images/home/preview8.png'),
  carry : require('../assets/images/home/carry.png'),
  frequent : require('../assets/images/home/frequent.png'),
  delivery : require('../assets/images/home/delivery.png'),
  footer : require('../assets/images/Foot.png'),
  hamburg : require('../assets/images/hamburg.png'),
  check : require('../assets/images/check.png'),
  plusminus : require('../assets/images/plusminus.png'),
  close : require('../assets/images/close.png'),
  uncheck : require('../assets/images/uncheck.png'),
  best1 : require('../assets/images/home/best1.png'),
  best2 : require('../assets/images/home/best2.png'),
  best3 : require('../assets/images/home/best3.png'),
  best4 : require('../assets/images/home/best4.png'),
  best5 : require('../assets/images/home/best5.png'),
  best6 : require('../assets/images/home/best6.png'),
  best7 : require('../assets/images/home/best7.png'),
  best8 : require('../assets/images/home/best8.png'),
  best9 : require('../assets/images/home/best9.png'),
  best10 : require('../assets/images/home/best10.png'),
  news : require('../assets/images/home/news.png'),
  left : require('../assets/images/home/left.png'),
  right : require('../assets/images/home/right.png'),
  head : require('../assets/images/home/head.png'),
  eye : require('../assets/images/home/eye.png'),
  up : require('../assets/images/home/up.png'),
  down : require('../assets/images/home/down.png'),
  basket : require('../assets/images/home/basket.png'),
  guide : require('../assets/images/home/guide.png'),
  bell : require('../assets/images/home/bell.png'),
  heart : require('../assets/images/home/heart.png'),
  search : require('../assets/images/home/search.png'),
  product1 : require('../assets/images/product/product1.png'),
  basketsample : require('../assets/images/product/basketsample.png'),
  producttemplate1 : require('../assets/images/product/producttemplate1.png'),
  producttemplate2 : require('../assets/images/product/producttemplate2.png'),
  producttemplate3 : require('../assets/images/product/producttemplate3.png'),
  banner : require('../assets/images/banner.png'),
  banner2 : require('../assets/images/banner2.png'),
  sample2 : require('../assets/images/product/sample2.png'),
  sample3 : require('../assets/images/product/sample3.png'),
  desktop : require('../assets/images/desktop.png'),
  mobile : require('../assets/images/mobile.png'),
  right : require('../assets/images/right.png'),
  left : require('../assets/images/left.png'),
  ai : require('../assets/images/all.png'),
  pdf : require('../assets/images/zip.png'),
  download : require('../assets/images/download.png'),
  best : require('../assets/images/best.png'),
  mdpick : require('../assets/images/mdpick.png'),
  saletop : require('../assets/images/saletop.png'),
  new : require('../assets/images/new.png'),
  heart : require('../assets/images/heart.png'),
  categorysample: require('../assets/images/categorysample.png'),
  loading: require('../assets/images/loading.gif'),
}



