

import React, { Fragment, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import './BestItem.css'
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import Fade from 'react-reveal/Fade';

const Container = styled.div`
  border-radius: 5px;
  width:18%;
  height:260px;
  flex-direction:column;
  background-color: #fff;
  margin-right:10px;
  margin-bottom:40px;
  display: flex;
  border-radius:10px;
`

const CheckItem = styled.div`
  background-color: #b5b5b7c9;

  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`

const UnCheckItem = styled.div`

  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`
const WithItem = ({img, name, price, product_id}) => {

  const navigate = useNavigate();

  const [isHovering, setIsHovering] = useState(false);
  const _handleMouseOver = () =>{
    if(isHovering){
      return;
    }
    setIsHovering(true); 

  }
  const _handleMouseOut= () =>{

    if(!isHovering){
      return;
    }
    setIsHovering(false);

  
  }


  const _handleProduct = () => {
    
    console.log("product_id", product_id);

    // navigate("/product", { state: { PRODUCT_ID: product_id } });
  }


  return (

    <Fragment>

       

        <Container onClick={_handleProduct} onMouseOver={_handleMouseOver} onMouseOut= {_handleMouseOut} >
         
          <div 
          style={{display:"flex", width: "90%",marginTop: 10, marginLeft:10}}>

            <div className="itemcontainer">
              <img src={img} class="image" style={{width:"100%"}}/>
              <div className="middle">
                <div className="text">온라인 최저가 | 123,000원</div>
                <div className="text">제품 옵션 | 삼성 애플전용</div>
                <div className="text">누적주문건수 | 1,230건</div>
                <div className="text">추가항목 | CtoC 케이블</div>
              </div>
            </div>
          </div>

     
          
          <div style={{display:"flex", marginTop:10, flexGrow:1}}> <div
          style={{fontSize: 13,textAlign: "left",paddingLeft: 10, color:"#323232"}}>{name}</div></div>

         
          <div style={{display:"flex", flexGrow:1}}>
          <div style={{fontSize: 13,textAlign: "left",paddingLeft: 10}}> 
            <span style={{color:"#929292", fontWeight:700}}>{'파트너 공급가   |   '}</span>
            <span style={{color:"#929292"}}>{(price)}원</span>   
          </div>
          </div>

        </Container>
    </Fragment>

  
  );
};

WithItem.defaultProps={
  price : 0
}
export default WithItem;
