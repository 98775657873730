import { db, auth, storage } from "../api/config";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
  doc,
  setDoc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const registbanner = async ({ bannermain, bannerimg, type1, type2 }) => {
  const bannerRef = doc(collection(db, "BANNER"));
  const id = bannerRef.id;
  const newbanner = {
    BANNER_ID: id,
    BANNERMAIN: bannermain,
    BANNERIMG: bannerimg,
    REGISTDATE: Date.now(),
    TYPE1: type1,
    TYPE2: type2,
    VIEWCOUNT: 0,
    DISPOSE: false,
  };
  try {
    await setDoc(bannerRef, newbanner);
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return id;
  }
};
export const getBanner = async () => {
  const bannerRef = collection(db, "BANNER");

  let bannerItems = [];
  let success = false;
  const q = query(bannerRef, orderBy("REGISTDATE", "desc"));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      bannerItems.push(doc.data());
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(bannerItems);
      } else {
        resolve(bannerItems);
      }
    });
  }
};
export const getMainBanner = async () => {
  const bannerRef = collection(db, "BANNER");

  let bannerItems = [];
  let success = false;
  const q = query(
    bannerRef,
    where("TYPE2", "==", "메인배너"),
    where("screen", "==", "1600이하"),
    orderBy("ORDER", "asc")
  );

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      bannerItems.push(doc.data());
    });
    console.log("bannerItems", bannerItems);

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(bannerItems);
      } else {
        resolve(bannerItems);
      }
    });
  }
};

export const getMainMaxBanner = async () => {
  const bannerRef = collection(db, "BANNER");

  let bannerItems = [];
  let success = false;
  const q = query(
    bannerRef,
    where("TYPE2", "==", "메인배너"),
    where("screen", "==", "1600이상"),
    orderBy("ORDER", "asc")
  );

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      bannerItems.push(doc.data());
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(bannerItems);
      } else {
        resolve(bannerItems);
      }
    });
  }
};

export const getSideBanner = async () => {
  const bannerRef = collection(db, "BANNER");

  let bannerItems = [];
  let success = false;
  const q = query(bannerRef, where("TYPE2", "==", "카테고리배너"));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      bannerItems.push(doc.data());
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(bannerItems);
      } else {
        resolve(bannerItems);
      }
    });
  }
};

export const getPopupBanner = async () => {
  const bannerRef = collection(db, "BANNER");

  let bannerItems = [];
  let success = false;
  const q = query(bannerRef, where("TYPE1", "==", "팝업"));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      bannerItems.push(doc.data());
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(bannerItems);
      } else {
        resolve(bannerItems);
      }
    });
  }
};

export const deletebanner = async (BANNER_ID) => {
  console.log("delete banner", BANNER_ID);
  const bannerRef = doc(db, "BANNER", BANNER_ID);
  try {
    await deleteDoc(bannerRef);
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};
export const updatebanner = async ({ data }) => {
  const bannerRef = collection(db, "BANNER");

  const BANNER_ID = data.BANNER_ID;

  const rows = query(bannerRef, where("BANNER_ID", "==", BANNER_ID));
  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      console.log("update", doc.data());
      updateDoc(doc.ref, {
        BANNERMAIN: data.BANNERMAIN,
        BANNERIMG: data.BANNERIMG,
        DISPOSE: data.DISPOSE,
        REGISTDATE: Date.now(),
        TYPE1: data.TYPE1,
        TYPE2: data.TYPE2,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      resolve(0);

      console.log("member update complete");
    });
  }
};
