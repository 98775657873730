import React from 'react';

import Layout from '../components/Layout/Layout';
import MemberLogincontainer from '../container/MemberLogincontainer';
import MemberLayout from '../components/Layout/MemberLayout';

const MemberLoginpage = () => {
  return (
    <MemberLayout>
      <MemberLogincontainer/>
    </MemberLayout>

  );
}

export default MemberLoginpage;
