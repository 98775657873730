import { db, auth, storage } from "../api/config";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
  doc,
  setDoc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const registOrder = async ({
  ORDER_MASSORDER,
  USER_ID,
  BANK_USER,
  BANK_NAME,
  BANK_NUM,
  CARD_NAME,
  CARD_NUM,
  CARD_TYPE,
  MONEY,
  ORDERLIST,
  ORDERUSER,
  ORDERRECEIVEUSER,
  ORDERTEL,
  RECEIVETEL,
  RECEIVEADDRESS1,
  RECEIVEADDRESS2,
  RECEIVEDELIVERYMSG,
  RECEIVEZONECODE,
  STORENAME,
  BRANDNAME,
  ORDERNUM,
  ORDER_TYPE,
}) => {
  let success = false;

  console.log("registOrder" )

  const Ref = doc(collection(db, "ORDER"));
  const id = Ref.id;

  try {
    const newData = {
      ORDER_ID: id,
      ORDERNUM: ORDERNUM,
      ORDER_MASSORDER,
      USER_ID,
      BANK_USER,
      BANK_NAME,
      BANK_NUM,
      CARD_NAME,
      CARD_NUM,
      CARD_TYPE,
      MONEY,
      ORDERSTATUS: ORDER_TYPE,
      ORDERLIST,
      ORDERUSER,
      ORDERRECEIVEUSER,
      ORDERTEL,
      RECEIVETEL,
      RECEIVEADDRESS1,
      RECEIVEADDRESS2,
      RECEIVEDELIVERYMSG,
      RECEIVEZONECODE,
      STORENAME,
      BRANDNAME,
      REGISTDATE: Date.now(),
    };

    await setDoc(Ref, newData);
  } catch (e) {
    success = false;
    console.log("register", e);
    return null;
  } finally {
    return id;
  }
};

export const registOrderTmp = async ({
  USER_ID,
  ORDER_MASSORDER,
  BANK_USER,
  BANK_NAME,
  BANK_NUM,
  CARD_NAME,
  CARD_NUM,
  CARD_TYPE,
  MONEY,
  ORDERLIST,
  ORDERUSER,
  ORDERRECEIVEUSER,
  ORDERTEL,
  RECEIVETEL,
  RECEIVEADDRESS1,
  RECEIVEADDRESS2,
  RECEIVEDELIVERYMSG,
  RECEIVEZONECODE,
  STORENAME,
  BRANDNAME,
  ORDERNUM,
  ORDER_TYPE,
}) => {
  let success = false;

  console.log("registOrderTmp")

  const Ref = doc(collection(db, "ORDERTMP"));
  const id = Ref.id;

  try {
    const newData = {
      ORDER_ID: id,
      ORDERNUM: ORDERNUM,
      ORDER_MASSORDER,
      USER_ID,
      BANK_USER,
      BANK_NAME,
      BANK_NUM,
      CARD_NAME,
      CARD_NUM,
      CARD_TYPE,
      MONEY,
      ORDERSTATUS: ORDER_TYPE,
      ORDERLIST,
      ORDERUSER,
      ORDERRECEIVEUSER,
      ORDERTEL,
      RECEIVETEL,
      RECEIVEADDRESS1,
      RECEIVEADDRESS2,
      RECEIVEDELIVERYMSG,
      RECEIVEZONECODE,
      STORENAME,
      BRANDNAME,
      REGISTDATE: Date.now(),
      ORDER_TMP_ID: ORDERNUM
    };

    await setDoc(Ref, newData);
  } catch (e) {
    success = false;
    console.log("register OrderTmp", e);
    return null;
  } finally {
    return id;
  }
};

export const registMassOrder = async ({
  USER_ID,
  BANK_USER,
  BANK_NAME,
  BANK_NUM,
  CARD_NAME,
  CARD_NUM,
  CARD_TYPE,
  MONEY,
  ORDERLIST,
  ORDERUSER,
  ORDERRECEIVEUSER,
  ORDERTEL,
  RECEIVETEL,
  RECEIVEADDRESS1,
  RECEIVEADDRESS2,
  STORENAME,
  BRANDNAME,
  ORDERNUM,
  ORDER_TYPE,
  MASS,
  DELIVERYMESSAGE,
  INFOMESSAGE
}) => {
  let success = false;

  const Ref = doc(collection(db, "ORDER"));
  const id = Ref.id;


  try {
    const newData = {
      ORDER_ID: id,
      ORDERNUM: ORDERNUM,
      USER_ID,
      BANK_USER,
      BANK_NAME,
      BANK_NUM,
      CARD_NAME,
      CARD_NUM,
      CARD_TYPE,
      MONEY,
      ORDERSTATUS: ORDER_TYPE,
      ORDERLIST,
      ORDERUSER,
      ORDERRECEIVEUSER,
      ORDERTEL,
      RECEIVETEL,
      RECEIVEADDRESS1,
      RECEIVEADDRESS2,
      STORENAME,
      BRANDNAME,
      MASS,
      INFOMESSAGE,
      DELIVERYMESSAGE,
      REGISTDATE: Date.now(),
    };

    await setDoc(Ref, newData);
  } catch (e) {
    success = false;
    console.log("registMassOrder", e);
    
    return null;

    
  } finally {
    return id;
  }
};
export const getOrder = async ({ USER_ID }) => {
  console.log("USER_ID", USER_ID);

  if (USER_ID == "") {
    return [];
  }
  const Ref = collection(db, "ORDER");

  let Item = [];
  let success = false;
  const q = query(Ref, where("USER_ID", "==", USER_ID)
  ,orderBy("REGISTDATE", "desc"));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      Item.push(doc.data());
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(Item);
      } else {
        resolve(-1);
      }
    });
  }
};

export const getOrderTmp = async ({ORDERNUM}) => {

  const OrderTmpRef = collection(db, "ORDERTMP");

  console.log("ORDERNUM", ORDERNUM);

  let Item = [];
  let success = false;
  const q = query(OrderTmpRef, where("ORDERNUM", "==", parseInt(ORDERNUM)));

  
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      Item.push(doc.data());

      console.log("data push", doc.data())
    });

    if (querySnapshot.size > 0) {
      success = true;
    }

    
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(Item);
      } else {
        resolve(-1);
      }
    });
  }
};
export const getlastOrderNm = async ({ USER_ID }) => {
  console.log("USER_ID", USER_ID);

  if (USER_ID == "") {
    return [];
  }
  const Ref = collection(db, "ORDER");

  let Item = [];
  let index = 0;
  let success = false;
  const q = query(
    Ref,
    where("USER_ID", "==", USER_ID),
    orderBy("REGISTDATE", "desc")
  );

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      Item.push(doc.data());
    });

    index = Item[Item.length].OREDRNUM;
    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(index);
      } else {
        resolve(-1);
      }
    });
  }
};

export const updateOrderstatus = async ({ ORDER_ID, ORDERSTATUS, OLDORDERSTATUS }) => {
  const orderRef = collection(db, "ORDER");
  const rows = query(orderRef, where("ORDER_ID", "==", ORDER_ID));
  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        ORDERSTATUS: ORDERSTATUS,
        OLDORDERSTATUS: OLDORDERSTATUS,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      resolve(0);
    });
  }
};

export const updateOrderstatusex = async ({ ORDER_ID, ORDERSTATUS, OLDORDERSTATUS, REQNAME, REQCONTENT }) => {
  const orderRef = collection(db, "ORDER");
  const rows = query(orderRef, where("ORDER_ID", "==", ORDER_ID));
  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        ORDERSTATUS: ORDERSTATUS,
        OLDORDERSTATUS: OLDORDERSTATUS,
        REQTITLE: REQNAME,
        REQCONTENT: REQCONTENT,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      resolve(0);
    });
  }
};

export const updateOrderlist = async ({ ORDER_ID, ORDERLIST, UPDATEMONEY }) => {
  const orderRef = collection(db, "ORDER");
  const rows = query(orderRef, where("ORDER_ID", "==", ORDER_ID));
  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      updateDoc(doc.ref, {
        ORDERLIST: ORDERLIST,
        MONEY: UPDATEMONEY,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      resolve(0);
    });
  }
};


