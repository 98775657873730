

import React, { Fragment, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";
import { getBanner, getMainBanner } from "../../utility/bannerfirebase";

import Fade from 'react-reveal/Fade';
import ReactTyped from "react-typed";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import Ad from './Ad';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { MaxPC, SmallPC } from "../../components/Responsive";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Autoplay]); // *





const swiperStyle={
  position :"relative",
  width :'100%',
}

const MainBannerText = styled.div`
  font-size: 33px;
  position: absolute;
  top: 35%;
  z-index: 10;
  padding-left:15%;
  color: ${({color})=> color};
  justify-content:flex-start;
  font-weight:900;
  font-family: Pretendard-Bold;
`
const SubBannerText = styled.div`
  font-size: 20px;
  position: absolute;
  top: 45%;
  padding-left:15%;
  z-index: 10;
  width: 30%;
  color: ${({color})=> color};
  justify-content:flex-start;
  font-family: 'Pretendard-Regular';
`

const BannerButtonView = styled.div`
  font-size: 20px;
  position: absolute;
  top: 70%;
  z-index: 10;
  left: 15%;

`
const BannerButton = styled.div`
  font-size: 20px;
  width: 200px;
  height:50px;
  border :1px solid #000;
  border-radius : 30px;
  color: #000;
  display:flex;
  justify-content:center;
  align-items:center;
  font-family: 'Pretendard-Regular';
`


const Advertise = () => {
  const [banneritems, setBanneritems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);


  const colorConvert = (color)=>{

    if(color =='흰색'){
      return "#fff";
    }else if(color =='검정색'){
      return "#000";
    }else if(color =='파란색'){
      return "#0000ff";
    }else if(color =='그린색'){
      return "#00ff00";
    }else if(color =='빨간색'){
      return "#ff0000";
    }
  }
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next),
    // 다른 설정들...
  };

  const slideStyle = {
    marginLeft: swiperIndex === 0 ? '0%' : '-20%', // 이전 슬라이드의 일부를 보이도록 설정
  };
  useEffect(()=>{
    async function fetchData(){
      let bannerlist = [];
      let bannerlistTmp = [];
      bannerlistTmp = await getMainBanner();


      bannerlistTmp.forEach(element => {
        if(element.DISPOSE == true){
          bannerlist.push(element);
        }
      });

      setBanneritems(bannerlist);

      // console.log("bannerlist", bannerlist);
    }

    fetchData();
	}, [refresh]);

  return (

    <div style={{display:"flex", flexDirection:"row", width:"100%", backgroundColor:"white", marginTop:10}}>

      <Swiper spaceBetween={10} 
      onActiveIndexChange={(swiperCore) => {setSwiperIndex(swiperCore.activeIndex) }}
      
      navigation= {
     {   nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',}
      }
        

      slidesPerView={1} loop={true} scrollbar={{ draggable: true }} style={swiperStyle} autoplay={{ delay: 5000, disableOnInteraction: true }} >
        {
          banneritems.map((data, index = 0)=>(
            <SwiperSlide key={index}>
                  <SmallPC>
                  <img src={data.BANNERIMG} style={{ width:"100%", height:"450px"}} /> 
                  </SmallPC>
                  <MaxPC>
                  <img src={data.BANNERIMG} style={{ width:"100%", height:"550px"}} /> 
                  </MaxPC>

              
              {
                // (swiperIndex == index || index == 1 ) &&
                <Fragment>

                {
                  data.TYPE4 =='효과있음' ? (  <MainBannerText className="bannerblink" color={colorConvert(data.TYPE3)}>
                                  <Fade bottom delay={1000}>
                                    {data.BANNERMAIN}

                                  </Fade>
                                  </MainBannerText>) :(  <MainBannerText color={colorConvert(data.TYPE3)}>
                                  <Fade bottom delay={1000}>
                                    {data.BANNERMAIN}

                                  </Fade>
                                  </MainBannerText>)
                }
                

                  <SubBannerText color={colorConvert(data.TYPE3)}>
                  <Fade bottom delay={2000}>
                  {data.BANNERCONTENT}
                  </Fade>
                  </SubBannerText>
                  <BannerButtonView>
                  <Fade bottom delay={4000}>
                    <BannerButton>
                    SHOP NOW
                    </BannerButton>
                  </Fade>
                  </BannerButtonView>
                </Fragment>
              }
        


     
              <Ad/>

            </SwiperSlide>

            
          ))
        }
      </Swiper> 


    </div>
  
  );
};

export default Advertise;
