import React, { Fragment, useContext, useEffect, useState } from "react";
import { MaxPC, Mobile, PC, SmallPC } from "../../Responsive";
import { imageDB } from "../../../components/imageDB";
import { COLORS } from "../../Theme";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Define } from "../../Define";
import { UserContext } from "../../../context/Users";

const PCHeader = styled.div`
  height: 100px;
  text-align: center;
  align-items: center;
  display: flex;
`;
const MaxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  padding: 0px 20%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px 15%;
`;

const ImageLine = styled.div``;
const CategoryLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;
const SelectCategory = styled.div`
  background-color: #323232;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-weight:900;
`;
const Category = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #5b5b5b;
  font-weight:900;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Header = ({ callback, type }) => {
  const navigate = useNavigate();
  const { dispatch, user } = useContext(UserContext);
  
  

  const _handleMain = () => {
    navigate("/home");

    let categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
    setSelect(categoryselect);
    setAllcategory(false);

    callback(Define.home);
  };
  const _handleAllCategory = () => {
    // console.log("all category");

    // setAllcategory(true);
    // callback(Define.allcategory);

    navigate("/itemproduct", { state: { CATEGORYINFO: "전체" } });

    setAllcategory(true);
    let categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
    setSelect(categoryselect);
    callback(Define.allcategory);
  };
  const _handleCategory = (index) => {
    let categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
    categoryselect[index] = 1;

    setSelect(categoryselect);
    setAllcategory(false);

    if (index == 0) {
      callback(Define.allcategory);
      navigate("/itemproduct", { state: { CATEGORYINFO: "전체" } });
    } else if (index == 1) {
      callback(Define.best);
      navigate("/bestproduct");
    } else if (index == 2) {
      callback(Define.new);
      navigate("/newproduct");
    } else if (index == 3) {
      callback(Define.md);
      navigate("/mdproduct");
    } else if (index == 4) {
      callback(Define.gomargin);
      navigate("/marginproduct");
    } else if (index == 5) {
      callback(Define.top);
      navigate("/topproduct");
    } else if (index == 6) {
      callback(Define.soldout);
      navigate("/soldoutproduct");
    } else if (index == 7) {
      if (user.USERID == "") {
        alert("로그인이 필요한 메뉴입니다");
        navigate("/memberlogin");
        return;
      } else {
        callback(Define.notice);
        navigate("/notice");
      }
    }
  };

  const [allcategory, setAllcategory] = useState(false);
  const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
  const [select, setSelect] = useState(categoryselect);

  useEffect(() => {
    // console.log("type", type);
    if (type == Define.allcategory) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[0] = 1;
      setSelect(categoryselect);
    } else if (type == Define.best) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[1] = 1;
      setSelect(categoryselect);
    } else if (type == Define.new) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[2] = 1;
      setSelect(categoryselect);
    } else if (type == Define.md) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[3] = 1;
      setSelect(categoryselect);
    } else if (type == Define.gomargin) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[4] = 1;
      setSelect(categoryselect);
    } else if (type == Define.top) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[5] = 1;
      setSelect(categoryselect);
    } else if (type == Define.soldout) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[6] = 1;
      setSelect(categoryselect);
    } else if (type == Define.out) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[7] = 1;
      setSelect(categoryselect);
    }

    return () => {};
  }, []);

  const categoryItems = [
    "전체카테고리",
    "BEST 100",
    "신규출시",
    "MD픽!",
    "GO!마진",
    "판매량 TOP10",
    "임시품절",
    "게시판",
  ];

  return (
    <Fragment>
      <PCHeader>
        <MaxPC>
          <MaxContainer>
            <ImageLine onClick={_handleMain}>
              <img src={imageDB.mainlogo} width={130} />
            </ImageLine>

            <CategoryLine>
              {categoryItems.map((category, index = 0) => (
                <>
                  {select[index] == 0 ? (
                    <Category
                      key={index}
                      onClick={() => {
                        _handleCategory(index);
                      }}
                    >
                      {category}
                    </Category>
                  ) : (
                    <SelectCategory
                      key={index}
                      onClick={() => {
                        _handleCategory(index);
                      }}
                    >
                      {category}
                    </SelectCategory>
                  )}
                </>
              ))}
            </CategoryLine>
          </MaxContainer>
        </MaxPC>
        <SmallPC>
          <Container>
            <ImageLine onClick={_handleMain}>
              <img src={imageDB.mainlogo} width={130} />
            </ImageLine>

            <CategoryLine>
              {categoryItems.map((category, index = 0) => (
                <>
                  {select[index] == 0 ? (
                    <Category
                      key={index}
                      onClick={() => {
                        _handleCategory(index);
                      }}
                    >
                      {category}
                    </Category>
                  ) : (
                    <SelectCategory
                      key={index}
                      onClick={() => {
                        _handleCategory(index);
                      }}
                    >
                      {category}
                    </SelectCategory>
                  )}
                </>
              ))}
            </CategoryLine>
          </Container>
        </SmallPC>
      </PCHeader>
    </Fragment>
  );
};

export default Header;
