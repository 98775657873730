import { db, auth, storage } from "../api/config";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
  doc,
  setDoc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const registBasket = async ({
  USER_ID,
  PRODUCT_ID,
  OPTIONBASKETLIST,
}) => {
  let success = false;

  const Ref = doc(collection(db, "BASKET"));
  const id = Ref.id;

  try {
    const newData = {
      BASKET_ID: id,
      USER_ID,
      PRODUCT_ID,
      OPTIONBASKETLIST,
      REGISTDATE: Date.now(),
    };

    await setDoc(Ref, newData);
  } catch (e) {
    success = false;
    return null;
  } finally {
    return id;
  }
};

export const getBaskset = async ({ USER_ID }) => {
  console.log("USER_ID", USER_ID);

  if (USER_ID == "") {
    return [];
  }
  const Ref = collection(db, "BASKET");

  let Item = [];
  let success = false;
  const q = query(Ref, where("USER_ID", "==", USER_ID));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      Item.push(doc.data());
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(Item);
      } else {
        resolve(-1);
      }
    });
  }
};

export const getBasksetbyBASKET_ID = async ({ BASKET_ID }) => {
  console.log("BASKET_ID", BASKET_ID);

  const Ref = collection(db, "BASKET");

  let Item = {};
  let success = false;
  const q = query(Ref, where("BASKET_ID", "==", BASKET_ID));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      Item = doc.data();
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(Item);
      } else {
        resolve(-1);
      }
    });
  }
};

export const deleteBasket = async ({ BASKET_ID }) => {
  const basektRef = doc(db, "BASKET", BASKET_ID);
  try {
    await deleteDoc(basektRef);
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};

export const updateBasket = async ({ BASKET_ID, ORDER_TMP_ID }) => {

  const basketRef = collection(db, "BASKET");
  const rows = query(basketRef, where("BASKET_ID", '==', BASKET_ID));
  try {

    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {

      console.log("update", doc.data())
      updateDoc(doc.ref, {
        ORDER_TMP_ID: ORDER_TMP_ID,

      });
    });

  } catch (e) {
    console.log("error", e.message);
  } finally {

    return new Promise((resolve, resject) => {
      resolve(0);

      console.log("member update complete");
    })

  }


}


export const deleteBasketByorderId = async ({ ORDER_TMP_ID }) => {

  const basektRef = doc(db, "BASKET", ORDER_TMP_ID);

  try {
    await deleteDoc(basektRef);
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return;
  }
};

export const updateBasketByorderId = async ({ ORDER_TMP_ID }) => {

  const basketRef = collection(db, "BASKET");
  const rows = query(basketRef, where("ORDER_TMP_ID", '==', ORDER_TMP_ID));
  try {

    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {

      console.log("update", doc.data())
      updateDoc(doc.ref, {
        ORDER_TMP_ID: "",

      });
    });

  } catch (e) {
    console.log("error", e.message);
  } finally {

    return new Promise((resolve, resject) => {
      resolve(0);

      console.log("member update complete");
    })

  }


}



