

import React, { useEffect} from "react";
import {useMediaQuery} from 'react-responsive';

export const Mobile = (props) => {
  const isMobile = useMediaQuery({
    query : "(max-width:768px)"
  });
  
  return <>{isMobile && props.children}</>
}

export const PC = (props) => {
  const isPc = useMediaQuery({
    query : "(min-width:769px)"
  });
  
  return <>{isPc && props.children}</>
}

export const SmallPC = (props) => {
  const isPc = useMediaQuery({
    query : "(max-width:1440px)"
  });
  
  return <>{isPc && props.children}</>
}

export const MaxPC = (props) => {
  const isPc = useMediaQuery({
    query : "(min-width:1450px)"
  });
  
  return <>{isPc && props.children}</>
}
