import React, { useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/PageEx";
import { getMd, getMdVideo } from "../../utility/mdfirebase";
import SubLabel from "./SubLabel";
import { getnews } from "../../utility/newsfirebase";
import PickItem from "./PickItem";
import { MaxPC, SmallPC } from "../../components/Responsive";
import { ProductNameTag } from "../../components/Define";
import { getproductbyid } from "../../utility/productfirebase";

const Pick = () => {
  const [mditems, setMditems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [mdvideo, setMdVideo] = useState("");
  useEffect(() => {
    async function fetchData() {
      let mditems = [];
      let mditemsTmp = [];
      mditemsTmp = await getMd();
      // console.log("temp", mditemsTmp);
      
      let mdvideoitem = await getMdVideo();

      if (mdvideoitem != null) {
        let mdvideo_ = mdvideoitem.MDVIDEO;
        setMdVideo(mdvideo_);
      }

      mditemsTmp.map(async (data, index) => {
        const PRODUCT_ID = data.PRODUCT_ID;
        const indata = await getproductbyid({ PRODUCT_ID });
        if (indata != null) {
          mditems.push(indata);
        }

       setMditems(mditems);
      });


      // console.log("mditems", mditems);
    }

    fetchData();
  }, [refresh]);

  const [news1, setNews1] = useState("");
  const [news1_id, setNews1_id] = useState("");
  const [type1, setType1] = useState("");
  const [news2, setNews2] = useState("");
  const [news2_id, setNews2_id] = useState("");
  const [type2, setType2] = useState("");

  useEffect(() => {
    async function fetchData() {
      let newslist = [];

      newslist = await getnews();

      // console.log("newslist", newslist);

      setNews1("");
      setNews2("");
      newslist.forEach((element) => {
        if (element.ORDER == 1) {
          setNews1(element.NEWS);
          // console.log("news1", element.NEWS, news1);
          setNews1_id(element.NEWS_ID);
          setType1(element.TYPE);
          setRefresh((refresh) => refresh * -1);
        }
        if (element.ORDER == 2) {
          setNews2(element.NEWS);
          setNews2_id(element.NEWS_ID);
          setType2(element.TYPE);
          setRefresh((refresh) => refresh * -1);
        }
      });

      // console.log("newslist", newslist);
    }

    fetchData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        marginTop: 50,
        marginBottom: 50,
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          height: 60,
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Label name={"MD ' s Pick "} weight={800} />
        <div style={{ height: 10 }} />
        <SubLabel
          name={"영상으로 함께 만나보는 제품"}
          weight={100}
          color={"#999"}
        />
        <div style={{ height: 10 }} />
      </div>

      {/* <div style={{display:"flex", flexDirection:"row", marginTop:10, flexWrap:"wrap", justifyContent:"space-around"}}>
        
      {
          mditems.map((data, index)=>(
            <BestItem product_id ={data.PRODUCT_ID} img={data.PRODUCTIMG} name={data.PRODUCTNAME} select= {data.PRODUCTSELECT}
            price={data.PRODUCTREGULARPRICE} lowprice = {data.PRODUCTLOWPRICE} component ={data.PRODUCTCOMPONENT} add={data.PRODUCT_ADD11_INFO}/>
          ))
        }

      </div> */}
      <div style={{ display: "flex", marginTop: 20, flexDirection: "row" }}>
        <SmallPC>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              alignItems: "center",
            }}
          >
            {mdvideo != "" && (
              <video
                class="video"
                autoplay
                controls
                muted
                style={{ width: "100%" }}
              >
                <source type="video/mp4" src={mdvideo + ".mp4"} />
              </video>
            )}
          </div>
        </SmallPC>
        <MaxPC>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "70%",
              alignItems: "center",
            }}
          >
            {mdvideo != "" && (
              <video
                class="video"
                autoplay
                controls
                muted
                style={{ width: "100%" }}
              >
                <source type="video/mp4" src={mdvideo + ".mp4"} />
              </video>
            )}
          </div>
        </MaxPC>

        <div
          style={{
            width: "500px",
            height: "480px",
            maxHeight: "500px",
            overflow: "auto",
            paddingLeft: 20,
            paddingTop: 5,
          }}
        >
          {mditems.map((data, index) => (
            <>
              {
                <>
                  <PickItem
                    product_id={data.PRODUCT_ID}
                    img={data.PRODUCTIMAGEARY[0]}
                    name={ProductNameTag + data.PRODUCTNAME}
                    select={data.PRODUCTSELECT}
                    price={data.PRODUCTREGULARPRICE}
                    lowprice={data.PRODUCTLOWPRICE}
                    component={data.PRODUCTCOMPONENT}
                    add={data.PRODUCT_ADD11_INFO}
                    supply={data.PRODUCTSUPPLYPRICE}
                  />
                  <div
                    style={{
                      height: 1,
                      backgroundColor: "#ededed",
                      margin: "10px 0px",
                    }}
                  ></div>
                </>
              }
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pick;
