import React from 'react';
import Homecontainer from '../container/Homecontainer';
import Layout from '../components/Layout/Layout';
import Productcontainer from '../container/Productcontainer';
import ProductLayout from '../components/Layout/ProductLayout';
import { useLocation } from 'react-router-dom';

const Productpage = () => {
  const {state} = useLocation();	// 2번 라인
  const {PRODUCT_ID} = state;	// 3번 라인

  return (
    <Layout>
      <Productcontainer PRODUCT_ID={PRODUCT_ID}/>
    </Layout>

  );
}

export default Productpage;
