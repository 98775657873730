import { db, auth, storage } from "../api/config";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
  doc,
  setDoc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const registUser = async ({
  USERID,
  PASSWORD,
  TEL,
  RANK,
  BUSINESSNUM,
  CEONAME,
  STORENAME,
  BANKLICENSE,
  BUSINESSLICENSE,
  BANK,
  BRAND,
  EMAIL,
}) => {
  let success = false;

  const Ref = doc(collection(db, "USER"));
  const id = Ref.id;

  try {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      USERID,
      PASSWORD
    );


    const newData = {
      USER_ID: id,
      USERID,
      PASSWORD,
      TEL,
      RANK,
      BUSINESSNUM,
      CEONAME,
      STORENAME,
      BANKLICENSE,
      BUSINESSLICENSE,
      BANK,
      BRAND,
      EMAIL,
      LEVEL: "일반",
      MEMO: "",
      APPROVE : false,
      REGISTDATE: Date.now(),
    };

    await setDoc(Ref, newData);
  } catch (e) {
    console.log("auth ", e.message);
    alert(e.message);
    success = false;
    return null;
  } finally {
    return id;
  }
};


export const updateUser = async ({
  USERID,
  PASSWORD,
  TEL,
  RANK,
  BUSINESSNUM,
  CEONAME,
  STORENAME,
  BANKLICENSE,
  BUSINESSLICENSE,
  BANK,
  BRAND,
  EMAIL,
}) => {
  let success = false;

  const Ref = collection(db, "USER");
  const rows = query(Ref, where("USERID", "==", USERID));
  try {
    const querySnapshot = await getDocs(rows);
    querySnapshot.forEach(function (doc) {
      console.log("update", doc.data());
      updateDoc(doc.ref, {
        USERID: USERID,
        PASSWORD: PASSWORD,
        TEL: TEL,
        RANK: RANK,
        BUSINESSNUM: BUSINESSNUM,
        CEONAME: CEONAME,
        STORENAME: STORENAME,
        BANKLICENSE: BANKLICENSE,
        BUSINESSLICENSE: BUSINESSLICENSE,
        BANK: BANK,
        BRAND: BRAND,
        EMAIL: EMAIL,
        REGISTDATE: Date.now(),
      });
    });
  } catch (e) {
    console.log("auth ", e.message);

    alert(e.message);
    success = false;
    return null;
  } finally {
    return 0;
  }
};

export const updateOutUser = async ({
  USERID,
}) => {
  let success = false;

  const Ref = collection(db, "USER");
  const rows = query(Ref, where("USERID", "==", USERID));
  try {
    const querySnapshot = await getDocs(rows);
    querySnapshot.forEach(function (doc) {
      console.log("update", doc.data());
      updateDoc(doc.ref, {
        OUT : true, 
        OUTDATE: Date.now(),
      });
    });
  } catch (e) {
    console.log("auth ", e.message);

    alert(e.message);
    success = false;
    return null;
  } finally {
    return 0;
  }
};

export const loginUser = async ({ USERID, PASSWORD }) => {
  console.log("USER INFO", USERID, PASSWORD);

  let success = false;

  let user = {};

  const member = await getUser({ USERID });

  if (member.OUT == true) {
    
    return -2;
  }

  try {
    user = await signInWithEmailAndPassword(auth, USERID, PASSWORD);
    success = true;
  } catch (e) {
    console.log("error", e);
  } finally {
    return new Promise((resolve, reject) => {
      if (success) {
        resolve(user);
      } else {
        resolve(-1);
      }
    });
  }
};
export const getUser = async ({ USERID }) => {
  const Ref = collection(db, "USER");

  let Item = null;
  let success = false;
  const q = query(Ref, where("USERID", "==", USERID));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      Item = doc.data();
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(Item);
      } else {
        resolve(-1);
      }
    });
  }
};


export const signupUser = async () => {
  return new Promise((resolve, resject) => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.

        resolve(0);
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
        resolve(-1);
      });
  });
};



