

import React, { Fragment, useContext, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";
import Fade from 'react-reveal/Fade';

import { getBanner, getMainBanner, getMainMaxBanner } from "../../utility/bannerfirebase";

import ReactTyped from "react-typed";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import Ad from './Ad';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { getproductbynum } from "../../utility/productfirebase";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/Users";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Autoplay]); // *


const swiperStyle={
  position :"relative",
  width :'100%',
}



const MainBannerText = styled.div`
  font-size: 33px;
  position: absolute;
  top: 100px;
  left: 190px;
  text-align:left;
  z-index: 2;
  color: ${({color})=> color};
  justify-content:flex-start;
  font-weight:900;

`
const SubBannerText = styled.div`
  font-size: 18px;
  position: absolute;
  top: 150px;
  left: 190px;
  text-align:left;
  z-index: 2;
  width: 30%;
  color: ${({color})=> color};
  justify-content:flex-start;
  font-family: 'Pretendard-Regular';
`

const BannerButtonView = styled.div`
  font-size: 18px;
  position: absolute;
  top: 260px;
  left: 190px;
  text-align:left;
  justify-content:flex-start;
  z-index: 2;
  cursor:pointer;


`;
const BannerButton = styled.div`
  font-size: 16px;
  width: 150px;
  height:40px;
  border :1px solid #000;
  border-radius : 30px;
  color: #000;
  display:flex;
  justify-content:center;
  align-items:center;
  font-family: 'Pretendard-Regular';
`

const MainMaxBanner = () => {

  const navigate = useNavigate();
  const { user, dispatch2 } = useContext(UserContext);
  
  const [banneritems, setBanneritems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [bannerimg, setBannerimg] = useState('');
  const [bannermain, setBannermain] = useState('');
  const [bannercontent, setBannercontent] = useState('');

  const colorConvert = (color)=>{

    if(color =='흰색'){
      return "#fff";
    }else if(color =='검정색'){
      return "#000";
    }else if(color =='파란색'){
      return "#0000ff";
    }else if(color =='그린색'){
      return "#00ff00";
    }else if(color =='빨간색'){
      return "#ff0000";
    }
  }
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next),
    // 다른 설정들...
  };

  const slideStyle = {
    marginLeft: swiperIndex === 0 ? '0%' : '-20%', // 이전 슬라이드의 일부를 보이도록 설정
  };


  const _handleProduct = async(data)=>{


    const PRODUCTNUM = data;

    const product = await getproductbynum({PRODUCTNUM});


    if (user.USERID == "") {
      alert("로그인을 먼저 진행해주세요");
      return;
    }
    
    navigate("/product", {state : {PRODUCT_ID: product.PRODUCT_ID}});

  }

  useEffect(()=>{
    async function fetchData(){
      let bannerlist = [];
      let bannerlistTmp = [];
      bannerlistTmp = await getMainMaxBanner();

      bannerlistTmp.forEach(element => {

        // console.log("banners", element);
        if(element.DISPOSE == true){
          bannerlist.push(element);
          setBannerimg(element.BANNERIMG);
          setBannermain(element.BANNERMAIN);
          setBannercontent(element.BANNERCONTENT);
        }
      });

      let data = bannerlist[0];
      bannerlist.splice(0, 1);
      bannerlist.push(data);

      
      setBanneritems(bannerlist);

      // console.log("bannerlist", bannerlist);
    }

    fetchData();
	}, [refresh]);

  return (

    <div style={{flexDirection:"row", backgroundColor:"white", justifyContent:"center", display:"flex"}}>
  
    <Swiper spaceBetween={10} 
    onActiveIndexChange={(swiperCore) => {setSwiperIndex(swiperCore.activeIndex) }}
    navigation= {
    {   nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',}
    }
    slidesPerView={1} loop={true} scrollbar={{ draggable: false }} style={swiperStyle} autoplay={{ delay: 7000, disableOnInteraction: true }} >
      {
        banneritems.map((data, index = 0)=>(
          <SwiperSlide key={index}>
              <img src={data.BANNERIMG} style={{ width:"100%", height:"450px"}} /> 

            
              <MainBannerText color={"#000"}>
                <Fade bottom delay={1000}>
                  {data.BANNERMAIN}
                </Fade>
              </MainBannerText>
          
              <SubBannerText color={"#000"}>
              <Fade bottom delay={2000}>
              {data.BANNERCONTENT}
              </Fade>
              </SubBannerText>
              <BannerButtonView onClick={()=>{_handleProduct(data.PRODUCTNUM)}}>
              <Fade bottom delay={4000}>
                <BannerButton >
                SHOP NOW
                </BannerButton>
              </Fade>
              </BannerButtonView>

              {/* <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div> */}

          </SwiperSlide>

          
        ))
      }
    </Swiper> 

  </div>
 
    // <div style={{flexDirection:"row", backgroundColor:"white", justifyContent:"center", display:"flex"}}>
    //     <img src={bannerimg} style={{width:"100%", contain:"content"}}/>
    //     <MainBannerText color={"#000"}>
    //           <Fade bottom delay={1000}>
    //             {bannermain}
    //           </Fade>
    //     </MainBannerText>
            
                

    //     <SubBannerText color={"#000"}>
    //     <Fade bottom delay={2000}>
    //     {bannercontent}
    //     </Fade>
    //     </SubBannerText>
    //     <BannerButtonView>
    //     <Fade bottom delay={4000}>
    //       <BannerButton>
    //       SHOP NOW
    //       </BannerButton>
    //     </Fade>
    //     </BannerButtonView>

    // </div>
  
  );
};

export default MainMaxBanner;
