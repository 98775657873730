import React, { Fragment, useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import PreviewItem from "./CategoryviewItem";
import Page from "../../components/Layout/PageEx";
import { getcategory1, getcategoryview } from "../../utility/categoryfirebase";
import CategoryviewItem from "./CategoryviewItem";
import SubLabel from "./SubLabel";

const Categoryview = () => {
  const [cateogoryitems, setCategoryitems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  useEffect(() => {
    async function fetchData() {
      let categorylist = [];
      categorylist = await getcategoryview();

      setCategoryitems(categorylist);

      // console.log("categorylist", categorylist);
    }

    fetchData();
  }, [refresh]);

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: 5,
          width: "100%",
          paddingLeft: 30,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 15,
            flexWrap: "wrap",
            justifyContent: "space-around",
            padding: "20px 0px",
          }}
        >
          <PreviewItem img={imageDB.mainlogo2} name={"전체상품"} main={true} />

          {cateogoryitems.map((data, index) => (
            <CategoryviewItem img={data.CATEGORYIMG} name={data.CATEGORYNAME} />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Categoryview;
