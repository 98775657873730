import { db, auth, storage } from "../api/config";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
  doc,
  setDoc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { UserContext } from "../context/Users";
import { useContext } from "react";


export const uploadImage = async ({ uri, email }) => {
  console.log("uploadImage", email, uri);
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };

    xhr.onerror = function (e) {
      reject(new TypeError("Network request failed"));
    };

    xhr.responseType = "blob";
    xhr.open("GET", uri, true);
    xhr.send(null);
  });

  return new Promise((resolve, reject) => {
    const imagefile = "images/" + email + ".png";
    const spaceRef = ref(storage, imagefile);

    uploadBytes(spaceRef, blob).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        // blob.close(); 주석을 임시로 삭제
        resolve(url);
      });
    });
  });
};

export const registproduct = async ({
  productimageary,
  productnum,
  oneselect,
  twoselect,
  productselect,
  productcategory,
  productname,
  productmodelname,
  producttag,
  productcost,
  productregularprice,
  productlowprice,
  productsupplyprice,
  productdeliveryfee,
  productdeliverycost,
  productjejudeliveryfee,
  productislanddeliveryfee,
  productovercountdelivery,
  productoverdeliveryfee,
  productdeliveryservice,
  productkcnum,
  productweight,
  productsize,
  productcomponent,
  productadd11,
  productadd12,
  productadd21,
  productadd22,
  relationproductitems,
  addproductitems,
}) => {
  const productRef = doc(collection(db, "PRODUCT"));
  const id = productRef.id;
  const newproduct = {
    PRODUCT_ID: id,
    PRODUCTDISPOSE: true,
    PRODUCTSOLDOUT: false,
    PRODUCTCOSTVIEW: true,
    PRODUCTIMAGEARY: productimageary,
    PRODUCTNUM: productnum,
    PRODUCTONESELECT: oneselect,
    PRODUCTTWOSELECT: twoselect,
    PRODUCTSELECT: productselect,
    PRODUCTCATEGORY: productcategory,
    PRODUCTNAME: productname,
    PRODUCTMODELNAME: productmodelname,
    PRODUCTTAG: producttag,
    PRODUCTCOST: productcost,
    PRODUCTREGULARPRICE: productregularprice,
    PRODUCTLOWPRICE: productlowprice,
    PRODUCTSUPPLYPRICE: productsupplyprice,
    PRODUCTDELIVERYFEE: productdeliveryfee,
    PRODUCTDELIVERYCOST: productdeliverycost,
    PRODUCTJEJUDELIVERYFEE: productjejudeliveryfee,
    PRODUCTISLANDDELIVERYFEE: productislanddeliveryfee,
    PRODUCTOVERCOUNTDELIVERY: productovercountdelivery,
    PRODUCTOVERDELIVERYFEE: productoverdeliveryfee,
    PRODUCTDELIVERYSERVICE: productdeliveryservice,
    PRODUCTKCNUM: productkcnum,
    PRODUCTWEIGHT: productweight,
    PRODUCTSIZE: productsize,
    PRODUCTCOMPONENT: productcomponent,
    PRODUCT_ADD11_INFO: productadd11,
    PRODUCT_ADD12_INFO: productadd12,
    PRODUCT_ADD21_INFO: productadd21,
    PRODUCT_ADD22_INFO: productadd22,
    PRODUCT_RELATIONPRODUCTITMES: relationproductitems,
    PRODUCT_ADDPRODUCTITMES: addproductitems,
    REGISTDATE: Date.now(),
  };
  try {
    await setDoc(productRef, newproduct);
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return id;
  }
};
export const registproducttmp = async ({
  productimageary,
  productnum,
  oneselect,
  twoselect,
  productselect,
  productcategory,
  productname,
  productmodelname,
  producttag,
  productcost,
  productregularprice,
  productlowprice,
  productsupplyprice,
  productdeliveryfee,
  productdeliverycost,
  productjejudeliveryfee,
  productislanddeliveryfee,
  productovercountdelivery,
  productoverdeliveryfee,
  productdeliveryservice,
  productkcnum,
  productweight,
  productsize,
  productcomponent,
  productadd11,
  productadd12,
  productadd21,
  productadd22,
  relationproductitems,
  addproductitems,
}) => {
  const producttmpRef = doc(collection(db, "PRODUCTTMP"));
  const id = producttmpRef.id;
  const newproduct = {
    PRODUCTTMP_ID: id,
    PRODUCTDISPOSE: false,
    PRODUCTSOLDOUT: false,
    PRODUCTCOSTVIEW: true,
    PRODUCTIMAGEARY: productimageary,
    PRODUCTNUM: productnum,
    PRODUCTONESELECT: oneselect,
    PRODUCTTWOSELECT: twoselect,
    PRODUCTSELECT: productselect,
    PRODUCTCATEGORY: productcategory,
    PRODUCTNAME: productname,
    PRODUCTMODELNAME: productmodelname,
    PRODUCTTAG: producttag,
    PRODUCTCOST: productcost,
    PRODUCTREGULARPRICE: productregularprice,
    PRODUCTLOWPRICE: productlowprice,
    PRODUCTSUPPLYPRICE: productsupplyprice,
    PRODUCTDELIVERYFEE: productdeliveryfee,
    PRODUCTDELIVERYCOST: productdeliverycost,
    PRODUCTJEJUDELIVERYFEE: productjejudeliveryfee,
    PRODUCTISLANDDELIVERYFEE: productislanddeliveryfee,
    PRODUCTOVERCOUNTDELIVERY: productovercountdelivery,
    PRODUCTOVERDELIVERYFEE: productoverdeliveryfee,
    PRODUCTDELIVERYSERVICE: productdeliveryservice,
    PRODUCTKCNUM: productkcnum,
    PRODUCTWEIGHT: productweight,
    PRODUCTSIZE: productsize,
    PRODUCTCOMPONENT: productcomponent,
    PRODUCT_ADD11_INFO: productadd11,
    PRODUCT_ADD12_INFO: productadd12,
    PRODUCT_ADD21_INFO: productadd21,
    PRODUCT_ADD22_INFO: productadd22,
    PRODUCT_RELATIONPRODUCTITMES: relationproductitems,
    PRODUCT_ADDPRODUCTITMES: addproductitems,
    REGISTDATE: Date.now(),
  };
  try {
    await setDoc(producttmpRef, newproduct);
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return id;
  }
};
export const getproduct = async () => {


  const productRef = collection(db, "PRODUCT");

  let productItem = [];
  let success = false;
  const q = query(productRef);

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {

      productItem.push(doc.data());

 
    
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(productItem);
      } else {
        resolve(productItem);
      }
    });
  }
};
export const getproductbyid = async ({ PRODUCT_ID }) => {
  const productRef = collection(db, "PRODUCT");

  let product = {};
  let success = false;
  const q = query(productRef, where("PRODUCT_ID", "==", PRODUCT_ID));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      product = doc.data();
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(product);
      } else {
        resolve(product);
      }
    });
  }
};

export const getproductbynum = async ({ PRODUCTNUM }) => {
  const productRef = collection(db, "PRODUCT");


  
  let product = null;
  let success = false;
  const q = query(productRef, where("PRODUCTNUM", "==", PRODUCTNUM));

  try {
    const querySnapshot = await getDocs(q);


    querySnapshot.forEach((doc) => {
      product = doc.data();

    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(product);
      } else {
        resolve(product);
      }
    });
  }
};

export const updateproduct = async ({ data }) => {
  console.log("data", data.PRODUCT_ID, data);

  const productRef = collection(db, "PRODUCT");

  const PRODUCT_ID = data.PRODUCT_ID;

  const rows = query(productRef, where("PRODUCT_ID", "==", PRODUCT_ID));
  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      console.log("update", doc.data());
      updateDoc(doc.ref, {
        PRODUCTCHECK: true,
        PRODUCTDISPOSE: data.PRODUCTDISPOSE,
        PRODUCTCOSTVIEW: data.PRODUCTCOSTVIEW,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      resolve(0);

      console.log("member update complete");
    });
  }
};
export const updateallproduct = async ({
  product_id,
  productimageary,
  productnum,
  oneselect,
  twoselect,
  productselect,
  productcategory,
  productname,
  productmodelname,
  producttag,
  productcost,
  productregularprice,
  productlowprice,
  productsupplyprice,
  productdeliveryfee,
  productdeliverycost,
  productjejudeliveryfee,
  productislanddeliveryfee,
  productovercountdelivery,
  productoverdeliveryfee,
  productdeliveryservice,
  productkcnum,
  productweight,
  productsize,
  productcomponent,
  productadd11,
  productadd12,
  productadd21,
  productadd22,
  relationproductitems,
  addproductitems,
}) => {
  const productRef = collection(db, "PRODUCT");

  const rows = query(productRef, where("PRODUCT_ID", "==", product_id));
  try {
    const querySnapshot = await getDocs(rows);

    querySnapshot.forEach(function (doc) {
      console.log("update", doc.data());
      updateDoc(doc.ref, {
        PRODUCTIMAGEARY: productimageary,
        PRODUCTNUM: productnum,
        PRODUCTONESELECT: oneselect,
        PRODUCTTWOSELECT: twoselect,
        PRODUCTSELECT: productselect,
        PRODUCTCATEGORY: productcategory,
        PRODUCTNAME: productname,
        PRODUCTMODELNAME: productmodelname,
        PRODUCTTAG: producttag,
        PRODUCTCOST: productcost,
        PRODUCTREGULARPRICE: productregularprice,
        PRODUCTLOWPRICE: productlowprice,
        PRODUCTSUPPLYPRICE: productsupplyprice,
        PRODUCTDELIVERYFEE: productdeliveryfee,
        PRODUCTDELIVERYCOST: productdeliverycost,
        PRODUCTJEJUDELIVERYFEE: productjejudeliveryfee,
        PRODUCTISLANDDELIVERYFEE: productislanddeliveryfee,
        PRODUCTOVERCOUNTDELIVERY: productovercountdelivery,
        PRODUCTOVERDELIVERYFEE: productoverdeliveryfee,
        PRODUCTDELIVERYSERVICE: productdeliveryservice,
        PRODUCTKCNUM: productkcnum,
        PRODUCTWEIGHT: productweight,
        PRODUCTSIZE: productsize,
        PRODUCTCOMPONENT: productcomponent,
        PRODUCT_ADD11_INFO: productadd11,
        PRODUCT_ADD12_INFO: productadd12,
        PRODUCT_ADD21_INFO: productadd21,
        PRODUCT_ADD22_INFO: productadd22,
        PRODUCT_RELATIONPRODUCTITMES: relationproductitems,
        PRODUCT_ADDPRODUCTITMES: addproductitems,
      });
    });
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      resolve(0);

      console.log("member update complete");
    });
  }
};
