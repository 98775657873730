import {db, auth, storage} from '../api/config';
import { collection, getDocs, query, updateDoc,where,doc,setDoc, deleteDoc, orderBy } from 'firebase/firestore';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";



export const registBest = async({
   PRODUCTIMG,
   PRODUCT_ID, 

}) =>{
    const bestRef = doc(collection(db, "BEST"));
    const id = bestRef.id;
    const newbest = {
        BEST_ID : id,
        PRODUCTIMG : PRODUCTIMG,
        PRODUCT_ID : PRODUCT_ID,
        REGISTDATE : Date.now(),
    }
    try{
        await setDoc(bestRef, newbest);
    }catch(e){
        console.log("error", e.message);
    }finally{
        return id;
    }

}
export const getBest = async()=>{
    const bestRef = collection(db, "BEST");

    let bestItems = [];
    let success = false;
    const q = query(bestRef, orderBy("REGISTDATE",'desc'));

    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {
            
            bestItems.push(doc.data());
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(bestItems);
            }else{
                resolve(bestItems);  
            }          
        }) 
    }
}
export const deleteBest = async({BEST_ID})=>{
    console.log("delete best", BEST_ID);
    const bestRef = doc(db, "BEST", BEST_ID);
    try{
        await deleteDoc(bestRef);
    }catch(e){
        console.log("error", e.message);
    }finally{
        return;
    }
}
export const updatebest = async({PRODUCTIMG, PRODUCT_ID,BEST_ID})=>{

    const bestRef = collection(db, "BEST");
    const rows = query(bestRef, where("BEST_ID",'==', BEST_ID ));
    try{

        const querySnapshot =  await getDocs(rows);

        querySnapshot.forEach(function (doc) {

            console.log("update", doc.data())
            updateDoc(doc.ref, {
                PRODUCTIMG : PRODUCTIMG,
                PRODUCT_ID : PRODUCT_ID,
         
            });
        });

    }catch(e){
        console.log("error", e.message);
    }finally{

        return new Promise((resolve, resject)=>{
            resolve(0);

            console.log("member update complete");
        })  

    }


}
