import React, { useEffect } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/PageEx";
import WithItem from "./WithItem";
import Fade from "react-reveal/Fade";
import ProductInfo from "./ProductInfo";

const With = ({ product }) => {

  console.log("width", product)
  return (
    <div
      style={{
        display: "flex",
        marginTop: 10,
        padding: "0px 10%",
        flexDirection: "column",
      }}
    >
      {product.PRODUCT_RELATIONPRODUCTITMES.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              height: 60,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Label name={"이상품과 관련된 상품"} weight={600} size={25} />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
              flexWrap: "wrap",
            }}
          >
            {product.PRODUCT_RELATIONPRODUCTITMES.map((data, index) => (
              <WithItem
                img={data.PRODUCTIMG}
                name={data.PRODUCTNAME}
                price={data.PRODUCTPRICE}
                product_id={data.PRODUCT_ID}
              />
            ))}
          </div>
        </>
      )}

      <div style={{ marginBottom: 30 }}></div>

      <ProductInfo product={product} />
    </div>
  );
};

export default With;
