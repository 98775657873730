import React from "react";

import Layout from "../components/Layout/Layout";

import { Define } from "../components/Define";
import Itemproductcontainer from "../container/Itemproductcontainer";
import { useLocation } from "react-router-dom";
import Noticecontainer from "../container/Noticecontainer";

const Noticepage = ({ pagetype }) => {
  const { state } = useLocation(); // 2번 라인
  // const {CATEGORYINFO} = state;	// 3번 라인

  let Categoryinfo = "";
  if (state == undefined) {
    Categoryinfo = "전체";
  } else {
    Categoryinfo = state;
  }

  return (
    <Layout type={pagetype}>
      <Noticecontainer />
    </Layout>
  );
};

export default Noticepage;
