import React, { Fragment, useContext, useEffect, useState } from "react";
import { imageDB } from "../components/imageDB";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import "../components/css/common.css";
import Search from "../components/Layout/SearchBasket";
import Best from "./component/Best";
import BestItem from "./component/BestItem";
import { getproduct } from "../utility/productfirebase";
import { CategoryContext } from "../context/Category";
import CategoryItem from "./component/CategoryItems";
import { deleteBasketByorderId } from "../utility/basketfirebase";
import { getOrderTmp, registOrder } from "../utility/orderfirebase";
import axios from "axios";

const Label = styled.div`
  margin-top: 40px;
  font-size: 30px;
`;
const Filter = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  align-items: center;
  margin-top: 20px;
  border-radius: 10px;
`;

const FilterItems = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  font-size: 16px;
`;
const FilterSelect = styled.div`
  font-size: 16px;
  font-weight: 900;
  text-decoration: underline;
`;
const FilterNoselect = styled.div`
  font-size: 14px;
`;

const FilterItem = styled.div`
  font-size: ${({ select }) => (select == true ? "15px" : "14px")};
  font-weight: 900;
  text-decoration: ${({ select }) => (select == true ? "underline" : "none")};
`;

const ReturnMsg = styled.div`
  font-size: 25px;
  font-weight: 900;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    justify-content: center;
    align-items: center;
`

const CardApproveSuccesscontainer = ({ orderid, paymentKey, amount }) => {

  const navigate = useNavigate();

  const [status, setStatus] = useState(0);
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    setStatus(status);
  }, [refresh])

  useEffect(() => {
    
    console.log("orderinformation", orderid, paymentKey, amount);

    async function DeleteAddData() {
      const ORDER_TMP_ID = orderid;
      const del = await deleteBasketByorderId({ ORDER_TMP_ID });

      let OrderItem = []; 
      const ORDERNUM = ORDER_TMP_ID;
      OrderItem = await getOrderTmp( {ORDERNUM} );

      console.log("OrderItem", OrderItem, ORDERNUM);

      OrderItem.map(async (data, index) => {

        const USER_ID = data.USER_ID;
        const BANK_USER = "";
        const BANK_NAME = "";
        const BANK_NUM = "";
        const CARD_NAME = "TOS결재";
        const CARD_NUM = "TOS결재";
        const CARD_TYPE = "TOS결재";
        const MONEY = data.MONEY;

        const ORDERLIST = data.ORDERLIST;
        const ORDERUSER = data.ORDERUSER;
        const ORDERRECEIVEUSER = data.ORDERRECEIVEUSER;
        const ORDERTEL = data.ORDERTEL;
        const RECEIVETEL = data.RECEIVETEL;
        const RECEIVEADDRESS1 = data.RECEIVEADDRESS1;
        const RECEIVEADDRESS2 = data.RECEIVEADDRESS2;
        const RECEIVEDELIVERYMSG = data.RECEIVEDELIVERYMSG;
        const RECEIVEZONECODE = data.RECEIVEZONECODE;
        const STORENAME = data.STORENAME;
        const BRANDNAME = data.BRANDNAME;
        const ORDER_MASSORDER = data.ORDER_MASSORDER;

        const ORDERNUM = data.ORDERNUM;

        console.log("orderlist", ORDERLIST);

        const ORDER_TYPE = "결제완료";

        const registorder_ = await registOrder({
          USER_ID,
          ORDER_MASSORDER,
          BANK_USER,
          BANK_NAME,
          BANK_NUM,
          CARD_NAME,
          CARD_NUM,
          CARD_TYPE,
          MONEY,
          ORDERLIST,
          ORDERUSER,
          ORDERRECEIVEUSER,
          ORDERTEL,
          RECEIVETEL,
          RECEIVEADDRESS1,
          RECEIVEADDRESS2,
          RECEIVEDELIVERYMSG,
          RECEIVEZONECODE,
          STORENAME,
          BRANDNAME,
          ORDERNUM,
          ORDER_TYPE,
        });

      })

    }


    async function ApiCall() {
      const widgetSecretKey = "live_sk_KNbdOvk5rk5lLLNXOaao3n07xlzm";

      
      // const encryptedSecretKey =
      //   "Basic " + Buffer.from(widgetSecretKey + ":").toString("base64");
      
      const encryptedSecretKey = "Basic " + btoa(widgetSecretKey + ":");


      console.log("encryptdSecretkey", encryptedSecretKey);


      const ORDERNUM = orderid;

      const data = {
        orderId: orderid,
        amount: amount,
        paymentKey: paymentKey,
      }
   

      // 결제를 승인하면 결제수단에서 금액이 차감돼요.

      await axios.post("https://api.tosspayments.com/v1/payments/confirm",data, {
        headers: {
          Authorization: encryptedSecretKey,
          "Content-Type": "application/json",
        },
        responseType: "json"
      }).then(function (response) {
          // 결제 성공 비즈니스 로직을 구현하세요.
          console.log(response.body);
          // res.status(response.statusCode).json(response.body);

          setStatus(1);
          DeleteAddData();
          setRefresh(refresh => refresh + 1);

        })
        .catch(function (error) {
          // 결제 실패 비즈니스 로직을 구현하세요.
          console.log(error.response);
          // res.status(error.response.statusCode).json(error.response.body);
          setStatus(2);
          setRefresh(refresh => refresh + 1);
        }); 
    }

    ApiCall();


  },[]);

  return (
    <Container>

      <div style={{ padding: "150px 0px 200px" }}>
        <img
          src={imageDB.mainlogo}
          style={{  height: 100 }}
        />
        {
          status == 1 &&
          <ReturnMsg>카드결재 성공 하였습니다. 주문페이지를 확인해 주세요</ReturnMsg>  
        }

        {
          status == 2 &&
          <ReturnMsg>카드결재 실패 하였습니다.</ReturnMsg>
        }

      </div>

    

    </Container>
  );
}

export default CardApproveSuccesscontainer;