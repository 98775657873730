import React, { useEffect} from "react";


export const COLORS =  {
  sidecolor  : "#e9f5fad9",
  primary :"#47acffd9",
  mainbtncolor :"",
  highlightbtncolor : "#ffaa08d9",
  defaultbtncolor :"",
  disablecolor :"#bab5b5",
  footerbgcolor :"#323232",
  footercontentcolor : "#F8F8F8",
  testcolor :"#ebebebd9",
}

export const SIZE = {
  middlesize : 12,
  smallsize :14,
  largesize :16,
}




