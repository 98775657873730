import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "../css/common.css";
import SelectItem2 from "../../container/component/SelectItem2";
import Button from "../Layout/Button";
import { registAsk } from "../../utility/askfirebase";
import { UserContext } from "../../context/Users";

const Label = styled.div`
  margin-top: 40px;
  font-size: 30px;
  font-family: Pretendard-Bold;
  font-weight: 900;
`;

const BoldName = styled.div`
  font-size: 18px;
  font-weight: 900;
  margin-left: 10px;
  margin-right: 10px;
`;

const Exampletxt =
  "업체명 : " +
  "\r" +
  "대표자 : " +
  "\r" +
  "브랜드명 : " +
  "\r" +
  "담당자 : " +
  "\r" +
  "담당자 연락처 : " +
  "\r" +
  "문의 내용 : " +
  "\r";
const Etcask = () => {
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [content, setContent] = useState("");
  const [contentType, setContentType] = useState("상품문의");
  const { user, dispatch2 } = useContext(UserContext);

  const oncontentChange = (e) => {
    setContent(e.target.value);
    setRefresh((refresh) => refresh + 1);
  };

  const _handleCheck = () => {
    setCheck(!check);
    setRefresh((refresh) => refresh + 1);
  };
  const _handlewrite = async () => {
    const CONTENT = content;
    const USERID = user.USERID;
    const USER_ID = user.USER_ID;
    const CONTENTTYPE = "기타문의";
    const CEONAME = user.CEONAME;
    const SECRET = check;
    const ASKTYPE = "ETC";

    const regist = await registAsk({
      CONTENT,
      CONTENTTYPE,
      ASKTYPE,
      USERID,
      USER_ID,
      CEONAME,
      SECRET,
    });
    alert("게시물이 정상적으로 등록 되었습니다");
    navigate("/notice");
  };
  useEffect(() => {
    setCheck(check);
    setContent(content);
  }, [refresh]);

  return (
    <div className="Container">
      <>
        <Label>{"기타문의작성"}</Label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              background: "#ededed",
              height: 80,
              marginBottom: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              padding: "10px 0px",
              marginTop: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <BoldName>기타문의 : </BoldName>
              <div>
                머레이코리아에 직접 물어보고 싶으시거나 궁금한점 있으면 물어봐 주세요! 확인하는 즉시 안내해드립니다
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <textarea
              placeholder={Exampletxt}
              style={{
                background: "#fff",
                height: 700,
                width: "100%",
                resize: "none",
                outline: 0,
                padding: 20,
                lineHeight: 2,
                fontSize: 20,
              }}
              value={content}
              onChange={oncontentChange}
            ></textarea>
          </div>
          <div
            style={{
              display: "flex",
              margin: "10px 0px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 20,
              }}
            >
              <div style={{ width: 200 }}>비밀글 여부</div>
              <input
                style={{ height: 30 }}
                type="checkbox"
                onClick={_handleCheck}
                value={check}
              />
            </div>

            <Button
              callback={_handlewrite}
              Label={"작성하기"}
              bgcolor={"#689fff"}
              borderRadius={10}
              color={"#fff"}
              bordercolor={"#689fff"}
              height={50}
              width={"20%"}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default Etcask;