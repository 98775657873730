

import React, { useEffect} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";


const Loading = ({containerStyle}) => {

  const Container = styled.div`

  `

  return (

    <Container style={containerStyle}>
      <img src={imageDB.loading} style={{width:"100px"}}/>
    </Container>
  
  );
};



export default Loading;
