

import React, { useEffect} from "react";
import styled from "styled-components";
import { COLORS, SIZE } from "../Theme";
import { imageDB } from '../imageDB';

const Container = styled.div`
  height :30px;
  display: flex;
  justify-content: center;
  align-items : center;
  margin-right:100px;
`



const SearchBasket = ({height}) => {



  return (
    
    <Container height={height} >
        <div >
          <input type="text"  style={{width:120, backgroundColor:'white',
           height:25,border: 'unset',
           borderBottom: '1px solid #000000',
           paddingLeft: "unset",
           paddingTop:5,
           paddingBottom:5,
           paddingRight: "unset",
           borderRadius: 'unset'}}></input>

         
        </div>
        <div style={{position: 'relative',left: '-20px'}}>
            <img src={imageDB.search} width={20} />
        </div>

        <div>
            <img src={imageDB.basket} width={20} />
        </div>
  
    </Container>
  );
};

SearchBasket.defaultProps={
  bordercolor : '#000',
  borderRadius :5,
  height :100,
}
export default SearchBasket;
