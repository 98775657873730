import React, { useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";

const Container = styled.div``;

const CardJumin = ({ callback }) => {
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    setCardjumin1(cardjumin1);
    setCardjumin2(cardjumin2);
    setCardjumin3(cardjumin3);
    setCardjumin4(cardjumin4);
    setCardjumin5(cardjumin5);
    setCardjumin6(cardjumin6);
    callback(
      String(cardjumin1) +
        String(cardjumin2) +
        String(cardjumin3) +
        String(cardjumin4) +
        String(cardjumin5) +
        String(cardjumin6)
    );
  }, [refresh]);

  const [cardjumin1, setCardjumin1] = React.useState("");
  const [cardjumin2, setCardjumin2] = React.useState("");
  const [cardjumin3, setCardjumin3] = React.useState("");
  const [cardjumin4, setCardjumin4] = React.useState("");
  const [cardjumin5, setCardjumin5] = React.useState("");
  const [cardjumin6, setCardjumin6] = React.useState("");

  const oncardjumin1chagne = (e) => {
    setCardjumin1(e.target.value);
    setRefresh((refrehs) => refresh + 1);
  };
  const oncardjumin2chagne = (e) => {
    setCardjumin2(e.target.value);
    setRefresh((refrehs) => refresh + 1);
  };
  const oncardjumin3chagne = (e) => {
    setCardjumin3(e.target.value);
    setRefresh((refrehs) => refresh + 1);
  };
  const oncardjumin4chagne = (e) => {
    setCardjumin4(e.target.value);
    setRefresh((refrehs) => refresh + 1);
  };
  const oncardjumin5chagne = (e) => {
    setCardjumin5(e.target.value);
    setRefresh((refrehs) => refresh + 1);
  };
  const oncardjumin6chagne = (e) => {
    setCardjumin6(e.target.value);
    setRefresh((refrehs) => refresh + 1);
  };

  return (
    <Container>
      <div
        style={{
          paddingLeft: 20,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ width: "10px", marginRight: 30 }}>
          <input
            type="text"
            className="InputCommon2"
            value={cardjumin1}
            onChange={oncardjumin1chagne}
            placeholder=""
            style={{
              height: "10px",
              fontSize: "12px",
              width: 10,
            }}
          ></input>
        </div>
        <div style={{ width: "10px", marginRight: 30 }}>
          <input
            type="text"
            className="InputCommon2"
            value={cardjumin2}
            onChange={oncardjumin2chagne}
            style={{
              height: "10px",
              fontSize: "12px",
              width: 10,
            }}
          ></input>
        </div>
        <div style={{ width: "10px", marginRight: 30 }}>
          <input
            type="text"
            className="InputCommon2"
            value={cardjumin3}
            onChange={oncardjumin3chagne}
            style={{
              height: "10px",
              fontSize: "12px",
              width: 10,
            }}
          ></input>
        </div>
        <div style={{ width: "10px", marginRight: 30 }}>
          <input
            type="text"
            className="InputCommon2"
            value={cardjumin4}
            onChange={oncardjumin4chagne}
            style={{
              height: "10px",
              fontSize: "12px",
              width: 10,
            }}
          ></input>
        </div>
        <div style={{ width: "10px", marginRight: 30 }}>
          <input
            type="text"
            className="InputCommon2"
            value={cardjumin5}
            onChange={oncardjumin5chagne}
            style={{
              height: "10px",
              fontSize: "12px",
              width: 10,
            }}
          ></input>
        </div>
        <div style={{ width: "10px" }}>
          <input
            type="text"
            className="InputCommon2"
            value={cardjumin6}
            onChange={oncardjumin6chagne}
            style={{
              height: "10px",
              fontSize: "12px",
              width: 10,
            }}
          ></input>
        </div>
      </div>
    </Container>
  );
};

CardJumin.defaultProps = {};

export default CardJumin;
