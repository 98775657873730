import React, { useContext, useEffect, useState } from "react";
import { imageDB } from "../components/imageDB";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

import "../components/css/common.css";

import useDimensions from "react-use-dimensions";
import Button from "../components/Layout/Button";
import { COLORS } from "../components/Theme";
import Advertise from "./component/Advertise";
import Label from "./component/Label";
import Preview from "./component/Categoryview";
import Noticeview from "./component/Noticeview";
import Center from "./component/Center";
import Best from "./component/Best";
import Pick from "./component/Pick";
import News from "./component/News";
import Search from "../components/Layout/SearchBasket";
import MainItem from "./component/MainItem";
import ProductItem from "./component/ProductItem";
import OrderItem from "./component/Selectorder";
import OrderInfo from "./component/OrderInfo";
import ReceiveInfo from "./component/ReceiveInfo";
import PayInfo from "./component/PayInfo";
import { deleteBasket, getBaskset } from "../utility/basketfirebase";
import { UserContext } from "../context/Users";
import { getproductbyid } from "../utility/productfirebase";
import Loading from "./component/Loading";
import BasketItem from "./component/BasketItem";
import SelectOrder from "./component/Selectorder";
import Fade from "react-reveal/Fade";
import SideBanner from "./component/SideBanner";

const Padding = styled.div`
  padding: 0px 10px;
`;

const DataView = styled.div`
  border: 1px solid #a0a0a0;
  height: 80%;
  margin: 5px 5px 20px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  height: 50px;
`;

const ItemTitle = styled.div`
  display: flex;
  font-size: 16px;
`;
const LineContent = styled.div`
  display: flex;
  width: 100%;
`;

const SelectView = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 220px;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
`;
// const AllSelect = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: 1px solid;
//   background-color: ${({ allcheck }) =>
//     allcheck == true ? "#1fd148" : "#fff"};
//   color: ${({ allcheck }) => (allcheck == true ? "#fff" : "#000")};
//   width: 100px;
//   padding: 5px;
// `;
const AllSelect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ allcheck }) =>
    allcheck == true ? "1px solid #689fff" : "1px solid  #999"};
  background: ${({ allcheck }) => (allcheck == true ? "#689fff" : "#fff")};
  width: 80px;
  padding: 5px 10px;
  color: ${({ allcheck }) => (allcheck == true ? "#fff" : "#000")};
  font-size: 14px;
`;
const DeleteSelect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  width: 80px;
  padding: 5px 10px;
  color: red;
  font-size: 14px;
`;
const NoProduct = styled.div`
  font-size: 25px;
  font-weight: 900;
  margin-bottom: 500px;
`;

const TopShowButton = styled.div`
  height: 70px;
  width: 70px;
  background-color: #222;
  position: fixed;
  bottom: 30px;
  right: 100px;
  border-radius: 100px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;
const CategoryImageLayer = styled.div`
  margin: 20px 8%;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  padding: 0px 20px;
`;



const Basketcontainer = () => {
  const navigate = useNavigate();

  const [width, setWidth] = useState(100);
  const [ref, setRef] = useState(null);

  const [allcheck, setAllchek] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [basketcount, setBasketcount] = useState("");

  const { dispatch, user } = useContext(UserContext);
  const [basketlist, setBasketlist] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectorderlist, setSelectorderlist] = useState([]);
  const [topButton, setTopButton] = useState(false);

  const [checkupdate, setCheckupdate] = useState(true);

  //최초 구동시
  useEffect(() => {
    //mount 시에
    // console.log("init");
    window.scrollTo(0, 0);

    return () => {
      //unmount 시에
      // console.log("dispose");
    };
  }, []);

  //초기데이타
  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 300) {
        setTopButton(true);
      } else {
        setTopButton(false);
      }
    };

    window.addEventListener("scroll", handleShowButton);

    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  //장바구니
  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      const USER_ID = user.USER_ID;

      // console.log("basket user", user);
      let basketitems = [];
      basketitems = await getBaskset({ USER_ID });
      // console.log("USER_ID basketitems", basketitems);
      setBasketcount("장바구니 (" + basketitems.length + " )건");

      let basketoptionitems = [];
      if (basketitems != -1) {
        basketitems.map((data, index) => {
          data.OPTIONBASKETLIST.map((subdata, index) => {
            subdata["BASKET_ID"] = data.BASKET_ID;
            basketoptionitems.push(subdata);
          });
        });
      }
      // console.log("basketoptionitems", basketoptionitems);
      setBasketlist(basketoptionitems);
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    setBasketlist(basketlist);
    setSelectorderlist(selectorderlist);
    setAllchek(allcheck);
    setCheckupdate(true);
  }, [refresh]);

  //체크 여부를
  const callback_selectorder = (data) => {
    // console.log("callback_orderselect", data);
    const FindIndex = selectorderlist.findIndex(
      (x) => x.item.productoptionid == data.productoptionid
    );

    if (FindIndex > -1) {
      selectorderlist.splice(FindIndex, 1);
    } else {
      selectorderlist.push({ item: data });
    }

    setSelectorderlist(selectorderlist);
    setRefresh((refresh) => refresh + 1);
  };

  const TopMove = () => {
    window.scrollTo(0, 0);
  };

  // 삭제
  const selectdeleteClick = async () => {
    // console.log("selectorderlist", selectorderlist);
    selectorderlist.map(async (data, index) => {
      const FindIndex = basketlist.findIndex(
        (x) => x.productoptionid == data.item.productoptionid
      );
      if (FindIndex != -1) {
        //데이타 베이스 제거
        const BASKET_ID = basketlist[FindIndex].BASKET_ID;

        // console.log("BASKET_ID", BASKET_ID);

        const deletedata = await deleteBasket({ BASKET_ID });
        basketlist.splice(FindIndex, 1);
        selectorderlist.splice(index, 1);

        setRefresh((refresh) => refresh + 1);
      }
    });
  };

  const selectcheckClick = async () => {
    setCheckupdate(false);
    if (allcheck == true) {
      setAllchek(!allcheck);
      setSelectorderlist([]);
    } else {
      basketlist.map((data, index) => {
        selectorderlist.push({ item: data });
      });
      setAllchek(!allcheck);
      setSelectorderlist(selectorderlist);
    }
    setRefresh((refresh) => refresh + 1);
  };
  return (
    <>
      {loading == false ? (
        <>
          {basketlist.length > 0 ? (
            <div className="Container" style={{ minHeight: 600 }}>
              <CategoryImageLayer>
                <SideBanner />
              </CategoryImageLayer>

              <div style={{ padding: "0px 10%" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <MainItem ItemText={basketcount} />

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        width: "60%",
                        flexDirection: "column",
                      }}
                    >
                      <SelectView>
                        <AllSelect
                          allcheck={allcheck}
                          onClick={selectcheckClick}
                        >
                          전체선택
                        </AllSelect>
                        <DeleteSelect onClick={selectdeleteClick}>
                          삭제
                        </DeleteSelect>
                      </SelectView>
                      <LineContent />
                      {checkupdate == true && (
                        <>
                          {basketlist.map((data, index) => (
                            <BasketItem
                              key={index}
                              check={allcheck}
                              itemdata={data}
                              callback={callback_selectorder}
                            />
                          ))}
                        </>
                      )}
                    </div>
                    <div
                      style={{ display: "flex", width: "40%", paddingLeft: 40 }}
                    >
                      <SelectOrder selectItem={selectorderlist} />
                    </div>
                  </div>
                </div>

                {topButton == true && (
                  <TopShowButton onClick={TopMove}>TOP</TopShowButton>
                )}
              </div>
            </div>
          ) : (
            <>
              <img
                src={imageDB.mainlogo}
                style={{ paddingTop: 100, height: 100 }}
              />
              <NoProduct>장바구니 항목이 존재 하지 않습니다</NoProduct>
            </>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Basketcontainer;
