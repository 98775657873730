import React, { Fragment, useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import "./BestItem.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import { getproductbyid } from "../../utility/productfirebase";
import Fade from "react-reveal/Fade";

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 10px;
  border: ${({ checks }) =>
    checks == true ? "2px solid #689fff" : "1px solid #ededed"};
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justifyContent:center;
  width: ${({ width }) => width};
  background-color: ${({ bgcolor }) => bgcolor};
`;

const Column2 = styled.div`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding-left: 40px;
  font-size: 14px;
  line-height: 2;
  font-weight: 700;
`;
const SelectOrder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height};
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #d2d2d2;
`;

const SelectEnableOrder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height};
  background-color: #f3f3f3;
  color: #111010;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #aeaaaa;
`;

const CopyOrder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #bebebe;
  color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 14px;
`;
const Price = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100px;
`;

const BasketItem = ({ containerstyle, itemdata, callback, check }) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [checks, setChecks] = useState(check);

  const [product, setProduct] = useState({});
  const [optionbasketlist, setOptionbasketlist] = useState([]);

  const _handleSelectbasket = async () => {
    setChecks(!checks);
    callback(itemdata);
  };

  const _handleNoSelectbasket = async () => {
    setChecks(!checks);
    callback(itemdata);
  };

  const _handleOptionMinus = (data) => {
    if (checks) {
      alert("선택주문서에 주문된 상품입니다.선택주문 해제후에 입력해주세요");
      return;
    }
    if (data.productcount == 0) {
      return;
    }
    data.productprice -= data.productoriginprice;

    if (data.productoptionprice != "" || data.productoptionprice != 0) {
      data.productprice -= data.productoptionprice;
    }

    data.productcount = data.productcount - 1;

    setRefresh((refresh) => refresh + 1);
  };
  const _handleOptionPlus = (data) => {
    // if(qty == data.productcount ){
    //   return;
    // }
    if (checks) {
      alert("선택주문서에 주문된 상품입니다.선택주문 해제후에 입력해주세요");
      return;
    }

    // console.log("hadnleoptionplus", data);

    data.productprice += data.productoriginprice;

    if (data.productoptionprice != "" || data.productoptionprice != 0) {
      data.productprice += parseInt(
        data.productoptionprice.replaceAll(",", "")
      );
    }

    data.productcount = data.productcount + 1;
    setRefresh((refresh) => refresh + 1);
  };

  const optionData = (price) => {
    return price == "" ? "0" : price;
  };

  const _handleClick = () => {
       setChecks(!checks);
       callback(itemdata);
  }
  return (
    <Container>
      <Row onClick={_handleClick} checks={checks} className="button">

        <Column width="30%">
          <img
            src={itemdata.productimage}
            style={{
              width: "140px",
              height: "140px",
              border: "1px solid #ededed",
            }}
          />
        </Column>

        <Column2 width="60%">
          <div>
            상품명 :{itemdata.productname.slice(0, 45)}
            {itemdata.productname.length > 45 ? "..." : null}
          </div>
          <div>
            모델명 :{itemdata.productmodelname.slice(0, 45)}
            {itemdata.productmodelname.length > 45 ? "..." : null}{" "}
          </div>
          {itemdata.productoptionprice != 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>선택옵션 : {itemdata.productoptionname}</div>
              <div style={{ marginLeft: 5 }}>
                / 옵션가격 : {CommaFormatted(itemdata.productoptionprice)}원
              </div>
            </div>
          )}

          {itemdata.productoptionprice == 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>옵션상품 아님</div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: 30,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => {
                  _handleOptionMinus(itemdata);
                }}
                style={{
                  border: "1px solid #ededed",
                  width: 30,
                  height: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                -
              </div>
              <div
                style={{
                  border: "1px solid #ededed",
                  width: 60,
                  height: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {itemdata.productcount}
              </div>
              <div
                onClick={() => {
                  _handleOptionPlus(itemdata);
                }}
                style={{
                  border: "1px solid #ededed",
                  width: 30,
                  height: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                +
              </div>
            </div>
            <div style={{ marginLeft: 10 }}>
              상품공급가 : {CommaFormatted(itemdata.productprice)}원
            </div>
          </div>

          {/* {optionbasketlist.map((data, index) => (
            <Fade top delay={100}>
              <div style={{ height: 30, padding: "5px 0px" }} key={index}>
                <div style={{ display: "flex" }} value={data.optioncategory}>
                  {data.productoptionname}
                </div>
                <div style={{ textAlign: "left" }}>
                  {data.optionprice != 0 && (
                    <span>
                      가격 :{" "}
                      {CommaFormatted(
                        parseInt(data.productoptionprice) +
                          parseInt(data.productoriginprice)
                      )}
                      원 /
                    </span>
                  )}{" "}
                  재고 : {data.productoptionqty}개보유
                </div>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 20 }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: 30,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => {
                      _handleOptionMinus(data);
                    }}
                    style={{
                      border: "1px solid #ededed",
                      width: 30,
                      height: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    -
                  </div>
                  <div
                    style={{
                      border: "1px solid #ededed",
                      width: 60,
                      height: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {optionbasketlist[index].productcount}
                  </div>
                  <div
                    onClick={() => {
                      _handleOptionPlus(data);
                    }}
                    style={{
                      border: "1px solid #ededed",
                      width: 30,
                      height: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    +
                  </div>
                </div>
                <Price>
                  {CommaFormatted(optionbasketlist[index].productprice)}원
                </Price>
              </div>
            </Fade>
          ))} */}
        </Column2>
      </Row>
    </Container>
  );
};

BasketItem.defaultProps = {
  price: 0,
};
export default BasketItem;
