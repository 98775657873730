import firebase from "firebase/compat/app";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";


const firebaseConfig = {
    apiKey: "AIzaSyBYsk-o2BChsApSO-xxBmDTq7voz2Bross",
    authDomain: "murray-c93ca.firebaseapp.com",
    projectId: "murray-c93ca",
    storageBucket: "murray-c93ca.appspot.com",
    messagingSenderId: "59208772562",
    appId: "1:59208772562:web:211ac3d452e56b93dff60b",
    measurementId: "G-F519HF8678"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.firestore().settings({ experimentalForceLongPolling: true });
const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };