

import React, { Fragment, useContext, useEffect, useState} from "react";
import { Mobile, PC } from "../../Responsive";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Define } from "../../Define";
import { getSideBanner } from "../../../utility/bannerfirebase";
import { getcategory1, getcategory2 } from "../../../utility/categoryfirebase";
import { CategoryContext } from "../../../context/Category";
import { BannerContext } from "../../../context/Banner";


const PCSide = styled.div`
  background-color: #323232;
  display: flex;
  position: absolute;
  left: 0;
  width: 12%;
  z-index:2;
  justify-content : center;
  border-left: 1px solid #DEDEDE;


`
const PCSide2 = styled.div`
  align-items: right;
  display: flex;
  position: absolute;
  left: 0;
  width: 12%;
  z-index:2;
  justify-content : center;
  border-left: 1px solid #DEDEDE;

`

const Container = styled.div`
  display:flex;
  flex-direction: column;  
  align-items: flex-start;
  width: 100%;
  padding: 20px 0px;
`
const Container2 = styled.div`
  display:flex;
  flex-direction: column;  
  align-items: flex-start;
  width: 100%;
`
const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0px 20px;
  margin-top: 15px;
`

const MainItem = styled.div`
  color:${({select})=> select == true ? ('#fff'):('#b6b6b9') };
  font-weight:${({select})=> select == true ? ('900'):('600') };
  font-size:${({select})=> select == true ? ('16px'):('15px') };
  height:30px;
  padding-left: 10px;
`

const SubItem = styled.div`
  color:${({select})=> select == true ? ('#fff'):('#b6b6b9') };
  font-weight:${({select})=> select == true ? ('900'):('600') };
  font-size:${({select})=> select == true ? ('14px'):('13px') };
  padding-left:20px;
  height:30px;
`




const Leftside = ({categorycallback, type}) => {
  const navigate = useNavigate();
  const {category, dispatch} = useContext(CategoryContext);
  const {banner, dispatch2} = useContext(BannerContext);
  const [maincategory, setMaincategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [refresh, setRefresh] = useState(1);
  const [banneritems, setBanneritems] = useState(banner.bannerlist);
  const [totalcategoryitems, setTotalcategoryitems] = useState(category.categorylist);


  // console.log("LeftSide", type);
  useEffect(() => {
    window.scrollTo(0,0);
    return () => {
    };
  }, []);

  const categoryselect = (category, sub, subcategoryitems)=>{
    setMaincategory(category);
    setSubcategory("");
    categorycallback(category, sub, subcategoryitems);
  }

  function setcategory (subcategory){
    totalcategoryitems.map((data)=>{
      const FindIndex = data.category2.findIndex(x=>x == subcategory);
      if(FindIndex != -1){
        // console.log("category", data.category1);
        setMaincategory(data.category1);
        categorycallback(data.category1, subcategory, data.category2);
      }
    })
  }


  const categorysubselect = (subcategory)=>{
    setcategory(subcategory);
    setSubcategory(subcategory);
    setRefresh(refresh => refresh * -1);
  }




  return (
    <Fragment>
      <Mobile>
          <Container>
          </Container>
      </Mobile>
      <PC>
        {
          type == Define.allcategory &&
          <PCSide>   
          <Container>
          <Fragment>
            {
              totalcategoryitems.map((data, index)=>(
                <Items key={index}>
                <MainItem select ={data.category1 == maincategory} onClick={()=>{categoryselect(data.category1,"", data.category2)}}>{data.category1}</MainItem>
                {
                  data.category2.map((sub, index)=>(
                    <SubItem select ={sub == subcategory} onClick={()=>{categorysubselect(sub)}} key={index}>{sub}</SubItem>
                  ))
                }
                </Items>
              ))
            }

          
          </Fragment> 
          </Container>
          </PCSide>
        }   
        {
          ( type == Define.home || type ==undefined || type =='' || type == Define.best ) &&
          <PCSide2>
          <Container2>
            {
              banner.bannerlist.map((data, index)=>(
                <img src={data.BANNERIMG} key={index} style={{width:"100%", marginBottom:10, height:250}}/>
              ))
            }
          </Container2>
          </PCSide2>
        }         
      </PC>
    </Fragment>
  );
};

export default Leftside;