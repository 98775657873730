import React, { useEffect, useState } from "react";
import { imageDB } from "../components/imageDB";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";

import "../components/css/common.css";

import useDimensions from "react-use-dimensions";
import Button from "../components/Layout/Button";
import { COLORS } from "../components/Theme";
import Advertise from "./component/Advertise";
import Label from "./component/Label";
import Preview from "./component/Categoryview";
import Noticeview from "./component/Noticeview";
import Center from "./component/Center";
import Best from "./component/Best";
import Pick from "./component/Pick";
import News from "./component/News";
import Search from "../components/Layout/SearchBasket";
import With from "./component/With";
import { getproductbyid } from "../utility/productfirebase";
import { CommaFormatted } from "../utility/common";
import ProductRightside from "../components/Layout/ProductRightside/ProductRightside";
import Fade from "react-reveal/Fade";
import { MaxPC, SmallPC } from "../components/Responsive";
import Loading from "./component/Loading";
import { ProductNameTag } from "../components/Define";

const TopShowButton = styled.div`
  height: 70px;
  width: 70px;
  background-color: #222;
  position: fixed;
  bottom: 30px;
  right: 100px;
  border-radius: 100px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const Productcontainer = ({ PRODUCT_ID }) => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(100);
  const [product, setProduct] = useState({});
  const [productPlusTag, setProductPlusTag] = useState(false);

  const [saleprice, setSaleprice] = useState("");
  const onsalepricechange = (e) => {
    const value = e.target.value;
    const removedCommaValue = Number(value.replaceAll(",", ""));
    setSaleprice(removedCommaValue.toLocaleString());
  };
  const [salefee, setSalefee] = useState("");
  const onsalefeechange = (e) => {
    const value = e.target.value;
    const removedCommaValue = Number(value.replaceAll(",", ""));
    setSalefee(removedCommaValue.toLocaleString());
  };

  const [deliveryfee, setDeliveryfee] = useState("");
  const ondeliveryfeechange = (e) => {
    const value = e.target.value;
    const removedCommaValue = Number(value.replaceAll(",", ""));
    setDeliveryfee(removedCommaValue.toLocaleString());
  };

  const [margin, setMargin] = useState("");
  const [marginprice, setMarginprice] = useState("");

  const _handleMarginCalculate = () => {
    let saleconvert = Number(saleprice.replaceAll(",", ""));
    let supplyconvert = Number(product.PRODUCTSUPPLYPRICE.replaceAll(",", ""));
    let supplycost = Number(salefee.replaceAll(",", ""));
    let supplyfee = Number(deliveryfee.replaceAll(",", ""));

    // console.log("sale", saleconvert, supplyconvert, supplycost, supplyfee);

    let percent = parseInt(
      ((saleconvert - supplyconvert - supplycost - supplyfee) / saleconvert) *
        100
    );
    setMargin(percent + "%");
    setMarginprice(
      CommaFormatted(saleconvert - supplyconvert - supplycost - supplyfee) +
        "원"
    );
  };

  const [refresh, setRefresh] = useState(false);
  const [reload, setReload] = useState(1);

  const [topbutton, setTopButton] = useState(false);
  // console.log("product", PRODUCT_ID);

  useEffect(() => {
    setProductPlusTag(productPlusTag);
  }, [reload]);

  useEffect(() => {
    async function fetchData() {
      const product = await getproductbyid({ PRODUCT_ID });
      // console.log("product info", product);
      setProduct(product);
      setRefresh(true);
    }
    fetchData();
  }, []);

  useEffect(() => {
    //mount 시에
    // console.log("init");

    window.scrollTo(0, 0);

    return () => {
      //unmount 시에
      // console.log("dispose");
    };
  }, []);

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 800) {
        setTopButton(true);
      } else {
        setTopButton(false);
      }
    };

    window.addEventListener("scroll", handleShowButton);

    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  const TopMove = () => {
    window.scrollTo(0, 0);
  };

  const _handleProductImg = () => {
    setProductPlusTag(!productPlusTag);
    setReload((reload) => reload + 1);
    // navigate("/productimage", {state : {PRODUCT_IMGS: product.PRODUCTIMAGEARY}});
  };

  return (
    <>
      {refresh == true ? (
        <>
          <MaxPC>
            <div className="MaxProductContainer">
              {refresh == true && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "20px 10% 20px 10%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "450px",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <a href={product.PRODUCTIMAGEARY[0]} target="_blank">
                          <img
                            src={product.PRODUCTIMAGEARY[0]}
                            style={{ height: "450px", width: "450px" }}
                          />
                        </a>
                      </div>

                      {/* <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", 
                border :'1px solid #ededed',
                marginTop:10}}>
                    {
                      product.PRODUCTIMAGEARY.map((data, index)=>(
                        <>
                        {index < 7 &&
                            <Fade top delay={100}>
                            <div style={{display:"flex", height:"20%", flexDirection:"row", marginTop:20, marginLeft:10}}>
                            <img src={data} style={{width:'100px', height:'100px', borderRadius:10,}} />
                            </div>
                            </Fade>
                        }
                        {
                          index == 7  &&
                          <Fade top delay={100}>
                          <div 
                          onClick={_handleProductImg}
                          style={{display:"flex", height:"20%", flexDirection:"row", marginTop:20, marginLeft:10}}>
                          <img src={data} style={{width:'100px', height:'100px', borderRadius:10,}} />
                          <div style={{color:"#fff", position:"absolute", top:"40%", left:"30%", fontSize:18}}>더보기</div>
                          <div style={{color:"#fff", position:"absolute", top:"40%", left:"35%", fontSize:50}}>+</div>
                          </div>
                          
                          </Fade>
                        }
                        </>

                        
                    
                      ))  
                    }
                </div> */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          border: "1px solid #ededed",
                          padding: "10px 0px",
                          marginTop: 8,
                        }}
                      >
                        {product.PRODUCTIMAGEARY.map((data, index) => (
                          <>
                            {index <= 6 && (
                              <Fade top delay={100}>
                                <div
                                  style={{
                                    display: "flex",
                                    height: "20%",
                                    flexDirection: "row",
                                    marginLeft: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <a href={data} target="_blank">
                                    <img
                                      src={data}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: 10,
                                      }}
                                    />
                                  </a>
                                </div>
                              </Fade>
                            )}
                            {index == 7 && (
                              <Fade top delay={100}>
                                <div
                                  onClick={_handleProductImg}
                                  style={{
                                    display: "flex",
                                    height: "20%",
                                    flexDirection: "column",
                                    marginLeft: 10,
                                    marginBottom: 10,
                                    width: "100px",
                                  }}
                                >
                                  {/* <img src={data} style={{width:'100px', height:'100px', borderRadius:10,}} /> */}
                                  {product.PRODUCTIMAGEARY.length > 7 && (
                                    <div>
                                      <div
                                        style={{
                                          color: "#000",
                                          position: "absolute",
                                          top: "40%",
                                          left: "30%",
                                          fontSize: 18,
                                        }}
                                      >
                                        더보기
                                      </div>
                                      <div
                                        style={{
                                          color: "#000",
                                          position: "absolute",
                                          top: "50%",
                                          left: "35%",
                                          fontSize: 50,
                                        }}
                                      >
                                        +
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Fade>
                            )}
                          </>
                        ))}
                      </div>

                      {productPlusTag == true && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            padding: "10px 0px",
                            borderBottom: "1px solid #ededed",
                            borderLeft: "1px solid #ededed",
                            borderRight: "1px solid #ededed",
                            backgroundColor: "#f0f0f0",
                            zIndex: 2,
                          }}
                        >
                          {product.PRODUCTIMAGEARY.map((data, index) => (
                            <>
                              {index >= 8 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: 10,
                                  }}
                                >
                                  <a href={data} target="_blank">
                                    <img
                                      src={data}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: 10,
                                      }}
                                    />
                                  </a>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "35%",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "50%",
                          backgroundColor: "#fff",
                          border: "1px solid #E1E1E1",
                          margin: "0px 10px 10px 10px",
                          borderRadius: 5,
                          padding: "15px 0px 15px 20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#929292" }}>
                            상품명
                          </div>
                          <div style={{ width: "70%" }}>
                            {ProductNameTag + product.PRODUCTNAME}
                          </div>
                        </div>
                        <div
                          style={{
                            border: "0.5px solid #DADADA",
                            marginLeft: 25,
                            marginBottom: 10,
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#929292" }}>
                            상품번호
                          </div>
                          <div style={{ width: "70%" }}>
                            {product.PRODUCTNUM}
                          </div>
                        </div>
                        <div
                          style={{
                            border: "0.5px solid #DADADA",
                            marginLeft: 25,
                            marginBottom: 10,
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#929292" }}>
                            모델명
                          </div>
                          <div style={{ width: "70%" }}>
                            {product.PRODUCTMODELNAME}
                          </div>
                        </div>

                        <div
                          style={{
                            border: "0.5px solid #DADADA",
                            marginLeft: 25,
                            marginBottom: 10,
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#929292" }}>
                            정상가
                          </div>
                          <div style={{ width: "70%" }}>
                            {CommaFormatted(product.PRODUCTREGULARPRICE)}원
                          </div>
                        </div>
                        <div
                          style={{
                            border: "0.5px solid #DADADA",
                            marginLeft: 25,
                            marginBottom: 10,
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 2,
                            marginBottom: 10,
                            alignItems: "center",
                            justifyContent: "center",
                            paddingLeft: 20,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#FF0000",
                              color: "#fff",
                              padding: "5px 20px",
                              width:"85%",
                            }}
                          >
                            온라인최저가{" "}
                            {CommaFormatted(product.PRODUCTLOWPRICE)}원
                          </div>
                          <div style={{ color: "#FF0000", marginTop: 20 }}>
                            온라인 최저가는 반드시 지켜주셔야합니다.
                          </div>
                        </div>
                        <div
                          style={{
                            border: "0.5px solid #DADADA",
                            marginLeft: 25,
                            marginBottom: 10,
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexGrow: 1,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#929292" }}>
                            파트너공급가
                          </div>
                          <div style={{ width: "70%" }}>
                            {CommaFormatted(product.PRODUCTSUPPLYPRICE)}원
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "70%",
                          marginTop: 20,
                          backgroundColor: "#fff",
                          border: "1px solid #E1E1E1",
                          margin: "0px 10px",
                          borderTopRightRadius: 10,
                          borderTopLeftRadius: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "#323232",
                            color: "#fff",
                            flexDirection: "row",
                            height: 50,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ paddingLeft: 20 }}>마진 계산기</div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                            marginTop: 20,
                          }}
                        >
                          <div style={{ width: "30%", color: "#323232" }}>
                            판매가
                          </div>
                          <div style={{ width: "70%" }}>
                            <input
                              type="text"
                              onChange={onsalepricechange}
                              value={saleprice}
                              placeholder="판매가를 입력해주세요"
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #DADADA",
                                width: "80%",
                              }}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#323232" }}>
                            공급가
                          </div>
                          <div style={{ width: "70%" }}>
                            <input
                              type="text"
                              disabled
                              value={product.PRODUCTSUPPLYPRICE}
                              placeholder="공급가를 입력해주세요"
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #DADADA",
                                width: "80%",
                                background: "#ededed",
                              }}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#323232" }}>
                            수수료
                          </div>
                          <div style={{ width: "70%" }}>
                            <input
                              type="text"
                              onChange={onsalefeechange}
                              value={salefee}
                              placeholder="판매처 수수료를 입력해주세요"
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #DADADA",
                                width: "80%",
                              }}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#323232" }}>
                            배송비
                          </div>
                          <div style={{ width: "70%" }}>
                            <input
                              onChange={ondeliveryfeechange}
                              value={deliveryfee}
                              type="text"
                              placeholder="배송비를 입력해주세요"
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #DADADA",
                                width: "80%",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          onClick={_handleMarginCalculate}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#689fff",
                              color: "#fff",
                              padding: "5px 20px",
                              marginRight: 20,
                            }}
                          >
                            마진계산
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#323232" }}>
                            개당 판매마진률
                          </div>
                          <div style={{ width: "70%" }}>
                            <input
                              type="text"
                              disabled
                              value={margin}
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #DADADA",
                                width: "80%",
                                background: "#ededed",
                              }}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#323232" }}>
                            개당 판매마진
                          </div>
                          <div style={{ width: "70%" }}>
                            <input
                              type="text"
                              disabled
                              value={marginprice}
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #DADADA",
                                width: "80%",
                                background: "#ededed",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <ProductRightside product_id={PRODUCT_ID} />
                  </div>

                  {topbutton == true && (
                    <TopShowButton onClick={TopMove}>TOP</TopShowButton>
                  )}

                  <With product={product} />
                </>
              )}
            </div>
          </MaxPC>
          <SmallPC>
            <div className="ProductContainer">
              {refresh == true && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "20px 10% 20px 10%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "40%",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <a href={product.PRODUCTIMAGEARY[0]} target="_blank">
                          <img
                            src={product.PRODUCTIMAGEARY[0]}
                            style={{ height: "450px", width: "450px" }}
                          />
                        </a>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          border: "1px solid #ededed",
                          padding: "10px 0px",
                          marginTop: 8,
                        }}
                      >
                        {product.PRODUCTIMAGEARY.map((data, index) => (
                          <>
                            {index <= 6 && (
                              <Fade top delay={100}>
                                <div
                                  style={{
                                    display: "flex",
                                    height: "20%",
                                    flexDirection: "row",
                                    marginLeft: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <a href={data} target="_blank">
                                    <img
                                      src={data}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: 10,
                                      }}
                                    />
                                  </a>
                                </div>
                              </Fade>
                            )}
                            {index == 7 && (
                              <Fade top delay={100}>
                                <div
                                  onClick={_handleProductImg}
                                  style={{
                                    display: "flex",
                                    height: "20%",
                                    flexDirection: "column",
                                    marginLeft: 10,
                                    marginBottom: 10,
                                    width: "100px",
                                  }}
                                >
                                  {/* <img src={data} style={{width:'100px', height:'100px', borderRadius:10,}} /> */}
                                  {product.PRODUCTIMAGEARY.length > 7 && (
                                    <div>
                                      <div
                                        style={{
                                          color: "#000",
                                          position: "absolute",
                                          top: "40%",
                                          left: "30%",
                                          fontSize: 18,
                                        }}
                                      >
                                        더보기
                                      </div>
                                      <div
                                        style={{
                                          color: "#000",
                                          position: "absolute",
                                          top: "50%",
                                          left: "35%",
                                          fontSize: 50,
                                        }}
                                      >
                                        +
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Fade>
                            )}
                          </>
                        ))}
                      </div>

                      {productPlusTag == true && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            padding: "10px 0px",
                            borderBottom: "1px solid #ededed",
                            borderLeft: "1px solid #ededed",
                            borderRight: "1px solid #ededed",
                            backgroundColor: "#f0f0f0",
                            zIndex: 2,
                          }}
                        >
                          {product.PRODUCTIMAGEARY.map((data, index) => (
                            <>
                              {index >= 8 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: 10,
                                  }}
                                >
                                  <a href={data} target="_blank">
                                    <img
                                      src={data}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: 10,
                                      }}
                                    />
                                  </a>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "35%",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "50%",
                          backgroundColor: "#fff",
                          border: "1px solid #E1E1E1",
                          margin: "0px 10px 10px 10px",
                          borderRadius: 5,
                          padding: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#929292" }}>
                            상품명
                          </div>
                          <div style={{ width: "70%" }}>
                            {ProductNameTag + product.PRODUCTNAME}
                          </div>
                        </div>
                        <div
                          style={{
                            border: "0.5px solid #DADADA",
                            marginLeft: 25,
                            marginBottom: 10,
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#929292" }}>
                            상품번호
                          </div>
                          <div style={{ width: "70%" }}>
                            {product.PRODUCTNUM}
                          </div>
                        </div>
                        <div
                          style={{
                            border: "0.5px solid #DADADA",
                            marginLeft: 25,
                            marginBottom: 10,
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#929292" }}>
                            모델명
                          </div>
                          <div style={{ width: "70%" }}>
                            {product.PRODUCTMODELNAME}
                          </div>
                        </div>

                        <div
                          style={{
                            border: "0.5px solid #DADADA",
                            marginLeft: 25,
                            marginBottom: 10,
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#929292" }}>
                            정상가
                          </div>
                          <div style={{ width: "70%" }}>
                            {CommaFormatted(product.PRODUCTREGULARPRICE)}원
                          </div>
                        </div>
                        <div
                          style={{
                            border: "0.5px solid #DADADA",
                            marginLeft: 25,
                            marginBottom: 10,
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 2,
                            marginBottom: 10,
                            alignItems: "center",
                            justifyContent: "center",
                            paddingLeft: 20,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#FF0000",
                              color: "#fff",
                              padding: "5px 20px",
                              width: "85%",
                            }}
                          >
                            온라인최저가{" "}
                            {CommaFormatted(product.PRODUCTLOWPRICE)}원
                          </div>
                          <div style={{ color: "#FF6868", marginTop: 20 }}>
                            온라인 최저가는 반드시 지켜주셔야합니다.
                          </div>
                        </div>
                        <div
                          style={{
                            border: "0.5px solid #DADADA",
                            marginLeft: 25,
                            marginBottom: 10,
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexGrow: 1,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#929292" }}>
                            파트너공급가
                          </div>
                          <div style={{ width: "70%" }}>
                            {CommaFormatted(product.PRODUCTSUPPLYPRICE)}원
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "70%",
                          marginTop: 20,
                          backgroundColor: "#fff",
                          border: "1px solid #E1E1E1",
                          margin: "0px 10px",
                          borderTopRightRadius: 10,
                          borderTopLeftRadius: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "#323232",
                            color: "#fff",
                            flexDirection: "row",
                            height: 50,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ paddingLeft: 20 }}>마진 계산기</div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                            marginTop: 20,
                          }}
                        >
                          <div style={{ width: "30%", color: "#323232" }}>
                            판매가
                          </div>
                          <div style={{ width: "70%" }}>
                            <input
                              type="text"
                              onChange={onsalepricechange}
                              value={saleprice}
                              placeholder="판매가를 입력해주세요"
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #DADADA",
                                width: "80%",
                              }}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#323232" }}>
                            공급가
                          </div>
                          <div style={{ width: "70%" }}>
                            <input
                              type="text"
                              disabled
                              value={product.PRODUCTSUPPLYPRICE}
                              placeholder="공급가를 입력해주세요"
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #DADADA",
                                width: "80%",
                                background: "#ededed",
                              }}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#323232" }}>
                            수수료
                          </div>
                          <div style={{ width: "70%" }}>
                            <input
                              type="text"
                              onChange={onsalefeechange}
                              value={salefee}
                              placeholder="판매처 수수료를 입력해주세요"
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #DADADA",
                                width: "80%",
                              }}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#323232" }}>
                            배송비
                          </div>
                          <div style={{ width: "70%" }}>
                            <input
                              onChange={ondeliveryfeechange}
                              value={deliveryfee}
                              type="text"
                              placeholder="배송비를 입력해주세요"
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #DADADA",
                                width: "80%",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          onClick={_handleMarginCalculate}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#689fff",
                              color: "#fff",
                              padding: "5px 20px",
                              marginRight: 20,
                            }}
                          >
                            마진계산
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#323232" }}>
                            개당 판매마진률
                          </div>
                          <div style={{ width: "70%" }}>
                            <input
                              type="text"
                              disabled
                              value={margin}
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #DADADA",
                                width: "80%",
                                background: "#ededed",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "30%", color: "#323232" }}>
                            개당 판매마진
                          </div>
                          <div style={{ width: "70%" }}>
                            <input
                              type="text"
                              disabled
                              value={marginprice}
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #DADADA",
                                width: "80%",
                                background: "#ededed",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <ProductRightside product_id={PRODUCT_ID} />
                  </div>

                  {topbutton == true && (
                    <TopShowButton onClick={TopMove}>TOP</TopShowButton>
                  )}

                  <With product={product} />
                </>
              )}
            </div>
          </SmallPC>
        </>
      ) : (
        <Loading containerStyle={{ marginTop: "10%", marginBottom: "300px" }} />
      )}
    </>
  );
};

export default Productcontainer;
