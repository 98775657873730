import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "80%",
  height: "600px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  padding: "10px",
};
const IconCloseView = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const MainData = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 20px 0px;
  background-color: #fff;
  flex-wrap: wrap;
`;

const MainDataItem = styled.div`
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${({ check }) => (check == 1 ? "#ff4e193b" : "#EDEDED")};
  margin-left: 10px;
  margin-bottom: 10px;
`;
const MainDataItemText = styled.span`
  font-size: 12px;
  font-family: ${({ theme }) => theme.REGULAR};
  color: ${({ check }) => (check == 1 ? "#FF4E19" : "#000")};
`;
const ApplyItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  margin-bottom: 20px;
`;
const FilterApplyButton = styled.div`
  background-color: #ff4e19;
  padding: 5px 50px;
  border-radius: 5px;
`;
const FilterApplyButtonText = styled.span`
  color: #fff;
  font-size: 14px;
  font-family: ${({ theme }) => theme.REGULAR};
`;

export default function ChatbotModule({ callback }) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    callback();
  };

  React.useEffect(() => {}, []);

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconCloseView onClick={handleClose}>
              <AiFillCloseCircle size={30} />
            </IconCloseView>

            <iframe
              id="map"
              border="0"
              style={{
                borderStyle: "none",
                width: "100%",
                height: "550px",
              }}
              src={"https://pf.kakao.com/_xgPVGxj"}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
