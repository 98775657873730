

import {db, auth, storage} from '../api/config';
import { collection, getDocs, query, updateDoc,where,doc,setDoc, deleteDoc, orderBy } from 'firebase/firestore';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";




export const categoryregist = async({category1, category2, categoryimg})=>{

    const categoryRef = doc(collection(db, "CATEGORY"));
    const id = categoryRef.id;
    const newcategory = {
        CATEGORY_ID : id,
        CATEGORY1 :category1,
        CATEGORY2 :category2,
        CATEGORYIMG : categoryimg,
        REGISTDATE : Date.now(),
    }
    try{
        await setDoc(categoryRef, newcategory);
    }catch(e){
        console.log("error", e.message);
    }finally{
        return id;
    }
}
export const getcategory = async()=>{
    const categoryRef = collection(db, "CATEGORY");

    let categoryItem = [];
    let success = false;
    const q = query(categoryRef);

    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {
            categoryItem.push(doc.data());
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
   
     

    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(categoryItem);
            }else{
                resolve(categoryItem);  
            }
            
        }) 
    }

}
export const getcategoryview = async()=>{
    const categoryRef = collection(db, "CATEGORYVIEW");

    let categoryItem = [];
    let success = false;
    const q = query(categoryRef, orderBy("REGISTDATE",'asc'));

    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {

            categoryItem.push(doc.data());
      
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
   
     

    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(categoryItem);
            }else{
                resolve(categoryItem);  
            }
            
        }) 
    }

}
export const getcategory1 = async()=>{
    const categoryRef = collection(db, "CATEGORY");

    let categoryItem = [];
    let success = false;
    const q = query(categoryRef, orderBy("REGISTDATE",'asc'));

    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {

            if(doc.data().CATEGORY2 ==''){
                categoryItem.push(doc.data());
            }
      
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
   
     

    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(categoryItem);
            }else{
                resolve(categoryItem);  
            }
            
        }) 
    }

}
export const getcategory2 = async(category1)=>{

    const categoryRef = collection(db, "CATEGORY");

    let categoryItem = [];
    let success = false;
    const q = query(categoryRef, where("CATEGORY1",'==', category1 ));
    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {

            if(doc.data().CATEGORYIMG ==''){
                categoryItem.push(doc.data());
            }
    
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
   
     

    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(categoryItem);
            }else{
                resolve(categoryItem);  
            }
            
        }) 
    }

}
export const deletecategory = async({category_id})=>{

    console.log("delete category", category_id);
    const categoryRef = doc(db, "CATEGORY", category_id);
    try{
        await deleteDoc(categoryRef);
    }catch(e){
        console.log("error", e.message);
    }finally{
        return;
    }
}