

import React, { useEffect} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import Categoryview from "./Categoryview";


const Center = () => {

  return (
    <div style={{display:"flex", flexDirection:"column", width:"100%", alignItems:"center"}}>
        <Categoryview />
        {/* <Noticeview /> */}
    </div>
  
  );
};

export default Center;
