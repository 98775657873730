import React, { useContext, useEffect, useState } from "react";
import { imageDB } from "../components/imageDB";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

import "../components/css/common.css";

import useDimensions from "react-use-dimensions";
import Button from "../components/Layout/Button";
import { COLORS } from "../components/Theme";
import Advertise from "./component/Advertise";
import Label from "./component/Label";
import Preview from "./component/Categoryview";
import Noticeview from "./component/Noticeview";
import Center from "./component/Center";
import Best from "./component/Best";
import Pick from "./component/Pick";
import News from "./component/News";
import Search from "../components/Layout/SearchBasket";
import MainItem from "./component/MainItem";
import ProductItem from "./component/ProductItem";
import OrderItem from "./component/Selectorder";
import OrderInfo from "./component/OrderInfo";
import ReceiveInfo from "./component/ReceiveInfo";
import PayInfo from "./component/PayInfo";
import { deleteBasket, getBaskset } from "../utility/basketfirebase";
import { UserContext } from "../context/Users";
import { getproductbyid } from "../utility/productfirebase";
import Loading from "./component/Loading";
import BasketItem from "./component/BasketItem";
import SelectOrder from "./component/Selectorder";
import Fade from "react-reveal/Fade";
import OrderItem2 from "./component/Orderitem2";
import { BiSolidRectangle } from "react-icons/bi";
import Address from "./component/Address";
import { MaxPC, SmallPC } from "../components/Responsive";
import Approve from "./component/Approve";
import MyorderItem from "./component/MyorderItem";
import { getOrder, registOrder, updateOrderlist, updateOrderstatus } from "../utility/orderfirebase";
import OderViewModule from "./component/OrderViewModule";
import { OrderType } from "../components/Define";


import { CommaFormatted, CommaFormattedEx, getDateFullTime, getDateTime } from "../utility/common";
import OrderViewModule from "./component/OrderViewModule";
import SelectItem from "./component/SelectItem";
import DeliveryViewModule from "./component/DeliveryViewModule";
import PageEx from "../components/Layout/PageEx";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { BsCaretDownFill, BsCaretUpFill, BsDatabaseGear } from "react-icons/bs";
import { AiFillSetting } from "react-icons/ai";
import SideBanner from "./component/SideBanner";
import Periodsearch from "./component/Periodsearch";
import { PeriodType, SearchType } from "../utility/commondefine";
import moment from "moment";
import OrderSearch from "./component/OrderSearch";
import OrderReturnModule from "./component/OrderReturnModule";

const NoProduct = styled.div`
  font-size: 25px;
  font-weight: 900;
  margin-bottom: 500px;
`;

    
const IconSet = styled.div`
  align-items: center;
  display: flex;
  padding-left: 5px;
`;

const ProductLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: #9d9f9f;
  overflow-x: auto;
`;

const TopShowButton = styled.div`
  height: 70px;
  width: 70px;
  background-color: #222;
  position: fixed;
  bottom: 30px;
  right: 100px;
  border-radius: 100px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const MainLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  margin: 20px 0px;
`;
const SelectView = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 930px;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
`;

const Select = styled.div`
  height: 35px;
  width: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 12px;
  background: ${({ enable }) => (enable == true ? "#ff9405" : "#56a9ff")};
  color: ${({ enable }) => (enable == true ? "#fff" : "#fff")};
`;

const CategoryImageLayer = styled.div`
  margin: 20px 8%;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  padding: 0px 20px;
`;




const LineContent = styled.div`
  display: flex;
  width: 100%;
`;
const TableLayer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom:20px;
`;

const Myordercontainer = () => {
  const navigate = useNavigate();
  const { dispatch, user } = useContext(UserContext);
  const [width, setWidth] = useState(100);
  const [ref, setRef] = useState(null);
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(true);
  const [topButton, setTopButton] = useState(false);

  const [ordercount, setOrdercount] = useState(0);
  const [orderlist, setOrderlist] = useState([]);
  const [orderOriginlist, setOrderOriginlist] = useState([]);

  const [type, setType] = useState("전체");

  const [ordercount1, setOrdercount1] = useState(0);
  const [ordercount2, setOrdercount2] = useState(0);
  const [ordercount3, setOrdercount3] = useState(0);
  const [ordercount4, setOrdercount4] = useState(0);
  const [ordercount5, setOrdercount5] = useState(0);
  const [ordercount6, setOrdercount6] = useState(0);
  const [ordercount7, setOrdercount7] = useState(0);
  const [ordercount8, setOrdercount8] = useState(0);
  const [ordercount9, setOrdercount9] = useState(0);
  const [ordercount10, setOrdercount10] = useState(0);

  const [itemdata, setItemdata] = useState({});

  const [productcount, setProductcount] = useState(0);

  const [position, setPosition] = useState(1);
  const [page, setPage] = useState(1);
  const [pageupdate, setPageupdate] = useState(1);
  const [allproductcheck, setAllproductcheck] = useState(false);
  const [screenupdate, setScreenupdate] = useState(1);

  const [orderreq, setOrderreq] = useState('');


  

  //초기데이타
  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 300) {
        setTopButton(true);
      } else {
        setTopButton(false);
      }
    };

    window.addEventListener("scroll", handleShowButton);

    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  //최초 구동시
  useEffect(() => {
    //mount 시에
    console.log("init");
    window.scrollTo(0, 0);

    return () => {
      //unmount 시에
      console.log("dispose");
    };
  }, []);

  useEffect(() => {
    setLoading(true);
  
    const userA = JSON.parse(window.localStorage.getItem("user"));
    const USER_ID = userA.USER_ID;
    
    async function fetchData() {

      let orderitems = [];
      console.log("USER_ID orderitems", USER_ID);
      
      orderitems = await getOrder({ USER_ID });


      setOrdercount(orderitems.length);

      let ordercount1 = 0;
      let ordercount2 = 0;
      let ordercount3 = 0;
      let ordercount4 = 0;
      let ordercount5 = 0;
      let ordercount6 = 0;
      let ordercount7 = 0;
      let ordercount8 = 0;
      let ordercount9 = 0;
      let ordercount10 = 0;

      let orderoptionitemsTmp = [];
      let orderoptionitems = [];
      if (orderitems != -1) {
        let count = 0;
        orderitems.forEach((element) => {
          if (element.ORDERSTATUS == OrderType.order1) {
            ordercount1++;
          }
          if (element.ORDERSTATUS == OrderType.order2) {
            ordercount2++;
          }
          if (element.ORDERSTATUS == OrderType.order3) {
            ordercount3++;
          }
          if (element.ORDERSTATUS == OrderType.order4) {
            ordercount4++;
          }
          if (element.ORDERSTATUS == OrderType.order5) {
            ordercount5++;
          }
          if (element.ORDERSTATUS == OrderType.order6) {
            ordercount6++;
          }
          if (element.ORDERSTATUS == OrderType.order7) {
            ordercount7++;
          }
          if (element.ORDERSTATUS == OrderType.order8) {
            ordercount8++;
          }
          if (element.ORDERSTATUS == OrderType.order9) {
            ordercount9++;
          }
          if (element.ORDERSTATUS == OrderType.order10) {
            ordercount10++;
          }

          count++;
          if (count < page * 20 + 1 && count > (page - 1) * 20) {
            let orderitem = {
              USER_ID: "",
              ORDERNUM: "",
              REGISTDATE: "",
              PRODUCTNAME: "",
              productmodelname: "",
              productimage: "",
              productoptionname: "",
              productprice: "",
              productcount: "",
              ORDERRECEIVEUSER: "",
              RECEIVETEL: "",
              RECEIVEADDRESS1: "",
              RECEIVEADDRESS2: "",
              RECEIVEDELIVERYMSG: "",
              RECEIVEZONECODE:"",
              STORENAME: "",
              BANK_NAME: "",
              BANK_USER: "",
              BANK_NUM: "",
              CARD_NAME: "",
              CARD_NUM: "",
              CARD_TYPE: "",
              ORDER_TYPE: "",
              BGCOLOR: "#fff",
              COLOR: "#000",
              ORDERSTATUS: "",
              ORDERLIST: [],
              expand: false,
              check: false,
              ORDERUSER: "",
              ORDERTEL: "",
              STORENAME: "",
              ORDER_ID: "",
              MONEY: 0,
              BRANDNAME: "",
              INVOICE: "",
              ORDER_MASSORDER: "",
              REQTITLE: "",
              REQCONTENT:"",
            };
            orderitem.USER_ID = element.USER_ID;
            orderitem.ORDER_MASSORDER = element.ORDER_MASSORDER;
            orderitem.ORDER_ID = element.ORDER_ID;
            orderitem.ORDERNUM = element.ORDERNUM;
            orderitem.REGISTDATE = element.REGISTDATE;
            orderitem.ORDERRECEIVEUSER = element.ORDERRECEIVEUSER;
            orderitem.RECEIVETEL = element.RECEIVETEL;
            orderitem.RECEIVEADDRESS1 = element.RECEIVEADDRESS1;
            orderitem.RECEIVEADDRESS2 = element.RECEIVEADDRESS2;
            orderitem.RECEIVEDELIVERYMSG = element.RECEIVEDELIVERYMSG;
            orderitem.RECEIVEZONECODE = element.RECEIVEZONECODE;
            orderitem.MONEY = element.MONEY;
            orderitem.ORDERSTATUS = element.ORDERSTATUS;
            orderitem.ORDERLIST = element.ORDERLIST;
            orderitem.ORDERUSER = element.ORDERUSER;
            orderitem.ORDERTEL = element.ORDERTEL;
            orderitem.STORENAME = element.STORENAME;

            orderitem.BANK_NAME = element.BANK_NAME;
            orderitem.BANK_USER = element.BANK_USER;
            orderitem.BANK_NUM = element.BANK_NUM;
            orderitem.CARD_NAME = element.CARD_NAME;
            orderitem.CARD_NUM = element.CARD_NUM;
            orderitem.CARD_TYPE = element.CARD_TYPE;
            orderitem.BRANDNAME = element.BRANDNAME;
            orderitem.INVOICE = element.INVOICE;
            orderitem.REQCONTENT = element.REQCONTENT;
            orderitem.REQTITLE = element.REQTITLE;

            console.log("proudctlist", element.ORDERLIST[0].productname);

            if (element.ORDERLIST.length > 1) {
              orderitem.PRODUCTNAME =
                element.ORDERLIST[0].productname +
                "외" +
                element.ORDERLIST.length +
                "건";
              orderoptionitemsTmp.push(orderitem);
            } else {
              orderitem.PRODUCTNAME = element.ORDERLIST[0].productname;
              orderoptionitemsTmp.push(orderitem);
            }
          }
        });
      }
      setOrdercount1(ordercount1);
      setOrdercount2(ordercount2);
      setOrdercount3(ordercount3);
      setOrdercount4(ordercount4);
      setOrdercount5(ordercount5);
      setOrdercount6(ordercount6);
      setOrdercount7(ordercount7);
      setOrdercount8(ordercount8);
      setOrdercount9(ordercount9);
      setOrdercount10(ordercount10);

      setOrderlist(orderoptionitemsTmp);

      let orderitemsTmp = [];
      orderitemsTmp = orderoptionitemsTmp;
      setOrderOriginlist(orderitemsTmp);
      
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {

    setOrderOriginlist(orderOriginlist);
    setPage(page);

    async function fetchData() {
      let orderlist = [];

      let count = 0;
      orderOriginlist.forEach((element) => {
        count++;
        if (
          count <= (page * 20 || orderOriginlist.length) &&
          count > (page - 1) * 20
        ) {
          orderlist.push(element);
        }
      });
      setOrderlist(orderlist);
    }
    fetchData();

  }, [pageupdate]);
  
  const _handlepage = async (page) => {
    console.log("_handlepage", page, position);

    setOrderlist([]);

    if (page == "next") {
      setPosition(position + 20);
      setPage(position + 20);
    } else if (page == "prev") {
      setPosition(position - 20);
      setPage(position - 20);
    } else {
      setPage(page);
    }


    setPageupdate((pageupdate) => pageupdate + 1);
  };


  useEffect(() => {
    setType(type);
    setOrdercount1(ordercount1);
    setOrdercount2(ordercount2);
    setOrdercount3(ordercount3);
    setOrdercount4(ordercount4);
    setOrdercount5(ordercount5);
    setOrdercount6(ordercount6);
    setOrdercount7(ordercount7);
    setOrdercount8(ordercount8);
    setOrdercount9(ordercount9);
    setOrdercount10(ordercount10);
    setOrderlist(orderlist);
    setAllproductcheck(allproductcheck);
 
  }, [refresh]);

  
  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      const USER_ID = user.USER_ID;
      let orderitems = [];
      orderitems = await getOrder({ USER_ID });

      
      console.log("USER_ID orderitems", orderitems);
      setOrdercount(orderitems.length);

      let ordercount1 = 0;
      let ordercount2 = 0;
      let ordercount3 = 0;
      let ordercount4 = 0;
      let ordercount5 = 0;
      let ordercount6 = 0;
      let ordercount7 = 0;
      let ordercount8 = 0;
      let ordercount9 = 0;
      let ordercount10 = 0;

      let orderoptionitemsTmp = [];
      let orderoptionitems = [];
      if (orderitems != -1) {
        let count = 0;
        orderitems.forEach((element) => {
          if (element.ORDERSTATUS == OrderType.order1) {
            ordercount1++;
          }
          if (element.ORDERSTATUS == OrderType.order2) {
            ordercount2++;
          }
          if (element.ORDERSTATUS == OrderType.order3) {
            ordercount3++;
          }
          if (element.ORDERSTATUS == OrderType.order4) {
            ordercount4++;
          }
          if (element.ORDERSTATUS == OrderType.order5) {
            ordercount5++;
          }
          if (element.ORDERSTATUS == OrderType.order6) {
            ordercount6++;
          }
          if (element.ORDERSTATUS == OrderType.order7) {
            ordercount7++;
          }
          if (element.ORDERSTATUS == OrderType.order8) {
            ordercount8++;
          }
          if (element.ORDERSTATUS == OrderType.order9) {
            ordercount9++;
          }
          if (element.ORDERSTATUS == OrderType.order10) {
            ordercount10++;
          }


          count++;
          if (count < page * 20 + 1 && count > (page - 1) * 20) {
            let orderitem = {
              USER_ID: "",
              ORDERNUM: "",
              REGISTDATE: "",
              PRODUCTNAME: "",
              productmodelname: "",
              productimage: "",
              productoptionname: "",
              productprice: "",
              productcount: "",
              ORDERRECEIVEUSER: "",
              RECEIVETEL: "",
              RECEIVEADDRESS1: "",
              RECEIVEADDRESS2: "",
              RECEIVEDELIVERYMSG: "",
              RECEIVEZONECODE: "",
              STORENAME: "",
              BANK_NAME: "",
              BANK_USER: "",
              BANK_NUM: "",
              CARD_NAME: "",
              CARD_NUM: "",
              CARD_TYPE: "",
              ORDER_TYPE: "",
              BGCOLOR: "#fff",
              COLOR: "#000",
              ORDERSTATUS: "",
              ORDERLIST: [],
              expand: false,
              check: false,
              ORDERUSER: "",
              ORDERTEL: "",
              STORENAME: "",
              ORDER_ID: "",
              MONEY: 0,
              BRANDNAME: "",
              INVOICE: "",
              ORDER_MASSORDER: "",
              REQTITLE: "",
              REQCONTENT: "",
            };
            orderitem.USER_ID = element.USER_ID;
            orderitem.ORDER_MASSORDER = element.ORDER_MASSORDER;
            orderitem.ORDER_ID = element.ORDER_ID;
            orderitem.ORDERNUM = element.ORDERNUM;
            orderitem.REGISTDATE = element.REGISTDATE;
            orderitem.ORDERRECEIVEUSER = element.ORDERRECEIVEUSER;
            orderitem.RECEIVETEL = element.RECEIVETEL;
            orderitem.RECEIVEADDRESS1 = element.RECEIVEADDRESS1;
            orderitem.RECEIVEADDRESS2 = element.RECEIVEADDRESS2;
            orderitem.RECEIVEDELIVERYMSG = element.RECEIVEDELIVERYMSG;
            orderitem.RECEIVEZONECODE = element.RECEIVEZONECODE;
            orderitem.MONEY = element.MONEY;
            orderitem.ORDERSTATUS = element.ORDERSTATUS;
            orderitem.ORDERLIST = element.ORDERLIST;
            orderitem.ORDERUSER = element.ORDERUSER;
            orderitem.ORDERTEL = element.ORDERTEL;
            orderitem.STORENAME = element.STORENAME;

            orderitem.BANK_NAME = element.BANK_NAME;
            orderitem.BANK_USER = element.BANK_USER;
            orderitem.BANK_NUM = element.BANK_NUM;
            orderitem.CARD_NAME = element.CARD_NAME;
            orderitem.CARD_NUM = element.CARD_NUM;
            orderitem.CARD_TYPE = element.CARD_TYPE;
            orderitem.BRANDNAME = element.BRANDNAME;
            orderitem.INVOICE = element.INVOICE;
            orderitem.REQCONTENT = element.REQCONTENT;
            orderitem.REQTITLE = element.REQTITLE;


            console.log("proudctlist", element.ORDERLIST[0].productname);

            if (element.ORDERLIST.length > 1) {
              orderitem.PRODUCTNAME =
                element.ORDERLIST[0].productname +
                "외" +
                element.ORDERLIST.length +
                "건";
              orderoptionitemsTmp.push(orderitem);
            } else {
              orderitem.PRODUCTNAME = element.ORDERLIST[0].productname;
              orderoptionitemsTmp.push(orderitem);
            }
          }
        });
      }
      setOrdercount1(ordercount1);
      setOrdercount2(ordercount2);
      setOrdercount3(ordercount3);
      setOrdercount4(ordercount4);
      setOrdercount5(ordercount5);
      setOrdercount6(ordercount6);
      setOrdercount7(ordercount7);
      setOrdercount8(ordercount8);
      setOrdercount9(ordercount9);
      setOrdercount10(ordercount10);

      setOrderlist(orderoptionitemsTmp);

      let orderitemsTmp = [];
      orderitemsTmp = orderoptionitemsTmp;
      setOrderOriginlist(orderitemsTmp);
      
      setLoading(false);
    }

    fetchData();
  }, [screenupdate]);

  
  const TopMove = () => {
    window.scrollTo(0, 0);
  };

  const _handleAllCheck = (data) => {
    if (data.check == true) {
      data.ORDERLIST.map((data, index) => {
        data.productcheck = false;
        console.log("data", data.productcheck);
      });
      data.check = false;
    } else {
      data.ORDERLIST.map((data, index) => {
        data.productcheck = true;
        console.log("data", data.productcheck);
      });
      data.check = true;
    }

    setRefresh((refresh) => refresh + 1);
  };
  const _handleSubCheck = (data) => {
    if (data.productcheck == true) {
      data.productcheck = false;
    } else {
      data.productcheck = true;
    }

    setRefresh((refresh) => refresh + 1);
  };

  const orderstatuscallback = async (data, ORDER_ID,OPTIONTYPE, data2, item) => {
    // 주문상태 변화
    setType("전체"); 

    const ORDERSTATUS = data;
    const OLDORDERSTATUS = OPTIONTYPE;


    if (ORDERSTATUS == '교환신청' || ORDERSTATUS == '반품신청') {
      setOrderreq(ORDERSTATUS);

      console.log("item", item);
      setItemdata(item);
    } else {
      const u = await updateOrderstatus({ ORDER_ID, ORDERSTATUS, OLDORDERSTATUS });

      async function fetchData() {
        const USER_ID = user.USER_ID;
        let orderitems = [];
        orderitems = await getOrder({ USER_ID });



        console.log("USER_ID orderitems", orderitems);
        // setOrdercount("주문갯수 (" + orderitems.length + " )건");
        setOrdercount(orderitems.length);

        let ordercount1 = 0;
        let ordercount2 = 0;
        let ordercount3 = 0;
        let ordercount4 = 0;
        let ordercount5 = 0;
        let ordercount6 = 0;
        let ordercount7 = 0;
        let ordercount8 = 0;
        let ordercount9 = 0;
        let ordercount10 = 0;

        let orderoptionitemsTmp = [];
        let orderoptionitems = [];
        if (orderitems != -1) {
          let count = 0;
          orderitems.forEach((element) => {
            if (element.ORDERSTATUS == OrderType.order1) {
              ordercount1++;
            }
            if (element.ORDERSTATUS == OrderType.order2) {
              ordercount2++;
            }
            if (element.ORDERSTATUS == OrderType.order3) {
              ordercount3++;
            }
            if (element.ORDERSTATUS == OrderType.order4) {
              ordercount4++;
            }
            if (element.ORDERSTATUS == OrderType.order5) {
              ordercount5++;
            }
            if (element.ORDERSTATUS == OrderType.order6) {
              ordercount6++;
            }
            if (element.ORDERSTATUS == OrderType.order7) {
              ordercount7++;
            }
            if (element.ORDERSTATUS == OrderType.order8) {
              ordercount8++;
            }
            if (element.ORDERSTATUS == OrderType.order9) {
              ordercount9++;
            }
            if (element.ORDERSTATUS == OrderType.order10) {
              ordercount10++;
            }


            count++;

            console.log("page.....", page);

            let orderitem = {
              ORDERNUM: "",
              REGISTDATE: "",
              PRODUCTNAME: "",
              productmodelname: "",
              productimage: "",
              productoptionname: "",
              productprice: "",
              productcount: "",
              ORDERRECEIVEUSER: "",
              RECEIVETEL: "",
              RECEIVEADDRESS1: "",
              RECEIVEADDRESS2: "",
              RECEIVEDELIVERYMSG: "",
              RECEIVEZONECODE: "",
              STORENAME: "",
              BANK_NAME: "",
              BANK_USER: "",
              BANK_NUM: "",
              ORDER_TYPE: "",
              BGCOLOR: "#fff",
              COLOR: "#000",
              ORDERSTATUS: "",
              ORDERLIST: [],
              expand: false,
              ORDERUSER: "",
              ORDERTEL: "",
              STORENAME: "",
              INVOICE: "",
              REQTITLE: "",
              REQCONTENT: "",
            };
            orderitem.ORDERNUM = element.ORDERNUM;
            orderitem.REGISTDATE = element.REGISTDATE;
            orderitem.ORDERRECEIVEUSER = element.ORDERRECEIVEUSER;
            orderitem.RECEIVETEL = element.RECEIVETEL;
            orderitem.RECEIVEADDRESS1 = element.RECEIVEADDRESS1;
            orderitem.RECEIVEADDRESS2 = element.RECEIVEADDRESS2;
            orderitem.RECEIVEDELIVERYMSG = element.RECEIVEDELIVERYMSG;
            orderitem.RECEIVEZONECODE = element.RECEIVEZONECODE;
            orderitem.MONEY = element.MONEY;
            orderitem.ORDERSTATUS = element.ORDERSTATUS;
            orderitem.ORDERLIST = element.ORDERLIST;
            orderitem.ORDERUSER = element.ORDERUSER;
            orderitem.ORDERTEL = element.ORDERTEL;
            orderitem.STORENAME = element.STORENAME;
            orderitem.INVOICE = element.INVOICE;
            orderitem.REQCONTENT = element.REQCONTENT;
            orderitem.REQTITLE = element.REQTITLE;

            console.log("proudctlist", element.ORDERLIST[0].productname);

            if (element.ORDERLIST.length > 1) {
              orderitem.PRODUCTNAME =
                element.ORDERLIST[0].productname +
                "외" +
                element.ORDERLIST.length +
                "건";
              orderoptionitemsTmp.push(orderitem);
            } else {
              orderitem.PRODUCTNAME = element.ORDERLIST[0].productname;
              orderoptionitemsTmp.push(orderitem);
            }
          });
        }
        setOrdercount1(ordercount1);
        setOrdercount2(ordercount2);
        setOrdercount3(ordercount3);
        setOrdercount4(ordercount4);
        setOrdercount5(ordercount5);
        setOrdercount6(ordercount6);
        setOrdercount7(ordercount7);
        setOrdercount8(ordercount8);
        setOrdercount9(ordercount9);
        setOrdercount10(ordercount10);

        setOrderlist(orderoptionitemsTmp);

        let orderitemsTmp = [];
        orderitemsTmp = orderoptionitemsTmp;
        setOrderOriginlist(orderitemsTmp);

        setRefresh(refresh => refresh + 1);
        setLoading(false);
      }

      fetchData();
    }



    setRefresh((refresh) => refresh + 1);
  };

  const orderstatusinnercallback = async (STATUS, ORDER_ID,OPTIONTYPE, data) => {
    // 주문상태 변화
    setType("전체");

    console.log("data", data);
    console.log("OPTIONTYPE", OPTIONTYPE);
    const OLDORDERSTATUS = OPTIONTYPE;

    const ORDERSTATUS = STATUS;

    let bAll = true;
    // 전체를 선택 했는지 부분을 선택 햇는지 확인하라
    data.ORDERLIST.map((data, index) => {
      if (data.productcheck == false) {
        bAll = false;
      }
    });

    async function fetchData() {
      const USER_ID = user.USER_ID;
      let orderitems = [];
      orderitems = await getOrder({ USER_ID });


      
      console.log("UPDATE......... orderitems", orderitems);
      // setOrdercount("주문갯수 (" + orderitems.length + " )건");
      setOrdercount(orderitems.length);

      let ordercount1 = 0;
      let ordercount2 = 0;
      let ordercount3 = 0;
      let ordercount4 = 0;
      let ordercount5 = 0;
      let ordercount6 = 0;
      let ordercount7 = 0;
      let ordercount8 = 0;
      let ordercount9 = 0;
      let ordercount10 = 0;

      let orderoptionitemsTmp = [];

      if (orderitems != -1) {
        let count = 0;
        orderitems.forEach((element) => {
          if (element.ORDERSTATUS == OrderType.order1) {
            ordercount1++;
          }
          if (element.ORDERSTATUS == OrderType.order2) {
            ordercount2++;
          }
          if (element.ORDERSTATUS == OrderType.order3) {
            ordercount3++;
          }
          if (element.ORDERSTATUS == OrderType.order4) {
            ordercount4++;
          }
          if (element.ORDERSTATUS == OrderType.order5) {
            ordercount5++;
          }
          if (element.ORDERSTATUS == OrderType.order6) {
            ordercount6++;
          }
          if (element.ORDERSTATUS == OrderType.order7) {
            ordercount7++;
          }
          if (element.ORDERSTATUS == OrderType.order8) {
            ordercount8++;
          }
          if (element.ORDERSTATUS == OrderType.order9) {
            ordercount9++;
          }
          if (element.ORDERSTATUS == OrderType.order10) {
            ordercount10++;
          }


          let orderitem = {
            USER_ID: "",
            ORDERNUM: "",
            REGISTDATE: "",
            PRODUCTNAME: "",
            productmodelname: "",
            productimage: "",
            productoptionname: "",
            productprice: "",
            productcount: "",
            ORDERRECEIVEUSER: "",
            RECEIVETEL: "",
            RECEIVEADDRESS1: "",
            RECEIVEADDRESS2: "",
            RECEIVEDELIVERYMSG: "",
            RECEIVEZONECODE: "",
            STORENAME: "",
            BANK_NAME: "",
            BANK_USER: "",
            BANK_NUM: "",
            CARD_NAME: "",
            CARD_NUM: "",
            CARD_TYPE: "",
            ORDER_TYPE: "",
            BGCOLOR: "#fff",
            COLOR: "#000",
            ORDERSTATUS: "",
            ORDERLIST: [],
            expand: false,
            check: false,
            ORDERUSER: "",
            ORDERTEL: "",
            STORENAME: "",
            ORDER_ID: "",
            MONEY: 0,
            BRANDNAME: "",
            INVOICE: "",
            ORDER_MASSORDER: "",
            REQTITLE: "",
            REQCONTENT: "",
          };
          orderitem.USER_ID = element.USER_ID;
          orderitem.ORDER_MASSORDER = element.ORDER_MASSORDER;
          orderitem.ORDER_ID = element.ORDER_ID;
          orderitem.ORDERNUM = element.ORDERNUM;
          orderitem.REGISTDATE = element.REGISTDATE;
          orderitem.ORDERRECEIVEUSER = element.ORDERRECEIVEUSER;
          orderitem.RECEIVETEL = element.RECEIVETEL;
          orderitem.RECEIVEADDRESS1 = element.RECEIVEADDRESS1;
          orderitem.RECEIVEADDRESS2 = element.RECEIVEADDRESS2;
          orderitem.RECEIVEDELIVERYMSG = element.RECEIVEDELIVERYMSG;
          orderitem.RECEIVEZONECODE = element.RECEIVEZONECODE;
          orderitem.MONEY = element.MONEY;
          orderitem.ORDERSTATUS = element.ORDERSTATUS;
          orderitem.ORDERLIST = element.ORDERLIST;
          orderitem.ORDERUSER = element.ORDERUSER;
          orderitem.ORDERTEL = element.ORDERTEL;
          orderitem.STORENAME = element.STORENAME;

          orderitem.BANK_NAME = element.BANK_NAME;
          orderitem.BANK_USER = element.BANK_USER;
          orderitem.BANK_NUM = element.BANK_NUM;
          orderitem.CARD_NAME = element.CARD_NAME;
          orderitem.CARD_NUM = element.CARD_NUM;
          orderitem.CARD_TYPE = element.CARD_TYPE;
          orderitem.BRANDNAME = element.BRANDNAME;
          orderitem.INVOICE = element.INVOICE;
          orderitem.REQCONTENT = element.REQCONTENT;
          orderitem.REQTITLE = element.REQTITLE;

          console.log("proudctlist", element.ORDERLIST[0].productname);

          if (element.ORDERLIST.length > 1) {
            orderitem.PRODUCTNAME =
              element.ORDERLIST[0].productname +
              "외" +
              element.ORDERLIST.length +
              "건";
            orderoptionitemsTmp.push(orderitem);
          } else {
            orderitem.PRODUCTNAME = element.ORDERLIST[0].productname;
            orderoptionitemsTmp.push(orderitem);
          }
        });
      }
      setOrdercount1(ordercount1);
      setOrdercount2(ordercount2);
      setOrdercount3(ordercount3);
      setOrdercount4(ordercount4);
      setOrdercount5(ordercount5);
      setOrdercount6(ordercount6);
      setOrdercount7(ordercount7);
      setOrdercount8(ordercount8);
      setOrdercount9(ordercount9);
      setOrdercount10(ordercount10);

      setOrderlist(orderoptionitemsTmp);

      let orderitemsTmp = [];
      orderitemsTmp = orderoptionitemsTmp;
      setOrderOriginlist(orderitemsTmp);
      
      setLoading(false);

      setRefresh(refresh => refresh + 1);
    }

    // 전체를 선택 했다면
    if (bAll) {
      const u = await updateOrderstatus({ ORDER_ID, ORDERSTATUS, OLDORDERSTATUS });
      fetchData();
    } else {
      // 부분을 선택 했다면
      // 기존 ORDERLIST에서  선택한 부분을 삭제 하고
      // 선택한 부분을  ORDER에 추가하자
      let neworderlistItem = [];
      let updateorderlistItem = [];

      let updateprice = 0;
      let newprice = 0;

      data.ORDERLIST.map((subdata, index) => {
        if (subdata.productcheck == false) {
          updateprice += subdata.productprice * subdata.productcount;
          updateorderlistItem.push(subdata);
        } else {
          newprice += subdata.productprice * subdata.productcount;
          neworderlistItem.push(subdata);
        }
      });

      let ORDERLIST = neworderlistItem;

      const USER_ID = data.USER_ID;
      const BANK_USER = data.BANK_USER;
      const BANK_NAME = data.BANK_NAME;
      const BANK_NUM = data.BANK_NUM;
      const CARD_NAME = data.CARD_NAME;
      const CARD_NUM = data.CARD_NUM;
      const CARD_TYPE = data.CARD_TYPE;
      const MONEY = newprice;
      const ORDERUSER = data.ORDERUSER;
      const ORDERRECEIVEUSER = data.ORDERRECEIVEUSER;
      const ORDERTEL = data.ORDERTEL;
      const RECEIVETEL = data.RECEIVETEL;
      const RECEIVEADDRESS1 = data.RECEIVEADDRESS1;
      const RECEIVEADDRESS2 = data.RECEIVEADDRESS2;

      const STORENAME = data.STORENAME;
      const BRANDNAME = data.BRANDNAME;
      const ORDERNUM = Date.now() - 1711654178996;
      const ORDER_TYPE = ORDERSTATUS;

      const registorder_ = await registOrder({
        USER_ID,
        BANK_USER,
        BANK_NAME,
        BANK_NUM,
        CARD_NAME,
        CARD_NUM,
        CARD_TYPE,
        MONEY,
        ORDERLIST,
        ORDERUSER,
        ORDERRECEIVEUSER,
        ORDERTEL,
        RECEIVETEL,
        RECEIVEADDRESS1,
        RECEIVEADDRESS2,
        STORENAME,
        BRANDNAME,
        ORDERNUM,
        ORDER_TYPE,
        OLDORDER_TYPE : ORDER_TYPE,
      });

      const ORDER_ID = data.ORDER_ID;
      const UPDATEMONEY = updateprice + 3000;
      ORDERLIST = updateorderlistItem;
      const updateorder_ = await updateOrderlist({
        ORDER_ID,
        ORDERLIST,
        UPDATEMONEY,
      });
      fetchData();
    }
  };

  const _handleSelect = (type) => {
    setType(type);

    async function fetchData() {
      const USER_ID = user.USER_ID;
      let orderitems = [];
      setOrderlist([]);
      orderitems = await getOrder({ USER_ID });


      
      console.log("USER_ID orderitems", orderitems);
      setOrdercount(orderitems.length);

      let ordercount1 = 0;
      let ordercount2 = 0;
      let ordercount3 = 0;
      let ordercount4 = 0;
      let ordercount5 = 0;
      let ordercount6 = 0;
      let ordercount7 = 0;
      let ordercount8 = 0;
      let ordercount9 = 0;
      let ordercount10 = 0;
      let orderoptionitemsTmp = [];
      if (orderitems != -1) {
        orderitems.map((data, index) => {
          if (data.ORDERSTATUS == OrderType.order1) {
            ordercount1++;
          }
          if (data.ORDERSTATUS == OrderType.order2) {
            ordercount2++;
          }
          if (data.ORDERSTATUS == OrderType.order3) {
            ordercount3++;
          }
          if (data.ORDERSTATUS == OrderType.order4) {
            ordercount4++;
          }
          if (data.ORDERSTATUS == OrderType.order5) {
            ordercount5++;
          }
          if (data.ORDERSTATUS == OrderType.order6) {
            ordercount6++;
          }
          if (data.ORDERSTATUS == OrderType.order7) {
            ordercount7++;
          }
          if (data.ORDERSTATUS == OrderType.order8) {
            ordercount8++;
          }
          if (data.ORDERSTATUS == OrderType.order9) {
            ordercount9++;
          }
          if (data.ORDERSTATUS == OrderType.order10) {
            ordercount10++;
          }


          let orderitem = {
            USER_ID: "",
            ORDERNUM: "",
            REGISTDATE: "",
            PRODUCTNAME: "",
            productmodelname: "",
            productimage: "",
            productoptionname: "",
            productprice: "",
            productcount: "",
            ORDERRECEIVEUSER: "",
            RECEIVETEL: "",
            RECEIVEADDRESS1: "",
            RECEIVEADDRESS2: "",
            RECEIVEDELIVERYMSG: "",
            RECEIVEZONECODE: "",
            STORENAME: "",
            BANK_NAME: "",
            BANK_USER: "",
            BANK_NUM: "",
            CARD_NAME: "",
            CARD_NUM: "",
            CARD_TYPE: "",
            ORDER_TYPE: "",
            BGCOLOR: "#fff",
            COLOR: "#000",
            ORDERSTATUS: "",
            ORDERLIST: [],
            expand: false,
            check: false,
            MONEY: 0,
            ORDERUSER: "",
            ORDERTEL: "",
            ORDER_ID: "",
            BRANDNAME: "",
            ORDER_MASSORDER: "",
          };
          orderitem.USER_ID = data.USER_ID;
          orderitem.ORDER_MASSORDER = data.ORDER_MASSORDER;
          orderitem.ORDERNUM = data.ORDERNUM;
          orderitem.REGISTDATE = data.REGISTDATE;
          orderitem.ORDERRECEIVEUSER = data.ORDERRECEIVEUSER;
          orderitem.RECEIVETEL = data.RECEIVETEL;
          orderitem.RECEIVEADDRESS1 = data.RECEIVEADDRESS1;
          orderitem.RECEIVEADDRESS2 = data.RECEIVEADDRESS2;
          orderitem.RECEIVEDELIVERYMSG = data.RECEIVEDELIVERYMSG;
          orderitem.RECEIVEZONECODE = data.RECEIVEZONECODE;
          orderitem.INVOICE = data.INVOICE;
          orderitem.MONEY = data.MONEY;
          orderitem.ORDERSTATUS = data.ORDERSTATUS;
          orderitem.ORDERLIST = data.ORDERLIST;
          orderitem.STORENAME = data.STORENAME;
          orderitem.BANK_NAME = data.BANK_NAME;
          orderitem.BANK_USER = data.BANK_USER;
          orderitem.BANK_NUM = data.BANK_NUM;
          orderitem.CARD_NAME = data.CARD_NAME;
          orderitem.CARD_NUM = data.CARD_NUM;
          orderitem.CARD_TYPE = data.CARD_TYPE;
          orderitem.ORDERUSER = data.ORDERUSER;
          orderitem.ORDERTEL = data.ORDERTEL;
          orderitem.ORDER_ID = data.ORDER_ID;
          orderitem.BRANDNAME = data.BRANDNAME;
          if (data.ORDERLIST.length > 1) {
            orderitem.PRODUCTNAME =
              data.ORDERLIST[0].productname +
              "외" +
              data.ORDERLIST.length +
              "건";

            console.log("type", type, data.ORDERSTATUS);

            if (type == "전체") {
              orderoptionitemsTmp.push(orderitem);
            } else if (type == data.ORDERSTATUS) {
              orderoptionitemsTmp.push(orderitem);
              console.log("data", orderitem);
            }
          } else {
            orderitem.PRODUCTNAME = data.ORDERLIST[0].productname;
            console.log("type2", type, data.ORDERSTATUS);

            if (type == "전체") {
              orderoptionitemsTmp.push(orderitem);
            } else if (type == data.ORDERSTATUS) {
              orderoptionitemsTmp.push(orderitem);
              console.log("data2", orderitem);
            }
          }
        });
      }

      console.log("orderlist", orderoptionitemsTmp);

      setOrderlist(orderoptionitemsTmp);

      let orderitemsTmp = [];
      orderitemsTmp = orderoptionitemsTmp;
      setOrderOriginlist(orderitemsTmp);
      
      setRefresh((refresh) => refresh + 1);

      setOrdercount1(ordercount1);
      setOrdercount2(ordercount2);
      setOrdercount3(ordercount3);
      setOrdercount4(ordercount4);
      setOrdercount5(ordercount5);
      setOrdercount6(ordercount6);
      setOrdercount7(ordercount7);
      setOrdercount8(ordercount8);
      setOrdercount9(ordercount9);
      setOrdercount10(ordercount10);
      setLoading(false);
    }

    fetchData();
  };



  const _handleOrderView = (item) => {
    setOrderviewstatus(!orderviewstatus);
    setItemdata(item);
  };

  const [orderviewstatus, setOrderviewstatus] = useState(false);
  const orderviewcallback = () => {
    setOrderviewstatus(!orderviewstatus);
  };

  const orderreturncallback = () => {
    setOrderreq(!orderreq);

    async function fetchData() {
      const USER_ID = user.USER_ID;
      let orderitems = [];
      setOrderlist([]);
      orderitems = await getOrder({ USER_ID });



      console.log("USER_ID orderitems", orderitems);
      setOrdercount(orderitems.length);

      let ordercount1 = 0;
      let ordercount2 = 0;
      let ordercount3 = 0;
      let ordercount4 = 0;
      let ordercount5 = 0;
      let ordercount6 = 0;
      let ordercount7 = 0;
      let ordercount8 = 0;
      let ordercount9 = 0;
      let ordercount10 = 0;
      let orderoptionitemsTmp = [];
      if (orderitems != -1) {
        orderitems.map((data, index) => {
          if (data.ORDERSTATUS == OrderType.order1) {
            ordercount1++;
          }
          if (data.ORDERSTATUS == OrderType.order2) {
            ordercount2++;
          }
          if (data.ORDERSTATUS == OrderType.order3) {
            ordercount3++;
          }
          if (data.ORDERSTATUS == OrderType.order4) {
            ordercount4++;
          }
          if (data.ORDERSTATUS == OrderType.order5) {
            ordercount5++;
          }
          if (data.ORDERSTATUS == OrderType.order6) {
            ordercount6++;
          }
          if (data.ORDERSTATUS == OrderType.order7) {
            ordercount7++;
          }
          if (data.ORDERSTATUS == OrderType.order8) {
            ordercount8++;
          }
          if (data.ORDERSTATUS == OrderType.order9) {
            ordercount9++;
          }
          if (data.ORDERSTATUS == OrderType.order10) {
            ordercount10++;
          }


          let orderitem = {
            USER_ID: "",
            ORDERNUM: "",
            REGISTDATE: "",
            PRODUCTNAME: "",
            productmodelname: "",
            productimage: "",
            productoptionname: "",
            productprice: "",
            productcount: "",
            ORDERRECEIVEUSER: "",
            RECEIVETEL: "",
            RECEIVEADDRESS1: "",
            RECEIVEADDRESS2: "",
            RECEIVEDELIVERYMSG: "",
            RECEIVEZONECODE: "",
            STORENAME: "",
            BANK_NAME: "",
            BANK_USER: "",
            BANK_NUM: "",
            CARD_NAME: "",
            CARD_NUM: "",
            CARD_TYPE: "",
            ORDER_TYPE: "",
            BGCOLOR: "#fff",
            COLOR: "#000",
            ORDERSTATUS: "",
            ORDERLIST: [],
            expand: false,
            check: false,
            MONEY: 0,
            ORDERUSER: "",
            ORDERTEL: "",
            ORDER_ID: "",
            BRANDNAME: "",
            ORDER_MASSORDER: "",
          };
          orderitem.USER_ID = data.USER_ID;
          orderitem.ORDER_MASSORDER = data.ORDER_MASSORDER;
          orderitem.ORDERNUM = data.ORDERNUM;
          orderitem.REGISTDATE = data.REGISTDATE;
          orderitem.ORDERRECEIVEUSER = data.ORDERRECEIVEUSER;
          orderitem.RECEIVETEL = data.RECEIVETEL;
          orderitem.RECEIVEADDRESS1 = data.RECEIVEADDRESS1;
          orderitem.RECEIVEADDRESS2 = data.RECEIVEADDRESS2;
          orderitem.RECEIVEDELIVERYMSG = data.RECEIVEDELIVERYMSG;
          orderitem.RECEIVEZONECODE = data.RECEIVEZONECODE;
          orderitem.INVOICE = data.INVOICE;
          orderitem.MONEY = data.MONEY;
          orderitem.ORDERSTATUS = data.ORDERSTATUS;
          orderitem.ORDERLIST = data.ORDERLIST;
          orderitem.STORENAME = data.STORENAME;
          orderitem.BANK_NAME = data.BANK_NAME;
          orderitem.BANK_USER = data.BANK_USER;
          orderitem.BANK_NUM = data.BANK_NUM;
          orderitem.CARD_NAME = data.CARD_NAME;
          orderitem.CARD_NUM = data.CARD_NUM;
          orderitem.CARD_TYPE = data.CARD_TYPE;
          orderitem.ORDERUSER = data.ORDERUSER;
          orderitem.ORDERTEL = data.ORDERTEL;
          orderitem.ORDER_ID = data.ORDER_ID;
          orderitem.BRANDNAME = data.BRANDNAME;
          if (data.ORDERLIST.length > 1) {
            orderitem.PRODUCTNAME =
              data.ORDERLIST[0].productname +
              "외" +
              data.ORDERLIST.length +
              "건";

            console.log("type", type, data.ORDERSTATUS);

            if (type == "전체") {
              orderoptionitemsTmp.push(orderitem);
            } else if (type == data.ORDERSTATUS) {
              orderoptionitemsTmp.push(orderitem);
              console.log("data", orderitem);
            }
          } else {
            orderitem.PRODUCTNAME = data.ORDERLIST[0].productname;
            console.log("type2", type, data.ORDERSTATUS);

            if (type == "전체") {
              orderoptionitemsTmp.push(orderitem);
            } else if (type == data.ORDERSTATUS) {
              orderoptionitemsTmp.push(orderitem);
              console.log("data2", orderitem);
            }
          }
        });
      }

      console.log("orderlist", orderoptionitemsTmp);

      setOrderlist(orderoptionitemsTmp);

      let orderitemsTmp = [];
      orderitemsTmp = orderoptionitemsTmp;
      setOrderOriginlist(orderitemsTmp);

      setRefresh((refresh) => refresh + 1);

      setOrdercount1(ordercount1);
      setOrdercount2(ordercount2);
      setOrdercount3(ordercount3);
      setOrdercount4(ordercount4);
      setOrdercount5(ordercount5);
      setOrdercount6(ordercount6);
      setOrdercount7(ordercount7);
      setOrdercount8(ordercount8);
      setOrdercount9(ordercount9);
      setOrdercount10(ordercount10);
      setLoading(false);
    }

    fetchData();
  }

  const _handleDeliveryView = (item) => {
    setDeliveryviewstatus(!deliveryviewstatus);
  };

  const [deliveryviewstatus, setDeliveryviewstatus] = useState(false);
  const delveryviewcallback = () => {
    setDeliveryviewstatus(!deliveryviewstatus);
  };

  const _handleCostDown = () => {};
  const _handleCostUp = () => {};

  const _handleexpand = (data) => {
    if (data.expand) {
      data.expand = false;
    } else {
      data.expand = true;
    }
    setRefresh((refresh) => refresh + 1);
  };


  const _handleProductCheck = (data) => {
    if (data.PRODUCTCHECK == true) {
      data.PRODUCTCHECK = false;
    } else {
      data.PRODUCTCHECK = true;
    }
    setRefresh((refresh) => refresh + 1);
  };

  async function getFind(keyword_, search_) {
    let searchtype = "주문번호";
    if (search_ != "") {
      searchtype = search_;
    }
    console.log("findmember", keyword_, searchtype);

    let productitemsTmp = [];

    // 키워드 검색 하고
    if (searchtype == "주문번호") {
      orderOriginlist.map((data, index) => {
        console.log("data", data);
        if (String(data.ORDERNUM).indexOf(keyword_) != -1 || keyword_ == "") {
          productitemsTmp.push(data);
        }
      });
    } else if (searchtype == "주문상품") {
      orderOriginlist.map((data, index) => {
        console.log("data", data);
        if (
          data.ORDERLIST[0].productname.indexOf(keyword_) != -1 ||
          keyword_ == ""
        ) {
          productitemsTmp.push(data);
        }
      });
    } else if (searchtype == "모델명") {
      orderOriginlist.map((data, index) => {
        console.log("data", data);
        if (
          data.ORDERLIST[0].productmodelname.indexOf(keyword_) != -1 ||
          keyword_ == ""
        ) {
          productitemsTmp.push(data);
        }
      });
    } else if (searchtype == "옵션") {
      orderOriginlist.map((data, index) => {
        console.log("data", data);
        if (
          data.ORDERLIST[0].productoptionname.indexOf(keyword_) != -1 ||
          keyword_ == ""
        ) {
          productitemsTmp.push(data);
        }
      });
    } else if (searchtype == "송장번호") {
      orderOriginlist.map((data, index) => {
        if (data.INVOICE.indexOf(keyword_) != -1 || keyword_ == "") {
          productitemsTmp.push(data);
        }
      });
    }

    // 통합 검색 하자
    setOrderlist(productitemsTmp);
    setRefresh((refresh) => refresh + 1);

  }
  
  const keywordcallback = async (keyword_, type_) => {

    getFind(keyword_, type_);
  };
  const expandcallback = () => {
    
  }
  const periodsearch1 = (startdate, enddate) => {
      const d1 = new Date(startdate);
      const d2 = new Date(enddate);

      let count = 0;

      console.log("time", d1, d2);

      if (d2 < d1) {
        return;
      }

      if (d1 == "" || d2 == "") {
        return;
      }
      let orderoptionitemsTmp = [];
      let ordercount1 = 0;
      let ordercount2 = 0;
      let ordercount3 = 0;
      let ordercount4 = 0;
      let ordercount5 = 0;
      let ordercount6 = 0;
      let ordercount7 = 0;
      let ordercount8 = 0;
      let ordercount9 = 0;
      let ordercount10 = 0;

      orderOriginlist.forEach((element) => {
        if (element.REGISTDATE >= d1 && element.REGISTDATE <= d2) {
          if (element.ORDERSTATUS == OrderType.order1) {
            ordercount1++;
          }
          if (element.ORDERSTATUS == OrderType.order2) {
            ordercount2++;
          }
          if (element.ORDERSTATUS == OrderType.order3) {
            ordercount3++;
          }
          if (element.ORDERSTATUS == OrderType.order4) {
            ordercount4++;
          }
          if (element.ORDERSTATUS == OrderType.order5) {
            ordercount5++;
          }
          if (element.ORDERSTATUS == OrderType.order6) {
            ordercount6++;
          }
          if (element.ORDERSTATUS == OrderType.order7) {
            ordercount7++;
          }
          if (element.ORDERSTATUS == OrderType.order8) {
            ordercount8++;
          }
          if (element.ORDERSTATUS == OrderType.order9) {
            ordercount9++;
          }
          if (element.ORDERSTATUS == OrderType.order10) {
            ordercount10++;
          }


          count++;

          let orderitem = {
            USER_ID: "",
            ORDERNUM: "",
            REGISTDATE: "",
            PRODUCTNAME: "",
            productmodelname: "",
            productimage: "",
            productoptionname: "",
            productprice: "",
            productcount: "",
            ORDERRECEIVEUSER: "",
            RECEIVETEL: "",
            RECEIVEADDRESS1: "",
            RECEIVEADDRESS2: "",
            RECEIVEDELIVERYMSG: "",
            RECEIVEZONECODE: "",
            STORENAME: "",
            BANK_NAME: "",
            BANK_USER: "",
            BANK_NUM: "",
            CARD_NAME: "",
            CARD_NUM: "",
            CARD_TYPE: "",
            ORDER_TYPE: "",
            BGCOLOR: "#fff",
            COLOR: "#000",
            ORDERSTATUS: "",
            ORDERLIST: [],
            expand: false,
            check: false,
            ORDERUSER: "",
            ORDERTEL: "",
            STORENAME: "",
            ORDER_ID: "",
            MONEY: 0,
            BRANDNAME: "",
            INVOICE: "",
            ORDER_MASSORDER: "",
            REQTITLE: "",
            REQCONTENT: "",
          };
          orderitem.USER_ID = element.USER_ID;
          orderitem.ORDER_MASSORDER = element.ORDER_MASSORDER;
          orderitem.ORDER_ID = element.ORDER_ID;
          orderitem.ORDERNUM = element.ORDERNUM;
          orderitem.REGISTDATE = element.REGISTDATE;
          orderitem.ORDERRECEIVEUSER = element.ORDERRECEIVEUSER;
          orderitem.RECEIVETEL = element.RECEIVETEL;
          orderitem.RECEIVEADDRESS1 = element.RECEIVEADDRESS1;
          orderitem.RECEIVEADDRESS2 = element.RECEIVEADDRESS2;
          orderitem.RECEIVEDELIVERYMSG = element.RECEIVEDELIVERYMSG;
          orderitem.RECEIVEZONECODE = element.RECEIVEZONECODE;
          orderitem.MONEY = element.MONEY;
          orderitem.ORDERSTATUS = element.ORDERSTATUS;
          orderitem.ORDERLIST = element.ORDERLIST;
          orderitem.ORDERUSER = element.ORDERUSER;
          orderitem.ORDERTEL = element.ORDERTEL;
          orderitem.STORENAME = element.STORENAME;

          orderitem.BANK_NAME = element.BANK_NAME;
          orderitem.BANK_USER = element.BANK_USER;
          orderitem.BANK_NUM = element.BANK_NUM;
          orderitem.CARD_NAME = element.CARD_NAME;
          orderitem.CARD_NUM = element.CARD_NUM;
          orderitem.CARD_TYPE = element.CARD_TYPE;
          orderitem.BRANDNAME = element.BRANDNAME;
          orderitem.INVOICE = element.INVOICE;
          orderitem.REQCONTENT = element.REQCONTENT;
          orderitem.REQTITLE = element.REQTITLE;

          if (element.ORDERLIST.length > 1) {
            orderitem.PRODUCTNAME =
              element.ORDERLIST[0].productname +
              "외" +
              element.ORDERLIST.length +
              "건";
            orderoptionitemsTmp.push(orderitem);
          } else {
            orderitem.PRODUCTNAME = element.ORDERLIST[0].productname;
            orderoptionitemsTmp.push(orderitem);
          }
        }
      });
      setOrdercount1(ordercount1);
      setOrdercount2(ordercount2);
      setOrdercount3(ordercount3);
      setOrdercount4(ordercount4);
      setOrdercount5(ordercount5);
      setOrdercount6(ordercount6);
      setOrdercount7(ordercount7);
      setOrdercount8(ordercount8);
      setOrdercount9(ordercount9);
      setOrdercount10(ordercount10);

      setOrderlist(orderoptionitemsTmp);
      setOrdercount(orderoptionitemsTmp.length);
      setRefresh((refresh) => refresh + 1);

  };

  const periodsearch2 = (type) => {
  
    const now = moment();

    let time = "";
    time = type;
    console.log("time", time);

    // if (type == PeriodType.period1) {
    //   time = moment(now).subtract(moment(now).format("H"), "h").toDate();
    // } else if (type == PeriodType.period2) {
    //   time = moment(now).subtract(7, "days").toDate();
    // } else if (type == PeriodType.period3) {
    //   time = moment(now).subtract(30, "days").toDate();
    // } else if (type == PeriodType.period4) {
    //   time = moment(now).subtract(90, "days").toDate();
    // } else if (type == PeriodType.period5) {
    //   time = moment(now).subtract(900000, "days").toDate();
    // }

    let ordercount1 = 0;
    let ordercount2 = 0;
    let ordercount3 = 0;
    let ordercount4 = 0;
    let ordercount5 = 0;
    let ordercount6 = 0;
    let ordercount7 = 0;
    let ordercount8 = 0;
    let ordercount9 = 0;
    let ordrecount10 = 0;
    let orderoptionitemsTmp = [];

    let count = 0;
    orderOriginlist.forEach((element) => {

      console.log("type", element.REGISTDATE/1000, time);

      if (element.REGISTDATE/1000 >= time) {
        
    
        if (element.ORDERSTATUS == OrderType.order1) {
          ordercount1++;
        }
        if (element.ORDERSTATUS == OrderType.order2) {
          ordercount2++;
        }
        if (element.ORDERSTATUS == OrderType.order3) {
          ordercount3++;
        }
        if (element.ORDERSTATUS == OrderType.order4) {
          ordercount4++;
        }
        if (element.ORDERSTATUS == OrderType.order5) {
          ordercount5++;
        }
        if (element.ORDERSTATUS == OrderType.order6) {
          ordercount6++;
        }
        if (element.ORDERSTATUS == OrderType.order7) {
          ordercount7++;
        }
        if (element.ORDERSTATUS == OrderType.order8) {
          ordercount8++;
        }
        if (element.ORDERSTATUS == OrderType.order9) {
          ordercount9++;
        }
        if (element.ORDERSTATUS == OrderType.order10) {
          ordercount10++;
        }


        count++;

        let orderitem = {
          USER_ID: "",
          ORDERNUM: "",
          REGISTDATE: "",
          PRODUCTNAME: "",
          productmodelname: "",
          productimage: "",
          productoptionname: "",
          productprice: "",
          productcount: "",
          ORDERRECEIVEUSER: "",
          RECEIVETEL: "",
          RECEIVEADDRESS1: "",
          RECEIVEADDRESS2: "",
          RECEIVEDELIVERYMSG: "",
          RECEIVEZONECODE :"",
          STORENAME: "",
          BANK_NAME: "",
          BANK_USER: "",
          BANK_NUM: "",
          CARD_NAME: "",
          CARD_NUM: "",
          CARD_TYPE: "",
          ORDER_TYPE: "",
          BGCOLOR: "#fff",
          COLOR: "#000",
          ORDERSTATUS: "",
          ORDERLIST: [],
          expand: false,
          check: false,
          ORDERUSER: "",
          ORDERTEL: "",
          STORENAME: "",
          ORDER_ID: "",
          MONEY: 0,
          BRANDNAME: "",
          INVOICE: "",
          ORDER_MASSORDER: "",
          REQTITLE: "",
          REQCONTENT: "",
        };
        orderitem.USER_ID = element.USER_ID;
        orderitem.ORDER_MASSORDER = element.ORDER_MASSORDER;
        orderitem.ORDER_ID = element.ORDER_ID;
        orderitem.ORDERNUM = element.ORDERNUM;
        orderitem.REGISTDATE = element.REGISTDATE;
        orderitem.ORDERRECEIVEUSER = element.ORDERRECEIVEUSER;
        orderitem.RECEIVETEL = element.RECEIVETEL;
        orderitem.RECEIVEADDRESS1 = element.RECEIVEADDRESS1;
        orderitem.RECEIVEADDRESS2 = element.RECEIVEADDRESS2;
        orderitem.RECEIVEDELIVERYMSG = element.RECEIVEDELIVERYMSG;
        orderitem.RECEIVEZONECODE = element.RECEIVEZONECODE;
        orderitem.MONEY = element.MONEY;
        orderitem.ORDERSTATUS = element.ORDERSTATUS;
        orderitem.ORDERLIST = element.ORDERLIST;
        orderitem.ORDERUSER = element.ORDERUSER;
        orderitem.ORDERTEL = element.ORDERTEL;
        orderitem.STORENAME = element.STORENAME;

        orderitem.BANK_NAME = element.BANK_NAME;
        orderitem.BANK_USER = element.BANK_USER;
        orderitem.BANK_NUM = element.BANK_NUM;
        orderitem.CARD_NAME = element.CARD_NAME;
        orderitem.CARD_NUM = element.CARD_NUM;
        orderitem.CARD_TYPE = element.CARD_TYPE;
        orderitem.BRANDNAME = element.BRANDNAME;
        orderitem.INVOICE = element.INVOICE;
        orderitem.RECEIVEDELIVERYMSG = element.RECEIVEDELIVERYMSG;
        orderitem.REQCONTENT = element.REQCONTENT;
        orderitem.REQTITLE = element.REQTITLE;

        console.log("proudctlist", element.ORDERLIST[0].productname);

        if (element.ORDERLIST.length > 1) {
          orderitem.PRODUCTNAME =
            element.ORDERLIST[0].productname +
            "외" +
            element.ORDERLIST.length +
            "건";
          orderoptionitemsTmp.push(orderitem);
        } else {
          orderitem.PRODUCTNAME = element.ORDERLIST[0].productname;
          orderoptionitemsTmp.push(orderitem);
        }
      }
    });
    setOrdercount1(ordercount1);
    setOrdercount2(ordercount2);
    setOrdercount3(ordercount3);
    setOrdercount4(ordercount4);
    setOrdercount5(ordercount5);
    setOrdercount6(ordercount6);
    setOrdercount7(ordercount7);
    setOrdercount8(ordercount8);

    setOrdercount9(ordercount9);
    setOrdercount10(ordercount10);

    setOrderlist(orderoptionitemsTmp);
    setOrdercount(orderoptionitemsTmp.length);
    // setRefresh((refresh) => refresh + 1);
      

  };

  return (
    <>
      {loading == false ? (
        <>
          {orderviewstatus == true ? (
            <OrderViewModule
              callback={orderviewcallback}
              orderuser={itemdata.ORDERUSER}
              orderreceiveuser={itemdata.ORDERRECEIVEUSER}
              ordertel={itemdata.ORDERTEL}
              storename={itemdata.STORENAME}
              brandname={itemdata.BRANDNAME}
              receivetel={itemdata.RECEIVETEL}
              receiveaddress1={itemdata.RECEIVEADDRESS1}
              receiveaddress2={itemdata.RECEIVEADDRESS2}
              receivezonecode={itemdata.RECEIVEZONECODE}
              receivedeliverymsg={itemdata.RECEIVEDELIVERYMSG}
              bankuser={itemdata.BANK_USER}
              bankname={itemdata.BANK_NAME}
              banknum={itemdata.BANK_NUM}
              carduser={itemdata.CARD_USER}
              cardnum={itemdata.CARD_NUM}
              money={itemdata.MONEY}
              registdate={itemdata.REGISTDATE}
              ordernum={itemdata.ORDERNUM}
              reqcontent={itemdata.REQCONTENT}
              reqtitle ={itemdata.REQTITLE}
            ></OrderViewModule>
          ) : null}


          {
            (orderreq == '교환신청' || orderreq == '반품신청') && <OrderReturnModule callback={orderreturncallback} PRODUCTNAME={itemdata.PRODUCTNAME} TYPE={orderreq} ORDER_ID={itemdata.ORDER_ID} />
          }

          {deliveryviewstatus == true ? (
            <DeliveryViewModule
              callback={delveryviewcallback}
              ordernum={itemdata.ORDERNUM}
            ></DeliveryViewModule>
          ) : null}

          <SmallPC>
            <div className="SmallItemContainer">
              <>

                
                  <div className="Container">
                    <Label name={"주문현황"}></Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>

                    <div style={{ padding: "0px 10%" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>


                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "10px 0px",
                            justifyContent: "flex-start",
                          }}
                        >
                          <OrderSearch
                            type={SearchType.order}
                            keywordcallback={keywordcallback}
                            expand={false}
                          ></OrderSearch>
                        </div>

                        <Periodsearch
                          callback={periodsearch1}
                          btncallback={periodsearch2}
                        />

                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "column",
                            }}
                          >
                            <SelectView>
                              <Select
                                className="button"
                                enable={type == "전체"}
                                onClick={() => {
                                  _handleSelect("전체");
                                }}
                              >
                                전체 : ({ordercount})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "주문접수"}
                                onClick={() => {
                                  _handleSelect("주문접수");
                                }}
                              >
                                주문접수 : ({ordercount1})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "결제완료"}
                                onClick={() => {
                                  _handleSelect("결제완료");
                                }}
                              >
                                결제완료 : ({ordercount2})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "배송준비"}
                                onClick={() => {
                                  _handleSelect("배송준비");
                                }}
                              >
                                배송준비 : ({ordercount3})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "배송중"}
                                onClick={() => {
                                  _handleSelect("배송중");
                                }}
                              >
                                배송중 : ({ordercount4})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "배송완료"}
                                onClick={() => {
                                  _handleSelect("배송완료");
                                }}
                              >
                                배송완료 : ({ordercount5})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "반품신청"}
                                onClick={() => {
                                  _handleSelect("반품신청");
                                }}
                              >
                                반품신청 : ({ordercount9})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "교환신청"}
                                onClick={() => {
                                  _handleSelect("교환신청");
                                }}
                              >
                                교환신청 : ({ordercount10})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "반품"}
                                onClick={() => {
                                  _handleSelect("반품");
                                }}
                              >
                                반품 : ({ordercount6})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "교환"}
                                onClick={() => {
                                  _handleSelect("교환");
                                }}
                              >
                                교환 : ({ordercount7})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "주문취소"}
                                onClick={() => {
                                  _handleSelect("주문취소");
                                }}
                              >
                                주문취소 : ({ordercount8})
                              </Select>
                            </SelectView>
                            <LineContent />

                            <TableLayer>
                              <table
                                class="bluetop"
                                style={{ overflow: "auto" }}
                              >
                                <tr style={{ height: 35 }}>
                                  <th>No</th>
                                  <th>주문번호</th>
                                  <th style={{ width: 100 }}>상태</th>
                                  <th>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>주문일시</div>
                                      <div
                                        onClick={_handleCostDown}
                                        style={{
                                          display: "flex",
                                          marginLeft: 5,
                                        }}
                                      >
                                        <HiOutlineArrowSmDown />
                                      </div>
                                      <div
                                        onClick={_handleCostUp}
                                        style={{ display: "flex" }}
                                      >
                                        <HiOutlineArrowSmUp />
                                      </div>
                                    </div>
                                  </th>
                                  <th>주문상품</th>
                                  <th>수령자명</th>
                                <th>수령자연락처</th>
                                <th>우편번호</th>
                                <th style={{ width: "100px" }}>수령자주소</th>
                           
                                <th>총결제금액</th>
                                <th>송장번호</th>
                                <th>배송메시지</th>
                                  <th>배송확인</th>
                                  <th>주문서보기</th>
                                </tr>

                                {orderlist.map((data, index) => (
                                  <>
                                    <tr>
                                      <td style={{ width: 30 }}>
                                        {index + (page - 1) * 20 + 1}
                                      </td>
                                      <td>{data.ORDERNUM}</td>
                                  
                                      <td style={{ width: 100 }}>
                                        {
                                          data.ORDERSTATUS == "반품" ||
                                          data.ORDERSTATUS == "교환" ||
                                          data.ORDERSTATUS == "반품신청" ||
                                          data.ORDERSTATUS == "교환신청" ||
                                          data.ORDERSTATUS == "주문취소" ? (
                                          <> {data.ORDERSTATUS} </>
                                        ) : (
                                          <SelectItem
                                            OPTIONTYPE={data.ORDERSTATUS}
                                            ORDER_ID={data.ORDER_ID}
                                            item={data}
                                            callback={orderstatuscallback}
                                          />
                                        )}
                                      </td>

                                 
                                      <td style={{ width: 100 }}>
                                        {getDateFullTime(data.REGISTDATE)}
                                      </td>
                                      <td style={{ width: 250 }}>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            textAlign: "left",
                                          }}
                                        >
                                          <div> {data.PRODUCTNAME}</div>
                                          <>
                                            {data.expand == false ? (
                                              <div
                                                style={{
                                                  marginLeft: 10,
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                                onClick={() => {
                                                  _handleexpand(data);
                                                }}
                                              >
                                                <BsCaretDownFill size={20} />
                                              </div>
                                            ) : (
                                              <div
                                                style={{
                                                  marginLeft: 10,
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                                onClick={() => {
                                                  _handleexpand(data);
                                                }}
                                              >
                                                <BsCaretUpFill size={20} />
                                              </div>
                                            )}
                                          </>
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          width: 120,
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.ORDERRECEIVEUSER}
                                      </td>
                                      <td
                                        style={{
                                          width: 120,
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.RECEIVETEL}
                                      </td>
                                      <td>
                                        {data.RECEIVEZONECODE}
                                      </td>
                                      <td
                                        style={{
                                          minWidth: 120,
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.RECEIVEADDRESS1}{" "}
                                        {data.RECEIVEADDRESS2}
                                      </td>
                                      <td
                                        style={{
                                          width: 120,
                                          textAlign: "center",
                                        }}
                                      >
                                        {CommaFormatted(data.MONEY)}
                                      </td>

                                      <td>
                                        {data.INVOICE}
                                      </td>
                                      <td>
                                        {data.RECEIVEDELIVERYMSG.slice(0, 10)}
                                        {data.RECEIVEDELIVERYMSG.length > 10 ? "..." : null}{" "}
                                      </td>
                                      <td style={{ width: 95 }}>

                                        <a target='_blank'        
                                          href={'https://link.tracker.delivery/track?client_id=4f2ontf17tg6vtq5edbti8h2en&carrier_id=kr.hanjin&tracking_number=' + data.INVOICE}>
                                          <Button
                                            containerStyle={{
                                              marginLeft: 10,
                                              fontWeight: 200,
                                            }}
                                            Label={"배송확인"}
                                            bgcolor={"white"}
                                            item={data}
                                            size={12}
                                            borderRadius={5}
                                            color={"#000"}
                                            bordercolor={"#999"}
                                            height={30}
                                            width={"80px"}
                                            callback={()=>{}}
                                          />
                                        </a>
                                       
                                      </td>
                                      <td style={{ width: 95 }}>
                                        <Button
                                          containerStyle={{
                                            marginLeft: 10,
                                            fontWeight: 200,
                                          }}
                                          Label={"주문상세보기"}
                                          bgcolor={"#fff"}
                                          item={data}
                                          size={12}
                                          borderRadius={5}
                                          color={"#000"}
                                          bordercolor={"#999"}
                                          height={30}
                                          width={"80px"}
                                          callback={_handleOrderView}
                                        />
                                      </td>
                                    </tr>
                                    {data.expand == true && (
                                      <tr>
                                        <td colSpan={12}>
                                          <div style={{ padding: 20 }}>
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                                marginLeft: "50px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <Label
                                                name="상태변경 : "
                                                size={12}
                                                color={"#5d5d5d"}
                                              ></Label>

                                              <SelectItem
                                                containerStyle={{
                                                  marginLeft: 5,
                                                }}
                                                OPTIONTYPE={
                                                  data.ORDERSTATUS
                                                }
                                                ORDER_ID={data.ORDER_ID}
                                                ITEMS={data}
                                                callback={
                                                  orderstatusinnercallback
                                                }
                                              />
                                            </div>
                                            <table
                                              class="whitetop"
                                              style={{
                                                overflow: "auto",
                                                padding: 10,
                                                width: "100%",
                                              }}
                                            >
                                              <tr>
                                                <th>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      onClick={() => {
                                                        _handleAllCheck(data);
                                                      }}
                                                      checked={data.check}
                                                      style={{ width: "25px" }}
                                                    />
                                                  </div>
                                                </th>
                                                <th>No</th>
                                                <th style={{ width: 100 }}>
                                                  상태
                                                </th>
                                                <th>주문상품</th>
                                                <th>상품이미지</th>
                                                <th>모델명</th>
                                                <th>옵션</th>
                                                <th>수량</th>
                                                <th>공급가</th>
                                                <th>배송비</th>
                                              </tr>

                                              {data.ORDERLIST.map(
                                                (subdata, index) => (
                                                  <tr>
                                                    <td style={{ width: 30 }}>
                                                      <input
                                                        type="checkbox"
                                                        style={{ width: 30 }}
                                                        onClick={() => {
                                                          _handleSubCheck(
                                                            subdata
                                                          );
                                                        }}
                                                        checked={
                                                          subdata.productcheck
                                                        }
                                                      />
                                                    </td>
                                                    <td style={{ width: 30 }}>
                                                      {index +
                                                        (page - 1) * 20 +
                                                        1}
                                                    </td>
                                                    <td style={{ width: 100 }}>
                                                      {data.ORDERSTATUS}
                                                    </td>
                                                    <td style={{ width: 400 }}>
                                                      {subdata.productname}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: 120,
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <img
                                                        src={
                                                          subdata.productimage
                                                        }
                                                        style={{ height: 30 }}
                                                      />
                                                    </td>
                                                    <td>
                                                      {subdata.productmodelname}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: 120,
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {
                                                        subdata.productoptionname
                                                      }
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: 120,
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {subdata.productcount}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: 120,
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {CommaFormattedEx(
                                                        subdata.productprice
                                                      )}
                                                    </td>
                                                    <td>
                                                      {CommaFormattedEx(3000)}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                ))}
                              </table>
                            </TableLayer>
                            <div style={{ marginTop: 10, marginBottom: 15 }}>
                              <PageEx
                                count={ordercount}
                                position={position}
                                callback={_handlepage}
                              />
                            </div>


                          </div>
                        </div>
                      </div>

                      {topButton == true && (
                        <TopShowButton onClick={TopMove}>TOP</TopShowButton>
                      )}
                    </div>

                    {orderlist.length == 0 && (
                      <NoProduct style={{ marginTop: 50 }}>
                        {type} 항목이 존재 하지 않습니다
                      </NoProduct>
                    )}
                  </div>
                
              </>
            </div>
          </SmallPC>

          <MaxPC>
            <div className="SmallItemContainer">
              <>
                {ordercount > 0 ? (
                  <div className="Container">
                    <Label name={"주문현황"}></Label>

                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>

                    <div style={{ padding: "0px 10%" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* <MainItem ItemText={"주문현황"} /> */}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "10px 0px",
                            justifyContent: "flex-start",
                          }}
                        >
                          <OrderSearch
                            type={SearchType.order}
                            keywordcallback={keywordcallback}
                            expand={false}
                          ></OrderSearch>
                        </div>

                        <Periodsearch
                          callback={periodsearch1}
                          btncallback={periodsearch2}
                        />
                        
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "column",
                            }}
                          >
                            <SelectView>
                              <Select
                                className="button"
                                enable={type == "전체"}
                                onClick={() => {
                                  _handleSelect("전체");
                                }}
                              >
                                전체 : ({ordercount})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "주문접수"}
                                onClick={() => {
                                  _handleSelect("주문접수");
                                }}
                              >
                                주문접수 : ({ordercount1})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "결제완료"}
                                onClick={() => {
                                  _handleSelect("결제완료");
                                }}
                              >
                                결제완료 : ({ordercount2})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "배송준비"}
                                onClick={() => {
                                  _handleSelect("배송준비");
                                }}
                              >
                                배송준비 : ({ordercount3})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "배송중"}
                                onClick={() => {
                                  _handleSelect("배송중");
                                }}
                              >
                                배송중 : ({ordercount4})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "배송완료"}
                                onClick={() => {
                                  _handleSelect("배송완료");
                                }}
                              >
                                배송완료 : ({ordercount5})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "반품"}
                                onClick={() => {
                                  _handleSelect("반품");
                                }}
                              >
                                반품 : ({ordercount6})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "교환"}
                                onClick={() => {
                                  _handleSelect("교환");
                                }}
                              >
                                교환 : ({ordercount7})
                              </Select>
                              <Select
                                className="button"
                                enable={type == "주문취소"}
                                onClick={() => {
                                  _handleSelect("주문취소");
                                }}
                              >
                                주문취소 : ({ordercount8})
                              </Select>
                            </SelectView>
                            <LineContent />

                            <TableLayer>
                              <table
                                class="bluetop"
                                style={{ overflow: "auto" }}
                              >
                                <tr style={{ height: 35 }}>
                                  <th>No</th>
                                  <th>주문번호</th>
                                  <th style={{ width: 100 }}>상태</th>
                                  <th>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>주문일시</div>
                                      <div
                                        onClick={_handleCostDown}
                                        style={{
                                          display: "flex",
                                          marginLeft: 5,
                                        }}
                                      >
                                        <HiOutlineArrowSmDown />
                                      </div>
                                      <div
                                        onClick={_handleCostUp}
                                        style={{ display: "flex" }}
                                      >
                                        <HiOutlineArrowSmUp />
                                      </div>
                                    </div>
                                  </th>
                                  <th>주문상품</th>
                                  <th>수령자명</th>
                                  <th>수령자연락처</th>
                                  <th style={{ width: "100px" }}>수령자주소</th>
                                  <th>총결제금액</th>
                                  <th>송장번호</th>
                                  <th>배송확인</th>
                                  <th>주문서보기</th>
                                </tr>

                                {orderlist.map((data, index) => (
                                  <>
                                    <tr>
                                      <td style={{ width: 30 }}>
                                        {index + (page - 1) * 20 + 1}
                                      </td>
                                      <td>{data.ORDERNUM}</td>
                                      <td style={{ width: 100 }}>
                                        {
                                          data.ORDERSTATUS == "반품" ||
                                            data.ORDERSTATUS == "반품신청" ||
                                            data.ORDERSTATUS == "교환신청" ||
                                            data.ORDERSTATUS == "교환" ||
                                            data.ORDERSTATUS == "주문취소" ? (
                                            <> {data.ORDERSTATUS} </>
                                          ) : (
                                            <SelectItem
                                              OPTIONTYPE={data.ORDERSTATUS}
                                              ORDER_ID={data.ORDER_ID}
                                              callback={orderstatuscallback}
                                            />
                                          )}
                                      </td>
                                      <td style={{ width: 100 }}>
                                        {getDateFullTime(data.REGISTDATE)}
                                      </td>
                                      <td style={{ width: 250 }}>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            textAlign: "left",
                                          }}
                                        >
                                          <div> {data.PRODUCTNAME}</div>
                                          <>
                                            {data.expand == false ? (
                                              <div
                                                style={{
                                                  marginLeft: 10,
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                                onClick={() => {
                                                  _handleexpand(data);
                                                }}
                                              >
                                                <BsCaretDownFill size={20} />
                                              </div>
                                            ) : (
                                              <div
                                                style={{
                                                  marginLeft: 10,
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                                onClick={() => {
                                                  _handleexpand(data);
                                                }}
                                              >
                                                <BsCaretUpFill size={20} />
                                              </div>
                                            )}
                                          </>
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          width: 120,
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.ORDERRECEIVEUSER}
                                      </td>
                                      <td
                                        style={{
                                          width: 120,
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.RECEIVETEL}
                                      </td>
                                      <td
                                        style={{
                                          minWidth: 120,
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.RECEIVEADDRESS1}{" "}
                                        {data.RECEIVEADDRESS2}
                                      </td>
                                      <td
                                        style={{
                                          width: 120,
                                          textAlign: "center",
                                        }}
                                      >
                                        {CommaFormatted(data.MONEY)}
                                      </td>
                                      <td>{data.INVOICE}</td>
                                      <td>
                                        {data.RECEIVEDELIVERYMSG.slice(0, 10)}
                                        {data.RECEIVEDELIVERYMSG.length > 10 ? "..." : null}{" "}
                                      </td>
                                   
                                      <td style={{ width: 95 }}>

                                        <a target='_blank'
                                          href={'https://link.tracker.delivery/track?client_id=4f2ontf17tg6vtq5edbti8h2en&carrier_id=kr.cjlogistics&tracking_number=' + data.INVOICE}>
                                          <Button
                                            containerStyle={{
                                              marginLeft: 10,
                                              fontWeight: 200,
                                            }}
                                            Label={"배송확인"}
                                            bgcolor={"white"}
                                            item={data}
                                            size={12}
                                            borderRadius={5}
                                            color={"#000"}
                                            bordercolor={"#999"}
                                            height={30}
                                            width={"80px"}
                                            callback={() => { }}
                                          />
                                        </a>

                                      </td>
                                      <td style={{ width: 95 }}>
                                        <Button
                                          containerStyle={{
                                            marginLeft: 10,
                                            fontWeight: 200,
                                          }}
                                          Label={"주문상세보기"}
                                          bgcolor={"#fff"}
                                          item={data}
                                          size={12}
                                          borderRadius={5}
                                          color={"#000"}
                                          bordercolor={"#999"}
                                          height={30}
                                          width={"80px"}
                                          callback={_handleOrderView}
                                        />
                                      </td>
                                    </tr>
                                    {data.expand == true && (
                                      <tr>
                                        <td colSpan={12}>
                                          <div style={{ padding: 20 }}>
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                                marginLeft: "50px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <Label
                                                name="상태변경 : "
                                                size={12}
                                                color={"#5d5d5d"}
                                              ></Label>

                                              <SelectItem
                                                containerStyle={{
                                                  marginLeft: 5,
                                                }}
                                                OPTIONTYPE={
                                                  data.ORDERSTATUS
                                                }
                                                ORDER_ID={data.ORDER_ID}
                                                ITEMS={data}
                                                callback={
                                                  orderstatusinnercallback
                                                }
                                              />                                            </div>
                                            <table
                                              class="whitetop"
                                              style={{
                                                overflow: "auto",
                                                padding: 10,
                                                width: "100%",
                                              }}
                                            >
                                              <tr>
                                                <th>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      onClick={() => {
                                                        _handleAllCheck(data);
                                                      }}
                                                      checked={data.check}
                                                      style={{ width: "25px" }}
                                                    />
                                                  </div>
                                                </th>
                                                <th>No</th>
                                                <th style={{ width: 100 }}>
                                                  상태
                                                </th>
                                                <th>주문상품</th>
                                                <th>상품이미지</th>
                                                <th>모델명</th>
                                                <th>옵션</th>
                                                <th>수량</th>
                                                <th>공급가</th>
                                                <th>배송비</th>
                                              </tr>

                                              {data.ORDERLIST.map(
                                                (subdata, index) => (
                                                  <tr>
                                                    <td style={{ width: 30 }}>
                                                      <input
                                                        type="checkbox"
                                                        style={{ width: 30 }}
                                                        onClick={() => {
                                                          _handleSubCheck(
                                                            subdata
                                                          );
                                                        }}
                                                        checked={
                                                          subdata.productcheck
                                                        }
                                                      />
                                                    </td>
                                                    <td style={{ width: 30 }}>
                                                      {index +
                                                        (page - 1) * 20 +
                                                        1}
                                                    </td>
                                                    <td style={{ width: 100 }}>
                                                      {data.ORDERSTATUS}
                                                    </td>
                                                    <td style={{ width: 400 }}>
                                                      {subdata.productname}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: 120,
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <img
                                                        src={
                                                          subdata.productimage
                                                        }
                                                        style={{ height: 30 }}
                                                      />
                                                    </td>
                                                    <td>
                                                      {subdata.productmodelname}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: 120,
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {
                                                        subdata.productoptionname
                                                      }
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: 120,
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {subdata.productcount}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: 120,
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {CommaFormattedEx(
                                                        subdata.productprice
                                                      )}
                                                    </td>
                                                    <td>
                                                      {CommaFormattedEx(3000)}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                ))}
                              </table>
                            </TableLayer>


                            <div style={{ marginTop: 10, marginBottom: 15 }}>
                              <PageEx
                                count={ordercount}
                                position={position}
                                callback={_handlepage}
                              />
                            </div>


                            {/* <div style={{ marginTop: 10, marginBottom: 15 }}>
                              <PageEx
                                count={ordercount}
                                position={position}
                                callback={_handlepage}
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>

                      {topButton == true && (
                        <TopShowButton onClick={TopMove}>TOP</TopShowButton>
                      )}
                    </div>

                    {orderlist.length == 0 && (
                      <NoProduct style={{ marginTop: 50 }}>
                        {type} 항목이 존재 하지 않습니다
                      </NoProduct>
                    )}
                  </div>
                ) : (
                  <div className="MyOrdercontainer">
                    {/* <img
                      src={imageDB.mainlogo}
                      style={{
                        paddingTop: 100,
                        height: 100,
                        width: 300,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                    <NoProduct>주문 항목이 존재 하지 않습니다</NoProduct> */}
                  </div>
                )}
              </>
            </div>
          </MaxPC>

  


        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Myordercontainer;
