import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import Label from "./Label";
import "./OrderViewModule.css";
import { CommaFormatted, getDateFullTime } from "../../utility/common";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "680px",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  padding: "10px",
};
const IconCloseView = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Row = styled.div`
  display: flex;
  padding-left: 10px;
  height: 45px;
  align-items: center;
`;

export default function OrderViewModule({
  callback,
  ordernum,
  orderuser,
  ordertel,
  storename,
  brandname,
  orderreceiveuser,
  receivetel,
  receiveaddress1,
  receiveaddress2,
  receivedeliverymsg,
  receivezonecode,
  bankuser,
  bankname,
  banknum,
  carduser,
  cardnum,
  money,
  registdate,
  reqtitle,
  reqcontent
}) {
  // console.log("orderuser", orderuser);
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    callback();
  };

  const [refresh, setRefresh] = React.useState(1);

  React.useEffect(() => {}, []);

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconCloseView onClick={handleClose}>
              <AiFillCloseCircle size={30} />
            </IconCloseView>
            <Row>
              <Label name={"주문 정보 : " + ordernum} weight={600} size={20} />
            </Row>
            <div style={{height: "600px",overflowY: "scroll"}}>
              <table style={{ marginTop: 5, fontSize: 14 }}>
                <thead>
                  <tr>
                    <th scope="col" style={{ width: 150 }}>
                      구분
                    </th>
                    <th scope="col" style={{ width: 300 }}>
                      내용
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">주문담당자명/직급</th>
                    <td>{orderuser}</td>
                  </tr>
                  <tr>
                    <th scope="row">주문자연락처</th>
                    <td>{ordertel}</td>
                  </tr>
                  <tr>
                    <th scope="row">주문업체명</th>
                    <td>{storename}</td>
                  </tr>
                  <tr>
                    <th scope="row">발송시 표기 브랜드</th>
                    <td>{brandname}</td>
                  </tr>
                  <tr style={{ backgroundColor: "#fef672" }}>
                    <th scope="row">수령자명</th>
                    <td>{orderreceiveuser}</td>
                  </tr>
                  <tr style={{ backgroundColor: "#fef672" }}>
                    <th scope="row">수령자연락처</th>
                    <td>{receivetel}</td>
                  </tr>
                  <tr style={{ backgroundColor: "#fef672" }}>
                    <th scope="row">수령자주소</th>
                    <td>{receiveaddress1}</td>
                  </tr>
                  <tr>
                    <th scope="row">수령자상세주소</th>
                    <td>{receiveaddress2}</td>
                  </tr>
                  <tr>
                    <th scope="row">수령자우편번호</th>
                    <td>{receivezonecode}</td>
                  </tr>
                  <tr>
                    <th scope="row">배송메시지</th>
                    <td>{receivedeliverymsg}</td>
                  </tr>
                  <tr>
                    <th scope="row">반품/교환신청제목</th>
                    <td>{reqtitle}</td>
                  </tr>
                  <tr>
                    <th scope="row">반품/교환신청내용</th>
                    <td>{reqcontent}</td>
                  </tr>
                  <tr>
                    <th scope="row">결제타입</th>
                    <td>
                      {bankname != "" && <div>실시간 계좌이체 </div>}
                      {bankname == "" && <div>카드결제 </div>}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">결제정보</th>
                    <td>
                      {bankname != "" && (
                        <>
                          <div>은행:{bankname}</div>
                          <div>계좌주:{bankuser}</div>
                          <div>계좌번호:{banknum}</div>
                        </>
                      )}
                      {bankname == "" && (
                        <div>
                          카드번호: {cardnum} 카드사용자:{carduser}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">결제금액</th>
                    <td>{CommaFormatted(money)}원</td>
                  </tr>

                  <tr>
                    <th scope="row">결제일시</th>
                    <td>{getDateFullTime(registdate)}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
