import React, { Fragment, useContext, useEffect, useState } from "react";
import { Mobile, PC } from "../../Responsive";
import { imageDB } from "../../imageDB";
import { COLORS } from "../../Theme";
import styled from "styled-components";
import Chat from "../../../container/component/Chat";
import Button from "../Button";
import Guide from "../../../container/component/Guide";
import ControlView from "../../../container/component/ControlView";
import { getproductbyid } from "../../../utility/productfirebase";
import { CommaFormatted } from "../../../utility/common";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/Users";
import { registBasket } from "../../../utility/basketfirebase";
import { registHeart } from "../../../utility/heartfirebase";
import { ref } from "firebase/storage";
import { ProductNameTag } from "../../Define";
import Loading from "../../../container/component/Loading";

const PCSide = styled.div`
  height: 300px;
  background-color: white;
  align-items: right;
  display: flex;
  position: absolute;
  right: 0;
  width: 50px;
  border-radius: 10px;
  z-index: 2;
`;
const PCSideL = styled.div`
  height: 300px;
  background-color: white;
  align-items: right;
  display: flex;
  position: fixed;
  right: 15%;
  width: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 2;
`;
const PCSideExpand = styled.div`
  background-color: white;
  align-items: right;
  display: flex;
  flex-direction: column;

  width: 26%;
  border-bottom-left-radius: 15px;
  align-items: center;
  border: 1px solid #dedede;
  z-index: 2;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
`;
const MainText = styled.span`
  font-weight: 700;
  color: black;
`;

const CheckBtn = styled.div`
  background-color: #323232;
  width: 30px;
  height: 30px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Btn = styled.div`
  width: 30px;
  height: 30px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
  background-color: #e7e7e7;
  height: 1px;
  width: 30px;
`;
const BankAccount = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 40px;
`;
const Nego = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 20px;
`;
const Customer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 20px;
`;
const Price = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100px;
`;

const ProductRightside = ({ product_id }) => {
  const PRODUCT_ID = product_id;
  const navigation = useNavigate();
  const [expand, setExpand] = useState(false);
  const [product, setProduct] = useState({});
  const [refresh, setRefresh] = useState(1);
  const [basketlist, setBasketlist] = useState([]);
  const [optionbasketlist, setOptionbasketlist] = useState([]);
  const [count, setCount] = useState(0);
  const [price, setPrice] = useState(0);
  const { dispatch, user } = useContext(UserContext);

  const [load, setLoad] = useState(true);

  const [productoptionservice2, setProductoptionservice2] = useState("");
  const [productoptionservice1, setProductoptionservice1] = useState("");
  const [optionservices, setOptionServices] = useState([]);
  const onproductoptionservice1Change = (e) => {
    setProductoptionservice1(e.target.value);
  };
  const onproductoptionservice2Change = (e) => {
    setProductoptionservice2(e.target.value);
  };

  const _handlebasket = async () => {
    if (user.USERID == "") {
      alert("로그인이 필요한 메뉴입니다");
      navigation("/memberlogin");
      return;
    }

    const USER_ID = user.USER_ID;
    const PRODUCT_ID = product.PRODUCT_ID;

    let OPTIONBASKETLIST = [];
    optionbasketlist.map((data, index) => {
      if (data.productcount > 0) {
        OPTIONBASKETLIST.push(data);
      }
    });

    if (OPTIONBASKETLIST.length == 0) {
      alert("장바구니에 담을 재고수량이 없습니다");
      return;
    }

    const basket = await registBasket({
      USER_ID,
      PRODUCT_ID,
      OPTIONBASKETLIST,
    });
    alert("장바구니에 저장 되었습니다");
    navigation("/basket");
  };

  const _handleorder = async () => {
    if (user.USERID == "") {
      alert("로그인이 필요한 메뉴입니다");
      navigation("/memberlogin");
      return;
    }

    const USER_ID = user.USER_ID;
    const PRODUCT_ID = product.PRODUCT_ID;
    let OPTIONBASKETLIST = [];
    optionbasketlist.map((data, index) => {
      if (data.productcount > 0) {
        OPTIONBASKETLIST.push(data);
      }
    });

    if (OPTIONBASKETLIST.length == 0) {
      alert("주문할 상품의 재고수량이 없습니다");
      return;
    }

    alert("주문 페이지로 이동합니다");

    navigation("/order", {
      state: { OPTIONBASKETLIST: OPTIONBASKETLIST },
      BASKET: false,
    });
  };

  const _handleheart = async () => {
    if (user.USERID == "") {
      alert("로그인이 필요한 메뉴입니다");
      navigation("/memberlogin");
      return;
    }

    const USER_ID = user.USER_ID;
    const PRODUCT_ID = product.PRODUCT_ID;

    const heart = await registHeart({ USER_ID, PRODUCT_ID });

    alert("찜처리가 완료 되었습니다");
  };
  const _handleMinus = () => {
    if (optionbasketlist[0].productcount == 0) {
      return;
    }
    optionbasketlist[0].productprice -= parseInt(
      product.PRODUCTSUPPLYPRICE.replaceAll(",", "")
    );
    optionbasketlist[0].productcount = optionbasketlist[0].productcount - 1;
    setRefresh((refresh) => refresh + 1);
  };
  const _handlePlus = () => {
    if (product.PRODUCT_INVENTORY == optionbasketlist[0].productcount) {
      return;
    }
    optionbasketlist[0].productprice += parseInt(
      product.PRODUCTSUPPLYPRICE.replaceAll(",", "")
    );
    optionbasketlist[0].productcount = optionbasketlist[0].productcount + 1;
    setRefresh((refresh) => refresh + 1);
  };

  const _handleOptionMinus = (index, optionname, optionprice, qty) => {
    // console.log("handleoptionminus", optionname, optionprice, qty);
    if (optionbasketlist[index].productcount == 0) {
      return;
    }
    optionbasketlist[index].productprice -= parseInt(
      product.PRODUCTSUPPLYPRICE.replaceAll(",", "")
    );
    if (optionprice != 0 || optionprice != "") {
      optionbasketlist[index].productprice -= parseInt(
        optionprice.replaceAll(",", "")
      );
    }
    optionbasketlist[index].productcount =
      optionbasketlist[index].productcount - 1;

    setRefresh((refresh) => refresh + 1);
  };
  const _handleOptionPlus = (index, optionname, optionprice, qty) => {
    if (qty == optionbasketlist[index].productcount) {
      return;
    }
    optionbasketlist[index].productoptionname = optionname;
    optionbasketlist[index].productoptionprice = optionprice;

    optionbasketlist[index].productprice += parseInt(
      product.PRODUCTSUPPLYPRICE.replaceAll(",", "")
    );

    if (optionprice != 0) {
      optionbasketlist[index].productprice += parseInt(
        optionprice.replaceAll(",", "")
      );
    }

    optionbasketlist[index].productcount =
      optionbasketlist[index].productcount + 1;
    setRefresh((refresh) => refresh + 1);
  };

  const optionData = (price) => {
    return price == "" ? "0" : price;
  };

  useEffect(() => {
    setLoad(true);
    async function fetchData() {
      const product = await getproductbyid({ PRODUCT_ID });
      // console.log("product info", product);

      if (product.PRODUCT_OPTIONCHECK == true) {
        product.PRODUCT_OPTIONLIST.map((data, index) => {
          let basketitem = {
            productname: product.PRODUCTNAME,
            productmodelname: product.PRODUCTMODELNAME,
            productimage: product.PRODUCTIMAGEARY[0],
            productcost: product.PRODUCTCOST,
            productprice: 0,
            productoriginprice: parseInt(
              product.PRODUCTSUPPLYPRICE.replaceAll(",", "")
            ),
            productid: product.PRODUCT_ID,
            productoptionname: "",
            productoptionprice: 0,
            productcount: 0,
            productcheck: false,
            productoptionid: Date.now() + index,
          };
          optionbasketlist.push(basketitem);
        });
      } else {
        let basketitem = {
          productname: product.PRODUCTNAME,
          productmodelname: product.PRODUCTMODELNAME,
          productimage: product.PRODUCTIMAGEARY[0],
          productcost: product.PRODUCTCOST,
          productprice: 0,
          productoriginprice: parseInt(
            product.PRODUCTSUPPLYPRICE.replaceAll(",", "")
          ),
          productid: product.PRODUCT_ID,
          productoptionname: "",
          productoptionprice: 0,
          productcount: 0,
          productcheck: false,
          productoptionid: Date.now(),
        };
        optionbasketlist.push(basketitem);
      }

      setProduct(product);
      setOptionbasketlist(optionbasketlist);
      // console.log("optionbasketlist", optionbasketlist);
      setLoad(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setOptionbasketlist(optionbasketlist);
  }, [refresh]);

  return (
    <PC>
      {load == false ? (
        <Fragment>
          <PCSideExpand>
            <div style={{ display: "flex", width: "90%", marginTop: 20 }}>
              <div style={{ fontWeight: 700 }}>주문서</div>
            </div>
            <div
              style={{
                height: 2,
                width: "90%",
                backgroundColor: "#323232",
                marginTop: 10,
              }}
            ></div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                textAlign: "left",
                margin: 10,
              }}
            >
              {ProductNameTag + product.PRODUCTNAME}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
                marginTop: 10,
                fontSize: 13,
                marginBottom: 10,
              }}
            >
              {product.PRODUCT_OPTIONCHECK == true ? (
                <>
                  <di style={{ justifyContent: "flex-start", display: "flex" }}>
                    옵션상품
                  </di>
                  {product.PRODUCT_OPTIONLIST.map((data, index) => (
                    <>
                      <div
                        style={{ height: 30, padding: "5px 0px" }}
                        key={index}
                      >
                        <div
                          style={{ display: "flex" }}
                          value={data.optioncategory}
                        >
                          {data.optionname}
                        </div>
                        <div style={{ textAlign: "left" }}>
                          {data.optionprice != 0 && (
                            <span>
                              가격 :{" "}
                              {CommaFormatted(optionData(data.optionprice))}원 /
                            </span>
                          )}
                          재고 : {data.optionqty}개보유
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            height: 30,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="button"
                            onClick={() => {
                              _handleOptionMinus(
                                index,
                                data.optionname,
                                data.optionprice,
                                data.optionqty
                              );
                            }}
                            style={{
                              border: "1px solid #ededed",
                              width: 50,
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            -
                          </div>
                          <div
                            style={{
                              border: "1px solid",
                              width: 60,
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {optionbasketlist[index].productcount}
                          </div>
                          <div
                            className="button"
                            onClick={() => {
                              _handleOptionPlus(
                                index,
                                data.optionname,
                                data.optionprice,
                                data.optionqty
                              );
                            }}
                            style={{
                              border: "1px solid #ededed",
                              width: 50,
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            +
                          </div>
                        </div>
                        <Price>
                          {CommaFormatted(optionbasketlist[index].productprice)}
                          원
                        </Price>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div
                    style={{ justifyContent: "flex-start", display: "flex" }}
                  >
                    단일상품
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      fontSize: "13px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      padding: "10px 5px",
                      border: "1px solid #DADADA",
                      width: "100%",
                      margin: "10px 0px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      재고수량 : {optionData(product.PRODUCT_INVENTORY)}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: 30,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="button"
                        onClick={_handleMinus}
                        style={{
                          border: "1px solid #ededed",
                          width: 50,
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        -
                      </div>
                      <div
                        style={{
                          border: "1px solid",
                          width: 60,
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {optionbasketlist[0].productcount}
                      </div>
                      <div
                        className="button"
                        onClick={_handlePlus}
                        style={{
                          border: "1px solid #ededed",
                          width: 50,
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        +
                      </div>
                    </div>
                    <Price>
                      {CommaFormatted(optionbasketlist[0].productprice)}원
                    </Price>
                  </div>
                </>
              )}
            </div>

            <div style={{ display: "flex", width: "90%", marginTop: 10 }}>
              <Button
                Label={"장바구니 담기"}
                bgcolor={"#000"}
                borderRadius={10}
                color={"#fff"}
                bordercolor={"#000"}
                height={40}
                callback={_handlebasket}
              />
            </div>

            <div style={{ display: "flex", width: "90%", marginTop: 10 }}>
              <Button
                Label={"주문하기"}
                bgcolor={"#689fff"}
                borderRadius={10}
                color={"#fff"}
                bordercolor={"#689fff"}
                height={40}
                callback={_handleorder}
              />
            </div>

            <div
              style={{
                display: "flex",
                width: "90%",
                marginTop: 10,
                marginBottom: 20,
              }}
            >
              <Button
                Label={"찜하기"}
                bgcolor={"#fff"}
                borderRadius={10}
                color={"#000"}
                bordercolor={"#999595"}
                height={40}
                callback={_handleheart}
              />
            </div>
          </PCSideExpand>
        </Fragment>
      ) : (
        <Loading />
      )}
    </PC>
  );
};

export default ProductRightside;
