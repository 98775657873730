import React, { useEffect, useState } from "react";
import styled from "styled-components";

const PageModel = styled.div`
  display: flex;
  width: 30px;
  background-color: ${({ setClick }) => {
    if (setClick == true) {
      return "#676464";
    } else {
      return "#fff";
    }
  }};
  color: ${({ setClick }) => {
    if (setClick == true) {
      return "#fff";
    } else {
      return "#676464";
    }
  }};

  height: 30px;
  justify-content: center;
  align-items: center;
`;
const Defaultpage = styled.div`
  display: flex;
  width: 30px;
  border-radius: 50px;
  height: 30px;
  color: #000;
  justify-content: center;
  align-items: center;
`;

const PageEx = ({ count, position, callback }) => {
  const [pagenum1, setPagenum1] = useState(true);
  const [pagenum2, setPagenum2] = useState(false);
  const [pagenum3, setPagenum3] = useState(false);
  const [pagenum4, setPagenum4] = useState(false);
  const [pagenum5, setPagenum5] = useState(false);
  const [pagenum6, setPagenum6] = useState(false);

  const _handlepage = async (page, type) => {
    setPagenum1(false);
    setPagenum2(false);
    setPagenum3(false);
    setPagenum4(false);
    setPagenum5(false);
    setPagenum6(false);

    if (type == 1) {
      setPagenum1(true);
    }
    if (type == 2) {
      setPagenum2(true);
    }
    if (type == 3) {
      setPagenum3(true);
    }
    if (type == 4) {
      setPagenum4(true);
    }
    if (type == 5) {
      setPagenum5(true);
    }
    if (type == 6) {
      setPagenum6(true);
    }

    callback(page);
  };

  useEffect(() => {
    if (position % 10 == 1) {
      setPagenum1(true);
    }
  }, [position]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: 20,
      }}
    >
      {position > 20 && (
        <PageModel
          style={{ marginRight: 10 }}
          onClick={() => {
            _handlepage("prev", 0);
          }}
        >
          이전
        </PageModel>
      )}
      <PageModel
        setClick={pagenum1}
        onClick={() => {
          _handlepage(position + 0, 1);
        }}
      >
        {position + 0}
      </PageModel>
      {count > (position + 0) * 10 && (
        <PageModel
          setClick={pagenum2}
          onClick={() => {
            _handlepage(position + 1, 2);
          }}
        >
          {position + 1}
        </PageModel>
      )}
      {count > (position + 1) * 10 && (
        <PageModel
          setClick={pagenum3}
          onClick={() => {
            _handlepage(position + 2, 3);
          }}
        >
          {position + 2}
        </PageModel>
      )}
      {count > (position + 2) * 10 && (
        <PageModel
          setClick={pagenum4}
          onClick={() => {
            _handlepage(position + 3, 4);
          }}
        >
          {position + 3}
        </PageModel>
      )}
      {count > (position + 3) * 10 && (
        <PageModel
          setClick={pagenum5}
          onClick={() => {
            _handlepage(position + 4, 5);
          }}
        >
          {position + 4}
        </PageModel>
      )}
      {count > (position + 4) * 10 && (
        <PageModel
          setClick={pagenum5}
          onClick={() => {
            _handlepage(position + 5, 6);
          }}
        >
          {position + 5}
        </PageModel>
      )}

      {count > (position + 5) * 10 && (
        <PageModel
          setClick={pagenum5}
          onClick={() => {
            _handlepage(position + 6, 7);
          }}
        >
          {position + 6}
        </PageModel>
      )}
      {count > (position + 6) * 10 && (
        <PageModel
          setClick={pagenum5}
          onClick={() => {
            _handlepage(position + 7, 8);
          }}
        >
          {position + 7}
        </PageModel>
      )}
      {count > (position + 7) * 10 && (
        <PageModel
          setClick={pagenum5}
          onClick={() => {
            _handlepage(position + 8, 9);
          }}
        >
          {position + 8}
        </PageModel>
      )}
      {count > (position + 8) * 10 && (
        <PageModel
          setClick={pagenum5}
          onClick={() => {
            _handlepage(position + 9, 10);
          }}
        >
          {position + 9}
        </PageModel>
      )}

      {count > (position + 9) * 10 && (
        <PageModel
          style={{ marginLeft: 10 }}
          onClick={() => {
            _handlepage("next", 11);
          }}
        >
          다음
        </PageModel>
      )}
    </div>
  );
};

export default PageEx;
