import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import Button from "../../components/Layout/Button";
import { UserContext } from "../../context/Users";
import { getUser } from "../../utility/userfirebase";
import DaumPostcode from 'react-daum-postcode';
import { Modal } from "antd";

const Container = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  align-items: center;
`;

const ItemTitle = styled.div`
  display: flex;
  font-size: 16px;
  width: 10%;
`;
const ItemContent = styled.div`
  display: flex;
  width: 60%;
  margin-left: 20px;
`;

const SwitchInput = styled.input`
  background-color: ${({ disabled }) =>
    disabled == true ? "#ededed" : "#fff"};
  width: 100%;
  padding-left: unset;
  border: 1px solid #c5c5c5;
  border-radius: unset;
  padding: 10px;
`;

const EnableInput = styled.input`
  background-color: unset;
  width: 100%;
  padding-left: unset;
  border: 1px solid #c5c5c5;
  border-radius: unset;
  padding: 10px;
`;

const Address = ({ callback }) => {
  const { user, dispatch } = useContext(UserContext);
  const [refresh, setRefresh] = useState(1);
  const [receiveenable, setReceiveenable] = useState(true);
  const [useritem, setUseritem] = useState({});

  const [orderuser, setOrderuser] = useState("");

  const [receivezonecode, setReceivezonecode] = useState('');

  const onorderuserChange = (e) => {
    setOrderuser(e.target.value);
    callback(
      orderuser,
      receiveuser,
      ordertel,
      receivetel,
      receiveaddress1,
      receiveaddress2,
      receivedeliverymsg,
      receivezonecode,
      storename,
      brandname
    );
  };

  const [receiveuser, setReceiveuser] = useState("");
  const onreceiveuserChange = (e) => {
    setReceiveuser(e.target.value);
    callback(
      orderuser,
      receiveuser,
      ordertel,
      receivetel,
      receiveaddress1,
      receiveaddress2,
      receivedeliverymsg,
      receivezonecode,
      storename,
      brandname
    );
  };
  const [ordertel, setOrdertel] = useState("");
  const onordertelChange = (e) => {
    setOrdertel(e.target.value);
    callback(
      orderuser,
      receiveuser,
      ordertel,
      receivetel,
      receiveaddress1,
      receiveaddress2,
      receivedeliverymsg,
      receivezonecode,
      storename,
      brandname
    );
  };

  const [receivetel, setReceivetel] = useState("");
  const onreceivetelChange = (e) => {
    setReceivetel(e.target.value);
    callback(
      orderuser,
      receiveuser,
      ordertel,
      receivetel,
      receiveaddress1,
      receiveaddress2,
      receivedeliverymsg,
      receivezonecode,
      storename,
      brandname
    );
  };
  const [receiveaddress1, setReceiveaddress1] = useState("");
  const onreceiveaddress1Change = (e) => {
    setReceiveaddress1(e.target.value);
    callback(
      orderuser,
      receiveuser,
      ordertel,
      receivetel,
      receiveaddress1,
      receiveaddress2,
      receivedeliverymsg,
      receivezonecode,
      storename,
      brandname
    );
  };
  const [receiveaddress2, setReceiveaddress2] = useState("");
  const onreceiveaddress2Change = (e) => {
    setReceiveaddress2(e.target.value);
    callback(
      orderuser,
      receiveuser,
      ordertel,
      receivetel,
      receiveaddress1,
      receiveaddress2,
      receivedeliverymsg,
      receivezonecode,
      storename,
      brandname
    );
  };

  const [receivedeliverymsg, setReceivedeliverymsg] = useState("");
  const onreceivedeliverymsg = (e) => {
    setReceivedeliverymsg(e.target.value);
    callback(
      orderuser,
      receiveuser,
      ordertel,
      receivetel,
      receiveaddress1,
      receiveaddress2,
      receivedeliverymsg,
      receivezonecode,
      storename,
      brandname
    );
  };


  const onreceivezonecodeChange = (e) => {
    setReceivezonecode(e.target.value);
    callback(
      orderuser,
      receiveuser,
      ordertel,
      receivetel,
      receiveaddress1,
      receiveaddress2,
      receivedeliverymsg,
      receivezonecode,
      storename,
      brandname
    );
  };

  const [storename, setStorename] = useState("");
  const onstorenameChange = (e) => {
    setStorename(e.target.value);
    callback(
      orderuser,
      receiveuser,
      ordertel,
      receivetel,
      receiveaddress1,
      receiveaddress2,
      receivedeliverymsg,
      receivezonecode,
      storename,
      brandname
    );
  };

  const [brandname, setBrandname] = useState("");
  const onbrandnameChange = (e) => {
    setBrandname(e.target.value);
    callback(
      orderuser,
      receiveuser,
      ordertel,
      receivetel,
      receiveaddress1,
      receiveaddress2,
      receivezonecode,
      receivedeliverymsg,
      receivezonecode,
      storename,
      brandname
    );
  };

  useEffect(() => {
    const USERID = user.USERID;
    async function FetchData() {
      const userdata = await getUser({ USERID });
      setUseritem(userdata);
      setOrderuser(userdata.RANK);
      setOrdertel(userdata.TEL);
      setStorename(userdata.STORENAME);
      setReceiveuser("");
      setReceivetel("");

      // console.log(useritem);
    }

    FetchData();
  }, []);

  const _handlereceiveCheck = () => {
    setReceiveenable(!receiveenable);
    setRefresh((refresh) => refresh + 1);
  }; 

  const [isopen, setIsopen] = useState(false);

  const _handleDaumAddr = () => {

    if (receiveenable) {
      return;
    }
    setIsopen(true);
    

  }

  const handleComplete = (data) => {
    console.log("address", data.address, data.zonecode);

    setReceiveaddress1(data.address);
    setReceivezonecode(data.zonecode);

    onToggleModal();
  };

  const onToggleModal = () => {
    setIsopen((prev) => !prev);
  };

  useEffect(() => {
    setReceiveenable(receiveenable);
  }, [refresh]);

  return (
    <Container>

      {
        isopen == true &&
        <Modal visible={isopen} onOk={onToggleModal} onCancel={onToggleModal}>
          <DaumPostcode onComplete={handleComplete} />
        </Modal>
      }
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          paddingBottom: "10px",
          borderBottom: "2px solid #000",
        }}
      >
        <div>주문자정보</div>
      </div>

      <div style={{ padding: 20, width: "100%" }}>
        <Item>
          <ItemTitle>담당자명 / 직급</ItemTitle>
          <ItemContent>
            <input
              type="text"
              className="InputCommon2"
              value={orderuser}
              onChange={onorderuserChange}
            ></input>
          </ItemContent>
        </Item>

        <Item>
          <ItemTitle>연락처</ItemTitle>
          <ItemContent>
            <input
              type="text"
              className="InputCommon2"
              value={ordertel}
              onChange={onordertelChange}
            ></input>
          </ItemContent>
        </Item>

        <Item>
          <ItemTitle>업체명</ItemTitle>
          <ItemContent>
            <input
              type="text"
              className="InputCommon2"
              value={storename}
              onChange={onstorenameChange}
            ></input>
          </ItemContent>
        </Item>

        <Item>
          <ItemTitle>발송시 표기 브랜드</ItemTitle>
          <ItemContent>
            <input
              type="text"
              className="InputCommon2"
              value={brandname}
              onChange={onbrandnameChange}
            ></input>
          </ItemContent>
        </Item>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          paddingBottom: "10px",
          borderBottom: "2px solid #000",
        }}
      >
        <input
          onClick={_handlereceiveCheck}
          type="checkbox"
          style={{ width: "40px" }}
        ></input>
        <div>수령자정보</div>
    
      </div>

      <div style={{ padding: 20, width: "100%" }}>
        <Item>
          <ItemTitle>수령자명</ItemTitle>
          <ItemContent>
            <SwitchInput
              disabled={receiveenable}
              type="text"
              value={receiveuser}
              onChange={onreceiveuserChange}
            ></SwitchInput>
          </ItemContent>
        </Item>

        <Item>
          <ItemTitle>연락처</ItemTitle>
          <ItemContent>
            <SwitchInput
              disabled={receiveenable}
              type="text"
              value={receivetel}
              onChange={onreceivetelChange}
            ></SwitchInput>
          </ItemContent>
        </Item>

        <Item>
          <ItemTitle>주소</ItemTitle>
          <ItemContent>
            <SwitchInput
              disabled={receiveenable}
              type="text"
              value={receiveaddress1}
              onChange={onreceiveaddress1Change}
            ></SwitchInput>
            <Button
              containerStyle={{
                marginLeft: 10,
                fontWeight: 200,
              }}
              Label={"주소지 찾기"}
              bgcolor={"white"}
              size={12}
              borderRadius={5}
              color={"#000"}
              bordercolor={"#999"}
              height={35}
              width={"80px"}
              callback={_handleDaumAddr}
            />
          </ItemContent>
        </Item>

        <Item>
          <ItemTitle>상세주소</ItemTitle>
          <ItemContent>
            <SwitchInput
              disabled={receiveenable}
              type="text"
              value={receiveaddress2}
              onChange={onreceiveaddress2Change}
            ></SwitchInput>
          </ItemContent>
        </Item>

        <Item>
          <ItemTitle>우편번호</ItemTitle>
          <ItemContent>
            <SwitchInput
              disabled={receiveenable}
              type="text"
              value={receivezonecode}
              onChange={onreceivezonecodeChange}
            ></SwitchInput>
          </ItemContent>
        </Item>


        <Item>
          <ItemTitle>배송메시지</ItemTitle>
          <ItemContent>
            <SwitchInput
              disabled={receiveenable}
              type="text"
              value={receivedeliverymsg}
              onChange={onreceivedeliverymsg }
            ></SwitchInput>
          </ItemContent>
        </Item>

      </div>
    </Container>
  );
};

Address.defaultProps = {
  color: "#000",
  weight: 600,
};

export default Address;
