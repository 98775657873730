

import React, { useEffect, useState} from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Rightside from "./Rightside/Rightside";
import Leftside from "./Leftside/Leftside";
import { Define } from "../Define";




const MemberLayout = (props) => {

  // console.log("Layout props", props);

  const [menu, setMenu] = useState("");
  const [reload, setReload] = useState(false);

  const _callback = (type) =>{
    setMenu(type);
    setReload(true);
  }

  useEffect(()=>{
    if(reload){
      setMenu(menu);
      setReload(false);
      // console.log("menu", menu);
    }

  }, [reload])


  return (
    <div>
      <Header callback={_callback} type={props.type} />
  
        <main>
          {props.children}
        </main>
      <Footer />
    </div>
  );
};

export default MemberLayout;
