

import React, { Fragment, useContext, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import './BestItem.css'
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted, ratevalue } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import { MaxPC, SmallPC } from "../../components/Responsive";
import { UserContext } from "../../context/Users";

const Container = styled.div`
  border-radius: 5px;
  flex-direction:column;
  margin-right:20px;
  display: flex;
  border-radius:10px;
  margin-bottom:20px;
  margin-top:30px;
`

const CheckItem = styled.div`
  background-color: #b5b5b7c9;
  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`

const UnCheckItem = styled.div`

  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`
const Sticker1 = styled.div`
  background-color: #ff00007d;
  color: #fff;
  font-size: 12px;
  top: -8px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 30px;
`
const ProductInfo = styled.div`
  padding: 15px 0px;
  border-top: none;
`
const ProductName = styled.div`
  font-size: 14px;
  text-align: left;
  padding-left: 10px;
  color: #000;
  font-weight:600;
  font-family: Pretendard-Regular;
`

const ProductPrice = styled.span`
  font-size: 15px;
  text-align: left;
  color: #000;
  font-weight:900;
  font-family: Pretendard-Bold;
`

const ProductRate = styled.span`
  font-size: 14px;
  text-align: left;
  color: #fff;
  font-family: Pretendard-Regular;
  padding-left:5px;
  position : absolute;
  top:290px;
  right:50px;
  background-color :#ff0000;
  padding : 5px 10px;
  border-radius :5px;

`

const ProductSmallRate = styled.span`
  font-size: 14px;
  text-align: left;
  color: #fff;
  font-family: Pretendard-Regular;
  padding-left:5px;
  position : absolute;
  top:220px;
  right:10px;
  background-color :#ff0000;
  padding : 5px 10px;
  border-radius :5px;

`
const ProductSubPrice = styled.div`
  font-size: 13px;
  text-align: left;

  color: #929292;
  text-decoration:line-through;
`

const CategoryItems = ({product_id, img, name, price,lowprice, component,add ,select, time, index, supply, soldout}) => {

  const navigate = useNavigate();
  const { user, dispatch2 } = useContext(UserContext);

  const [isHovering, setIsHovering] = useState(false);
  const _handleMouseOver = () =>{
    if(isHovering){
      return;
    }
    setIsHovering(true); 

  }
  const _handleMouseOut= () =>{

    if(!isHovering){
      return;
    }
    setIsHovering(false);

  
  }


  const _handleProduct = () => {
    
    if (user.USERID == "") {
      alert("로그인을 먼저 진행해주세요");
      return;
    }
    
    navigate("/product", {state : {PRODUCT_ID: product_id}});
  }

  const existselect = (selectname)=>{
    let selectlist = select;

    const FindIndex = selectlist.findIndex(x=>x == selectname);
  
    if(FindIndex == -1){
      return false;
    }else{
      return true;
    }

  }

  return (
    <Fragment>
      <Container
        onClick={_handleProduct}
        onMouseOver={_handleMouseOver}
        onMouseOut={_handleMouseOut}
      >
        <div style={{ display: "flex" }}>
          <div className="itemcontainer">
            <SmallPC>
              <img
                src={img}
                class="image"
                style={{ height: "260px", width: "260px" }}
              />
              <div className="smallmiddle">
                <div className="text">온라인 최저가 | {lowprice}원</div>
                <div className="text">제품 구성품 | {component}</div>
                <div className="text">누적주문건수 | 1,230건</div>
                <div className="text">추가항목 | {add}</div>
              </div>

              {soldout == true && (
                <div className="soldout">
                  <div className="soldouttext">임시품절</div>
                </div>
              )}
            </SmallPC>
            <MaxPC>
              <img
                src={img}
                class="image"
                style={{ height: "270px", width: "270px" }}
              />
              <div className="middle">
                <div className="text">온라인 최저가 | {lowprice}원</div>
                <div className="text">제품 구성품 | {component}</div>
                <div className="text">누적주문건수 | 1,230건</div>
                <div className="text">추가항목 | {add}</div>
              </div>
              {soldout == true && (
                <div className="soldout">
                  <div className="soldouttext">임시품절</div>
                </div>
              )}
            </MaxPC>

            <div class="wrapper">
              {existselect("newselect") == true && (
                <div class="NewButton">
                  <div className="NewButtonText">NEW</div>
                </div>
              )}

              {existselect("mdselect") == true && (
                <div class="MdButton">
                  <div className="MdButtonText">MD픽</div>
                </div>
              )}
              {existselect("bestselect") == true && (
                <div class="BestButton">
                  <div className="BestButtonText">BEST</div>
                </div>
              )}

              {existselect("saleselect") == true && (
                <div class="SaleButton">
                  <div className="SaleButtonText">판매급상승</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <ProductInfo>
          <MaxPC>
            <div
              style={{
                display: "flex",
                marginTop: 10,
                flexGrow: 1,
                width: 270,
                height: 30,
              }}
            >
              {" "}
              <ProductName>
                {name.slice(0, 45)}
                {name.length > 45 ? "..." : null}
              </ProductName>
            </div>
          </MaxPC>

          <SmallPC>
            <div
              style={{
                display: "flex",
                marginTop: 10,
                flexGrow: 1,
                width: 260,
                height: 30,
              }}
            >
              {" "}
              <ProductName>
                {name.slice(0, 45)}
                {name.length > 45 ? "..." : null}
              </ProductName>
            </div>
          </SmallPC>

          <div style={{ display: "flex", flexGrow: 1, marginTop: 10 }}>
            <div style={{ fontSize: 13, textAlign: "left", paddingLeft: 10 }}>
              <span style={{ color: "#929292", fontWeight: 700 }}>
                {"파트너 공급가   |   "}
              </span>
              {user.USERID != "" ? (
                <ProductPrice>{CommaFormatted(supply)}원</ProductPrice>
              ) : (
                <ProductPrice>로그인후확인</ProductPrice>
              )}

              {/* <ProductSubPrice>
                {user.USERID != "" ? (
                  <ProductPrice>{CommaFormatted(supply)}원</ProductPrice>
                ) : null}
              </ProductSubPrice> */}

              {/* <SmallPC>
                {ratevalue(lowprice, price) != "" && (
                  <ProductSmallRate>
                    {ratevalue(lowprice, price)}
                  </ProductSmallRate>
                )}
              </SmallPC>
              <MaxPC>
                {ratevalue(lowprice, price) != "" && (
                  <ProductRate>{ratevalue(lowprice, price)}</ProductRate>
                )}
              </MaxPC> */}
            </div>
          </div>
        </ProductInfo>
      </Container>
    </Fragment>
  );
};

CategoryItems.defaultProps={
  price : 0,
  time :0,
}
export default CategoryItems;
