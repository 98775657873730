import React, { useState, useEffect, useContext, Fragment } from "react";
import { imageDB } from "../components/imageDB";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

import "../components/css/common.css";

import useDimensions from "react-use-dimensions";
import Button from "../components/Layout/Button";
import { COLORS } from "../components/Theme";
import Advertise from "./component/Advertise";
import Label from "./component/Label";
import Preview from "./component/Categoryview";
import Noticeview from "./component/Noticeview";
import Center from "./component/Center";
import Best from "./component/Best";
import Pick from "./component/Pick";
import News from "./component/News";
import Search from "../components/Layout/SearchBasket";
import { getBanner, getSideBanner } from "../utility/bannerfirebase";
import { CategoryContext } from "../context/Category";
import { getcategory1, getcategory2 } from "../utility/categoryfirebase";
import { BannerContext } from "../context/Banner";
import Advertise2 from "./component/Advertise2";
import Fade from "react-reveal/Fade";
import New from "./component/NewProduct";
import New2 from "./component/AllProduct";
import MainBanner from "./component/MainBanner";
import NewProduct from "./component/NewProduct";
import { MaxPC, SmallPC } from "../components/Responsive";
import MainMaxBanner from "./component/MainMaxBanner";
import AllProduct from "./component/AllProduct";
import Searchproduct from "./component/SearchProduct";
import { getproduct } from "../utility/productfirebase";

const Padding = styled.div``;
const TopShowButton = styled.div`
  height: 70px;
  width: 70px;
  background-color: #222;
  position: fixed;
  bottom: 30px;
  right: 70px;
  border-radius: 100px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const DataView = styled.div`
  border: 1px solid #a0a0a0;
  height: 80%;
  margin: 5px 5px 20px;
`;

const Searchcontainer = ({ search }) => {
  const navigate = useNavigate();

  // console.log("Searchcontainer", search);

  const [topbutton, setTopButton] = useState(false);

  const { category, dispatch } = useContext(CategoryContext);
  const { banner, dispatch2 } = useContext(BannerContext);

  const [width, setWidth] = useState(100);
  const [refresh, setRefresh] = useState(1);
  const [banneritems, setBanneritems] = useState([]);

  const [searchitems, setSearchitems] = useState([]);

  const _handleRegist = () => {
    navigate("/register");
  };

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 1000) {
        setTopButton(true);
      } else {
        setTopButton(false);
      }
    };

    window.addEventListener("scroll", handleShowButton);

    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  });

  const TopMove = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, []);
  return (
    <>
      <SmallPC>
        <div className="Homecontainer">
          <Searchproduct search={search} />
        </div>

        {topbutton == true && (
          <TopShowButton onClick={TopMove}>TOP</TopShowButton>
        )}
      </SmallPC>
      <MaxPC>
        <div className="HomeMaxcontainer">
          <Searchproduct search={search} />
        </div>

        {topbutton == true && (
          <TopShowButton onClick={TopMove}>TOP</TopShowButton>
        )}
      </MaxPC>
    </>
  );
};

export default Searchcontainer;
