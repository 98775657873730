import React, { Fragment, useEffect } from "react";
import "./Footer.css";
import { MaxPC, Mobile, PC, SmallPC } from "../../Responsive";

import { imageDB } from "../../../components/imageDB";
import { COLORS } from "../../Theme";
import styled from "styled-components";

const SitePCFooter = styled.div`
  background: ${COLORS.footerbgcolor};
  height: 350px;

  bottom: 0%;
  color: ${COLORS.footercontentcolor};
`;
const Footer = ({ menu, bottom, top }) => {
  return (
    <Fragment>
      <Mobile></Mobile>

      <SmallPC>
        <SitePCFooter top={top}>
          <img src={imageDB.footer} height={"350px"} width={"100%"} />
        </SitePCFooter>
      </SmallPC>
      <MaxPC>
        <SitePCFooter top={top}>
          <img src={imageDB.footer} height={"350px"} width={"100%"} />
        </SitePCFooter>
      </MaxPC>
    </Fragment>
  );
};

Footer.defaultProps = {
  top: 2750,
};

export default Footer;
