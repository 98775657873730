import React, { Fragment, useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/PageEx";
import { getBest } from "../../utility/bestfirebase";
import SubLabel from "./SubLabel";
import Fade from "react-reveal/Fade";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { getMd, getNew } from "../../utility/mdfirebase";
import Nav from "../Nav";
import { MaxPC, SmallPC } from "../../components/Responsive";
import SmallItem from "./SmallItem";
import { getproductbyid } from "../../utility/productfirebase";
import { ProductNameTag } from "../../components/Define";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]); // *

const swiperStyle = {
  position: "relative",
  width: "100%",
};

const NewProduct = () => {
  const [bestitems, setBestitems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  useEffect(() => {
    async function fetchData() {
      let bestitems = [];
      let bestitemsTmp = [];
      bestitemsTmp = await getNew();

      bestitemsTmp.map(async (data, index) => {
        const PRODUCT_ID = data.PRODUCT_ID;
        const indata = await getproductbyid({ PRODUCT_ID });
        if (indata != null) {
          bestitems.push(indata);
        }

        setBestitems(bestitems);
      });

      // console.log("bestitems", bestitems);
    }

    fetchData();
  }, [refresh]);

  return (
    <>
      {bestitems.length > 0 && (
        <div
          style={{ display: "flex", padding: "40px 0px", flexDirection: "row" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "17%",
              alignItems: "flex-start",
              paddingTop: "70px",
            }}
          >
            <Label name={"신상품"} weight={800} />
            <div style={{ height: 10 }} />
            <SmallPC>
              <div style={{ color: "#999", textAlign: "left" }}>
                신규 출시한 따끈따끈한 <br></br> 상품입니다
              </div>
            </SmallPC>
            <MaxPC>
              <SubLabel
                name={"신규 출시한 따끈따끈한 상품입니다"}
                weight={100}
                color={"#999"}
              />
            </MaxPC>
          </div>

          <div style={{ width: "82%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "no-wrap",
                padding: "40px 20px 0px 20px",
                justifyContent: "space-around",
                backgroundColor: "white",
              }}
            >
              <Swiper
                spaceBetween={10}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                slidesPerView={4}
                loop={true}
                scrollbar={{ draggable: true }}
                style={swiperStyle}
                autoplay={{ delay: 8000, disableOnInteraction: true }}
              >
                {bestitems.map((data, index = 0) => (
                  <SwiperSlide key={index}>
                    <BestItem
                      time={index}
                      product_id={data.PRODUCT_ID}
                      img={data.PRODUCTIMAGEARY[0]}
                      name={ProductNameTag + data.PRODUCTNAME}
                      select={data.PRODUCTSELECT}
                      price={data.PRODUCTREGULARPRICE}
                      lowprice={data.PRODUCTLOWPRICE}
                      component={data.PRODUCTCOMPONENT}
                      add={data.PRODUCT_ADD11_INFO}
                      supply={data.PRODUCTSUPPLYPRICE}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewProduct;
