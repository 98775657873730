import React, { Fragment, useContext, useEffect, useState } from "react";
import { Mobile, PC } from "../../Responsive";
import { imageDB } from "../../imageDB";
import { COLORS } from "../../Theme";
import styled from "styled-components";
import Chat from "../../../container/component/Chat";
import Button from "../Button";
import Guide from "../../../container/component/Guide";
import ControlView from "../../../container/component/ControlView";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/Users";
import { signupUser } from "../../../utility/userfirebase";
import { CgFileDocument } from "react-icons/cg";
import ChatbotModule from "../../../container/component/ChatbotModule";
import { commaescapevalue, useSleep } from "../../../utility/common";
import * as XLSX from "xlsx";
import Loading from "../../../container/component/Loading";
import MassOrderModule from "../../../container/component/MassOrderModule";
import { getproductbyid, getproductbynum } from "../../../utility/productfirebase";
import { getOrder, registMassOrder, registOrder, registOrderTmp } from "../../../utility/orderfirebase";
import { Box } from "@mui/material";
import { OrderType } from "../../Define";
import Badge from "../../Badge";
import { getnotice } from "../../../utility/askfirebase";
import { loadTossPayments } from '@tosspayments/payment-sdk';


const PCSide = styled.div`
  height: 300px;
  background-color: white;
  align-items: right;
  display: flex;
  position: fixed;
  right: 0;
  width: 50px;
  border-radius: 10px;
  z-index: 2;
`;
const PCSideL = styled.div`
  height: 300px;
  background-color: white;
  align-items: right;
  display: flex;
  position: fixed;
  right: 22%;
  width: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 3;
`;
const PCSideExpand = styled.div`
  height: 900px;
  background-color: white;
  align-items: right;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  width: 22%;
  border-bottom-left-radius: 15px;
  align-items: center;
  z-index: 5;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
`;
const MainText = styled.span`
  font-weight: 700;
  color: black;
`;

const CheckBtn = styled.div`
  background-color: #fff;
  width: 30px;
  height: 30px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Btn = styled.div`
  width: 30px;
  height: 30px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BtnEx = styled.div`
  width: 50px;
  height: 30px;
  margin: 10px 0px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
  background-color: #e7e7e7;
  height: 1px;
  width: 30px;
`;
const BankAccount = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 15px;
`;
const Nego = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 15px;
`;
const Customer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 15px;
`;

const BoxLayer = styled.div`
  width: 28%;
  background-color: #e3e3e3;
  height: 60px;
  border-radius: 5px;
  color: #000;
  font-size: 13px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Rightside = () => {
  const navigate = useNavigate();
  const [expand, setExpand] = useState(false);
  const { dispatch, user } = useContext(UserContext);
  const [items, setItems] = useState([]);
  const [totalprice, setTotalprice] = React.useState(0);
  const [refresh, setRefresh] = useState(1);

  const [noticecount, setNoticecount] = useState(0);

  const [loading, setLoading] = useState(false);

  const _handleExpand = () => {
    setExpand(!expand);
  };
  const _handleRegister = () => {
    navigate("/Memberregister", { state: { TYPE: "NEW" } });
  };
  const _handleLogin = () => {
    navigate("/Memberlogin");
  };
  const _handleBasket = () => {
    if (user.USERID == "") {
      alert("로그인이 필요한 메뉴입니다");
      navigate("/memberlogin");
      return;
    }
    navigate("/basket");
  };
  const _handleMyorder = () => {
    if (user.USERID == "") {
      alert("로그인이 필요한 메뉴입니다");
      navigate("/memberlogin");
      return;
    }
    navigate("/myorder");
  };
  const _handleLogout = async () => {
    const logoutuser = await signupUser();

    // console.log("_handleLogout", logoutuser);

    if (logoutuser != -1) {
      user.USERID = "";
      user.USERPW = "";
      user.USERNAME = "";
      user.USER_ID = "";
      dispatch(user);
      const userObjString = JSON.stringify(user);

      // console.log("userObjString", userObjString);

      window.localStorage.setItem("user", userObjString);

      navigate("/home");
    }
  };

  const chatbotcallback = () => {
    setChatbotstatus(!chatbotstatus);
  };

  const Massordercashcallback = async(bankuser, bankname, banknum) => {
    if (bankuser == "") {
      setMassorderstatus(!massorderstatus);
    } else {  
      
      setLoading(true);
      items.map((data, index) => {

        const orderlist = data.orderlist;
        const orderuser = data.receiveordername;
        const ordertel = data.receiveordertel;
        const receivetel = data.receiveordertel;
        const receiveaddress1 = data.receiveorderaddr;
        const receiveaddress2 = "";
        const deliverymessage = data.deliverymessage;
        const infomessage = data.infomessage;
        const producttotalprice = data.orderlist[0].productprice * data.orderlist[0].productcount;
        const ordernum = Date.now() + index - 1711654178996;

        // console.log("prodcutprice", productprice);

        CashPurchase({
          bankuser,
          bankname,
          banknum,
          orderlist,
          orderuser,
          ordertel,
          receivetel,
          receiveaddress1,
          receiveaddress2,
          deliverymessage,
          infomessage,
          producttotalprice,
          ordernum,
        });
        
      })
      
    }
    await useSleep(10000);
    setLoading(false);
    alert("대량주문이 완료 되었습니다");
    // navigate("/myorder");
  }
  const CashPurchase = async ({
    bankuser,
    bankname,
    banknum,
    orderlist,
    orderuser,
    ordertel,
    receivetel,
    receiveaddress1,
    receiveaddress2,
    deliverymessage,
    infomessage,
    producttotalprice,
    ordernum,
  }) => {
    const USER_ID = user.USER_ID;

    const BANK_USER = bankuser;
    const BANK_NAME = bankname;
    const BANK_NUM = banknum;
    const CARD_NAME = "";
    const CARD_NUM = "";
    const CARD_TYPE = "";
    const MONEY = producttotalprice;

    const ORDERLIST = orderlist;
    const ORDERUSER = orderuser;
    const ORDERRECEIVEUSER = orderuser;
    const ORDERTEL = ordertel;
    const RECEIVETEL = receivetel;
    const RECEIVEADDRESS1 = receiveaddress1;
    const RECEIVEADDRESS2 = receiveaddress2;
    const RECEIVEDELIVERYMSG = deliverymessage;
    const STORENAME = user.STORENAME;
    const BRANDNAME = "";
    const RECEIVEZONECODE = "";

    const MASS = true;

    const INFOMESSAGE = infomessage;
    const ORDERNUM = ordernum;

    // console.log("orderlist", ORDERLIST);

    const ORDER_TYPE = "주문접수";
    const ORDER_MASSORDER = '엑셀주문';

    const registorder_ = await registOrder({
      USER_ID,
      ORDER_MASSORDER,
      BANK_USER,
      BANK_NAME,
      BANK_NUM,
      CARD_NAME,
      CARD_NUM,
      CARD_TYPE,
      MONEY,
      ORDERLIST,
      ORDERUSER,
      ORDERRECEIVEUSER,
      RECEIVEDELIVERYMSG,
      ORDERTEL,
      RECEIVETEL,
      RECEIVEADDRESS1,
      RECEIVEADDRESS2,
      RECEIVEZONECODE,
      STORENAME,
      BRANDNAME,
      ORDERNUM,
      ORDER_TYPE,
    });

  };

  const Massordercreditcallback = async (bankuser, bankname, banknum) => {
   
    const ORDERNUM = Date.now() - 1711654178996;
    const ORDER_MASSORDER = '엑셀주문';
    let MONEY = 0;

    const STORENAME = user.STORENAME;

    let CARDORDER_PRODUCT = "";
    

    items.map(async (data, index) => {
      
      if (index == 0) {
        CARDORDER_PRODUCT = data.orderlist[0].productname;
      }
      MONEY += data.orderlist[0].productprice * data.orderlist[0].productcount;

      const BANK_USER = "";
      const BANK_NAME = "";
      const BANK_NUM = "";
      const CARD_NAME = "TOS결재";
      const CARD_NUM = "TOS결재";
      const CARD_TYPE = "TOS결재";
      const USER_ID = user.USER_ID;

      const ORDERLIST = data.orderlist;
      const ORDERUSER = data.orderuser;
      const ORDERRECEIVEUSER = data.orderuser;
      const ORDERTEL = data.ordertel;
      const RECEIVETEL = data.receivetel;
      const RECEIVEADDRESS1 = data.receiveaddress1;
      const RECEIVEADDRESS2 = data.receiveaddress2;
      const RECEIVEDELIVERYMSG = data.deliverymessage;

      const BRANDNAME = "";
      const RECEIVEZONECODE = "";

      const ORDER_TYPE = "결제진행중";


      const registorder_ = await registOrderTmp({
        USER_ID,
        ORDER_MASSORDER,
        BANK_USER,
        BANK_NAME,
        BANK_NUM,
        CARD_NAME,
        CARD_NUM,
        CARD_TYPE,
        MONEY,
        ORDERLIST,
        ORDERUSER,
        ORDERRECEIVEUSER,
        ORDERTEL,
        RECEIVETEL,
        RECEIVEADDRESS1,
        RECEIVEADDRESS2,
        RECEIVEDELIVERYMSG,
        RECEIVEZONECODE,
        STORENAME,
        BRANDNAME,
        ORDERNUM,
        ORDER_TYPE,
      });

    });

    const random = new Date().getTime() + Math.random()
    const randomId = btoa(random)

    const clientKey = "live_ck_Gv6LjeKD8aPgaa1O5EPxrwYxAdXy";

    loadTossPayments(clientKey).then(tossPayments => {
      // 카드 결제 메서드 실행
      tossPayments.requestPayment(`간편결제`, {
        amount: MONEY, // 가격
        orderId: ORDERNUM, // 주문 id
        orderName: CARDORDER_PRODUCT + "외" + items.length + "건", // 결제 이름
        customerName: STORENAME, // 판매자, 판매처 이름
        successUrl: 'http://localhost:3000/success', // 성공시 리다이렉트 주소
        failUrl: 'http://localhost:3000/failed', // 실패시 리다이렉트 주소

      })
    })


  }






  const _handlechatbot = () => {
    setChatbotstatus(!chatbotstatus);
  };
  const _handleHeart = () => {
    if (user.USERID == "") {
      alert("로그인이 필요한 메뉴입니다");
      navigate("/memberlogin");
      return;
    }
    navigate("/heartproduct");
  };

  const _handleNotice = () => {
    navigate("/notice");
  };


  const readExcel = (file) => {
    setMassorderstatus(true);
    if (user.USERID == "") {
      alert("로그인이 필요한 메뉴입니다");
      navigate("/memberlogin");
      return;
    }
    
    const promise = new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      const bufferArray = e.target.result;

      const wb = XLSX.read(bufferArray, { type: "buffer" });

      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      resolve(data);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
    });

    const productpromise = (productnum) => new Promise((resolve, reject) => {

      const PRODUCTNUM = String(productnum);
      async function fetchData() {
        const product = await getproductbynum({ PRODUCTNUM });
        resolve(product);
      }
      fetchData();
      
    });
    promise.then((exceldata) => {

      let producttotalpricetmp = 0;
      exceldata.map((data, index) => {

        let massorder = {
          receiveordername: "",
          receiveordertel: "",
          receiveorderaddr: "",
          orderlist: [],
          deliverymessage: "",
          infomessage: "",
        };

        productpromise(Object.values(data)[3]).then((result) => {


          massorder.receiveordername = Object.values(data)[0];
          massorder.receiveordertel = Object.values(data)[1];
          massorder.receiveorderaddr = Object.values(data)[2];

          if (Object.values(data)[6] != undefined) {
            massorder.deliverymessage = Object.values(data)[6];
          } else {
            massorder.deliverymessage = "";
          }

          if (Object.values(data)[7] != undefined) {
            massorder.infomessage = Object.values(data)[7];
          } else {
            massorder.infomessage = "";
          }
          
     
          
          

          let order = {
            productcount: 0,
            productid: "",
            productnum:"",
            productimage: "",
            productname: "",
            productmodelname : "",
            productoptionid: "",
            productoptionprice: "",
            productoptionname: "",
            productoptionprice : 0,
            productprice:"",
          }

          order.productcount = Object.values(data)[5];
          order.productid = result.PRODUCT_ID;
          order.productnum = result.PRODUCTNUM;
          order.productimage = result.PRODUCTIMAGEARY[0];
          order.productname = result.PRODUCTNAME;
          order.productmodelname = result.PRODUCTMODELNAME;

          //옵션 가격 체크
          if (Object.values(data)[4] == 'N') {
            order.productoptionname = "";
            order.productoptionprice = "";
          } else {
            order.productoptionname = Object.values(data)[4];
            order.productoptionprice = "";
          }



          order.productprice = parseInt(
            commaescapevalue(result.PRODUCTSUPPLYPRICE)
          );

          producttotalpricetmp += order.productcount * order.productprice;

          massorder.orderlist.push(order);
          items.push(massorder);
          setTotalprice(producttotalpricetmp);

          setRefresh(refresh => refresh + 1);

        });
        
      })

      
    });
};

  
  const [chatbotstatus, setChatbotstatus] = useState(false);

  const [excelData, setExcelData] = useState([]);
  const [massorderstatus, setMassorderstatus] = useState(false);


  const [ordercount1, setOrdercount1] = useState(0);
  const [ordercount4, setOrdercount4] = useState(0);
  const [ordercount5, setOrdercount5] = useState(0);
  

  
    useEffect(() => {
 

      async function fetchData() {
        const USER_ID = user.USER_ID;
        let orderitems = [];
        orderitems = await getOrder({ USER_ID });

        let ordercount1 = 0;
        let ordercount2 = 0;
        let ordercount3 = 0;
        let ordercount4 = 0;
        let ordercount5 = 0;
        let ordercount6 = 0;
        let ordercount7 = 0;
        let ordercount8 = 0;

        let orderoptionitems = [];
        if (orderitems != -1) {
          orderitems.map((data, index) => {
            if (data.ORDERSTATUS == OrderType.order1) {
              ordercount1++;
            }
     
            if (data.ORDERSTATUS == OrderType.order4) {
              ordercount4++;
            }
            if (data.ORDERSTATUS == OrderType.order5) {
              ordercount5++;
            }
      

      
          });
        }

        setOrdercount1(ordercount1);
        setOrdercount4(ordercount4);
        setOrdercount5(ordercount5);



        


      }

      fetchData();
    }, []);
  
  useEffect(() => {

    async function FetchData() {
      const noticeitemsTmp = await getnotice();

      let count = 0;
      noticeitemsTmp.map((data, index) => {
     
        if (data.READ != undefined) {
            const FindIndex = data.READ.findIndex((x) => x == user.USERID);

            if (FindIndex == -1) {
              count++;
            } 
        } else {
          count++;
        }
     
        
      })

      setNoticecount(count);

      setRefresh((refresh) => refresh + 1);
      

    }

    FetchData();
  })
  
  useEffect(() => {

    setItems(items);
    setTotalprice(totalprice);
    setNoticecount(noticecount);
    
  }, [refresh])

  const _handleLoginAdjust = () => {
    navigate("/Memberregister", {state : {TYPE : 'ADJUST'}});
  }
  
  return (
    <Fragment>
      {loading == true && <Loading />}
      {chatbotstatus == true ? (
        <ChatbotModule callback={chatbotcallback}></ChatbotModule>
      ) : null}
      <Mobile>
        <Container>{"Header"}</Container>
      </Mobile>
      {massorderstatus == true ? (
        <MassOrderModule
          callback={Massordercashcallback}
          callback2={Massordercreditcallback}
          massorderlist={items}
          price={totalprice}
        ></MassOrderModule>
      ) : (
        <PC>
          {expand == false && (
            <PCSide className="Box">
              <Container>
                <CheckBtn onClick={_handleExpand}>
                  <img src={imageDB.left} width={20} />
                </CheckBtn>
                <Line />
                <Btn onClick={_handlechatbot}>
                  <img src={imageDB.head} width={20} />
                </Btn>
                <Line />
                <BtnEx onClick={_handleNotice}>
                  <img src={imageDB.bell} width={20} />
                  <Badge
                    count={noticecount}
                    width={15}
                    height={35}
                    backgroundColor={"#FF4E19"}
                    color={"#FFF"}
                    containerStyle={{
                      position: "relative",
                      backgroundColor: "#FF4E19",
                      width: "15px",
                      borderRadius: "5px",
                    }}
                  />
                </BtnEx>
                <Line />
                <Btn onClick={_handleHeart}>
                  <img src={imageDB.heart} width={20} />
                </Btn>
                <Line />
                <Btn onClick={_handleBasket}>
                  <img src={imageDB.basket} width={20} />
                </Btn>
                <Line />
                <Btn onClick={_handleMyorder}>
                  <CgFileDocument size={20} />
                </Btn>
                <Line />
              </Container>
            </PCSide>
          )}
          {expand == true && (
            <Fragment>
              <PCSideL>
                <Container>
                  <CheckBtn onClick={_handleExpand}>
                    <img src={imageDB.right} width={20} />
                  </CheckBtn>
                  <Line />
                  <Btn onClick={_handlechatbot}>
                    <img src={imageDB.head} width={20} />
                  </Btn>
                  <Line />
                  <BtnEx onClick={_handleNotice}>
                    <img src={imageDB.bell} width={20} />
                    <Badge
                      count={noticecount}
                      width={15}
                      height={35}
                      backgroundColor={"#FF4E19"}
                      color={"#FFF"}
                      containerStyle={{
                        position: "relative",
                        backgroundColor: "#FF4E19",
                        width: "15px",
                        borderRadius: "5px",
                      }}
                    />
                  </BtnEx>
                  <Line />
                  <Btn onClick={_handleHeart}>
                    <img src={imageDB.heart} width={20} />
                  </Btn>
                  <Line />
                  <Btn onClick={_handleBasket}>
                    <img src={imageDB.basket} width={20} />
                  </Btn>
                  <Line />
                  <Btn onClick={_handleMyorder}>
                    <CgFileDocument size={20} />
                  </Btn>
                  <Line />
                </Container>
              </PCSideL>
              <PCSideExpand>
                <div style={{ display: "flex", width: "90%", marginTop: 20 }}>
                  <Chat />
                </div>

                {user.USERID != "" ? (
                  <div
                    style={{
                      marginTop: 20,
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "60%" }}>
                      <span>{user.CEONAME}님이</span>
                      <br />
                      <span>로그인되어 있습니다.</span>
                    </div>

                    <Button
                      containerStyle={{ padding: "0px 2px" }}
                      Label={"회원수정"}
                      bgcolor={"#323232"}
                      borderRadius={10}
                      color={"#fafafc"}
                      bordercolor={"#323232"}
                      height={30}
                      width={"30%"}
                      callback={_handleLoginAdjust}
                    />
                  </div>
                ) : (
                  <div style={{ display: "flex", width: "90%", marginTop: 10 }}>
                    <Button
                      Label={"로그인"}
                      bgcolor={"#fff"}
                      borderRadius={10}
                      color={"#000"}
                      bordercolor={"#000"}
                      height={40}
                      callback={_handleLogin}
                    />
                  </div>
                )}

                {user.USERID != "" ? (
                  <div style={{ display: "flex", width: "90%", marginTop: 10 }}>
                    <Button
                      Label={"로그아웃"}
                      bgcolor={"#000"}
                      borderRadius={10}
                      color={"#fff"}
                      bordercolor={"#000"}
                      height={40}
                      callback={_handleLogout}
                    />
                  </div>
                ) : (
                  <div style={{ display: "flex", width: "90%", marginTop: 10 }}>
                    <Button
                      Label={"회원가입"}
                      bgcolor={"#000"}
                      borderRadius={10}
                      color={"#fff"}
                      bordercolor={"#000"}
                      height={40}
                      callback={_handleRegister}
                    />
                  </div>
                )}

                {user.USERID != "" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 10,
                      width: "100%",
                    }}
                  >
                    <BoxLayer className="button" onClick={_handleMyorder}>
                      <div>주문건수</div>
                      <div style={{ marginTop: 5 }}>{ordercount1}건</div>
                    </BoxLayer>
                    <BoxLayer className="button" onClick={_handleMyorder}>
                      <div>배송중</div>
                      <div style={{ marginTop: 5 }}>{ordercount4}건</div>
                    </BoxLayer>
                    <BoxLayer className="button" onClick={_handleMyorder}>
                      <div>배송완료</div>
                      <div style={{ marginTop: 5 }}>{ordercount5}건</div>
                    </BoxLayer>
                  </div>
                ) : null}

                <div style={{ display: "flex", width: "90%", marginTop: 10 }}>
                  <Guide />
                </div>

                <ControlView />
                <Nego style={{ marginTop: 20 }}>
                  <div
                    style={{
                      display: "flex",
                      color: "#323232",
                      fontSize: 16,
                      height: 25,
                      fontWeight: 700,
                    }}
                  >
                    엑셀대량주문
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 2,
                      backgroundColor: "#323232",
                      margin: "0px 0px 15px 0px",
                    }}
                  ></div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <label
                      style={{
                        display: "flex",
                        height: 20,
                        fontSize: 13,
                        textDecoration: "underline",
                        textUnderlinePosition: "under",
                        color: "#3083FF",
                      }}
                      for="inputFile"
                    >
                      엑셀 대량 주문하기
                    </label>
                    <input
                      type="file"
                      id="inputFile"
                      accept="xlsx"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        readExcel(file);
                      }}
                    ></input>
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/murray-c93ca.appspot.com/o/%E2%98%85%E1%84%86%E1%85%A5%E1%84%85%E1%85%A6%E1%84%8B%E1%85%B5_%E1%84%8B%E1%85%A6%E1%86%A8%E1%84%89%E1%85%A6%E1%86%AF%E1%84%83%E1%85%A2%E1%84%85%E1%85%A3%E1%86%BC%E1%84%8C%E1%85%AE%E1%84%86%E1%85%AE%E1%86%AB%20%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.xlsx?alt=media&token=c67d74bb-d670-43f4-b3ac-4533eb788d70"
                      style={{
                        display: "flex",
                        height: 20,
                        fontSize: 13,
                        textDecoration: "underline",
                        textUnderlinePosition: "under",
                        color: "#000",
                        marginLeft: 20,
                      }}
                    >
                      엑셀 대량 주문양식 다운로드
                    </a>
                  </div>
                </Nego>
                <Customer>
                  <div
                    style={{
                      display: "flex",
                      color: "#323232",
                      fontSize: 16,
                      height: 25,
                      fontWeight: 700,
                    }}
                  >
                    고객센터
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 2,
                      backgroundColor: "#323232",
                      margin: "0px 0px 15px 0px",
                    }}
                  ></div>
                  <div style={{ display: "flex", height: 20, fontSize: 13 }}>
                    031-971-8841 / 070-7777-1366
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: 1,
                      backgroundColor: "#DADADA",
                      margin: "0px 0px 15px 0px",
                    }}
                  ></div>
                  <div style={{ display: "flex", height: 20, fontSize: 13 }}>
                    상담가능 시간 ㅣ 평일 10:00 - 17:00
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 20,
                      fontSize: 12,
                      textAlign: "left",
                      lineHeight: 1.5,
                    }}
                  >
                    *점심시간 12:30 - 13:30 ㅣ *주말 및 공휴일은 문의/상담불가
                  </div>
                </Customer>
                <BankAccount style={{ marginTop: 10 }}>
                  <div
                    style={{
                      display: "flex",
                      color: "#323232",
                      fontSize: 16,
                      height: 25,
                      fontWeight: 700,
                    }}
                  >
                    BANK ACCOUNT
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 2,
                      backgroundColor: "#323232",
                      margin: "0px 0px 15px 0px",
                    }}
                  ></div>
                  <div style={{ display: "flex", height: 20, fontSize: 13 }}>
                    신한은행 140-013-073458
                  </div>
                  <div style={{ display: "flex", height: 20, fontSize: 13 }}>
                    *오후 1시 30분까지 입금 확인될 경우 당일배송
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 1,
                      backgroundColor: "#DADADA",
                      margin: "0px 0px 15px 0px",
                    }}
                  ></div>
                  <div style={{ display: "flex", height: 20, fontSize: 13 }}>
                    예금주 (주)머레이코리아
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 1,
                      backgroundColor: "#DADADA",
                      margin: "0px 0px 15px 0px",
                    }}
                  ></div>
                </BankAccount>
                <Nego>
                  <div
                    style={{
                      display: "flex",
                      color: "#323232",
                      fontSize: 16,
                      height: 25,
                      fontWeight: 700,
                    }}
                  >
                    입점/제휴
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 2,
                      backgroundColor: "#323232",
                      margin: "0px 0px 15px 0px",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      height: 20,
                      fontSize: 13,
                      textDecoration: "underline",
                      textUnderlinePosition: "under",
                      color: "#3083FF",
                    }}
                  >
                    입점/ 제휴 문의하기
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 1,
                      backgroundColor: "#DADADA",
                      margin: "0px 0px 15px 0px",
                    }}
                  ></div>
                </Nego>
              </PCSideExpand>
            </Fragment>
          )}
        </PC>
      )}
    </Fragment>
  );
};

export default Rightside;
