import React, { useContext, useEffect, useState } from "react";
import Homecontainer from "../container/Homecontainer";

import ProductLayout from "../components/Layout/ProductLayout";
import Basketcontainer from "../container/Basketcontainer";
import Layout from "../components/Layout/Layout";
import Ordercontainer from "../container/Ordercontainer";
import { useLocation, useNavigate } from "react-router-dom";
import Myordercontainer from "../container/Myordercontainer";
import { UserContext } from "../context/Users";
import moment from "moment";

const Myorderpage = () => {
  const [orderlist, setOrderlist] = useState([]);

  const { user, dispatch } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const now = moment();
    let LoginEnableTime = moment(now).subtract(60, "minute").unix();

    console.log("LoginEnableTime", LoginEnableTime);
    console.log("LoginRealTime", user.LOGINTIME / 1000);

    if (LoginEnableTime > user.LOGINTIME / 1000) {
      alert(
        "60분간 사용하지 않아 자동 로그아웃 처리되겠습니다. 다시 로그인해주세요"
      );
      user.USERID = "";
      user.USERPW = "";
      user.USERNAME = "";
      user.USER_ID = "";
      dispatch(user);

      navigate("/memberlogin");
    }
    user.LOGINTIME = Date.now();
    dispatch(user);
  }, []);
  
  return (
    <Layout type="">
      <Myordercontainer orderlist={orderlist} />
    </Layout>
  );
};

export default Myorderpage;
