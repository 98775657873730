import React, {useState, createContext} from "react";

const CategoryContext = createContext({
    category : { categorylist : []},
    dispatch :() => {},
})

const CategoryProvider = ({children}) =>{
    const [category, setcategory] = useState([]);

    const dispatch = ({categorylist}) =>{
        setcategory({categorylist});
    };

    const value = {category, dispatch};

    return <CategoryContext.Provider value ={value}>{children}</CategoryContext.Provider>
}

export { CategoryContext, CategoryProvider};