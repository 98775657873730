import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "../css/common.css";
import SelectItem2 from "../../container/component/SelectItem2";
import Button from "../Layout/Button";
import { registAsk } from "../../utility/askfirebase";
import { setRef } from "@mui/material";
import { UserContext } from "../../context/Users";

const Label = styled.div`
  margin-top: 40px;
  font-size: 30px;
  font-family: Pretendard-Bold;
  font-weight: 900;
`;

const BoldName = styled.div`
  font-size: 18px;
  font-weight: 900;
  margin-left: 10px;
  margin-right: 10px;
`;

const Exampletxt =
  "업체명 : " +
  "\r" +
  "대표자 : " +
  "\r" +
  "브랜드명 : " +
  "\r" +
  "운영중인 온라인몰 링크 : " +
  "\r" +
  "제안 담당자 : " +
  "\r" +
  "제안 담당자 연락처 : " +
  "\r" +
  "제안 한줄 요약 : " +
  "\r" +
  "제안 내용 : " +
    "\r";
  

const Cooperationask = () => {
    const navigate = useNavigate();
    const [check, setCheck] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [content, setContent] = useState('');
    const [contentType, setContentType] = useState('제품소싱');
    const { user, dispatch2 } = useContext(UserContext);


    const oncontentChange = (e) => {
        setContent(e.target.value);
        setRefresh(refresh => refresh + 1);
    }
    
    const _handleCheck = () => {
        setCheck(!check);
        setRefresh(refresh => refresh + 1);
    };
    
    const selectcallback = (data) => {
        
        if (data == '' || data == undefined) {
            setContentType('제품소싱');
        } else {
            setContentType(data);
        }
     
        setRefresh((refresh) => refresh + 1);
    }
    const _handlewrite = async () => {    
        const CONTENT = content;
        const USERID = user.USERID;
        const USER_ID = user.USER_ID;
        const CONTENTTYPE = contentType;
        const CEONAME = user.CEONAME;
        const SECRET = check;
        const ASKTYPE = "COOPERATION";

        const regist = await registAsk({
          CONTENT,
          CONTENTTYPE,
          ASKTYPE,
          USERID,
          USER_ID,
          CEONAME,
          SECRET,
        });
        alert("게시물이 정상적으로 등록 되었습니다");
        navigate("/notice");

    }
    useEffect(() => {
        setCheck(check);
        setContent(content);
        setContentType(contentType);
    },[refresh])
    
    return (
      <div className="Container">
        <>
          <Label>{"협업문의작성"}</Label>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                height: 50,
                display: "flex",
                alignItems: "center",
              }}
            >
              <SelectItem2
                OPTIONTYPE={"협업문의"}
                callback={selectcallback}
              />
            </div>
            <div
              style={{
                background: "#ededed",
                height: 150,
                marginBottom: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                padding: "10px 0px",
                marginTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <BoldName>제품소싱 : </BoldName>
                <div>
                  유통 판매하고 싶은 상품이 있는데, 혼자 힘으로 생산하기
                  어렵거나 수입대행을 원할 경우 제안해주세요!
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <BoldName>공급요청 : </BoldName>
                <div>
                  운영 중인 자사 온라인몰/플랫폼이 있는데 머레이코리아 제품을
                  올려 판매하고 싶어요!
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <BoldName>입점제안 : </BoldName>
                <div>머레이코리아 B2B에 우리제품을 등록해 판매하고 싶어요!</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <BoldName>기타제안 : </BoldName>
                <div>기타 협업/ 마케팅 /광고 / 공동구매를 제안하고 싶어요!</div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <textarea
                placeholder={Exampletxt}
                style={{
                    background: "#fff",
                    height: 700,
                    width: "100%",
                    resize: "none",
                    outline: 0,
                    padding: 20,
                    lineHeight: 2,
                    fontSize: 20,
                }}
                value={content}
                onChange={oncontentChange}
              ></textarea>
            </div>

            <div
              style={{
                display: "flex",
                margin: "10px 0px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 20,
                }}
              >
                <div style={{ width: 200 }}>비밀글 여부</div>
                <input
                  style={{ height: 30 }}
                  type="checkbox"
                  onClick={_handleCheck}
                  value={check}
                />
              </div>

              <Button
                callback={_handlewrite}
                Label={"작성하기"}
                bgcolor={"#689fff"}
                borderRadius={10}
                color={"#fff"}
                bordercolor={"#689fff"}
                height={50}
                width={"20%"}
              />
            </div>
          </div>
        </>
      </div>
    );
};

export default Cooperationask;
