import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "../components/css/common.css";
import Search from "../components/Layout/SearchBasket";
import Best from "./component/Best";
import BestItem from "./component/BestItem";
import { getproduct } from "../utility/productfirebase";
import { Define, ProductNameTag } from "../components/Define";
import { MaxPC, SmallPC } from "../components/Responsive";
import { getcategory1, getcategory2 } from "../utility/categoryfirebase";
import ItemProduct from "./component/ItemProduct";
import { imageDB } from "../components/imageDB";
import CategoryItems from "./component/CategoryItems";
import Loading from "./component/Loading";
import Question from "./component/Question";
import Button from "../components/Layout/Button";
import SelectItem from "./component/SelectItem";
import SelectItem2 from "./component/SelectItem2";
import Cooperationask from "../components/Ask/Cooperationask";
import Etcask from "../components/Ask/Etcask";
import Productask from "../components/Ask/Productask";




const Tag = styled.div`
  font-size: 20px;
  color: ${({ enable }) => (enable == true ? "#000" : "#999")};
  font-weight: 700;
  border-bottom: ${({ enable }) =>
    enable == true ? "2px solid #000" : "1px solid #999"};
  width: 33.3%;
  height: 50px;
`;

const Label = styled.div`
  margin-top: 40px;
  font-size: 30px;
  font-family: Pretendard-Bold;
  font-weight: 900;
`;

const QuestionLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 30px;

`;

const NoProduct = styled.div`
  font-size: 25px;
  font-weight: 900;
`;
const BoldName = styled.div`
  font-size: 18px;
  font-weight: 900;
  margin-left:10px;
  margin-right:10px;
`;

const Exampletxt = "업체명 : " + "\r" + "대표자 : " + "\r" 
+"브랜드명 : " + "\r"
+"운영중인 온라인몰 링크 : " + "\r"
+"제안 담당자 : " + "\r"
+"제안 담당자 연락처 : " + "\r"
+"제안 한줄 요약 : " + "\r"
  + "제안 내용 : " + "\r";


const Exampletxt2 =
  "업체명 : " +
  "\r" +
  "대표자 : " +
  "\r" +
  "브랜드명 : " +
  "\r" +
  "담당자 : " +
  "\r" +
  "담당자 연락처 : " +
  "\r" +
  "문의 내용 : " +
  "\r";
   
  const Exampletxt3 =
    "업체명 : " +
    "\r" +
    "대표자 : " +
    "\r" +
    "브랜드명 : " +
    "\r" +
    "담당자 : " +
    "\r" +
    "담당자 연락처 : " +
    "\r" +
    "문의 내용 : " +
    "\r";

const NoticeWritecontainer = ({ mode }) => {
  const [refresh, setRefresh] = useState(1);
  const [load, setLoad] = useState(false);
  const [check, setCheck] = useState(false);
  const _handleCheck = () => {
    
  }
  
  useEffect(() => {
    //mount 시에
    console.log("init");

    window.scrollTo(0, 0);

    return () => {
      //unmount 시에
      console.log("dispose");
    };
  }, []);

  const navigate = useNavigate();
  return (
    <>
      {load == false ? (
        <>
          <SmallPC>
            <div
              className="SmallItemContainer"
              style={{
                minHeight: 600,
                justifyContent: "unset",
                width: "80%",
                padding: "0px 10%",
              }}
            >
              {mode == 1 && <Cooperationask />}
              {mode == 2 && <Productask />}
              {mode == 3 && <Etcask />}
            </div>
          </SmallPC>
          <MaxPC>
            <div
              className="MaxItemContainer"
              style={{
                minHeight: 600,
                justifyContent: "unset",
                width: "80%",
                padding: "0px 10%",
              }}
            >
              {mode == 1 && <Cooperationask />}
              {mode == 2 && <Productask />}
              {mode == 3 && <Etcask />}
            </div>
          </MaxPC>
        </>
      ) : (
        <>
          <Loading containerStyle={{ marginTop: "10%", marginBottom: "50%" }} />
        </>
      )}
    </>
  );
};

export default NoticeWritecontainer;
