import React, { Fragment, useContext, useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import "./AllItem.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted, ratevalue } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { MaxPC, SmallPC } from "../../components/Responsive";
import { UserContext } from "../../context/Users";

const SmallContainer = styled.div`
  border-radius: 5px;
  flex-direction: column;
  margin-right: 10px;
  display: flex;
  border-radius: 10px;
  width: 240px;
  justifycontent: center;
`;
const Container = styled.div`
  border-radius: 5px;
  flex-direction: column;
  margin-right: 10px;
  display: flex;
  border-radius: 10px;
  justifycontent: center;
`;

const CheckItem = styled.div`
  background-color: #b5b5b7c9;
  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`;

const UnCheckItem = styled.div`
  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`;
const Sticker1 = styled.div`
  background-color: #ff00007d;
  color: #fff;
  font-size: 12px;
  top: -8px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 30px;
`;
const ProductInfo = styled.div`
  padding: 15px 0px;
  border-top: none;
`;
const ProductName = styled.div`
  font-size: 16px;
  text-align: left;
  color: #000;
  font-weight: 600;
  font-family: "Pretendard-Regular";
`;

const ProductPrice = styled.span`
  font-size: 13px;
  text-align: left;
  color: #000;
  font-weight: 900;
`;

const ProductRate = styled.span`
  font-size: 14px;
  text-align: left;
  color: #ff0000;
  font-weight: 900;
  font-family: "Pretendard-Bold";
`;
const ProductSubPrice = styled.div`
  font-size: 11px;
  text-align: left;
  color: #929292;
  text-decoration: line-through;
`;

const MaxWidthImage = "370px";

const AllItem = ({
  product_id,
  img,
  name,
  price,
  lowprice,
  component,
  add,
  select,
  column,
  index,
  supply
}) => {
  const navigate = useNavigate();
  const { user, dispatch2 } = useContext(UserContext);

  const [isHovering, setIsHovering] = useState(false);
  const _handleMouseOver = () => {
    if (isHovering) {
      return;
    }
    setIsHovering(true);
    // console.log("mouse over");
  };
  const _handleMouseOut = () => {
    if (!isHovering) {
      return;
    }
    setIsHovering(false);
    // console.log("mouse out");
  };

  const _handleProduct = () => {

    if (user.USERID == '') {
      alert("로그인을 먼저 진행해주세요");
      return;
    }
 
    navigate("/product", { state: { PRODUCT_ID: product_id } });
  };

  const existselect = (selectname) => {
    let selectlist = select;

    const FindIndex = selectlist.findIndex((x) => x == selectname);

    if (FindIndex == -1) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Fragment>
      <MaxPC>
        <Container
          onClick={_handleProduct}
          onMouseOver={_handleMouseOver}
          onMouseOut={_handleMouseOut}
        >
          <div style={{ display: "flex" }}>
            <div className="itemcontainer">
              <img src={img} class="image" style={{ height: "370px" }} />
              <div class="wrapper">
                {existselect("newselect") == true && (
                  <div class="NewButton">
                    <div className="NewButtonText">NEW</div>
                  </div>
                )}

                {existselect("mdselect") == true && (
                  <div class="MdButton">
                    <div className="MdButtonText">MD픽</div>
                  </div>
                )}
                {existselect("bestselect") == true && (
                  <div class="BestButton">
                    <div className="BestButtonText">BEST</div>
                  </div>
                )}

                {existselect("saleselect") == true && (
                  <div class="SaleButton">
                    <div className="SaleButtonText">판매급상승</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ProductInfo>
            <div
              style={{
                display: "flex",
                flexWrap: "noWrap",
                width: MaxWidthImage,
                flexDirection : "column"
              }}
            >
              <ProductName>{name}</ProductName>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={{ fontSize: 13, textAlign: "left" }}>
                  <span style={{ color: "#929292", fontWeight: 700 }}>
                    {"파트너 공급가   |   "}
                  </span>
                  {user.USERID != "" ? (
                    <ProductPrice>{CommaFormatted(supply)}원</ProductPrice>
                  ) : (
                    <ProductPrice>로그인후확인</ProductPrice>
                  )}

                  {/* <ProductSubPrice>
                    {user.USERID != "" ? (
                      <ProductPrice>{CommaFormatted(lowprice)}원</ProductPrice>
                    ) : null}
                  </ProductSubPrice>
                  <ProductRate>{ratevalue(lowprice, price)}</ProductRate> */}
                </div>
              </div>
            </div>
          </ProductInfo>
        </Container>
      </MaxPC>

      <SmallPC>
        <SmallContainer
          onClick={_handleProduct}
          onMouseOver={_handleMouseOver}
          onMouseOut={_handleMouseOut}
        >
          <div style={{ display: "flex" }}>
            <div className="itemcontainer">
              <img
                src={img}
                class="image"
                style={{
                  height: "240px",
                }}
              />
              <div class="wrapper">
                {existselect("newselect") == true && (
                  <div class="NewButton">
                    <div className="NewButtonText">NEW</div>
                  </div>
                )}

                {existselect("mdselect") == true && (
                  <div class="MdButton">
                    <div className="MdButtonText">MD픽</div>
                  </div>
                )}
                {existselect("bestselect") == true && (
                  <div class="BestButton">
                    <div className="BestButtonText">BEST</div>
                  </div>
                )}

                {existselect("saleselect") == true && (
                  <div class="SaleButton">
                    <div className="SaleButtonText">판매급상승</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ProductInfo>
            <div style={{ display: "flex", flexWrap: "noWrap", width: 240,flexDirection:"column" }}>
              <ProductName>{name}</ProductName>
            </div>
            <div style={{ display: "flex", flexGrow: 1 }}>
              <div style={{ fontSize: 13, textAlign: "left" }}>
                <span style={{ color: "#929292", fontWeight: 700 }}>
                  {"파트너 공급가   |   "}
                </span>
                {user.USERID != "" ? (
                  <ProductPrice>{CommaFormatted(supply)}원</ProductPrice>
                ) : (
                  <ProductPrice>로그인후확인</ProductPrice>
                )}
                {/* 
                <ProductSubPrice>
                  {user.USERID != "" ? (
                    <ProductPrice>{CommaFormatted(lowprice)}원</ProductPrice>
                  ) : null}
                </ProductSubPrice>
                <ProductRate>{ratevalue(lowprice, price)}</ProductRate> */}
              </div>
            </div>
          </ProductInfo>
        </SmallContainer>
      </SmallPC>
    </Fragment>
  );
};

AllItem.defaultProps = {
  price: 0,
  time: 0,
};
export default AllItem;
