import React, {useState, createContext} from "react";

const UserContext = createContext({
  user: { CEONAME: "", USERID: "", USER_ID: "", PASSWORD: "", BANNERITEMS: [], LOGINTIME:"", STORENAME:"", LEVEL:""},
  dispatch: () => {},
});

const UserProvider = ({children}) =>{
    const [user, setUser] = useState({});

    const dispatch = ({
      CEONAME,
      USERID,
      USER_ID,
      PASSWORD,
      BANNERITEMS,
      LOGINTIME,

      STORENAME,
      LEVEL
    }) => {
      setUser({ CEONAME, USERID, USER_ID, PASSWORD, BANNERITEMS, LOGINTIME, STORENAME,LEVEL });
    };

    const value = {user, dispatch};

    return <UserContext.Provider value ={value}>{children}</UserContext.Provider>
}

export { UserContext, UserProvider};