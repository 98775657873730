import React from 'react';

import Layout from '../components/Layout/Layout';


import { Define } from '../components/Define';
import Heartproductcontainer from '../container/Heartproductcontainer';


const Heartproductpage = ({pagetype}) => {
  return (
    <Layout type={pagetype}>
      <Heartproductcontainer pagetype={pagetype}/>
    </Layout>

  );
}

export default Heartproductpage;
