import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import Button from "../../components/Layout/Button";
import { CommaFormatted } from "../../utility/common";
import CashOrderModule from "./CashOrderModule";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "10%",
  right: "5%",
  height: "650px",
  transform: "translate(0%, -5%)",
  width: "1200px",
  backgroundColor: "#fff",
  padding: "10px",
};


const IconCloseView = styled.div`
  display: flex;
  justify-content: flex-end;
  margin : 10px 0px;
`;
const MainData = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 20px 0px;
  background-color: #fff;
  flex-wrap: wrap;
`;

const MainDataItem = styled.div`
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${({ check }) => (check == 1 ? "#ff4e193b" : "#EDEDED")};
  margin-left: 10px;
  margin-bottom: 10px;
`;
const MainDataItemText = styled.span`
  font-size: 12px;
  font-family: ${({ theme }) => theme.REGULAR};
  color: ${({ check }) => (check == 1 ? "#FF4E19" : "#000")};
`;
const ApplyItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  margin-bottom: 20px;
`;
const FilterApplyButton = styled.div`
  background-color: #ff4e19;
  padding: 5px 50px;
  border-radius: 5px;
`;
const FilterApplyButtonText = styled.span`
  color: #fff;
  font-size: 14px;
  font-family: ${({ theme }) => theme.REGULAR};
`;

const TableLayer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 480px;
  overflow-y: auto;
`;

export default function MassOrderModule({
  callback,
  callback2,
  massorderlist,
  price,
}) {
  const [open, setOpen] = React.useState(true);

  const [cash, setCash] = React.useState(false);

  const [cardstatus, setCardstatus] = React.useState(false);

  const [refresh, setRefresh] = React.useState(1);

  const handleClose = () => {
    setOpen(false);

  };

  const _handlebankorder = () => {
    setCash(true);
  };

  const _handlecreditorder = () => {
    setCardstatus(true);
    callback2();
  }
  const cashordercallback = (bankuser, bankname, banknum) => {
    setCash(false);

    if (bankuser != '') {
       setOpen(false);
       callback(bankuser, bankname, banknum);   
    }
  };



  return (
    <div>
      {cash == true && (
        <CashOrderModule
          orderlist={massorderlist}
          productprice={price}
          callback={cashordercallback}
        ></CashOrderModule>
      )}


      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 20, paddingLeft: 20 }}>
                엑셀 대량주문 내역
              </div>
              <IconCloseView onClick={handleClose}>
                <AiFillCloseCircle size={30} />
              </IconCloseView>
            </div>

            <table class="bluetop">
              <tr>
                <th style={{ width: "100px" }}>상품번호</th>
                <th style={{ width: 100 }}>상품명</th>
                <th style={{ width: 100 }}>상품이미지</th>
                <th style={{ width: 100 }}>상품수량</th>
                <th style={{ width: 100 }}>옵션</th>
                <th style={{ width: 100 }}>가격</th>
                <th style={{ width: 100 }}>수령자명</th>
                <th style={{ width: 100 }}>수령자연락처</th>
                <th style={{ width: 100 }}>수령자주소</th>
                <th style={{ width: 100 }}>배송메시지</th>
                <th style={{ width: 100 }}>담당자참고사항</th>
              </tr>
            </table>
            <TableLayer>
              <table class="bluetop" style={{ borderTop: "none" }}>
                {massorderlist.map((data, index) => (
                  <tr key={index}>
                    <td style={{ width: "100px" }}>
                      {data.orderlist[0].productnum}
                    </td>
                    <td style={{ width: 100 }}>
                      {data.orderlist[0].productname}
                    </td>
                    <td style={{ width: 100 }}>
                      <img
                        src={data.orderlist[0].productimage}
                        style={{ width: 30 }}
                      />
                    </td>
                    <td style={{ width: 100 }}>
                      {data.orderlist[0].productcount}
                    </td>
                    <td style={{ width: 100 }}>
                      {data.orderlist[0].productoptionname}
                    </td>
                    <td style={{ width: 100 }}>
                      {CommaFormatted(data.orderlist[0].productprice)}원
                    </td>
                    <td style={{ width: 100 }}>{data.receiveordername}</td>
                    <td style={{ width: 100 }}>{data.receiveordertel}</td>
                    <td style={{ width: 100 }}>{data.receiveorderaddr}</td>
                    <td style={{ width: 100 }}>{data.delivermessage}</td>
                    <td style={{ width: 100 }}>{data.infomessage}</td>
                  </tr>
                ))}
              </table>
            </TableLayer>

            <div
              style={{
                display: "flex",
                marginTop: 30,
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: 50, fontWeight: 700 }}>
                총 결제 금액 {CommaFormatted(price)}원
              </div>


              <Button
                Label={"카드결제"}
                bgcolor={"#689fff"}
                borderRadius={5}
                color={"#fff"}
                bordercolor={"#689fff"}
                height={30}
                width={"200px"}
                containerStyle={{ marginLeft: 10 }}
                callback={_handlecreditorder}
              />


              
              <Button
                Label={"계좌이체"}
                bgcolor={"#689fff"}
                borderRadius={5}
                color={"#fff"}
                bordercolor={"#689fff"}
                height={30}
                width={"200px"}
                containerStyle={{marginLeft:10}}
                callback={_handlebankorder}
              />
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
