import React, {useState, createContext} from "react";

const BannerContext = createContext({
    banner : { bannerlist : []},
    dispatch2 :() => {},
})

const BannerProvider = ({children}) =>{
    const [banner, setBanner] = useState([]);

    const dispatch2 = ({bannerlist}) =>{
        setBanner({bannerlist});
    };

    const value = {banner, dispatch2};

    return <BannerContext.Provider value ={value}>{children}</BannerContext.Provider>
}

export { BannerContext, BannerProvider};