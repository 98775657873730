import React, { Fragment, useContext, useEffect, useState } from "react";
import { MaxPC, Mobile, PC, SmallPC } from "../../Responsive";
import { imageDB } from "../../imageDB";
import { COLORS } from "../../Theme";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Define } from "../../Define";
import Advertise from "../../../container/component/Advertise";
import ReactTyped from "react-typed";
import { UserContext } from "../../../context/Users";
import { signupUser } from "../../../utility/userfirebase";
import MainBanner from "../../../container/component/MainBanner";
import { getSideBanner } from "../../../utility/bannerfirebase";

const PCHeader = styled.div`
  height: 200px;
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 1400px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  padding: 20px 10px 0px 5%;
`;
const MaxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1800px;
  padding: 20px 20px 0px;
`;

const ImageLine = styled.div`
  display: flex;
  padding-left: 5%;
  padding-top: 20px;
`;
const ImageMaxLine = styled.div`
  display: flex;
  padding-top: 20px;
`;

const CategoryLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
`;

const MaxCategoryLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
`;
const SelectCategory = styled.div`
  background-color: #323232;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
`;
const Category = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 900;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #2a2a2a;
  position: fixed;
  z-index: 5;
`;
const EventContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #191a1f;
  position: fixed;
  z-index: 5;
  color: #fff;
`;

const MenuContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  z-index: 5;
  margin-top: 55px;
`;

const MaxMenuContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 1800px;
  background-color: #fff;
  z-index: 5;
  margin-top: 50px;
  padding: 0px 5% 0px;
`;

const SearchBasket = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
`;
const MaxSearchBasket = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 100px;
`;
const Infocontrol = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-around;
  margin-right: 30px;
  align-items: center;
  padding-top: 40px;
`;
const MaxInfocontrol = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-around;
  margin-right: 100px;
  align-items: center;
  padding-top: 40px;
`;
const LineControl = styled.div`
  border: 1px solid #0793ff;
  width: 100%;
  position: absolute;
  margin-top: 45px;
`;

const Homeheader = ({ callback, type }) => {
  const [small, setSmall] = useState(false);

  const { dispatch, user } = useContext(UserContext);

  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    async function fetchData() {
      let bannerlist = [];
      let bannerlistTmp = [];
      bannerlistTmp = await getSideBanner();

      bannerlistTmp.forEach((element) => {
        console.log("banners", element);

        if (element.DISPOSE == true) {
          bannerlist.push(element);
        }
      });

      user["BANNERITEMS"] = bannerlist;
        
      const userObjString = JSON.stringify(user);
      window.localStorage.setItem("user", userObjString);
      
      dispatch(user);
    }

    fetchData();
  }, []);

  

  useEffect(() => {
    // console.log("useritem ceoname", user.CEONAME, user);

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 200)
      );
    }

    setRefresh((refresh) => refresh + 1);
  }, []);

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 10) {
        // console.log("scroll 이동");

        let element = document.getElementById("eventheader");

        if (element != null) {
          document.getElementById("eventheader").style.display = "none";
        }

        element = document.getElementById("menuheader");

        if (element != null) {
          document.getElementById("menuheader").style.marginTop = "0px";
          document.getElementById("menuheader").style.height = "70px";
          document.getElementById("menuheader").style.width = "100%";
          document.getElementById("menuheader").style.borderBottom =
            "1px solid #ededed";
        }

        element = document.getElementById("categoryheader");

        if (element != null) {
          document.getElementById("categoryheader").style.transition =
            "all 0.5s ease-out";
          document.getElementById("categoryheader").style.transform =
            "translate(150px, -57px)";
          document.getElementById("categoryheader").style.padding = "unset";
        }
        element = document.getElementById("searchbasket");

        if (element != null) {
          document.getElementById("searchbasket").style.transition =
            "all 0.5s ease-out";
          document.getElementById("searchbasket").style.transform =
            "translate(0px, -65px)";
        }
        element = document.getElementById("searchbasket");

        if (element != null) {
          document.getElementById("infoheader").style.display = "none";
        }
      } else {
        let element = document.getElementById("eventheader");
        if (element != null) {
          document.getElementById("eventheader").style.display = "flex";
        }

        element = document.getElementById("menuheader");
        if (element != null) {
          document.getElementById("menuheader").style.marginTop = "55px";
          document.getElementById("menuheader").style.height = "115px";
          document.getElementById("menuheader").style.border = "none";
          document.getElementById("menuheader").style.width = "100%";
        }

        element = document.getElementById("categoryheader");
        if (element != null) {
          document.getElementById("categoryheader").style.transform =
            "translate(-10px, 0px)";
        }

        element = document.getElementById("searchbasket");
        if (element != null) {
          document.getElementById("searchbasket").style.transform =
            "translate(0px, 0px)";
        }

        element = document.getElementById("infoheader");
        if (element != null) {
          document.getElementById("infoheader").style.display = "flex";
          document.getElementById("infoheader").style.paddingLeft = "10%";
        }
      }
    };

    window.addEventListener("scroll", handleShowButton);

    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  const navigate = useNavigate();

  const _handleLogin = () => {
    navigate("/Memberlogin");
  };
  const _handleRegister = () => {
    navigate("/Memberregister", { state: { TYPE: "NEW" } });
  };

  const _handleMyorder = () => {
    if (user.USERID == "") {
      alert("로그인이 필요한 메뉴입니다");
      navigate("/memberlogin");
      return;
    }
    navigate("/myorder");
  };
  const _handleLogout = async () => {
    const logoutuser = await signupUser();

    if (logoutuser != -1) {
      user.USERID = "";
      user.USERPW = "";
      user.USERNAME = "";
      user.USER_ID = "";
      dispatch(user);

      const userObjString = JSON.stringify(user);
      // console.log("userObjString", userObjString);

      window.localStorage.setItem("user", userObjString);

      navigate("/home");
    }
  };

  const _handleMain = () => {
    navigate("/home");

    let categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
    setSelect(categoryselect);
    setAllcategory(false);

    callback(Define.home);
  };
  const _handleAllCategory = () => {
    // navigate('/categoryproduct', {state : {CATEGORYINFO: "전체"}});
    // setAllcategory(true);
    // let categoryselect = [0,0,0,0,0,0,0,0];
    // setSelect(categoryselect);
    // callback(Define.allcategory);
  };
  const _handleCategory = (index) => {
    let categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
    categoryselect[index] = 1;

    setSelect(categoryselect);
    setAllcategory(false);

    if (index == 0) {
      callback(Define.allcategory);
    navigate("/itemproduct", { state: { CATEGORYINFO: "전체" } });
    } else if (index == 1) {
      callback(Define.best);
      navigate("/bestproduct");
    } else if (index == 2) {
      callback(Define.new);
      navigate("/newproduct");
    } else if (index == 3) {
      callback(Define.md);
      navigate("/mdproduct");
    } else if (index == 4) {
      callback(Define.gomargin);
      navigate("/marginproduct");
    } else if (index == 5) {
      callback(Define.top);
      navigate("/topproduct");
    } else if (index == 6) {
      callback(Define.soldout);
      navigate("/soldoutproduct");
    } else if (index == 7) {
      callback(Define.notice);
      navigate("/notice");
    }

    setRefresh((refresh) => refresh * 1);
  };

  const _handleSearch = () => {
    callback(Define.search);
    navigate("/search", { state: { SEARCH: search } });
  };

  useEffect(() => {
    setSearch(search);
  }, [refresh]);

  const [allcategory, setAllcategory] = useState(false);
  const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
  const [select, setSelect] = useState(categoryselect);

  const [search, setSearch] = useState("");
  const onSearchChange = (e) => {
    setSearch(e.target.value);
    setRefresh((refresh) => refresh * 1);
  };

  const activeEnter = (e) => {
   
     if (e.key === "Enter") {
       _handleSearch();
        setRefresh((refresh) => refresh * 1);
     }
   };
  

  const _handleBasket = () => {
    if (user.USERID == "") {
      alert("로그인이 필요한 메뉴입니다");
      navigate("/memberlogin");
      return;
    }
    navigate("/basket");
  };

  useEffect(() => {
    // console.log("type", type);
    if (type == Define.allcategory) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[0] = 1;
      setSelect(categoryselect);
    } else if (type == Define.best) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[1] = 1;
      setSelect(categoryselect);
    } else if (type == Define.new) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[2] = 1;
      setSelect(categoryselect);
    } else if (type == Define.md) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[3] = 1;
      setSelect(categoryselect);
    } else if (type == Define.gomargin) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[4] = 1;
      setSelect(categoryselect);
    } else if (type == Define.top) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[5] = 1;
      setSelect(categoryselect);
    } else if (type == Define.soldout) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[6] = 1;
      setSelect(categoryselect);
    } else if (type == Define.out) {
      const categoryselect = [0, 0, 0, 0, 0, 0, 0, 0];
      categoryselect[7] = 1;
      setSelect(categoryselect);
    }
  }, []);

  const categoryItems = [
    "BEST 100",
    "신규출시",
    "MD픽!",
    "GO!마진",
    "판매량 TOP10",
    "임시품절",
    "게시판",
  ];

  return (
    <Fragment>
      <SmallPC>
        <PCHeader>
          {/* <SelectContainer id="selectheader">
              <img src={imageDB.desktop} style={{width:30, marginRight:20}}/>
              <img src={imageDB.mobile} style={{width:30}}/>
            </SelectContainer> */}
          <EventContainer id="eventheader">
            <ReactTyped
              strings={["30초면 끝! 머레이의 새로운 기능을 체험하세요"]}
              typeSpeed={100}
              loop
            />

            <LineControl></LineControl>
          </EventContainer>

          <MenuContainer id="menuheader">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "1440px",
              }}
            >
              <ImageLine onClick={_handleMain}>
                <img src={imageDB.mainlogo} width={130} height={45} />
              </ImageLine>
              <Infocontrol id="infoheader">
                {user.USERID != "" && user != undefined && (
                  <div className="headerblink">{user.CEONAME}님이 로그인</div>
                )}

                {user.USERID != "" ? (
                  <div onClick={_handleLogout}>로그아웃</div>
                ) : (
                  <div onClick={_handleLogin}>로그인</div>
                )}

                <div onClick={_handleRegister}>회원가입</div>
                <div onClick={_handleMyorder}>주문조회</div>
              </Infocontrol>
            </div>

            <Container>
              <CategoryLine id="categoryheader">
                {type == Define.allcategory ? (
                  <SelectCategory
                    onClick={() => {
                      _handleCategory(0);
                    }}
                  >
                    <Row>
                      <Row style={{ paddingLeft: 5 }}>전체카테고리</Row>
                    </Row>
                  </SelectCategory>
                ) : (
                  <Category
                    onClick={() => {
                      _handleCategory(0);
                    }}
                  >
                    <Row>
                      <Row style={{ paddingLeft: 5 }}>전체카테고리</Row>
                    </Row>
                  </Category>
                )}

                {categoryItems.map((category, index) => (
                  <>
                    {select[index] == 0 ? (
                      <Category
                        key={index}
                        onClick={() => {
                          _handleCategory(index + 1);
                        }}
                      >
                        {category}
                      </Category>
                    ) : (
                      <SelectCategory
                        key={index}
                        onClick={() => {
                          _handleCategory(index + 1);
                        }}
                      >
                        {category}
                      </SelectCategory>
                    )}
                  </>
                ))}
              </CategoryLine>

              <SearchBasket id="searchbasket">
                <div>
                  <input
                    type="text"
                    onChange={onSearchChange}
                    onKeyDown={(e) => activeEnter(e)}
                    value={search}
                    style={{
                      width: 150,
                      backgroundColor: "transparent",
                      height: 25,
                      border: "unset",
                      borderBottom: "2px solid #000000",
                      paddingLeft: "unset",
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingRight: "unset",
                      borderRadius: "unset",
                    }}
                  ></input>
                </div>
                <div
                  style={{ position: "relative", left: "-20px" }}
                  onClick={_handleSearch}
                >
                  <img src={imageDB.search} width={20} />
                </div>

                <div onClick={_handleBasket}>
                  <img src={imageDB.basket} width={20} />
                </div>
              </SearchBasket>
            </Container>
          </MenuContainer>
        </PCHeader>
      </SmallPC>
      <MaxPC>
        <PCHeader>
          {/* <SelectContainer id="selectheader">
              <img src={imageDB.desktop} style={{width:30, marginRight:20}}/>
              <img src={imageDB.mobile} style={{width:30}}/>
            </SelectContainer> */}
          <EventContainer id="eventheader">
            <ReactTyped
              strings={["30초면 끝! 머레이의 새로운 기능을 체험하세요"]}
              typeSpeed={100}
              loop
            />

            <LineControl></LineControl>
          </EventContainer>

          <MaxMenuContainer id="menuheader">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ImageMaxLine onClick={_handleMain}>
                <img src={imageDB.mainlogo} width={130} height={45} />
              </ImageMaxLine>
              <MaxInfocontrol id="infoheader">
                {user.USERID != "" && (
                  <div className="maxheaderblink">
                    {user.CEONAME}님이 로그인
                  </div>
                )}

                {user.USERID != "" ? (
                  <div onClick={_handleLogout}>로그아웃</div>
                ) : (
                  <div onClick={_handleLogin}>로그인</div>
                )}

                <div onClick={_handleRegister}>회원가입</div>
                <div onClick={_handleMyorder}>주문조회</div>
              </MaxInfocontrol>
            </div>

            <MaxContainer>
              <MaxCategoryLine id="categoryheader">
                {type == Define.allcategory ? (
                  <SelectCategory
                    onClick={() => {
                      _handleCategory(0);
                    }}
                  >
                    <Row>
                      <Row style={{ paddingLeft: 5 }}>전체카테고리</Row>
                    </Row>
                  </SelectCategory>
                ) : (
                  <Category
                    onClick={() => {
                      _handleCategory(0);
                    }}
                  >
                    <Row>
                      <Row style={{ paddingLeft: 5 }}>전체카테고리</Row>
                    </Row>
                  </Category>
                )}

                {categoryItems.map((category, index) => (
                  <>
                    {select[index] == 0 ? (
                      <Category
                        key={index}
                        onClick={() => {
                          _handleCategory(index + 1);
                        }}
                      >
                        {category}
                      </Category>
                    ) : (
                      <SelectCategory
                        key={index}
                        onClick={() => {
                          _handleCategory(index + 1);
                        }}
                      >
                        {category}
                      </SelectCategory>
                    )}
                  </>
                ))}
              </MaxCategoryLine>

              <MaxSearchBasket id="searchbasket">
                <div>
                  <input
                    type="text"
                    onChange={onSearchChange}
                    onKeyDown={(e) => activeEnter(e)}
                    value={search}
                    style={{
                      width: 150,
                      backgroundColor: "transparent",
                      height: 25,
                      border: "unset",
                      borderBottom: "2px solid #000000",
                      paddingLeft: "unset",
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingRight: "unset",
                      borderRadius: "unset",
                    }}
                  ></input>
                </div>
                <div
                  style={{ position: "relative", left: "-20px" }}
                  onClick={_handleSearch}
                >
                  <img src={imageDB.search} width={20} />
                </div>

                <div onClick={_handleBasket}>
                  <img src={imageDB.basket} width={20} />
                </div>
              </MaxSearchBasket>
            </MaxContainer>
          </MaxMenuContainer>

          {/* <MainBanner/> */}
        </PCHeader>
      </MaxPC>
    </Fragment>
  );
};

export default Homeheader;
