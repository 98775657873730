import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import Label from "./Label";
import "./OrderViewModule.css";
import { CommaFormatted, getDateFullTime, useSleep } from "../../utility/common";
import { setRef } from "@mui/material";
import Button from "../../components/Layout/Button";

import moment from "moment";
import { updateOrderstatus, updateOrderstatusex } from "../../utility/orderfirebase";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "680px",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  padding: "10px",
};
const IconCloseView = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Row = styled.div`
  display: flex;
  padding-left: 10px;
  height: 45px;
  align-items: center;
`;

export default function OrderReturnModule({
  callback,
  PRODUCTNAME,
  TYPE,
  ORDER_ID

}) {
  // console.log("orderuser", orderuser);
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    callback();
  };

  const [refresh, setRefresh] = React.useState(1);

  const [title, setTitle] = React.useState('');
  const [reqtime, setReqtime] = React.useState('');
  const ontitlechagne = (e) => {
    setTitle(e.target.value);
    setRefresh((refresh) => refresh + 1);
  };
  
  const [content, setContent] = React.useState('');
  const oncontentchange = (e) => {
    setContent(e.target.value);
    setRefresh((refresh) => refresh + 1);
  }

  const _handlereq = async() => {

    const ORDERSTATUS = TYPE;
    const OLDORDERSTATUS = "";
    const REQNAME = title;
    const REQCONTENT = content;
    const u = await updateOrderstatusex({ ORDER_ID, ORDERSTATUS, OLDORDERSTATUS, REQNAME, REQCONTENT });

    useSleep(1000);

    setOpen(false);
    callback();
  }

  React.useEffect(() => {
    setTitle(title);
    setContent(content);
    const now = moment();
    setReqtime(now);

 




  }, [refresh]);

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconCloseView onClick={handleClose}>
              <AiFillCloseCircle size={30} />
            </IconCloseView>

            <div style={{fontSize:20}}>상품 {TYPE}</div>
         
            <table style={{ marginTop: 5, fontSize: 14 }}>
              <thead>
                <tr>
                  <th scope="col" style={{ width: 150 }}>
                    구분
                  </th>
                  <th scope="col" style={{ width: 300 }}>
                    내용
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="row">제목</th>
                  <td>
                    <input
                      type="text"
                      className="InputCommon2"
                      value={title}
                      onChange={ontitlechagne}
                      placeholder=""
                      style={{
                        height: "10px",
                        width :"310px",
                        fontSize: "12px",
                      }}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <th scope="row">상품명</th>
                  <td>{PRODUCTNAME}</td>
                </tr>
   
                <tr>
                  <th scope="row">{TYPE} 이유</th>
                  <td>
                    <textarea
                      placeholder={'신청 이유를 적어주세요'}
                      style={{
                        background: "#fff",
                        height: 290,
                        width: "290px",
                        resize: "none",
                        border:"1px solid #c5c5c5",
                        outline: 0,
                        padding: "5px 20px",
                        lineHeight: 2,
                        fontSize: 16,
                      }}
                      value={content}
                      onChange={oncontentchange}
                    ></textarea>
                  </td>
                </tr>   <tr>
                  <th scope="row">신청일자</th>
                  <td>{getDateFullTime(reqtime)}</td>
                </tr>
              </tbody>
     
            </table>

            <Button
              containerStyle={{
                fontWeight: 200,
                marginTop: "30px",
                marginLeft: "10%"
              }}
              Label={TYPE}
              bgcolor={"#56a9ff"}
              size={18}
              borderRadius={5}
              color={"#fff"}
              bordercolor={"#999"}
              height={50}
              width={"80%"}
              callback={_handlereq}
            />

          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
