
import React, { useState, useEffect } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

`;

const LabelItem = styled.div`
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.75rem;
  text-decoration-line: none;
  color: rgb(120, 131, 145);
`;

const OPTIONS1 = [
  { value: "주문접수", name: "주문접수" },
  { value: "주문취소", name: "주문취소" },
];

const OPTIONS2 = [
  { value: "결제완료", name: "결제완료" },
  { value: "교환신청", name: "교환신청" },
  { value: "반품신청", name: "반품신청" },
];


const OPTIONS3 = [
  { value: "배송준비", name: "배송준비" },
  { value: "주문취소", name: "주문취소" },
  { value: "교환신청", name: "교환신청" },
  { value: "반품신청", name: "반품신청" },
];

const OPTIONS4 = [
  { value: "배송중", name: "배송중" },
  { value: "교환신청", name: "교환신청" },
  { value: "반품신청", name: "반품신청" },
];

const OPTIONS5 = [
  { value: "배송완료", name: "배송완료" },
  { value: "교환신청", name: "교환신청" },
  { value: "반품신청", name: "반품신청" },
];

const OPTIONS6 = [
  { value: "제품소싱", name: "제품소싱" },
  { value: "공급요청", name: "공급요청" },
  { value: "입점제안", name: "입점제안" },
  { value: "기타제안", name: "기타제안" },
];



const SelectBox = (props) => {
  
  const onSelectChange = (e) => {
      
      
    let msg = "정말로 " + e.target.value + "하시겠습니까?";
      if (window.confirm(msg) == false) {
          e.target.value = props.OPTIONTYPE;
          return;
    }
      
    props.callback(e.target.value);
  };
  

    return (
      <select
        style={{
          fontSize: 12,
          outline: 0,
          outline: 0,
          padding: 10,
          background: "#ffab3b",
          border: "1px solid #ffab3b",
          color: "#000",
        }}
        onChange={onSelectChange}
      >
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    );
};

const SelectItem = ({
    containerStyle,
    label,
    placeholder,
    callback,
    ORDER_ID,
    OPTIONTYPE,
    ITEMS,
    item,
    
}) => {

    console.log("ORDER_ID", ORDER_ID, item);
    const [data, setData] = useState("");
    const [refresh, setRefresh] = useState(1);
    const navigate = useNavigate();
    useEffect(() => {
    async function fetchData() {}
    fetchData();
    }, []);

    const [border, setBorder] = useState(false);
    const handleFocusBorder = () => {
      setBorder(true);
    };

    const selectcallback = (data) => {
      callback(data, ORDER_ID, OPTIONTYPE, ITEMS, item);
    };

    return (
      <Container style={containerStyle}>
        <LabelItem>{label}</LabelItem>
        <div style={{ width: "100%" }}>
          {OPTIONTYPE == "주문접수" && (
            <SelectBox
              options={OPTIONS1}
              callback={selectcallback}
              OPTIONTYPE={OPTIONTYPE}
            ></SelectBox>
          )}
          {OPTIONTYPE == "결제완료" && (
            <SelectBox
              options={OPTIONS2}
              callback={selectcallback}
              OPTIONTYPE={OPTIONTYPE}
            ></SelectBox>
          )}
          {OPTIONTYPE == "배송준비" && (
            <SelectBox
              options={OPTIONS3}
              callback={selectcallback}
              OPTIONTYPE={OPTIONTYPE}
            ></SelectBox>
          )}
          {OPTIONTYPE == "배송중" && (
            <SelectBox
              options={OPTIONS4}
              callback={selectcallback}
              OPTIONTYPE={OPTIONTYPE}
            ></SelectBox>
          )}
          {OPTIONTYPE == "배송완료" && (
            <SelectBox
              options={OPTIONS5}
              callback={selectcallback}
              OPTIONTYPE={OPTIONTYPE}
            ></SelectBox>
          )}
          {OPTIONTYPE == "협업문의" && (
            <SelectBox
              options={OPTIONS6}
              callback={selectcallback}
              OPTIONTYPE={OPTIONTYPE}
            ></SelectBox>
          )}

          <div className={border == true ? "inputFocus" : "inputNoFocus"}></div>
        </div>
      </Container>
    );
};

export default SelectItem;
