import React, { useContext, useEffect } from "react";

import Layout from "../components/Layout/Layout";

import { Define } from "../components/Define";
import Itemproductcontainer from "../container/Itemproductcontainer";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../context/Users";
import moment from "moment";

const Itemproductpage = ({ pagetype }) => {
  const { state } = useLocation(); // 2번 라인
  // const {CATEGORYINFO} = state;	// 3번 라인

  const { user, dispatch } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const now = moment();
    let LoginEnableTime = moment(now).subtract(60, "minute").unix();

    console.log("LoginEnableTime", LoginEnableTime);
    console.log("LoginRealTime", user.LOGINTIME / 1000);

    if (LoginEnableTime > user.LOGINTIME / 1000) {
      alert(
        "60분간 사용하지 않아 자동 로그아웃 처리되겠습니다. 다시 로그인해주세요"
      );
      user.USERID = "";
      user.USERPW = "";
      user.USERNAME = "";
      user.USER_ID = "";
      dispatch(user);

      navigate("/memberlogin");
    }
    user.LOGINTIME = Date.now();
    dispatch(user);
    
  }, []);

  let Categoryinfo = "";
  if (state == undefined) {
    Categoryinfo = "전체";
  } else {
    Categoryinfo = state;
  }

  console.log("state", state);

  return (
    <Layout type={pagetype}>
      <Itemproductcontainer pagetype={pagetype} CATEGORYINFO={Categoryinfo} />
    </Layout>
  );
};

export default Itemproductpage;
