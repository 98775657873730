import React, { Fragment, useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import "./BestItem.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted, getDateFullTime } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import { getproductbyid } from "../../utility/productfirebase";
import Fade from "react-reveal/Fade";
import Button from "../../components/Layout/Button";
import OderViewModule from "./OrderViewModule";
import OrderViewModule from "./OrderViewModule";

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid #999;
  padding-top: 20px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ bgcolor }) => bgcolor};
`;

const Column2 = styled.div`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  padding-left: 20px;
  font-size: 14px;
  line-height: 2;
  font-weight: 700;
`;

const Column3 = styled.div`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding-left: 20px;
  font-size: 14px;
  line-height: 2;
  font-weight: 700;
`;

const SelectOrder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height};
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #d2d2d2;
`;

const SelectEnableOrder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height};
  background-color: #f3f3f3;
  color: #111010;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #aeaaaa;
`;

const CopyOrder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #bebebe;
  color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 14px;
`;
const Price = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100px;
`;

const MyorderItem = ({ containerstyle, itemdata, callback, check }) => {
  // console.log("itemdata", itemdata);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [checks, setChecks] = useState(check);

  const [product, setProduct] = useState({});
  const [optionbasketlist, setOptionbasketlist] = useState([]);

  const _handleOptionMinus = (data) => {
    if (checks) {
      alert("선택주문서에 주문된 상품입니다.선택주문 해제후에 입력해주세요");
      return;
    }
    if (data.productcount == 0) {
      return;
    }
    data.productprice -= data.productoriginprice;

    if (data.productoptionprice != "" || data.productoptionprice != 0) {
      data.productprice -= data.productoptionprice;
    }

    data.productcount = data.productcount - 1;

    setRefresh((refresh) => refresh + 1);
  };
  const _handleOptionPlus = (data) => {
    // if(qty == data.productcount ){
    //   return;
    // }
    if (checks) {
      alert("선택주문서에 주문된 상품입니다.선택주문 해제후에 입력해주세요");
      return;
    }

    // console.log("hadnleoptionplus", data);

    data.productprice += data.productoriginprice;

    if (data.productoptionprice != "" || data.productoptionprice != 0) {
      data.productprice += parseInt(
        data.productoptionprice.replaceAll(",", "")
      );
    }

    data.productcount = data.productcount + 1;
    setRefresh((refresh) => refresh + 1);
  };

  const _handleStatuschange = (status) => {};

  const _handleOrderView = () => {
    setOrderviewstatus(!orderviewstatus);
  };

  const [orderviewstatus, setOrderviewstatus] = useState(false);
  const orderviewcallback = () => {
    setOrderviewstatus(!orderviewstatus);
  };

  return (
    <Container>
      {orderviewstatus == true ? (
        <OrderViewModule
          callback={orderviewcallback}
          orderuser={itemdata.ORDERUSER}
          orderreceiveuser={itemdata.ORDERRECEIVEUSER}
          ordertel={itemdata.ORDERTEL}
          storename={itemdata.STORENAME}
          brandname={itemdata.BRANDNAME}
          receivetel={itemdata.RECEIVETEL}
          receiveaddress1={itemdata.RECEIVEADDRESS1}
          receiveaddress2={itemdata.RECEIVEADDRESS2}
          receivezonecode={itemdata.RECEIVEZONECODE}
          bankuser={itemdata.BANK_USER}
          bankname={itemdata.BANK_NAME}
          banknum={itemdata.BANK_NUM}
          carduser={itemdata.CARD_USER}
          cardnum={itemdata.CARD_NUM}
          money={itemdata.MONEY}
          registdate={itemdata.REGISTDATE}
          ordernum={itemdata.ORDERNUM}
        ></OrderViewModule>
      ) : null}

      <Row>
        <Column width="25%">
          <img
            src={itemdata.productimage}
            style={{
              width: "160px",
              height: "160px",
              marginLeft: 15,
            }}
          />
        </Column>

        <Column2 width="40%">
          <div
            style={{
              backgroundColor: "#999",
              color: "#fff",
              padding: "0px 10px",
            }}
          >
            주문번호 :{itemdata.ORDERNUM}
          </div>
          <div>주문일시 :{getDateFullTime(itemdata.REGISTDATE)}</div>
          <div>
            상품명 :{itemdata.productname.slice(0, 45)}
            {itemdata.productname.length > 45 ? "..." : null}
          </div>
          <div>
            모델명 :{itemdata.productmodelname.slice(0, 45)}
            {itemdata.productmodelname.length > 45 ? "..." : null}{" "}
          </div>
          {itemdata.productoptionprice != 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>선택옵션 : {itemdata.productoptionname}</div>
              <div style={{ marginLeft: 5 }}>
                / 옵션가격 : {CommaFormatted(itemdata.productoptionprice)}원
              </div>
            </div>
          )}

          {itemdata.productoptionprice == 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>옵션상품 아님</div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: 30,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              주문수량 : {itemdata.productcount}
            </div>
            <div style={{ marginLeft: 10 }}>
              상품공급가 : {CommaFormatted(itemdata.productprice)}원
            </div>
          </div>
        </Column2>
        <Column3 width="40%">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "230px",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              Label={"주문접수"}
              bgcolor={"#fff"}
              borderRadius={5}
              color={"#000"}
              bordercolor={"#999"}
              height={30}
              width={"100px"}
              callback={_handleStatuschange}
            />

            <Button
              Label={"주문상세보기"}
              bgcolor={"#fff"}
              borderRadius={5}
              color={"#000"}
              bordercolor={"#999"}
              height={30}
              width={"100px"}
              callback={_handleOrderView}
            />
          </div>
        </Column3>
      </Row>
    </Container>
  );
};

MyorderItem.defaultProps = {
  price: 0,
};
export default MyorderItem;
