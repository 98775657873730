import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "../components/css/common.css";
import Search from "../components/Layout/SearchBasket";
import Best from "./component/Best";
import BestItem from "./component/BestItem";
import { getproduct } from "../utility/productfirebase";
import { Define, ProductNameTag } from "../components/Define";
import { MaxPC, SmallPC } from "../components/Responsive";
import { getcategory1, getcategory2 } from "../utility/categoryfirebase";
import ItemProduct from "./component/ItemProduct";
import { imageDB } from "../components/imageDB";
import CategoryItems from "./component/CategoryItems";
import Loading from "./component/Loading";
import MainBanner from "./component/MainBanner";
import SideBanner from "./component/SideBanner";
import { UserContext } from "../context/Users";

const Label = styled.div`
  margin-top: 40px;
  font-size: 30px;
  font-family: Pretendard-Bold;
  font-weight:900;
`;
const Filter = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  align-items: center;
  margin-top: 20px;
  border-radius: 10px;
`;

const FilterItems = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  font-size: 16px;
`;
const FilterSelect = styled.div`
  font-size: 16px;
  font-weight: 900;
  text-decoration: underline;
`;

const FilterNoselect = styled.div`
  font-size: 14px;
`;
const ItemLayer = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-top: 30px;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  padding : 0px 10%;
  min-height: 500px;
  margin-bottom: 100px;
`;

const BigItemLayer = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-top: 30px;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  padding: 0px 10%;
  min-height: 500px;
  margin-bottom: 100px;
`;
const CategoryLayer = styled.div`
  background-color: #0f0f0f;
  margin: 20px 8%;
  border-radius: 5px;
  width: 80%;
  flex-direction: column;
  display: flex;
  padding: 0px 20px;
  min-height: 120px;
`;
const CategoryImageLayer = styled.div`
  margin: 20px 8%;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  padding: 0px 20px;
`;
const CategorySubLayer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #c2c2c2;
  padding: 15px 0px;
  font-size: 16px;
  width: 80%;
  flex-direction: row;
  display: flex;
`;
const CategorySub2Layer = styled.div`
  display: flex;
  flex-direction: row;
  color: #c2c2c2;
  padding: 10px 10px 10px 10px;
  font-size: 16px;
`;
const CategorySubItem = styled.div`
  background-color: ${({ select }) => (select == true ? "#bababa" : "#0f0f0f")};
  color: ${({ select }) => (select == true ? "#000" : "#c2c2c2")};
  border-radius: ${({ select }) => (select == true ? "20px" : null)};
  padding: ${({ select }) => (select == true ? "5px 20px" : "5px 0px")};
  max-width: 160px;
  min-width: 140px;
  display: flex;
  justify-content: center;
`;
const CategoryItem = styled.div`
  background-color: ${({ select }) => (select == true ? "#bababa" : "#0f0f0f")};
  color: ${({ select }) => (select == true ? "#000" : "#c2c2c2")};
  border-radius: ${({ select }) => (select == true ? "20px" : null)};
  padding: ${({ select }) => (select == true ? "5px 20px" : "5px 0px")};
`;
const NoProduct = styled.div`
  font-size: 25px;
  font-weight: 900;
`;

const Itemproductcontainer = ({ pagetype, CATEGORYINFO }) => {
  console.log("Itemproductcontainer=======>", CATEGORYINFO.CATEGORYINFO, pagetype);

  const [productitems, setProductitems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [categoryitems, setCategoryitems] = useState([]);
  const [categorysubitems, setCategorysubitems] = useState([]);

  const [category1, setCategory1] = useState(CATEGORYINFO.CATEGORYINFO);
  const [category2, setCategory2] = useState(CATEGORYINFO.CATEGORYINFO + "전체");

  const [categoryselect1, setCategoryselect1] = useState('');
  const [categoryselect2, setCategoryselect2] = useState("");
  

  const [load, setLoad] = useState(false);

  const { dispatch, user } = useContext(UserContext);


  const existcategroy = (cateogyitems, categoryname) => {
    const FindIndex = cateogyitems.findIndex((x) => x == categoryname);

    if (FindIndex == -1) {
      return false;
    } else {
      return true;
    }
  };



  const Category2make = async (data) => {
    let categorylistTmp = [];
    let categorylist = [];

    let categoryitem = {
      CATEGORY1: "",
      CATEGORY2: "",
      CATEGORYIMG: "",
      REGISTDATE: "",
    };

    setCategoryselect1(data.CATEGORY1);
    
    categoryitem.CATEGORY1 = data.CATEGORY1;
    categoryitem.CATEGORY2 = data.CATEGORY1 + "전체";

    categorylistTmp = await getcategory2(data.CATEGORY1);

    categorylist.push(categoryitem);

    categorylistTmp.forEach((element) => {
      categorylist.push(element);
    });



    if (data.CATEGORY1 == '전체') {
      CategoryItemRefresh("", "");
      
    } else {

      setCategorysubitems(categorylist);

      setCategory1(data.CATEGORY1);
         
      setCategory2(data.CATEGORY1 + "전체");
      
      CategoryItemRefresh(data.CATEGORY1, "");
    }
 
      

  };

  const CategoryItemRefresh = (categoryselect1, categoryselect2) => {
    async function fetchData() {
      let productitemstmp = [];
  
      let productitems = [];
  
      let productitemstmp2 = [];

      productitemstmp2 = await getproduct();

      productitemstmp2.map((data) => {
        if (user.USERID == '') {
          if (data.PRODUCTDISPOSE == '일반') {
            productitemstmp.push(data);
          }
        } else {
          if (user.LEVEL == '일반') {
            if (data.PRODUCTDISPOSE == '일반') {
              productitemstmp.push(data);
            }
          } else if (user.LEVEL == 'VIP') {
            if (data.PRODUCTDISPOSE == 'VIP') {
              productitemstmp.push(data);
            }
          } else if (user.LEVEL == '빅셀러') {
            if (data.PRODUCTDISPOSE == '빅셀러') {
              productitemstmp.push(data);
            }
          } 
        }
      })




      console.log(
        "CategoryItemRefresh categoryselect2",
        categoryselect1,
        categoryselect2
      );
      productitemstmp.forEach((element) => {
        if (categoryselect1 != "전체상품") {
          if (element.PRODUCTONESELECT == categoryselect1) {

            if (categoryselect2 == '' || categoryselect2.indexOf('전체') != -1) {
               productitems.push(element);
            } else {
              if (element.PRODUCTTWOSELECT == categoryselect2) {
                 productitems.push(element);
              }
            }
           
          }
        } else {
          productitems.push(element);
        } 
      });

      setProductitems(productitems);
      setLoad(false);
    }


    fetchData();
  }

  const selectCategory = async (data) => {
    let categorylistTmp = [];
    let categorylist = [];

    let categoryitem = {
      CATEGORY1: "",
      CATEGORY2: "",
      CATEGORYIMG: "",
      REGISTDATE: "",
    };
    categoryitem.CATEGORY1 = data;
    categoryitem.CATEGORY2 = data + "전체";

    const category1 = data;
    if (category1 == undefined) {
          categorylistTmp = await getcategory2(category1);

          categorylist.push(categoryitem);

          categorylistTmp.forEach((element) => {
            categorylist.push(element);
          });

          if (data != "전체") {
            setCategorysubitems(categorylist);
          } else {
            setCategorysubitems([]);
          }

          setCategory1(data);
          setCategory2(data + "전체");

          setRefresh((refresh) => refresh * -1);
    }

  };

  const _handleSubCategory = async (data) => {
    // console.log("SubCategory select", data);
    if (data.CATEGORY1 != "전체") {
      setCategory2(data.CATEGORY2);
    } else {
      setCategory2(data.CATEGORY1 + "전체");
    }

    console.log("category 2", data);

    CategoryItemRefresh(data.CATEGORY1, data.CATEGORY2);
    
    setRefresh((refresh) => refresh + 1);
  };

  // 1차 카테고리  만듬
  useEffect(() => {

    console.log("useEffect   0");
    async function fetchData() {
      let categorylistTmp = [];
      let categorylist = [];

      let categoryitem = {
        CATEGORY1: "",
        CATEGORY2: "",
        CATEGORYIMG: "",
        REGISTDATE: "",
      };
  
      categoryitem.CATEGORY1 = "전체상품";
      categorylistTmp = await getcategory1();
      categorylist.push(categoryitem);
      categorylistTmp.forEach((element) => {
        categorylist.push(element);
        if (element.CATEGORY1 == CATEGORYINFO.CATEGORYINFO) {
          console.log("dddd")
          Category2make(element);
        }
          
      });

      setCategoryitems(categorylist);

      setRefresh((refresh) => refresh + 1);
      console.log("categorylist", categorylist);
    }

    fetchData();
  }, []);

  useEffect(() => {
    setProductitems(productitems);
    setCategoryitems(categoryitems);
    setCategoryselect1(categoryselect1);
  }, [refresh]);
  
  useEffect(() => {
    //mount 시에
    // console.log("init");

    window.scrollTo(0, 0);

    return () => {
      //unmount 시에
      // console.log("dispose");
    };
  }, []);

  useEffect(() => {

    console.log("useEffect   1");

    if (CATEGORYINFO == undefined) {
      selectCategory(CATEGORYINFO.CATEGORYINFO);
    }

    
    setRefresh((refresh) => refresh + 1);
  }, []);

  useEffect(() => {
    console.log("useEffect 3", pagetype, category1);
    setLoad(true);
    async function fetchData() {
      let productitemstmp = [];
      let productitems = [];
    
      let productitemstmp2 = [];

      productitemstmp2 = await getproduct();

      productitemstmp2.map((data) => {
        if (user.USERID == '') {
          if (data.PRODUCTDISPOSE == '일반') {
            productitemstmp.push(data);
          }
        } else {
          if (user.LEVEL == '일반') {
            if (data.PRODUCTDISPOSE == '일반') {
              productitemstmp.push(data);
            }
          } else if (user.LEVEL == 'VIP') {
            if (data.PRODUCTDISPOSE == 'VIP') {
              productitemstmp.push(data);
            }
          } else if (user.LEVEL == '빅셀러') {
            if (data.PRODUCTDISPOSE == '빅셀러') {
              productitemstmp.push(data);
            }
          } 
        }
      })


      productitemstmp.forEach((element) => {
        if (pagetype == Define.allcategory) {
          if (category1 == '전체상품' || category1 =='전체' || category1==undefined) {
            productitems.push(element);
          } else {
            if (element.PRODUCTONESELECT == category1) {
              productitems.push(element);
            }
          }

        } else if (pagetype == Define.best) {
          if (existcategroy(element.PRODUCTCATEGORY, "bestcategory")) {
            productitems.push(element);
          }
        } else if (pagetype == Define.new) {
          if (existcategroy(element.PRODUCTCATEGORY, "newcategory")) {
            productitems.push(element);
          }
        } else if (pagetype == Define.md) {
          if (existcategroy(element.PRODUCTCATEGORY, "mdcategory")) {
            productitems.push(element);
          }
        } else if (pagetype == Define.gomargin) {
          if (existcategroy(element.PRODUCTCATEGORY, "gomargincategory")) {
            productitems.push(element);
          }
        } else if (pagetype == Define.top) {
          if (existcategroy(element.PRODUCTCATEGORY, "top10category")) {
            productitems.push(element);
          }
        } else if (pagetype == Define.soldout) {
          if (existcategroy(element.PRODUCTCATEGORY, "tempoutcategory")) {
            productitems.push(element);
          }
        } else if (pagetype == Define.out) {
          if (existcategroy(element.PRODUCTCATEGORY, "outcategory")) {
            productitems.push(element);
          }
        }
      });

      setProductitems(productitems);


      console.log("productitems", productitems);

      setLoad(false);
    }

    fetchData();
  }, [pagetype]);


  const navigate = useNavigate();

  return (
    <>
      {load == false ? (
        <>
          <SmallPC>
            <div className="SmallItemContainer">
              <div>
                {pagetype == Define.allcategory && (
                  <>
                    <Label>{Define.allcategory}</Label>

                    <CategoryLayer>
                      <CategorySubLayer>
                        {categoryitems.map((data, index) => (
                          <CategoryItem
                            select={data.CATEGORY1 == category1}
                            onClick={() => {
                              Category2make(data);
                            }}
                          >
                            {data.CATEGORY1}
                          </CategoryItem>
                        ))}
                      </CategorySubLayer>

                      <CategorySub2Layer>
                        {categorysubitems.map((data, index) => (
                          <>
                            {data.CATEGORY2 != "" && (
                              <CategorySubItem
                                select={data.CATEGORY2 == category2}
                                onClick={() => {
                                  _handleSubCategory(data);
                                }}
                              >
                                {data.CATEGORY2}
                              </CategorySubItem>
                            )}
                          </>
                        ))}
                      </CategorySub2Layer>
                    </CategoryLayer>
                  </>
                )}
                {pagetype == Define.best && (
                  <>
                    <Label>{Define.best}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}
                {pagetype == Define.new && (
                  <>
                    <Label>{Define.new}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}
                {pagetype == Define.md && (
                  <>
                    <Label>{Define.md}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}

                {pagetype == Define.gomargin && (
                  <>
                    <Label>{Define.gomargin}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}

                {pagetype == Define.top && (
                  <>
                    <Label>{Define.top}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}

                {pagetype == Define.soldout && (
                  <>
                    <Label>{Define.soldout}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}

                {pagetype == Define.out && (
                  <>
                    <Label>{Define.out}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}
                {pagetype == Define.heart && (
                  <>
                    <Label>{Define.heart}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}

                {productitems.length == 0 && Define.out != pagetype && (
                  <>
                    <img
                      src={imageDB.mainlogo}
                      style={{ paddingTop: 100, height: 100 }}
                    />
                    <NoProduct>상품이 존재 하지 않습니다</NoProduct>
                  </>
                )}
                <ItemLayer>
                  {productitems.map((data, index = 0) => (
                    <CategoryItems
                      product_id={data.PRODUCT_ID}
                      img={data.PRODUCTIMAGEARY[0]}
                      name={ProductNameTag + data.PRODUCTNAME}
                      select={data.PRODUCTSELECT}
                      index={index + 1}
                      price={data.PRODUCTREGULARPRICE}
                      lowprice={data.PRODUCTLOWPRICE}
                      component={data.PRODUCTCOMPONENT}
                      add={data.PRODUCT_ADD11_INFO}
                      supply={data.PRODUCTSUPPLYPRICE}
                      soldout={existcategroy(
                        data.PRODUCTCATEGORY,
                        "tempoutcategory"
                      )}
                    />
                  ))}
                </ItemLayer>
              </div>
            </div>
          </SmallPC>
          <MaxPC>
            <div className="MaxItemContainer">
              <div>
                {pagetype == Define.allcategory && (
                  <>
                    <Label>{Define.allcategory}</Label>
                    <CategoryLayer>
                      <CategorySubLayer>
                        {categoryitems.map((data, index) => (
                          <CategoryItem
                            select={data.CATEGORY1 == category1}
                            onClick={() => {
                              Category2make(data);
                            }}
                          >
                            {data.CATEGORY1}
                          </CategoryItem>
                        ))}
                      </CategorySubLayer>

                      <CategorySub2Layer>
                        {categorysubitems.map((data, index) => (
                          <>
                            {data.CATEGORY2 != "" && (
                              <CategorySubItem
                                select={data.CATEGORY2 == category2}
                                onClick={() => {
                                  _handleSubCategory(data);
                                }}
                              >
                                {data.CATEGORY2}
                              </CategorySubItem>
                            )}
                          </>
                        ))}
                      </CategorySub2Layer>
                    </CategoryLayer>
                  </>
                )}
                {pagetype == Define.best && (
                  <>
                    <Label>{Define.best}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}
                {pagetype == Define.new && (
                  <>
                    <Label>{Define.new}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}
                {pagetype == Define.md && (
                  <>
                    <Label>{Define.md}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}

                {pagetype == Define.gomargin && (
                  <>
                    <Label>{Define.gomargin}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}

                {pagetype == Define.top && (
                  <>
                    <Label>{Define.top}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}

                {pagetype == Define.soldout && (
                  <>
                    <Label>{Define.soldout}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}

                {pagetype == Define.out && (
                  <>
                    <Label>{Define.out}</Label>
                    <CategoryImageLayer>
                      <SideBanner />
                    </CategoryImageLayer>
                  </>
                )}
                <MaxPC>
                  {productitems.length == 0 && Define.out != pagetype && (
                    <>
                      <img
                        src={imageDB.mainlogo}
                        style={{ paddingTop: 100, height: 100 }}
                      />
                      <NoProduct>상품이 존재 하지 않습니다</NoProduct>
                    </>
                  )}
                  <BigItemLayer>
                    {productitems.map((data, index) => (
                      <CategoryItems
                        product_id={data.PRODUCT_ID}
                        img={data.PRODUCTIMAGEARY[0]}
                        name={ProductNameTag + data.PRODUCTNAME}
                        select={data.PRODUCTSELECT}
                        index={index}
                        price={data.PRODUCTREGULARPRICE}
                        lowprice={data.PRODUCTLOWPRICE}
                        component={data.PRODUCTCOMPONENT}
                        add={data.PRODUCT_ADD11_INFO}
                        supply={data.PRODUCTSUPPLYPRICE}
                        soldout={existcategroy(
                          data.PRODUCTCATEGORY,
                          "tempoutcategory"
                        )}
                      />
                    ))}
                  </BigItemLayer>
                </MaxPC>
              </div>
            </div>
          </MaxPC>
        </>
      ) : (
        <>
          <Loading containerStyle={{ marginTop: "10%", marginBottom: "50%" }} />
        </>
      )}
    </>
  );
};

export default Itemproductcontainer;
