import React from 'react';

import Layout from '../components/Layout/Layout';
import MemberLogincontainer from '../container/MemberLogincontainer';
import MemberLayout from '../components/Layout/MemberLayout';
import CardApproveSuccesscontainer from '../container/CardApproveSuccesscontainer';
import { useSearchParams } from 'react-router-dom';


const CardApprovalSuccesspage = () => {

  const [searchParams, setSearchParams] = useSearchParams();




  let orderid = searchParams.get("orderId");
  let paymentKey = searchParams.get("paymentKey");

  let amount = searchParams.get("amount");

  return (
    <MemberLayout>
      <CardApproveSuccesscontainer orderid={orderid} paymentKey={paymentKey} amount={amount} />
    </MemberLayout>

  );
}

export default CardApprovalSuccesspage;
