import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/PageEx";
import { getBest } from "../../utility/bestfirebase";
import SubLabel from "./SubLabel";
import Fade from "react-reveal/Fade";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { getMd } from "../../utility/mdfirebase";
import BestItem2 from "./BestItem2";
import { MaxPC, SmallPC } from "../../components/Responsive";
import { getproduct } from "../../utility/productfirebase";
import { ProductNameTag } from "../../components/Define";
import AllItem from "./AllItem";
import { UserContext } from "../../context/Users";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]); // *

const swiperStyle = {
  position: "relative",
  width: "100%",
};

const Searchproduct = ({ search }) => {
  const [searchitems, setSearchitems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const { dispatch, user } = useContext(UserContext);

  useEffect(() => {
    // console.log("searchitems start");
    async function fetchData() {
      let searchlist = [];
      let searchItem = [];

      let productitemstmp2 = [];

      productitemstmp2 = await getproduct();

      productitemstmp2.map((data) => {
        if (user.USERID == '') {
          if (data.PRODUCTDISPOSE == '일반') {
            searchlist.push(data);
          }
        } else {
          if (user.LEVEL == '일반') {
            if (data.PRODUCTDISPOSE == '일반') {
              searchlist.push(data);
            }
          } else if (user.LEVEL == 'VIP') {
            if (data.PRODUCTDISPOSE == 'VIP') {
              searchlist.push(data);
            }
          } else if (user.LEVEL == '빅셀러') {
            if (data.PRODUCTDISPOSE == '빅셀러') {
              searchlist.push(data);
            }
          } 
        }
      })

      let Finds = searchlist.filter(
        (x) => x.PRODUCTNAME.toUpperCase().indexOf(search.toUpperCase()) != -1
      );
      Finds.map((data) => {
        searchItem.push(data);
      });

      setSearchitems(searchItem);
      // console.log("searchitems", searchitems);
    }

    fetchData();
  }, [search]);

  return (
    <div style={{ display: "flex", marginTop: 20, flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          height: 100,
          flexDirection: "column",
          alignItems: "flex-start",
          alignItems: "center",
        }}
      >
        <Label
          name={"검색 상품 [검색갯수] " + searchitems.length + "개"}
          weight={800}
        />
        <div style={{ height: 20 }} />
        {search == "" ? (
          <SubLabel
            name={"머레이의 모든판매 상품를 검색한 결과입니다"}
            weight={100}
            color={"#999"}
          />
        ) : (
          <SubLabel
            name={
              "머레이의 모든판매 상품에서 [" + search + "]로 검색한 결과입니다"
            }
            weight={100}
            color={"#999"}
          />
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            padding: "20px 0px",
            backgroundColor: "white",
            justifyContent: "space-between",
          }}
        >
          {searchitems.map((data, index = 0) => (
            <AllItem
              column={1}
              index={index}
              product_id={data.PRODUCT_ID}
              img={data.PRODUCTIMAGEARY[0]}
              name={ProductNameTag + data.PRODUCTNAME}
              select={data.PRODUCTSELECT}
              price={data.PRODUCTREGULARPRICE}
              lowprice={data.PRODUCTLOWPRICE}
              component={data.PRODUCTCOMPONENT}
              add={data.PRODUCT_ADD11_INFO}
            />
          ))}
        </div>
      </div>

      {/* <MaxPC>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              padding: "20px 0px",
              backgroundColor: "white",
            }}
          >
            {searchitems.map((data, index = 0) => (
              <AllItem
                column={1}
                index={index}
                product_id={data.PRODUCT_ID}
                img={data.PRODUCTIMAGEARY[0]}
                name={ProductNameTag + data.PRODUCTNAME}
                select={data.PRODUCTSELECT}
                price={data.PRODUCTREGULARPRICE}
                lowprice={data.PRODUCTLOWPRICE}
                component={data.PRODUCTCOMPONENT}
                add={data.PRODUCT_ADD11_INFO}
              />
            ))}
          </div>
        </div>
      </MaxPC>
      <SmallPC>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              padding: "20px 0px",
              backgroundColor: "white",
            }}
          >
            {searchitems.map((data, index = 0) => (
              <AllItem
                column={1}
                index={index}
                product_id={data.PRODUCT_ID}
                img={data.PRODUCTIMAGEARY[0]}
                name={ProductNameTag + data.PRODUCTNAME}
                select={data.PRODUCTSELECT}
                price={data.PRODUCTREGULARPRICE}
                lowprice={data.PRODUCTLOWPRICE}
                component={data.PRODUCTCOMPONENT}
                add={data.PRODUCT_ADD11_INFO}
              />
            ))}
          </div>
        </div>
      </SmallPC> */}
    </div>
  );
};

export default Searchproduct;
