import React, { useState, useEffect, useContext } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import Text from "./Text";
import { getDateFullTime } from "../../utility/common";
import { CiLock } from "react-icons/ci";
import { UserContext } from "../../context/Users";
import { readnotice } from "../../utility/askfirebase";

const Container = styled.div``;

const QuestionLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #ededed;
  height: 50px;
  align-items: center;
`;
const QuestionItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
`;
const QuestionButtonLayer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
`;

const AnswerLine = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ededed;
  padding: 20px 10px;
  margin-top: 5px;
  min-height: 300px;
  align-items: flex-start;
`;

const Unread = styled.div`
    background: #fd6a0df2;
    border-radius: 5px;
    padding: 5px 10px;
    color: #fff;
    margin-left: 10px;
    display: flex;
    font-size: 12px;
    flex-direction: column;
`
const Question2 = ({ containerStyle, items, index, notice, html }) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [check, setCheck] = useState(false);

  const { user, dispatch2 } = useContext(UserContext);

  function IsContainNoticeid(items) {
    console.log("IsContainNoticeid", items, user.USERID);
    const FindIndex = items.READ.findIndex((x) => x == user.USERID);

    if (FindIndex != -1) {
      console.log("읽음");

      return true;
    } else {
      console.log("읽지 않음");
      return false;
    }
  }


  function IsMyAskid(item) {

    console.log("IsMyAskid", item);
  
    if (item.USERID != user.USERID) {
  
      return false;
    } else {

      return true;
    }
  }
  const _handleNoticeCheck = async(items) => {
    setCheck(!check);

    if (!IsContainNoticeid(items)) {
      
      const NOTICE_ID = items.NOTICE_ID;
      const READ = items.READ;
      const USERID = user.USERID;
      const updateRead = await readnotice({ NOTICE_ID, READ, USERID });

      items.READ.push(user.USERID);
    }

    setRefresh((refresh) => refresh + 1);
  };


  const _handleCheck = () => {
    setCheck(!check);

    setRefresh((refresh) => refresh + 1);
  };

  useEffect(() => {
    async function fetchData() {}
    fetchData();
  }, []);

  useEffect(() => {
    setCheck(check);
  }, [refresh]);



  return (
    <Container style={containerStyle}>
      {notice == true ? (
        <table class="bluetop" style={{ overflow: "auto", width: "100%" }}>
          <tr style={{ height: 35 }}>
            <th style={{ maxWidth: 50 }}>No</th>
            <th style={{ width: "70%" }}>제목</th>
            <th>게시일자</th>
          </tr>
          <tbody>
            {items.map((data, index) => (
              <>
                <tr>
                  <td style={{ maxWidth: 50, fontSize: 16 }}>{index + 1}</td>
                  <td style={{ width: "70%" }}>
                    <div style={{ marginLeft: 5, display: "flex" }}>
                      <span style={{ fontSize: 16 }}>
                        {data.NOTICE.slice(0, 90)}
                        {data.NOTICE.length > 90 ? "..." : null}
                      </span>
                      {IsContainNoticeid(data) == false && (
                        <div className="unreadblink">{"읽지않음"}</div>
                      )}
                      {check == false && (
                        <div
                          style={{
                            marginLeft: 20,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <SlArrowDown
                            onClick={() => {
                              _handleNoticeCheck(data);
                            }}
                          />
                        </div>
                      )}
                      {check == true && (
                        <div
                          style={{
                            marginLeft: 20,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <SlArrowUp
                            onClick={() => {
                              _handleNoticeCheck(data);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div style={{ marginRight: 20, fontSize: 16 }}>
                      <Text
                        value={getDateFullTime(items.REGISTDATE)}
                        size={14}
                      />
                    </div>
                  </td>
                </tr>
                <>
                  {check == true && (
                    <tr>
                      <td>
                        {notice == false && (
                          <div style={{ marginBottom: 20 }}>
                            작성자 : {items.CEONAME}
                          </div>
                        )}

                        {html == true ? (
                          <div
                            style={{
                              padding: "5px 0px",
                              backgroundColor: "#fff",
                            }}
                            dangerouslySetInnerHTML={{ __html: items.CONTENT }}
                          ></div>
                        ) : (
                          <div>
                            <textarea
                              value={items.CONTENT}
                              size={14}
                              disabled
                              color={"#999"}
                              style={{
                                width: "100%",
                                outline: 0,
                                resize: "none",
                                border: "none",
                                height: 300,
                              }}
                            />
                            {items.ANSWER && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: 10,
                                  }}
                                >
                                  답변글
                                </div>
                                <textarea
                                  value={items.ANSWER}
                                  size={14}
                                  disabled
                                  color={"#999"}
                                  style={{
                                    width: "100%",
                                    outline: 0,
                                    resize: "none",
                                    border: "none",
                                    height: 300,
                                    marginTop: 10,
                                  }}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                </>
              </>
            ))}
          </tbody>
        </table>
      ) : null}
    </Container>
  );
};

export default Question2;
