import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "../components/css/common.css";
import Search from "../components/Layout/SearchBasket";
import Best from "./component/Best";
import BestItem from "./component/BestItem";
import { getproduct } from "../utility/productfirebase";
import { Define, ProductNameTag } from "../components/Define";
import { MaxPC, SmallPC } from "../components/Responsive";
import { getcategory1, getcategory2 } from "../utility/categoryfirebase";
import ItemProduct from "./component/ItemProduct";
import { imageDB } from "../components/imageDB";
import CategoryItems from "./component/CategoryItems";
import Loading from "./component/Loading";
import Question from "./component/Question";
import Button from "../components/Layout/Button";
import { getAsk, getnotice } from "../utility/askfirebase";
import Question2 from "./component/Question2";
import PageEx from "../components/Layout/PageEx";

const Tag = styled.div`
  font-size: 20px;
  color: ${({ enable }) => (enable == true ? "#000" : "#999")};
  font-weight: 700;
  border-bottom: ${({ enable }) =>
    enable == true ? "2px solid #000" : "1px solid #999"};
  width: 33.3%;
  height: 50px;
`;

const Label = styled.div`
  margin-top: 40px;
  font-size: 30px;
  font-family: Pretendard-Bold;
  font-weight: 900;
`;

const QuestionLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 30px;
`;

const NoProduct = styled.div`
  font-size: 25px;
  font-weight: 900;
`;

const Noticecontainer = ({ pagetype }) => {
  const [refresh, setRefresh] = useState(1);
  const [load, setLoad] = useState(false);
  const [mode, setMode] = useState(0);

  const [noticeitems, setNoticeitems] = useState([]);
  const [noticeitems2, setNoticeitems2] = useState([]);
  const [noticeitems3, setNoticeitems3] = useState([]);
  const [noticeitems1, setNoticeitems1] = useState([]);


  const [noticeoriginitems, setNoticeoriginitems] = useState([]);
  const [noticeoriginitems2, setNoticeoriginitems2] = useState([]);
  const [noticeoriginitems3, setNoticeoriginitems3] = useState([]);
  const [noticeoriginitems1, setNoticeoriginitems1] = useState([]);


  const [position, setPosition] = useState(1);
  const [position1, setPosition1] = useState(1);
  const [position2, setPosition2] = useState(1);
  const [position3, setPosition3] = useState(1);

  const [page, setPage] = useState(1);
  const [page1, setPage1] = useState(1);

  const [page2, setPage2] = useState(1);

  const [page3, setPage3] = useState(1);



  const [pageupdate, setPageupdate] = useState(1);
  const [pageupdate1, setPageupdate1] = useState(1);
  const [pageupdate2, setPageupdate2] = useState(1);
  const [pageupdate3, setPageupdate3] = useState(1);



  useEffect(() => {
    async function FetchData() {
      const askitemsTmp = await getAsk();
      const noticeitems1Tmp = [];
      const noticeitems2Tmp = [];
      const noticeitems3Tmp = [];

      let count = 0;
      askitemsTmp.map((data, index) => {

        count++;
        if (
          count <= (page * 20 || askitemsTmp.length) &&
          count > (page - 1) * 20
        ) {
          if (data.ASKTYPE == "COOPERATION") {
            noticeitems1Tmp.push(data);
          } else if (data.ASKTYPE == "PRODUCT") {
            noticeitems2Tmp.push(data);
          } else if (data.ASKTYPE == "ETC") {
            noticeitems3Tmp.push(data);
          }
        }


          
      });
      console.log("noticeitems", askitemsTmp);
      setNoticeitems1(noticeitems1Tmp);
      setNoticeitems2(noticeitems2Tmp);
      setNoticeitems3(noticeitems3Tmp);

      setNoticeoriginitems1(noticeitems1Tmp);
      setNoticeoriginitems2(noticeitems2Tmp);
      setNoticeoriginitems3(noticeitems3Tmp);

      const noticeitemsTmp = await getnotice();

      console.log("noticeitemsTemp", noticeitemsTmp);

      setNoticeoriginitems(noticeitemsTmp);
      setNoticeitems(noticeitemsTmp);
      
    }
    FetchData();
  }, []);

  useEffect(() => {
    //mount 시에
    console.log("init");
    window.scrollTo(0, 0);
    return () => {
      //unmount 시에
      console.log("dispose");
    };
  }, []);

  useEffect(() => {
    setMode(mode);
  }, [refresh]);

  const _handleSwitch = (mode) => {
    setMode(mode);
    setRefresh((refresh) => refresh + 1);
  };

  const navigate = useNavigate();

  const noticeitem3 = [];

  const _handlewrite = () => {
    navigate("/noticewrite", { state: { mode: mode } });
  };

  const _handlepage = async (page) => {
    console.log("_handlepage", page, position);

    if (mode == 0) {
      setNoticeitems([]);

      if (page == "next") {
        setPosition(position + 20);
        setPage(position + 20);
      } else if (page == "prev") {
        setPosition(position - 20);
        setPage(position - 20);
      } else {
        setPage(page);
      }

    }


    if (mode == 1) {
      setNoticeitems1([]);

      if (page1 == "next") {
        setPosition1(position1 + 20);
        setPage1(position1 + 20);
      } else if (page1 == "prev") {
        setPosition1(position1 - 20);
        setPage1(position1 - 20);
      } else {
        setPage1(page1);
      }
    }


    if (mode == 2) {
      setNoticeitems2([]);

      if (page2 == "next") {
        setPosition2(position2 + 20);
        setPage2(position2 + 20);
      } else if (page2 == "prev") {
        setPosition2(position2 - 20);
        setPage2(position2 - 20);
      } else {
        setPage2(page2);
      }
    }


    if (mode == 3) {
      setNoticeitems3([]);


      if (page3 == "next") {
        setPosition3(position3 + 20);
        setPage3(position3 + 20);
      } else if (page3 == "prev") {
        setPosition3(position3 - 20);
        setPage3(position3 - 20);
      } else {
        setPage3(page3);
      }
    }





    if (mode == 0) {
      setPageupdate((pageupdate) => pageupdate + 1);
    }


    if (mode == 1) {


      setPageupdate1((pageupdate1) => pageupdate1 + 1);
    }


    if (mode == 2) {
      setPageupdate2((pageupdate2) => pageupdate2 + 1);
    }

    if (mode == 3) {
      setPageupdate3((pageupdate3) => pageupdate3 + 1);
    }


  
 
  };

  useEffect(() => {

    setNoticeoriginitems(noticeoriginitems);
    setPage(page);

    async function fetchData() {
      let noticelist = [];

      let count = 0;
      noticeoriginitems.forEach((element) => {
        count++;
        if (
          count <= (page * 20 || noticeoriginitems.length) &&
          count > (page - 1) * 20
        ) {
          noticelist.push(element);
        }
      });
      setNoticeitems(noticelist);
    }
    fetchData();

  }, [pageupdate]);

  useEffect(() => {

    setNoticeoriginitems1(noticeoriginitems1);
    setPage(page);

    async function fetchData() {
      let noticelist = [];

      let count = 0;
      noticeoriginitems1.forEach((element) => {
        count++;
        if (
          count <= (page * 20 || noticeoriginitems1.length) &&
          count > (page - 1) * 20
        ) {
          noticelist.push(element);
        }
      });
      setNoticeitems1(noticelist);
    }
    fetchData();

  }, [pageupdate1]);

  useEffect(() => {

    setNoticeoriginitems2(noticeoriginitems2);
    setPage(page);

    async function fetchData() {
      let noticelist = [];

      let count = 0;
      noticeoriginitems2.forEach((element) => {
        count++;
        if (
          count <= (page * 20 || noticeoriginitems2.length) &&
          count > (page - 1) * 20
        ) {
          noticelist.push(element);
        }
      });
      setNoticeitems2(noticelist);
    }
    fetchData();

  }, [pageupdate2]);

  useEffect(() => {

    setNoticeoriginitems3(noticeoriginitems3);
    setPage(page);

    async function fetchData() {
      let noticelist = [];

      let count = 0;
      noticeoriginitems3.forEach((element) => {
        count++;
        if (
          count <= (page * 20 || noticeoriginitems3.length) &&
          count > (page - 1) * 20
        ) {
          noticelist.push(element);
        }
      });
      setNoticeitems3(noticelist);
    }
    fetchData();

  }, [pageupdate3]);



  return (
    <>
      {load == false ? (
        <>
          <SmallPC>
            <div
              className="SmallItemContainer"
              style={{
                minHeight: 600,
                justifyContent: "unset",
                width: "80%",
                padding: "0px 10%",
              }}
            >
              <Label>{"공지/제안/문의"}</Label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "50px",
                  justifyContent: "space-between",
                }}
              >
                {mode == 0 ? (
                  <Tag
                    onClick={() => {
                      _handleSwitch(0);
                    }}
                    enable={true}
                  >
                    공지사항
                  </Tag>
                ) : (
                  <Tag
                    onClick={() => {
                      _handleSwitch(0);
                    }}
                    enale={false}
                  >
                    공지사항
                  </Tag>
                )}

                {mode == 1 ? (
                  <Tag
                    onClick={() => {
                      _handleSwitch(1);
                    }}
                    enable={true}
                  >
                    협업 제안
                  </Tag>
                ) : (
                  <Tag
                    onClick={() => {
                      _handleSwitch(1);
                    }}
                    enale={false}
                  >
                    협업 제안
                  </Tag>
                )}

                {mode == 2 ? (
                  <Tag
                    onClick={() => {
                      _handleSwitch(2);
                    }}
                    enable={true}
                  >
                    상품 문의
                  </Tag>
                ) : (
                  <Tag
                    onClick={() => {
                      _handleSwitch(2);
                    }}
                    enale={false}
                  >
                    상품 문의
                  </Tag>
                )}
                {mode == 3 ? (
                  <Tag
                    onClick={() => {
                      _handleSwitch(3);
                    }}
                    enable={true}
                  >
                    기타 문의
                  </Tag>
                ) : (
                  <Tag
                    onClick={() => {
                      _handleSwitch(3);
                    }}
                    enale={false}
                  >
                    기타 문의
                  </Tag>
                )}
              </div>

              <QuestionLayout>
                {mode == 0 && (
                  <>
                    <table
                      class="bluetop"
                      style={{ overflow: "auto", width: "100%" }}
                    >
                      <tr style={{ height: 35 }}>
                        <th style={{ maxWidth: 50 }}>No</th>
                        <th style={{ width: "70%" }}>제목</th>
                        <th>게시일자</th>
                      </tr>
                    </table>

                    {noticeitems.length > 0 ? (
                      <>
                        {noticeitems.map((item, index) => (
                          <Question
                            index={index}
                            items={item}
                            html={true}
                            notice={true}
                          ></Question>
                        ))}

                        <div style={{ marginTop: 10, marginBottom: 15 }}>
                          <PageEx
                            count={noticeitems.length}
                            position={position}
                            callback={_handlepage}
                          />
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <img
                            src={imageDB.mainlogo}
                            style={{
                              paddingTop: 100,
                              height: "100px",
                              width: "300px",
                            }}
                          />
                        </div>

                        <NoProduct>공지사항이 존재 하지 않습니다</NoProduct>
                      </div>
                    )}
                  </>
                )}
                {mode == 1 && (
                  <>
                    <table
                      class="bluetop"
                      style={{ overflow: "auto", width: "100%" }}
                    >
                      <tr style={{ height: 35 }}>
                        <th style={{ width: "10%" }}>No</th>
                        <th style={{ width: "10%" }}>카테고리</th>
                        <th style={{ width: "40%" }}>제목</th>
                        <th style={{ width: "10%" }}>작성자</th>
                        <th style={{ width: "10%" }}>문의상태</th>
                        <th style={{ width: "20%" }}>게시일자</th>
                      </tr>
                    </table>

                    {noticeitems1.length > 0 ? (
                      <>
                        {noticeitems1.map((item, index) => (
                          <Question
                            index={index}
                            items={item}
                            notice={false}
                          ></Question>
                        ))}
                        <div style={{ marginTop: 10, marginBottom: 15 }}>
                          <PageEx
                            count={noticeitems1.length}
                            position={position1}
                            callback={_handlepage}
                          />
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <img
                            src={imageDB.mainlogo}
                            style={{
                              paddingTop: 100,
                              height: "100px",
                              width: "300px",
                            }}
                          />
                        </div>

                        <NoProduct>협업문의가 존재 하지 않습니다</NoProduct>
                      </div>
                    )}
                  </>
                )}
                {mode == 2 && (
                  <>
                    <table
                      class="bluetop"
                      style={{ overflow: "auto", width: "100%" }}
                    >
                      <tr style={{ height: 35 }}>
                        <th style={{ width: "10%" }}>No</th>
                        <th style={{ width: "10%" }}>카테고리</th>
                        <th style={{ width: "40%" }}>제목</th>
                        <th style={{ width: "10%" }}>작성자</th>
                        <th style={{ width: "10%" }}>문의상태</th>
                        <th style={{ width: "20%" }}>게시일자</th>
                      </tr>
                    </table>
                    {noticeitems2.length > 0 ? (
                      <>
                        {noticeitems2.map((item, index) => (
                          <Question
                            index={index}
                            items={item}
                            notice={false}
                          ></Question>
                        ))}
                        <div style={{ marginTop: 10, marginBottom: 15 }}>
                          <PageEx
                            count={noticeitems2.length}
                            position={position2}
                            callback={_handlepage}
                          />
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <img
                            src={imageDB.mainlogo}
                            style={{
                              paddingTop: 100,
                              height: "100px",
                              width: "300px",
                            }}
                          />
                        </div>

                        <NoProduct>상품문의가 존재 하지 않습니다</NoProduct>
                      </div>
                    )}
                  </>
                )}
                {mode == 3 && (
                  <>
                    <table
                      class="bluetop"
                      style={{ overflow: "auto", width: "100%" }}
                    >
                      <tr style={{ height: 35 }}>
                        <th style={{ width: "10%" }}>No</th>
                        <th style={{ width: "10%" }}>카테고리</th>
                        <th style={{ width: "40%" }}>제목</th>
                        <th style={{ width: "10%" }}>작성자</th>
                        <th style={{ width: "10%" }}>문의상태</th>
                        <th style={{ width: "20%" }}>게시일자</th>
                      </tr>
                    </table>
                    {noticeitems3.length > 0 ? (
                      <>
                        {noticeitems3.map((item, index) => (
                          <Question
                            index={index}
                            items={item}
                            notice={false}
                          ></Question>
                        ))}
                        <div style={{ marginTop: 10, marginBottom: 15 }}>
                          <PageEx
                            count={noticeitems3.length}
                            position={position3}
                            callback={_handlepage}
                          />
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <img
                            src={imageDB.mainlogo}
                            style={{
                              paddingTop: 100,
                              height: "100px",
                              width: "300px",
                            }}
                          />
                        </div>

                        <NoProduct>기타문의가 존재 하지 않습니다</NoProduct>
                      </div>
                    )}
                  </>
                )}
              </QuestionLayout>
              <div
                style={{
                  marginTop: 50,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginBottom: 20,
                }}
              >
                {mode == 1 && (
                  <Button
                    callback={_handlewrite}
                    Label={"협의문의하기"}
                    bgcolor={"#689fff"}
                    borderRadius={10}
                    color={"#fff"}
                    bordercolor={"#689fff"}
                    height={50}
                    width={"20%"}
                  />
                )}
                {mode == 2 && (
                  <Button
                    callback={_handlewrite}
                    Label={"상품문의하기"}
                    bgcolor={"#689fff"}
                    borderRadius={10}
                    color={"#fff"}
                    bordercolor={"#689fff"}
                    height={50}
                    width={"20%"}
                  />
                )}
                {mode == 3 && (
                  <Button
                    callback={_handlewrite}
                    Label={"기타문의하기"}
                    bgcolor={"#689fff"}
                    borderRadius={10}
                    color={"#fff"}
                    bordercolor={"#689fff"}
                    height={50}
                    width={"20%"}
                  />
                )}
              </div>
            </div>
          </SmallPC>
          <MaxPC>
            <div
              className="MaxItemContainer"
              style={{
                minHeight: 600,
                justifyContent: "unset",
                width: "80%",
                padding: "0px 10%",
              }}
            >
              <Label>{"공지/제안/문의"}</Label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "50px",
                  justifyContent: "space-between",
                }}
              >
                {mode == 0 ? (
                  <Tag
                    onClick={() => {
                      _handleSwitch(0);
                    }}
                    enable={true}
                  >
                    공지사항
                  </Tag>
                ) : (
                  <Tag
                    onClick={() => {
                      _handleSwitch(0);
                    }}
                    enale={false}
                  >
                    공지사항
                  </Tag>
                )}

                {mode == 1 ? (
                  <Tag
                    onClick={() => {
                      _handleSwitch(1);
                    }}
                    enable={true}
                  >
                    협업 제안
                  </Tag>
                ) : (
                  <Tag
                    onClick={() => {
                      _handleSwitch(1);
                    }}
                    enale={false}
                  >
                    협업 제안
                  </Tag>
                )}

                {mode == 2 ? (
                  <Tag
                    onClick={() => {
                      _handleSwitch(2);
                    }}
                    enable={true}
                  >
                    상품 문의
                  </Tag>
                ) : (
                  <Tag
                    onClick={() => {
                      _handleSwitch(2);
                    }}
                    enale={false}
                  >
                    상품 문의
                  </Tag>
                )}
                {mode == 3 ? (
                  <Tag
                    onClick={() => {
                      _handleSwitch(3);
                    }}
                    enable={true}
                  >
                    기타 문의
                  </Tag>
                ) : (
                  <Tag
                    onClick={() => {
                      _handleSwitch(3);
                    }}
                    enale={false}
                  >
                    기타 문의
                  </Tag>
                )}
              </div>

              <QuestionLayout>
                {mode == 0 && (
                  <>
                    <table
                      class="bluetop"
                      style={{ overflow: "auto", width: "100%" }}
                    >
                      <tr style={{ height: 35 }}>
                        <th style={{ maxWidth: 50 }}>No</th>
                        <th style={{ width: "70%" }}>제목</th>
                        <th>게시일자</th>
                      </tr>
                    </table>

                    {noticeitems.length > 0 ? (
                      <>
                        {noticeitems.map((item, index) => (
                          <Question
                            index={index}
                            items={item}
                            html={true}
                            notice={true}
                          ></Question>
                        ))}

                        <div style={{ marginTop: 10, marginBottom: 15 }}>
                          <PageEx
                            count={noticeitems.length}
                            position={position}
                            callback={_handlepage}
                          />
                        </div>

                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <img
                            src={imageDB.mainlogo}
                            style={{
                              paddingTop: 100,
                              height: "100px",
                              width: "300px",
                            }}
                          />
                        </div>

                        <NoProduct>공지사항이 존재 하지 않습니다</NoProduct>
                      </div>
                    )}
                  </>
                )}
                {mode == 1 && (
                  <>
                    <table
                      class="bluetop"
                      style={{ overflow: "auto", width: "100%" }}
                    >
                      <tr style={{ height: 35 }}>
                        <th style={{ width: "10%" }}>No</th>
                        <th style={{ width: "10%" }}>카테고리</th>
                        <th style={{ width: "40%" }}>제목</th>
                        <th style={{ width: "10%" }}>작성자</th>
                        <th style={{ width: "10%" }}>문의상태</th>
                        <th style={{ width: "20%" }}>게시일자</th>
                      </tr>
                    </table>

                    {noticeitems1.length > 0 ? (
                      <>
                        {noticeitems1.map((item, index) => (
                          <Question
                            index={index}
                            items={item}
                            notice={false}
                          ></Question>
                        ))}

                        <div style={{ marginTop: 10, marginBottom: 15 }}>
                          <PageEx
                            count={noticeitems1.length}
                            position={position1}
                            callback={_handlepage}
                          />
                        </div>
                        
                      </>

                      
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <img
                            src={imageDB.mainlogo}
                            style={{
                              paddingTop: 100,
                              height: "100px",
                              width: "300px",
                            }}
                          />
                        </div>

                        <NoProduct>협업문의가 존재 하지 않습니다</NoProduct>
                      </div>
                    )}
                  </>
                )}
                {mode == 2 && (
                  <>
                    <table
                      class="bluetop"
                      style={{ overflow: "auto", width: "100%" }}
                    >
                      <tr style={{ height: 35 }}>
                        <th style={{ width: "10%" }}>No</th>
                        <th style={{ width: "10%" }}>카테고리</th>
                        <th style={{ width: "40%" }}>제목</th>
                        <th style={{ width: "10%" }}>작성자</th>
                        <th style={{ width: "10%" }}>문의상태</th>
                        <th style={{ width: "20%" }}>게시일자</th>
                      </tr>
                    </table>
                    {noticeitems2.length > 0 ? (
                      <>
                        {noticeitems2.map((item, index) => (
                          <Question
                            index={index}
                            items={item}
                            notice={false}
                          ></Question>
                        ))}

                        <div style={{ marginTop: 10, marginBottom: 15 }}>
                          <PageEx
                            count={noticeitems2.length}
                            position={position2}
                            callback={_handlepage}
                          />
                        </div>

                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <img
                            src={imageDB.mainlogo}
                            style={{
                              paddingTop: 100,
                              height: "100px",
                              width: "300px",
                            }}
                          />
                        </div>

                        <NoProduct>상품문의가 존재 하지 않습니다</NoProduct>
                      </div>
                    )}
                  </>
                )}
                {mode == 3 && (
                  <>
                    <table
                      class="bluetop"
                      style={{ overflow: "auto", width: "100%" }}
                    >
                      <tr style={{ height: 35 }}>
                        <th style={{ width: "10%" }}>No</th>
                        <th style={{ width: "10%" }}>카테고리</th>
                        <th style={{ width: "40%" }}>제목</th>
                        <th style={{ width: "10%" }}>작성자</th>
                        <th style={{ width: "10%" }}>문의상태</th>
                        <th style={{ width: "20%" }}>게시일자</th>
                      </tr>
                    </table>
                    {noticeitems3.length > 0 ? (
                      <>
                        {noticeitems3.map((item, index) => (
                          <Question
                            index={index}
                            items={item}
                            notice={false}
                          ></Question>
                        ))}

                        <div style={{ marginTop: 10, marginBottom: 15 }}>
                          <PageEx
                            count={noticeitems3.length}
                            position={position3}
                            callback={_handlepage}
                          />
                        </div>

                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <img
                            src={imageDB.mainlogo}
                            style={{
                              paddingTop: 100,
                              height: "100px",
                              width: "300px",
                            }}
                          />
                        </div>

                        <NoProduct>기타문의가 존재 하지 않습니다</NoProduct>
                      </div>
                    )}
                  </>
                )}
              </QuestionLayout>
              <div
                style={{
                  marginTop: 50,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginBottom: 20,
                }}
              >
                {mode == 1 && (
                  <Button
                    callback={_handlewrite}
                    Label={"협의문의하기"}
                    bgcolor={"#689fff"}
                    borderRadius={10}
                    color={"#fff"}
                    bordercolor={"#689fff"}
                    height={50}
                    width={"20%"}
                  />
                )}
                {mode == 2 && (
                  <Button
                    callback={_handlewrite}
                    Label={"상품문의하기"}
                    bgcolor={"#689fff"}
                    borderRadius={10}
                    color={"#fff"}
                    bordercolor={"#689fff"}
                    height={50}
                    width={"20%"}
                  />
                )}
                {mode == 3 && (
                  <Button
                    callback={_handlewrite}
                    Label={"기타문의하기"}
                    bgcolor={"#689fff"}
                    borderRadius={10}
                    color={"#fff"}
                    bordercolor={"#689fff"}
                    height={50}
                    width={"20%"}
                  />
                )}
              </div>
            </div>
      
          </MaxPC>
        </>
      ) : (
        <>
          <Loading containerStyle={{ marginTop: "10%", marginBottom: "50%" }} />
        </>
      )}
    </>
  );
};

export default Noticecontainer;
