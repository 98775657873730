import React, { useEffect } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/PageEx";
import { useNavigate } from "react-router-dom";

const MainItem = ({ ItemText }) => {
  const navigate = useNavigate();

  const _handlebasket = () => {
    navigate("/basket");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#323232",
        color: "#fff",
        height: "50px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        alignItems: "center",
        paddingLeft: "20px",
        justifyContent: "flex-start",
        marginTop: 20,
      }}
    >
      {ItemText}
    </div>
  );
};

export default MainItem;
