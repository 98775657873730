import React, { useContext, useEffect, useState } from "react";
import Homecontainer from "../container/Homecontainer";
import Layout from "../components/Layout/Layout";
import { BannerContext } from "../context/Banner";
import { getSideBanner } from "../utility/bannerfirebase";
import Homelayout from "../components/Layout/Homelayout";
import { useLocation } from "react-router-dom";
import Searchcontainer from "../container/Searchcontainer";

const Searchpage = () => {
  const { banner, dispatch2 } = useContext(BannerContext);
  const [refresh, setRefresh] = useState(1);
  const [move, setMove] = useState(false);

  const { state } = useLocation(); // 2번 라인

  console.log("search", state.SEARCH);

  return (
    <Homelayout>
      <Searchcontainer search={state.SEARCH} />
    </Homelayout>
  );
};

export default Searchpage;
