import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import Label from "./Label";
import Button from "../../components/Layout/Button";
import CardType from "./CardType";
import CardNum from "./CardNum";
import CardExpire from "./CardExpire";
import CardPassword from "./CardPassword";
import { CommaFormatted } from "../../utility/common";
import { cardpay } from "../../utility/api";
import CardUser from "./CardUser";
import CardJumin from "./CardJumin";
import { loadTossPayments } from '@tosspayments/payment-sdk';

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  height: "650px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  padding: "10px",
};
const IconCloseView = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Row = styled.div`
  display: flex;
  padding-left: 10px;
  height: 35px;
  align-items: center;
  background-color: #828282;
  margin: 5px;
`;

export default function CardModule({ callback, money, orderuser }) {
  const [open, setOpen] = React.useState(true);
  const [cardtype, setCardtype] = React.useState("");
  const [cardnum, setCardnum] = React.useState("");
  const [carduser, setCarduser] = React.useState("");
  const [cardexpire, setCardexpire] = React.useState("");
  const [cardpassword, setCardpassword] = React.useState("");
  const [cardjumin, setCardjumin] = React.useState("");
  const handleClose = () => {
    setOpen(false);
    callback("", "", "");
  };

  const [refresh, setRefresh] = React.useState(1);

  // const _handlepurchase = async () => {
  //   // 결재 여부를 확인하자 실제 결재가 되는지 결재가 됏다면 실제 창을  callback 이동 시키자
  //   const CARD_USER = carduser;
  //   const CARD_MONEY = money;
  //   const CARD_NUM = cardnum;
  //   const CARD_EXPIRE = cardexpire;
  //   const CARD_PASSWORD = cardpassword;
  //   const CARD_JUMIN = cardjumin;
  //   const CARD_TYPE = cardtype;

  //   const cardpayregist = await cardpay({
  //     CARD_USER,
  //     CARD_MONEY,
  //     CARD_NUM,
  //     CARD_EXPIRE,
  //     CARD_PASSWORD,
  //     CARD_JUMIN,
  //   });

  //   if (cardpayregist.data.code != "A0200") {
   
  //     if (CARD_USER == "" || CARD_TYPE == "" || CARD_NUM == "") {
  //       alert("카드정보를 모두 입력해주세여");
  //       return;
  //     }

  //     const card_user = CARD_USER;
  //     const card_type = CARD_TYPE;
  //     const card_num = CARD_NUM;
  //     callback(card_user, card_type, card_num);
  //   } else {
  //     alert("카드사 결제에 실패 하였습니다");
  //   }
  // };

  const _handlepurchase = () => {

    alert("test");

    //orderId가 필요해서 만든 랜덤 아이디값
    const random = new Date().getTime() + Math.random()
    const randomId = btoa(random)

    const clientKey = "test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq";

    loadTossPayments(clientKey).then(tossPayments => {
      // 카드 결제 메서드 실행
      tossPayments.requestPayment(`간편결제`, {
        amount: 1, // 가격
        orderId: `A11111111`, // 주문 id
        orderName: `gagyeong`, // 결제 이름
        customerName: `머레이`, // 판매자, 판매처 이름
        successUrl: 'http://localhost:3000/success', // 성공시 리다이렉트 주소
        failUrl: 'http://localhost:3000/fail', // 실패시 리다이렉트 주소
    
      })
    })

    // if (radio === '가상계좌') {
    //   loadTossPayments(clientKey).then(tossPayments => {
    //     // 카드 결제 메서드 실행
    //     tossPayments.requestPayment(`${radio}`, {
    //       amount: 1, // 가격
    //       orderId: `${randomId}`, // 주문 id
    //       orderName: `gagyeong`, // 결제 이름
    //       customerName: `${name}`, // 판매자, 판매처 이름
    //       successUrl: 'http://localhost:3000/success', // 성공시 리다이렉트 주소
    //       failUrl: 'http://localhost:3000/failed', // 실패시 리다이렉트 주소
    //       validHours: 24, // 유효시간
    //       cashReceipt: {
    //         type: '소득공제',
    //       },
    //     })
    //   })
    // } else {
    //   loadTossPayments(clientKey).then(tossPayments => {
    //     // 카드 결제 메서드 실행
    //     tossPayments.requestPayment(`${radio}`, {
    //       amount: 1, // 가격
    //       orderId: `${randomId}`, // 주문 id
    //       orderName: `gagyeong`, // 결제 이름
    //       customerName: `${name}`, // 판매자, 판매처 이름
    //       successUrl: 'http://localhost:3000/success', // 성공시 리다이렉트 주소
    //       failUrl: 'http://localhost:3000/failed', // 실패시 리다이렉트 주소
    //     })
    //   })
    // }
  }

  const cardtypecallback = (cardtype) => {
    console.log("cardtype", cardtype);
    setCardtype(cardtype);
    setRefresh((refresh) => refresh + 1);
  };
  const cardnumcallback = (cardnum) => {
    console.log("cardnum", cardnum);
    setCardnum(cardnum);
    setRefresh((refresh) => refresh + 1);
  };
  const cardusercallback = (carduser) => {
    console.log("carduser", carduser);
    setCarduser(carduser);
    setRefresh((refresh) => refresh + 1);
  };
  const cardexpirecallback = (year, month) => {
    console.log("cardexpire", month, year);
    setCardexpire(String(year) + String(month));
    setRefresh((refresh) => refresh + 1);
  };
  const cardpasswordcallback = (password) => {
    console.log("password", password);
    setCardpassword(password);
    setRefresh((refresh) => refresh + 1);
  };
  const cardjumincallback = (jumin) => {
    setCardjumin(jumin);
    setRefresh((refresh) => refresh + 1);
  };

  React.useEffect(() => {
    setCardtype(cardtype);
    setCardjumin(cardjumin);
    setCardnum(cardnum);
    setCarduser(carduser);
    setCardexpire(cardexpire);
    setCardpassword(cardpassword);
  }, [refresh]);

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconCloseView onClick={handleClose}>
              <AiFillCloseCircle size={30} />
            </IconCloseView>
            <Row>
              <Label
                name={"결제할 카드를 선택해주세여"}
                weight={600}
                color={"#fff"}
                size={13}
              />
            </Row>
            <CardType callback={cardtypecallback} />

            <Row>
              <Label
                name={"2. 결제금액 : " + CommaFormatted(money) + "원"}
                weight={600}
                color={"#fff"}
                size={13}
              />
            </Row>

            <Row>
              <Label
                name={"3. 카드사용자 이름을 입력해주세요"}
                weight={600}
                color={"#fff"}
                size={13}
              />
            </Row>
            <CardUser callback={cardusercallback} />

            <Row>
              <Label
                name={"4. 카드사용자 주민번호 앞자리를 입력해주세요 "}
                weight={600}
                color={"#fff"}
                size={13}
              />
            </Row>
            <CardJumin callback={cardjumincallback} />

            <Row>
              <Label
                name={"5. 카드번호를 입력해주세요"}
                weight={600}
                color={"#fff"}
                size={13}
              />
            </Row>

            <CardNum callback={cardnumcallback} />

            <Row>
              <Label
                name={"6. 카드유효기간을 입력해주세요"}
                weight={600}
                color={"#fff"}
                size={13}
              />
            </Row>
            <CardExpire callback={cardexpirecallback} />
            <Row>
              <Label
                name={"7. 비밀번호 입력해주세요"}
                weight={600}
                size={13}
                color={"#fff"}
              />
            </Row>
            <CardPassword callback={cardpasswordcallback} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "80%",
                padding: "0px 10%",
                marginTop: "10px",
              }}
            >
              <Button
                Label={"결제"}
                bgcolor={"#fff"}
                borderRadius={10}
                color={"#000"}
                bordercolor={"#000"}
                height={40}
                callback={_handlepurchase}
              />
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
