import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import Label from "./Label";
import "./OrderViewModule.css";
import { CommaFormatted, getDateFullTime } from "../../utility/common";


const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "600px",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  padding: "10px",
};
const IconCloseView = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Row = styled.div`
  display: flex;
  padding-left: 10px;
  height: 45px;
  align-items: center;
`;

export default function DeliveryViewModule({
  callback,
  ordernum,
}) {

  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    callback();
  };

  const [refresh, setRefresh] = React.useState(1);

  React.useEffect(() => {

  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconCloseView onClick={handleClose}>
              <AiFillCloseCircle size={30} />
            </IconCloseView>
            {/* <Row>
              <Label name={"주문 정보 : " + ordernum} weight={600} size={20} />
            </Row>
            <table style={{ marginTop: 5, fontSize: 14 }}>
              <thead>
                <tr>
                  <th scope="col" style={{ width: 150 }}>
                    구분
                  </th>
                  <th scope="col" style={{ width: 300 }}>
                    내용
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">송장번호</th>
                  <td>{"송장번호가 확인되지 않았습니다"}</td>
                </tr>
                <tr>
                  <th scope="row">상품위치</th>
                  <td>{"송장번호가 확인되지 않았습니다"}</td>
                </tr>
              </tbody>
            </table> */}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
