import React, { useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import Preview from "./Categoryview";
import Noticeview from "./Noticeview";
import BestItem from "./BestItem";
import Page from "../../components/Layout/PageEx";
import ChatbotModule from "./ChatbotModule";

const Chat = () => {
  const Center = styled.div`
    width: 50px;
    border-radius: 50px;
    background-color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  const chatbotcallback = () => {
    setChatbotstatus(!chatbotstatus);
  };
  const _handlechatbot = () => {
    setChatbotstatus(!chatbotstatus);
  };
  const [chatbotstatus, setChatbotstatus] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: 80,
        backgroundColor: "#323232",
        borderRadius: 20,
      }}
    >
      {chatbotstatus == true ? (
        <ChatbotModule callback={chatbotcallback}></ChatbotModule>
      ) : null}

      <div
        style={{
          display: "flex",
          width: "40%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Center>
          <img src={imageDB.head} width={30} />
        </Center>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "center",
        }}
        onClick={_handlechatbot}
      >
        <div style={{ display: "flex", fontSize: 13, color: "#fff" }}>
          카카오톡 실시간 문의
        </div>
        <div
          style={{
            display: "flex",
            fontSize: 12,
            backgroundColor: "#fff",
            borderRadius: 20,
            color: "#323232",
            padding: "2px 10px",
            marginTop: 5,
            width: "fit-content",
          }}
          className="button"
        >
          문의하기
        </div>
      </div>
    </div>
  );
};

export default Chat;
