import React, { useState, useEffect, useRef } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import "../../components/css/common.css";
import { IoIosAddCircleOutline } from "react-icons/io";

import Label from "./Label";
import { SearchType } from "../../utility/commondefine";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelData = styled.div`
  width: 100px;
  height: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  background-color: #f0f0f0;
`;
const SearchLayout = styled.div`
  width: 380px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const memberSearchItem = [
  "아이디",
  "상호명",
  "담당자명",
  "담당자전화번호",
  "회원등급",
];
const productSearchItem = [
  "상품번호",
  "카테고리명",
  "모델명",
  "상품명",
  "KC안전인증",
  "노출여부",
  "품절여부",
];

const orderSearchItem = [
  "주문번호",
  "주문상품",
  "모델명",
  "옵션",
];
const exchangeSearchItem = [
  "전체",
  "주문번호",
  "주문상품",
  "모델명",
  "옵션",
  "수령자명",
  "수령자주소",
  "수령자연락처",
  "택배사",
  "송장번호",
  "입금자명",
  "입금은행",
  "담당자명",
];

const returnSearchItem = [
  "전체",
  "주문번호",
  "주문상품",
  "모델명",
  "옵션",
  "수령자명",
  "수령자주소",
  "수령자연락처",
  "택배사",
  "송장번호",
  "입금자명",
  "입금은행",
  "담당자명",
];

const OrderSearch = ({
  containerStyle,
  type,
  keywordcallback,
  expandcallback,
  expand,
}) => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(100);
  const [refresh, setRefresh] = useState(1);
  const [selectsearchservice, setSelectsearchservice] = useState("주문번호");
  const [searchExpand, setSearchExpand] = useState(false);

  const [selectSearchItem, setSelectSearchItem] = useState([]);
  const onSelectsearchserviceChange = (e) => {
    setSelectsearchservice(e.target.value);
    setRefresh((refresh) => refresh + 1);
  };

  const [search, setSearch] = useState("");
  const onsearchChange = (e) => {
    setSearch(e.target.value);
    setRefresh((refresh) => refresh + 1);
  };

  const activeEnter = (e) => {
    if (e.key === "Enter") {

      keywordcallback(search, selectsearchservice);
      setRefresh((refresh) => refresh * 1);
    }
  };

  useEffect(() => {
    setSearch(search);
    setSelectsearchservice(selectsearchservice);
  }, [refresh]);

  useEffect(() => {
    window.scrollTo(0, 0);

      setSelectSearchItem(orderSearchItem);
  }, []);



  useEffect(() => {
    setSearchExpand(searchExpand);
  }, [refresh]);

  return (
    <Container style={containerStyle}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <SearchLayout>
            <select
              style={{
                width: "100px",
                height: 33,
                padding: "unset",
                outline: 0,
              }}
              className="commonselect"
              onChange={onSelectsearchserviceChange}
              value={selectsearchservice}
            >
              {selectSearchItem.map((data, index) => (
                <option value={data} key={index}>
                  {data}
                </option>
              ))}
            </select>
            <input
              type="text"
              className="commoninput"
              style={{
                width: "150px",
                height: "11px",
              }}
              onChange={onsearchChange}
              onKeyDown={(e) => activeEnter(e)}
              value={search}
            ></input>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100px",
              }}
            ></div>
          </SearchLayout>
        </div>
      </div>
    </Container>
  );
};

OrderSearch.defaultProps = {
  expand: true,
};

export default OrderSearch;
