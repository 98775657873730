import {db, auth, storage} from '../api/config';
import { collection, getDocs, query, updateDoc,where,doc,setDoc, deleteDoc, orderBy } from 'firebase/firestore';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";



export const registMd = async({
   PRODUCTIMG,
   PRODUCT_ID, 

}) =>{
    const mdRef = doc(collection(db, "MDPICK"));
    const id = mdRef.id;
    const newmd = {
        MD_ID : id,
        PRODUCTIMG : PRODUCTIMG,
        PRODUCT_ID : PRODUCT_ID,
        REGISTDATE : Date.now(),
    }
    try{
        await setDoc(mdRef, newmd);
    }catch(e){
        console.log("error", e.message);
    }finally{
        return id;
    }

}
export const getNew = async () => {
  const mdRef = collection(db, "NEWPICK");

  let newItems = [];
  let success = false;
  const q = query(mdRef, orderBy("REGISTDATE", "desc"));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      newItems.push(doc.data());
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(newItems);
      } else {
        resolve(newItems);
      }
    });
  }
};
export const getMd = async()=>{
    const mdRef = collection(db, "MDPICK");

    let mdItems = [];
    let success = false;
    const q = query(mdRef, orderBy("REGISTDATE",'desc'));

    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {
            
            mdItems.push(doc.data());
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(mdItems);
            }else{
                resolve(mdItems);  
            }          
        }) 
    }
}
export const getMdVideo = async () => {
  const mdRef = collection(db, "MDPICKVIDEO");

  let mdItems = null;
  let success = false;
  const q = query(mdRef);

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      mdItems = doc.data();
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(mdItems);
      } else {
        resolve(mdItems);
      }
    });
  }
};
export const deletemd = async({MD_ID})=>{
    console.log("delete MDPICK", MD_ID);
    const mdRef = doc(db, "MDPICK", MD_ID);
    try{
        await deleteDoc(mdRef);
    }catch(e){
        console.log("error", e.message);
    }finally{
        return;
    }
}
export const updatemd = async({PRODUCTIMG, PRODUCT_ID,MD_ID})=>{

    const mdRef = collection(db, "MD");
    const rows = query(mdRef, where("MD_ID",'==', MD_ID ));
    try{

        const querySnapshot =  await getDocs(rows);

        querySnapshot.forEach(function (doc) {

            console.log("update", doc.data())
            updateDoc(doc.ref, {
                PRODUCTIMG : PRODUCTIMG,
                PRODUCT_ID : PRODUCT_ID,
         
            });
        });

    }catch(e){
        console.log("error", e.message);
    }finally{

        return new Promise((resolve, resject)=>{
            resolve(0);

            console.log("member update complete");
        })  

    }


}
