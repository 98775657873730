import React, { useContext, useEffect, useState } from "react";
import Homecontainer from "../container/Homecontainer";
import Layout from "../components/Layout/Layout";
import { BannerContext } from "../context/Banner";
import { getSideBanner } from "../utility/bannerfirebase";
import Homelayout from "../components/Layout/Homelayout";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../context/Users";
import moment from "moment";

const Homepage = () => {
  const { banner, dispatch2 } = useContext(BannerContext);
  const [refresh, setRefresh] = useState(1);
  const [move, setMove] = useState(false);


  const { state } = useLocation(); // 2번 라인
  const { user, dispatch } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const now = moment();
    let LoginEnableTime = moment(now).subtract(60, "minute").unix();

    console.log("LoginEnableTime", LoginEnableTime);
    console.log("LoginRealTime", user.LOGINTIME / 1000);
    
    if (LoginEnableTime > user.LOGINTIME / 1000) {
      alert("60분간 사용하지 않아 자동 로그아웃 처리되겠습니다. 다시 로그인해주세요");
      user.USERID = "";
      user.USERPW = "";
      user.USERNAME = "";
      user.USER_ID = "";
      dispatch(user);

      navigate("/memberlogin");
    }
    user.LOGINTIME = Date.now();
    dispatch(user);
    
  }, [])
  
  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      let bannerlist = [];
      let bannerlistTmp = [];
      bannerlistTmp = await getSideBanner();

      bannerlistTmp.forEach((element) => {
        if (element.DISPOSE == true) {
          bannerlist.push(element);
        }
      });

      banner.bannerlist = bannerlist;
      dispatch2(banner);
      setRefresh((refresh) => refresh + 1);
    }
    fetchData();
    return () => {};
  }, []);

  return (
    <Homelayout>
      <Homecontainer />
    </Homelayout>
  );
};

export default Homepage;
