import React, { useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";

const Container = styled.div``;

const CardExpire = ({ callback }) => {
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    setCardMM(cardMM);
    setCardYY(cardYY);
  }, [refresh]);

  const [cardYY, setCardYY] = React.useState("");
  const [cardMM, setCardMM] = React.useState("");
  const oncardYYchange = (e) => {
    setCardYY(e.target.value);
    callback(e.target.value, cardMM);
  };
  const oncardMMchange = (e) => {
    setCardMM(e.target.value);
    callback(cardYY, e.target.value);
  };

  return (
    <Container>
      <div
        style={{
          paddingLeft: 20,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ width: "20px", marginRight: 30 }}>
          <input
            type="text"
            className="InputCommon2"
            value={cardMM}
            onChange={oncardMMchange}
            placeholder="월일"
            style={{
              height: "10px",
              fontSize: "12px",
              width: 20,
            }}
          ></input>
        </div>
        <div style={{ width: "20px" }}>
          <input
            type="text"
            className="InputCommon2"
            value={cardYY}
            onChange={oncardYYchange}
            placeholder="년도"
            style={{
              height: "10px",
              fontSize: "12px",
              width: 20,
            }}
          ></input>
        </div>
      </div>
    </Container>
  );
};

CardExpire.defaultProps = {};

export default CardExpire;
