

import React, { useEffect} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";


const Label = ({name, color, weight, size}) => {

  const Container = styled.div`
    font-size: ${(size)}px;
    color : ${(color)};
    font-weight:${(weight)};
    font-family: Pretendard-Bold;
  `

  return (

    <Container color={color} weight={weight}>
      {name}
    </Container>
  
  );
};

Label.defaultProps={
  color : '#000',
  weight: 600,
  size :28
}

export default Label;
