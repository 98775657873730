import React, { useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";

const Container = styled.div``;

const CardPassword = () => {
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    setCardpass1(cardpass1);
    setCardpass2(cardpass2);
  }, [refresh]);

  const [cardpass1, setCardpass1] = React.useState("");
  const oncardpass1chagne = (e) => {
    setCardpass1(e.target.value);
  };

  const [cardpass2, setCardpass2] = React.useState("");
  const oncardpass2chagne = (e) => {
    setCardpass2(e.target.value);
  };

  return (
    <Container>
      <div
        style={{
          paddingLeft: 20,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ width: "10px", marginRight: 30 }}>
          <input
            type="text"
            className="InputCommon2"
            value={cardpass1}
            onChange={oncardpass1chagne}
            placeholder=""
            style={{
              height: "10px",
              fontSize: "12px",
              width: 10,
            }}
          ></input>
        </div>
        <div style={{ width: "10px", marginRight: 30 }}>
          <input
            type="text"
            className="InputCommon2"
            value={cardpass2}
            onChange={oncardpass2chagne}
            style={{
              height: "10px",
              fontSize: "12px",
              width: 10,
            }}
          ></input>
        </div>
        <div style={{ width: "10px", marginRight: 30 }}>
          <input
            disabled
            type="text"
            className="InputCommon3"
            style={{
              height: "10px",
              fontSize: "12px",
              width: 10,
            }}
          ></input>
        </div>
        <div style={{ width: "10px" }}>
          <input
            disabled
            type="text"
            className="InputCommon3"
            style={{
              height: "10px",
              fontSize: "12px",
              width: 10,
            }}
          ></input>
        </div>
      </div>
    </Container>
  );
};

CardPassword.defaultProps = {};

export default CardPassword;
