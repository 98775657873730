import React, { Fragment, useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import "./BestItem.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import { getproductbyid } from "../../utility/productfirebase";
import Fade from "react-reveal/Fade";

const Container = styled.div``;

const Product = styled.div`
  width: 400px;
  padding: 20px;
  margin-right: 50px;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  background: white;
  margin: 20px;
  border-radius: 5px;
`;
const ProductImg = styled.div``;
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: 10px;
  text-align: left;
`;
const OrderItem2 = ({ containerstyle, itemdata }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Product>
        <ProductImg>
          <img src={itemdata.productimage} style={{ width: 150 }} />
        </ProductImg>
        <ProductInfo>
          <div>
            상품명 :{itemdata.productname.slice(0, 45)}
            {itemdata.productname.length > 45 ? "..." : null}
          </div>
          <div>
            모델명 :{itemdata.productmodelname.slice(0, 45)}
            {itemdata.productmodelname.length > 45 ? "..." : null}{" "}
          </div>
          {itemdata.productoptionprice != 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>선택옵션 : {itemdata.productoptionname}</div>
              <div style={{ marginLeft: 5 }}>
                / 옵션가격 : {CommaFormatted(itemdata.productoptionprice)}원
              </div>
            </div>
          )}
          {itemdata.productoptionprice == 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>옵션상품 아님</div>
            </div>
          )}
          <div>구매수량 : {itemdata.productcount}</div>
          <div>상품공급가 : {CommaFormatted(itemdata.productprice)}원</div>
        </ProductInfo>
      </Product>
    </Container>
  );
};

OrderItem2.defaultProps = {
  price: 0,
};
export default OrderItem2;
