import {db, auth, storage} from '../api/config';
import { collection, getDocs, query, updateDoc,where,doc,setDoc, deleteDoc, orderBy } from 'firebase/firestore';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";



export const registnews = async({
   type,
   news,
   order,
}) =>{
    const newsRef = doc(collection(db, "NEWS"));
    const id = newsRef.id;
    const newnews = {
        NEWS_ID : id,
        TYPE : type,
        NEWS : news,
        REGISTDATE : Date.now(),
        ORDER : order,
    }
    try{
        await setDoc(newsRef, newnews);
    }catch(e){
        console.log("error", e.message);
    }finally{
        return id;
    }

}
export const getnews = async()=>{
    const newsRef = collection(db, "NEWS");

    let newsItem = [];
    let success = false;
    const q = query(newsRef);

    try{
        const querySnapshot =  await getDocs(q);
        querySnapshot.forEach((doc) => {
            
            newsItem.push(doc.data());
        });
    
        if(querySnapshot.size > 0){
            success = true;
        }
    }catch(e){
         console.log("error", e.message);
    }finally{
        return new Promise((resolve, resject)=>{
            if(success){
                resolve(newsItem);
            }else{
                resolve(newsItem);  
            }          
        }) 
    }
}

export const deletenews = async(NEWS_ID)=>{

    const newsRef = doc(db, "NEWS", NEWS_ID);
    try{
        await deleteDoc(newsRef);
    }catch(e){
        console.log("error", e.message);
    }finally{
        return;
    }
}
