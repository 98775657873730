import React, { Fragment, useEffect, useState } from "react";

import styled from "styled-components";

import "../../components/css/common.css";
import "./BestItem.css";
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Layout/Button";
import Fade from "react-reveal/Fade";

const Container = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;
const MainText = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
const OrderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  font-size: 12px;
  margin-top: 20px;
`;
const OrderIndex = styled.div`
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  border-bottom: 1px solid #999;
  padding: 10px 0px;
`;

const SelectOrder = ({ selectItem }) => {
  const navigate = useNavigate();

  const _handleorder = async () => {
    let OPTIONBASKETLIST = [];
    selectItem.map((data, index) => {
      if (data.item.productcount > 0) {
        data["BAKSET_ID"] = data.BAKSET_ID;
        data["productcheck"] = false;

        OPTIONBASKETLIST.push(data.item);
      }
    });

    if (OPTIONBASKETLIST.length == 0) {
      alert("주문할 상품의 재고수량이 없습니다");
      return;
    }

    alert("주문 페이지로 이동합니다");

    navigate("/order", {
      state: {
        OPTIONBASKETLIST: OPTIONBASKETLIST,
        BASKET: true,
      },
    });
  };

  return (
    <>
      <Container>
        <MainText>선택주문</MainText>

        {selectItem.map((data, index) => (
          <OrderContent key={index}>
            <OrderIndex>선택 {index + 1}.</OrderIndex>
            <div style={{ marginTop: 10, fontSize: 14, lineHeight: 1.7 }}>
              <div>상품명 : {data.item.productname}</div>
              <div>모델명 : {data.item.productmodelname}</div>
              {data.item.productoptionprice != 0 && (
                <div>
                  <div>
                    옵션이름 : {data.item.productoptionname} / 옵션가격 :{" "}
                    {CommaFormatted(data.item.productoptionprice)}원
                  </div>
                </div>
              )}

              <div>주문수량 : {data.item.productcount}개</div>
              <div>상품공급가 : {CommaFormatted(data.item.productprice)}원</div>
            </div>
          </OrderContent>
        ))}

        {selectItem.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "cetner",
              marginTop: 50,
            }}
          >
            <Button
              callback={_handleorder}
              Label={"주문하기"}
              bgcolor={"#689fff"}
              borderRadius={10}
              color={"#fff"}
              bordercolor={"#689fff"}
              height={40}
              width={"80%"}
            />
          </div>
        )}
      </Container>
    </>
  );
};

SelectOrder.defaultProps = {
  price: 0,
};
export default SelectOrder;
