import moment from "moment";

export const SearchType = {
  member: "member",
  exchange: "exchange",
  order: "order",
  product: "product",
  return: "return",
};

export const OrderType = {
  order1: "주문접수",
  order2: "결재완료",
  order3: "배송준비",
  order4: "배송중",
  order5: "배송완료",
  order6: "반품",
  order7: "교환",
  order8: "주문취소",
};

export const PeriodType = {
  period1: "1일",
  period2: "일주일",
  period3: "1개월",
  period4: "3개월",
  period5: "전체",
};

export const ExchangeType = {
  exchange1: "교환신청",
  exchange2: "확인중",
  exchange3: "교환완료",
  exchange4: "교환취소",
};

export const ReturnType = {
  return1: "반품신청",
  return2: "확인중",
  return3: "반품완료",
  return4: "반품취소",
};
