import React from "react";

import Layout from "../components/Layout/Layout";

import { Define } from "../components/Define";
import Itemproductcontainer from "../container/Itemproductcontainer";
import { useLocation } from "react-router-dom";
import Noticecontainer from "../container/Noticecontainer";
import NoticeWritecontainer from "../container/NoticeWritecontainer";

const NoticeWritepage = ({ pagetype }) => {
  const { state } = useLocation(); // 2번 라인
  // const {CATEGORYINFO} = state;	// 3번 라인



  return (
    <Layout type={pagetype}>
      <NoticeWritecontainer mode ={state.mode} />
    </Layout>
  );
};

export default NoticeWritepage;
