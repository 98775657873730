import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../../components/css/common.css";
import { imageDB } from "../../components/imageDB";
import { CommaFormatted, commaescapevalue } from "../../utility/common";

const Container = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid #ededed;
`;

const ItemTitle = styled.div`
  display: flex;
  font-size: 16px;
  width: 10%;
`;
const ItemContent = styled.div`
  display: flex;
  width: 60%;
  margin-left: 20px;
`;

const SwitchInput = styled.input`
  background-color: ${({ disabled }) =>
    disabled == true ? "#ededed" : "#fff"};
  width: 100%;
  padding-left: unset;
  border: 1px solid #c5c5c5;
  border-radius: unset;
  padding: 10px;
`;

const Approve = ({
  productprice,
  productdeliveryfee,
  productjejudeliveryfee,
  productislanddelvieryfee,
  callback,
}) => {
  const [cardcheck, setCardcheck] = useState(true);
  const [bankcheck, setBankcheck] = useState(false);

  const _handlecardCheck = (e) => {
    setCardcheck(!cardcheck);
    setBankcheck(!bankcheck);
    setBankuser("");
    setBankname("");

    setRefresh((refresh) => refresh + 1);
  };
  const _handlebankCheck = (e) => {
    setCardcheck(!cardcheck);
    setBankcheck(!bankcheck);
    setRefresh((refresh) => refresh + 1);
  };

  const [refresh, setRefresh] = useState(1);

  const [bankuser, setBankuser] = useState("");
  const onbankuserChange = (e) => {
    setBankuser(e.target.value);
    setRefresh((refresh) => refresh + 1);
  };

  const [bankname, setBankname] = useState("");
  const onbanknameChange = (e) => {
    setBankname(e.target.value);
    setRefresh((refresh) => refresh + 1);
  };

  const [banknum, setBanknum] = useState("");
  const onbanknumChange = (e) => {
    setBanknum(e.target.value);
    setRefresh((refresh) => refresh + 1);
  };

  const [producttotalprice, setProducttotalprice] = useState(0);

  useEffect(() => {
    let price =
      parseInt(commaescapevalue(productprice)) +
      parseInt(commaescapevalue(productdeliveryfee));

    setProducttotalprice(price);
  }, []);

  useEffect(() => {
    setCardcheck(cardcheck);
    setBankcheck(bankcheck);
    setBankuser(bankuser);
    setBankname(bankname);
    setBanknum(banknum);

    callback(cardcheck, bankcheck, bankuser, bankname, banknum);
  }, [refresh]);

  return (
    <Container>
      <div style={{ padding: 20, width: "100%" }}>
        <Item>
          <ItemTitle>상품합계금액</ItemTitle>
          <ItemContent style={{ color: "#ff0000" }}>
            <div>{CommaFormatted(productprice)}원</div>
          </ItemContent>
        </Item>

        <Item>
          <ItemTitle>배송비</ItemTitle>
          <ItemContent>
            <div>{CommaFormatted(productdeliveryfee)}원</div>
          </ItemContent>
        </Item>

        <Item>
          <ItemTitle>할인 및 적립</ItemTitle>
          <ItemContent>
            <input disabled type="text" className="InputCommon3"></input>
          </ItemContent>
        </Item>

        <Item>
          <ItemTitle>쿠폰사용</ItemTitle>
          <ItemContent>
            <input disabled type="text" className="InputCommon3"></input>
          </ItemContent>
        </Item>

        <Item>
          <ItemTitle>최종결제금액</ItemTitle>
          <ItemContent style={{ color: "#ff0000" }}>
            {CommaFormatted(producttotalprice)}원
          </ItemContent>
        </Item>
      </div>

      <div style={{ display: "flex", flexDirection: "row", paddingLeft: 20 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            onChange={(e) => _handlecardCheck(e)}
            type="checkbox"
            checked={cardcheck}
            style={{ width: "20px", color: "#ff0000" }}
          ></input>
          <div style={{ color: "#ff0000" }}>카드결제</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            onChange={(e) => _handlebankCheck(e)}
            type="checkbox"
            checked={bankcheck}
            style={{ width: "20px", color: "#ff0000" }}
          ></input>
          <div style={{ color: "#ff0000" }}>계좌이체</div>
        </div>
      </div>

      <div style={{ padding: 20, width: "100%" }}>
        <Item style={{ border: "none" }}>
          <ItemTitle>입금자명</ItemTitle>
          <ItemContent>
            <SwitchInput
              disabled={cardcheck}
              type="text"
              value={bankuser}
              onChange={onbankuserChange}
            ></SwitchInput>
          </ItemContent>
        </Item>

        <Item style={{ border: "none" }}>
          <ItemTitle>입금은행</ItemTitle>
          <ItemContent>
            <SwitchInput
              disabled={cardcheck}
              type="text"
              value={bankname}
              onChange={onbanknameChange}
            ></SwitchInput>
          </ItemContent>
        </Item>

        <Item style={{ border: "none" }}>
          <ItemTitle>입금계좌번호</ItemTitle>
          <ItemContent>
            <SwitchInput
              disabled={cardcheck}
              type="text"
              value={banknum}
              onChange={onbanknumChange}
            ></SwitchInput>
          </ItemContent>
        </Item>
      </div>
    </Container>
  );
};

Approve.defaultProps = {
  color: "#000",
  weight: 600,
};

export default Approve;
