
import React, { useEffect, useState } from 'react';
import { imageDB } from "../components/imageDB";
import { useNavigate} from "react-router-dom";
import styled from 'styled-components';
import '../components/css/common.css';
import { MaxPC, SmallPC } from '../components/Responsive';



const ProductImagecontainer = ({PRODUCT_IMGS}) => {
  const navigate = useNavigate();
  const [productimgs, setProductimgs] = useState(PRODUCT_IMGS);


  useEffect(() => {
    //mount 시에
     console.log("init");
 
     window.scrollTo(0,0);
 
     return () => {
       console.log("disamount");
     };
   }, []);

  return (
    <>
      <MaxPC>
        <div className ="MaxProductContainer">
          <div>
            {
              productimgs.map((data, index)=>(
                
                <>
                {
                  index %  3 == 0 && <img src={data} style={{width:"500px", height:"500px"}}/>
                }
                {
                   index %  3 == 1  && <img src={data} style={{width:"500px", height:"500px"}}/>
                }
                {
                   index %  3 == 2  && <img src={data} style={{width:"500px", height:"500px"}}/>
                }
           
                </>
               
              ))
            }
          </div>
        </div>
      </MaxPC>
      <SmallPC>
          <div className ="ProductContainer">   
          <div>
            {
              productimgs.map((data, index)=>(
                
                <>
                {
                  index %  3 == 0 && <img src={data} style={{width:"500px", height:"500px"}}/>
                }
                {
                   index %  3 == 1  && <img src={data} style={{width:"500px", height:"500px"}}/>
                }
                {
                   index %  3 == 2  && <img src={data} style={{width:"500px", height:"500px"}}/>
                }
           
                </>
               
              ))
            }
          </div>
          </div>
     
      </SmallPC>
    </>
  );
}

export default ProductImagecontainer;
