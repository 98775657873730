

import React, { Fragment, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import './BestItem.css'
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted } from "../../utility/common";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Layout/Button";


const Container = styled.div`
  display: flex;
  border :1px solid #ededed;
  margin-top: 10px;
  flex-direction:column;
  justify-content: center;
  align-items: flex-start;
  padding :20px;
  margin:20px;

`

const Item = styled.div`
  height:50px;
  justify-content: center;
`



const ReceiveInfo = ({img, name, price}) => {

  const navigate = useNavigate();

  const [select , setSelect] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const _handleMouseOver = () =>{
    if(isHovering){
      return;
    }
    setIsHovering(true); 
    // console.log("mouse over");
  }
  const _handleMouseOut= () =>{

    if(!isHovering){
      return;
    }
    setIsHovering(false);
    // console.log("mouse out");
  
  }
  const allselect = () =>{
    setSelect(!select);
  }


  const _handleProduct = () =>{

    navigate("/product");
  }

  const _handleMinus =()=>{
    if(amount ==0){
      return;
    }
    let count = amount;
    count = count -1;
    setAmount(count);
  }
  const _handlePlus =()=>{

    let count = amount;
    count = count +1;
    setAmount(count);
  }

  return (

    <Container>
    <Item>수령자정보</Item>
    <Item>수령자명</Item>
    <Item>수령주소</Item>
    <Item>상세주소</Item>



</Container>

  
  );
};

ReceiveInfo.defaultProps={
  price : 0
}
export default ReceiveInfo;
