

import React, { Fragment, useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import './BestItem.css'
import { imageDB } from "../../components/imageDB";
import Label from "./Label";
import { CommaFormatted } from "../../utility/common";
import { useNavigate } from "react-router-dom";


const Container = styled.div`
  border-radius: 5px;
  width:19%;
  height:260px;
  flex-direction:column;
  background-color: #fff;
  margin-right:10px;
  margin-bottom:40px;
  display: flex;
  border-radius:10px;
`

const Column1 = styled.div`
  display:flex;

  margin-left:10px;
  flex-direction:column;
  font-size :14px;
  width:40%;

`
const Column2 = styled.div`
  display:flex;

  margin-left:10px;
  flex-direction:column;
  width:60%;
  font-size:14px;
  border-left : 1px solid;

`

const Row = styled.div`
  display:flex;
  border-bottom:1px solid;
  margin-left:10px;
  flex-direction:row;
  padding-bottom:20px;


`

const CheckItem = styled.div`
  background-color: #b5b5b7c9;

  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`

const UnCheckItem = styled.div`

  top: 35px;
  height: 180px;
  z-index: 3;
  width: 190px;
`

const Productname = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
`
const ProductItem = ({containerstyle, basketitem}) => {

  const navigate = useNavigate();

  const [select , setSelect] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const _handleMouseOver = () =>{
    if(isHovering){
      return;
    }
    setIsHovering(true); 
    // console.log("mouse over");
  }
  const _handleMouseOut= () =>{

    if(!isHovering){
      return;
    }
    setIsHovering(false);
    // console.log("mouse out");
  
  }
  const allselect = () =>{
    setSelect(!select);
  }


  const _handleProduct = () =>{

    navigate("/product");
  }

  const _handleMinus =()=>{
    if(amount ==0){
      return;
    }
    let count = amount;
    count = count -1;
    setAmount(count);
  }
  const _handlePlus =()=>{

    let count = amount;
    count = count +1;
    setAmount(count);
  }

  return (

    <Fragment>

        <Row>

          <div style={{display:"flex", flexDirection:"column", justifyContent:"center", width:'5%'}}>
          {
                  select == true ? (<img onClick={allselect}  src={imageDB.check} style={{width:"25px", height:"25px"}}/>) :
                  (<img  onClick={allselect} src={imageDB.uncheck} style={{width:"25px", height:"25px"}}/>)
          }
           </div>

          <Column1>


                <div style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",}}>
                    <img  onClick={allselect} src={basketitem.OPTIONBASKETLIST[0].productimage} style={{width:"70%", height:"70%"}}/>
                </div>

          

      
          
          </Column1>

          <Column2>

              <div style={{display:"flex", flexDirection:"column" ,justifyContent: "center", padding:"20px 40px"}}>
                    <Productname>상품명 : {basketitem.OPTIONBASKETLIST[0].productname}</Productname>
                    <div style={{textAlign:"left"}}>모델명 : {basketitem.OPTIONBASKETLIST[0].productmodelname}</div>
                    <div style={{textAlign:"left"}}>배송비 : 3000원</div>
                    <div style={{textAlign:"left"}}>도서산관지역 배송비(제주도) : 3000원(5000원)</div>
             
                </div>

             {
                basketitem.OPTIONBASKETLIST.map((data, index)=>(
                  <>
                        
                  <div style={{display:"flex", flexDirection:"row", width:"100%",alignItems: "center",justifyContent: "center"}}>
                  <div style={{display:"flex", flexDirection:"column", width:'40%', justifyContent:"center", alignItems:"center", paddingTop:20}}>
                 
                      <div style={{display:"flex", flexDirection:"row", marginTop:10, marginBottom:10}}>
                        <div style={{display:"flex",flexDirection:"row", alignItems:"center"}}>{data.productoptionname}</div>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", paddingLeft:20}}>
                          <div style={{border: "1px solid #ededed", width:30,height:"30px",
                        display: "flex", justifyContent: "center",alignItems: "center"}}>-</div>
                          <div style={{border: "1px solid #ededed", width:40,height:"30px",
                        display: "flex", justifyContent: "center",alignItems: "center"}}>{'0'}</div>
                          <div style={{border: "1px solid #ededed", width:40,height:"30px",
                        display: "flex", justifyContent: "center",alignItems: "center"}}>+</div>
                        </div>
             
                        </div>

                    {/* <div onClick={_handleMinus}style={{position:"relative",top: 25,left: -40}}>-</div>
                    <img src={imageDB.plusminus} style={{width:"70%"}}/>
                    <div style={{position:"relative", top:10,top: -24,}}>{data.productcount}</div>
                    <div onClick={_handlePlus}style={{position:"relative", top:10,top: -44,left: 40}}>+</div> */}
                  </div>
                  <div style={{display:"flex", flexDirection:"column" , width:'40%',justifyContent: "space-around",padding: "20px 0px", marginTop:20}}>
                    <div>상품공급가{' '}{CommaFormatted(data.productprice)}원</div>
                  </div>

                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", width:'5%', marginTop:20}}>
                    <img   src={imageDB.close} style={{width:"25px", height:"25px"}}/>
                  </div>
                  </div>
                  </>
           
                ))
              }
           
          </Column2>
        </Row>
  

     

    </Fragment>

  
  );
};

ProductItem.defaultProps={
  price : 0
}
export default ProductItem;
