import React, { Component, useContext, useEffect, useLayoutEffect } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import Homepage from "./screen/Homepage";
import Productpage from "./screen/Productpage";
import Registerpage from "./screen/Registerpage";
import Basketpage from "./screen/Basketpage";

import MemberLoginpage from "./screen/MemberLoginpage";
import MemberRegisterpage from "./screen/MemberRegisterpage";
import Itemproductpage from "./screen/Itemproductpage";
import { Define } from "./components/Define";
import Heartproductpage from "./screen/Heartproductpage";
import ProductImagepage from "./screen/ProductImagepage";
import { UserContext, UserProvider } from "./context/Users";
import Orderpage from "./screen/Orderpage";
import Myorderpage from "./screen/Myorderpage";
import Noticepage from "./screen/Noticepage";
import Searchpage from "./screen/Searchpage";
import UseLoginTime from "./components/LoginCheckTime";
import NoticeWritepage from "./screen/NoticeWritepage";
import Success from "./container/Success";
import CardApprovalSuccesspage from "./screen/CardApprovalSuccesspage";
import CardApprovalFailpage from "./screen/CardApprovalFailpage";

const App = () => {
  const { dispatch, user } = useContext(UserContext);

  useLayoutEffect(() => {
    const userObj = JSON.parse(window.localStorage.getItem("user"));

    if (userObj != undefined) {
      user.USERID = userObj.USERID;
      user.PASSWORD = userObj.PASSWORD;
      user.CEONAME = userObj.CEONAME;
      user.USER_ID = userObj.USER_ID;
      user.BANNERITEMS = userObj.BANNERITEMS;
      user.LOGINTIME = userObj.LOGINTIME;
      user.LEVEL = userObj.LEVEL;

    

      dispatch(user);
    }
  }, []);
  

  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/memberlogin" element={<MemberLoginpage />} />
      <Route path="/home" element={<Homepage />} />
      <Route path="/product" element={<Productpage />} />
      <Route path="/productimage" element={<ProductImagepage />} />
      <Route path="/memberregister" element={<MemberRegisterpage />} />
      <Route path="/basket" element={<Basketpage />} />
      <Route path="/success" element={<CardApprovalSuccesspage />} />
      <Route path="/failed" element={<CardApprovalFailpage />} />
      <Route path="/order" element={<Orderpage />} />
      {/* <Route path="/success" element={<Success />} /> */}
      <Route path="/myorder" element={<Myorderpage />} />
      <Route path="/search" element={<Searchpage />} />
      <Route path="/notice" element={<Noticepage pagetype={Define.notice} />} />
      <Route path="/noticewrite" element={<NoticeWritepage pagetype={Define.notice} />} />
      <Route
        path="/itemproduct"
        element={<Itemproductpage pagetype={Define.allcategory} />}
      />
      <Route
        path="/bestproduct"
        element={<Itemproductpage pagetype={Define.best} />}
      />
      <Route
        path="/newproduct"
        element={<Itemproductpage pagetype={Define.new} />}
      />
      <Route
        path="/mdproduct"
        element={<Itemproductpage pagetype={Define.md} />}
      />
      <Route
        path="/marginproduct"
        element={<Itemproductpage pagetype={Define.gomargin} />}
      />
      <Route
        path="/topproduct"
        element={<Itemproductpage pagetype={Define.top} />}
      />
      <Route
        path="/soldoutproduct"
        element={<Itemproductpage pagetype={Define.soldout} />}
      />
      <Route
        path="/outproduct"
        element={<Itemproductpage pagetype={Define.out} />}
      />
      <Route
        path="/heartproduct"
        element={<Heartproductpage pagetype={Define.heart} />}
      />
    </Routes>
  );
};

export default App;
