import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import Button from "../../components/Layout/Button";
import { CommaFormatted } from "../../utility/common";
import Approve from "./Approve";
import { BiSolidRectangle } from "react-icons/bi";
import Approve2 from "./Approve2";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "48%",
  left: "80%",
  height: "500px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  padding: "10px",
};

const MainLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  margin: 20px 0px;
`;


const IconCloseView = styled.div`
  display: flex;
  justify-content: flex-end;
  margin : 10px 0px;
`;
const MainData = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 20px 0px;
  background-color: #fff;
  flex-wrap: wrap;
`;

const MainDataItem = styled.div`
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${({ check }) => (check == 1 ? "#ff4e193b" : "#EDEDED")};
  margin-left: 10px;
  margin-bottom: 10px;
`;
const MainDataItemText = styled.span`
  font-size: 12px;
  font-family: ${({ theme }) => theme.REGULAR};
  color: ${({ check }) => (check == 1 ? "#FF4E19" : "#000")};
`;
const ApplyItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  margin-bottom: 20px;
`;
const FilterApplyButton = styled.div`
  background-color: #ff4e19;
  padding: 5px 50px;
  border-radius: 5px;
`;
const FilterApplyButtonText = styled.span`
  color: #fff;
  font-size: 14px;
  font-family: ${({ theme }) => theme.REGULAR};
`;

const TableLayer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 480px;
  overflow-y: auto;
`;

export default function CashOrderModule({ callback, orderlist, productprice  }) {
  const [open, setOpen] = React.useState(true);
  const [totalprice, setTotalprice] = React.useState(0);
  const [cardcheck, setCardcheck] = React.useState(false);
  const [bankcheck, setBankcheck] = React.useState(false);
  const [bankuser, setBankuser] = React.useState("");
  const [bankname, setBankname] = React.useState("");
  const [banknum, setBanknum] = React.useState("");

  const handleClose = () => {
    setOpen(false);
    callback("");
  };

  React.useEffect(() => {


  }, []);

  const _handlePurchase = () => {
      setOpen(false);
      callback(bankuser, bankname, banknum);
  };
  const approvecallback = (
  
      bankuser,
      bankname,
      banknum
    ) => {

      setBankuser(bankuser);
      setBankname(bankname);
      setBanknum(banknum);

      // console.log("approvecallback", cardcheck, bankcheck, bankuser, bankname);
    };
  
  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding:'0px 10px',
              }}
            >
              <IconCloseView onClick={handleClose}>
                <AiFillCloseCircle size={30} />
              </IconCloseView>

              <MainLabel
                style={{
                  borderBottom: "2px solid #000",
                  paddingBottom: 20,
                  margin: "20px 0px 0px 0px",
                }}
              >
                <BiSolidRectangle size={20} />
                <div style={{ marginLeft: 10 }}>결제정보</div>
              </MainLabel>

              <Approve2
                productprice={productprice}
                callback={approvecallback}
              ></Approve2>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
              >
                <Button
                  Label={"결제하기"}
                  bgcolor={"#000"}
                  width={"70%"}
                  borderRadius={10}
                  color={"#fff"}
                  bordercolor={"#000"}
                  height={35}
                  callback={_handlePurchase}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
