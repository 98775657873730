

import React, { useEffect, useState} from "react";

import styled from 'styled-components';

import '../../components/css/common.css';
import { imageDB } from "../../components/imageDB";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import Fade from 'react-reveal/Fade';


// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { getBanner, getMainBanner } from "../../utility/bannerfirebase";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Autoplay]); // *


const swiperStyle={
  position :"relative",
  width :'100%',
}

const MainBannerText = styled.div`
  font-size: 30px;
  position: absolute;
  top: 60%;
  z-index: 10;
  left: 5%;
  width: 40%;
  color: #000;
  font-weight:900;
  font-family: Pretendard-Bold;
`

const Ad = () => {
  const [banneritems, setBanneritems] = useState([]);
  const [refresh, setRefresh] = useState(1);
  useEffect(()=>{
    async function fetchData(){
      let bannerlist = [];
      let bannerlistTmp = [];
      bannerlistTmp = await getMainBanner();


      bannerlistTmp.forEach(element => {
        if(element.DISPOSE == true){
          bannerlist.push(element);
        }
      });

      setBanneritems(bannerlist);

      // console.log("bannerlist", bannerlist);
    }

    fetchData();
	}, [refresh]);

  return (

    <div style={{display:"flex", flexDirection:"row", width:"100%", backgroundColor:"white"}}>


    <Fade bottom delay={1000}>
    <MainBannerText></MainBannerText>
    </Fade>



    </div>
  
  );
};

export default Ad;
